import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { deleteUpload, getUploads } from '@shared/actions'
import { isLoadingUploads, getParsedUploads, getUploadsResources } from '@selectors/uploads'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { users: usersById } = getUploadsResources(state)
  const uploads = getParsedUploads(state)
  const uploadsLoading = isLoadingUploads(state)
  const loading = uploadsLoading && uploads.length === 0

  return {
    idOrg,
    usersById,
    uploads,
    loading
  }
}

const mapDispatchToProps = {
  deleteUpload,
  getUploads
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
