import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentApp, isAppDetailsValuesLoading, isAppsLoading } from '@selectors/apps'
import { getAppDetailsFields } from '@shared/actions'
import { EMPTY_OBJECT } from '@root/constants'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { app = EMPTY_OBJECT } = getCurrentApp(state) || EMPTY_OBJECT

  return {
    idOrg,
    idApp,
    expensesSources: app?.expensesSources,
    isInUse: !isEmpty(app),
    loading: isAppDetailsValuesLoading(state) || isAppsLoading(state)
  }
}

const mapDispatchToProps = {
  getAppDetailsFields
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
