import React, { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { Spacer } from '@toriihq/design-system'
import BackAndForwardNavigator from '@components/BackAndForwardNavigator'
import { useWorkflowEditorContext } from '../context'
import { useSelectedWorkflow } from '../../hooks/useSelectedWorkflow'
import { getWorkflowsAuditLogsBackAndForwardNavigatorOptions } from '@selectors/workflows'
import { ToolbarTitle } from './styles'
import { getWorkflowPathPrefix } from '@root/lenses/workflows'

export const WorkflowAuditToolbar = (): ReactElement => {
  const workflow = useSelectedWorkflow()
  const idWorkflow = workflow.id
  const { isPreviewMode, idAudit } = useWorkflowEditorContext()
  const backAndForwardNavigatorOptions: {
    linkSuffix: number
    label: string
  }[] = useSelector(getWorkflowsAuditLogsBackAndForwardNavigatorOptions)[idWorkflow] ?? []

  const shouldRender = Boolean(isPreviewMode && backAndForwardNavigatorOptions.length > 0)

  const auditIndex = idAudit
    ? backAndForwardNavigatorOptions.findIndex(audit => audit.linkSuffix === idAudit)
    : 0

  return shouldRender ? <Spacer top={'space-250'}>
    <ToolbarTitle>Viewing workflow edit history</ToolbarTitle>
    <BackAndForwardNavigator
      analyticsEvent='Navigate workflow edit history'
      label=''
      searchParams='?previewId='
      linkPrefix={`/${getWorkflowPathPrefix({ workflowType: workflow.type, triggerType: null })}/${idWorkflow}`}
      options={backAndForwardNavigatorOptions}
      optionIndex={auditIndex} />
  </Spacer> : <></>
}
