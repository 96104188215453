import { css } from 'glamor'
import { theme } from '@toriihq/design-system'

export const borderStyle = css({
  border: 'none',
  transition: 'outline .2s, box-shadow .2s, background-color .2s',
  borderRadius: theme.corner('radius-100'),
  outline: `1px solid ${theme.palette.border.primary} !important`,
  ':hover:not([disabled])': {
    outline: `1px solid ${theme.palette.border.primaryHover} !important`
  },
  ':focus-visible:not([disabled])': {
    outline: `1px solid ${theme.palette.border.active} !important`,
    boxShadow: `0 0 0 3px ${theme.palette.border.focus} !important`
  },
  '[disabled]': {
    cursor: 'not-allowed',
    backgroundColor: `${theme.palette.background.disabledLight} !important`,
    color: theme.palette.text.disabled,
    '::placeholder': {
      color: theme.palette.text.disabled
    }
  }
})

export const roundedBorderStyle = css({
  borderRadius: '6px'
})

export const textStyle = css({
  font: theme.typography.font.body02
})

export const sizeStyle = css({
  minHeight: '30px',
  width: '100%',
  padding: '0 15px'
})

export const controlStyle = css(
  borderStyle,
  textStyle,
  sizeStyle
)
