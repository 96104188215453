import * as Style from './style'
import React, { ReactElement } from 'react'
import { WORKFLOW_NODE_TYPE, Workflow } from '@shared/workflows/types'
import { EMPTY_OBJECT } from '@root/constants'
import { get, isNil } from 'lodash'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { useTriggerConfiguration } from '@pages/workflow_v2/hooks/useTriggerConfiguration'
import { TriggerConfiguration } from '@pages/workflow_v2/triggerConfiguration'
import { ActionConfiguration } from '@pages/workflow_v2/actionConfiguration'
import { useSelectNodeStep } from '@pages/workflow_v2/hooks/useSelectNodeStep'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'
import { WORKFLOW_STEP_TYPE } from '@shared/types'
import useScrollToTopOnChange from '@root/shared/hooks/useScrollToTopOnChange'
import { DrawerHeader } from './drawerHeader'
import { DrawerNavigation } from './drawerNavigation'
import { DrawerFooter } from './drawerFooter'
import { DrawerSteps } from './types'

interface Props {
  workflow: Workflow
}

const getNodeContentAndTitle = ({ idSelectedNode, workflow, stepIndex, selectedActionConfiguration, triggerLabel, isTriggerValid }) => {
  if (!idSelectedNode) {
    return {}
  }

  if (idSelectedNode === 'trigger') {
    return {
      title: triggerLabel,
      content: workflow?.triggerConfiguration,
      isValid: isTriggerValid,
      step: <TriggerConfiguration
        workflow={workflow}
      />
    }
  }

  const workflowNodes = workflow?.actions?.nodes || EMPTY_OBJECT
  const content = workflowNodes[idSelectedNode]
  const steps = selectedActionConfiguration?.steps || []
  return {
    title: selectedActionConfiguration?.label,
    step: <ActionConfiguration
      workflow={workflow}
      stepType={steps[Number(stepIndex)]?.type}
      stepIndex={Number(stepIndex)}
      idAction={idSelectedNode} />,
    content: stepIndex === '0' ? content : JSON.stringify(steps[stepIndex], null, 2),
    isValid: get(selectedActionConfiguration, ['isValid'], true)
  }
}

export const WorkflowDrawer = ({ workflow }: Props): ReactElement => {
  const { idSelectedNode, idSelectedNodeStep, selectNodeStep } = useWorkflowNavigation()
  const {
    getActionConfiguration,
    getActionSteps,
    isActionTypeSelected,
    doesActionExist
  } = useActionsConfiguration({ workflow })
  const { triggerLabel, isTriggerValid, triggerSteps } = useTriggerConfiguration({ workflow })
  const drawerContentDivRef = useScrollToTopOnChange([idSelectedNodeStep])

  const selectedActionConfiguration = idSelectedNode ? getActionConfiguration(idSelectedNode) : undefined
  const isTriggerNode = idSelectedNode === ID_TRIGGER_NODE
  const isDrawerOpen = !isNil(idSelectedNode) && (isTriggerNode || doesActionExist(idSelectedNode))
  const actionSelected = Boolean(idSelectedNode && isActionTypeSelected(idSelectedNode))

  useSelectNodeStep({ idSelectedNode, idSelectedNodeStep, isTriggerNode, selectNodeStep, isDrawerOpen, actionSelected })

  const getDrawerSteps = (): DrawerSteps => isTriggerNode ? triggerSteps : getActionSteps(idSelectedNode!)
  const isActionSelectionStep = () => {
    if (!isDrawerOpen || isTriggerNode) {
      return false
    }
    const actionSteps = getActionSteps(idSelectedNode)
    const actionStep = idSelectedNodeStep && actionSteps?.[idSelectedNodeStep]
    const isStepSetup = actionStep?.type === WORKFLOW_STEP_TYPE.STEP_SETUP
    const isNotActionStepSetup = !isStepSetup
    return isNotActionStepSetup
  }

  const hideDrawerNavigation = !isDrawerOpen || isTriggerNode
  const hideDrawerFooter = !isDrawerOpen || isActionSelectionStep()
  const hideContinueOnError = isTriggerNode || selectedActionConfiguration?.type !== WORKFLOW_NODE_TYPE.ACTION
  const nodeContentAndTitle = isDrawerOpen ? getNodeContentAndTitle({
    idSelectedNode,
    workflow,
    stepIndex: idSelectedNodeStep,
    selectedActionConfiguration,
    triggerLabel,
    isTriggerValid
  }) : undefined

  return (
    <Style.DrawerContainer isOpen={isDrawerOpen}>
      <DrawerHeader
        title={nodeContentAndTitle?.title}
        isValid={nodeContentAndTitle?.isValid}
        idNode={idSelectedNode!}
      />
      {!hideDrawerNavigation && <DrawerNavigation steps={getActionSteps(idSelectedNode!)} />}
      {idSelectedNodeStep && <Style.DrawerContent ref={drawerContentDivRef}>
        {nodeContentAndTitle?.step}
      </Style.DrawerContent>}
      {!hideDrawerFooter && <DrawerFooter steps={getDrawerSteps()} hideContinueOnErrorToggle={hideContinueOnError} />}
    </Style.DrawerContainer>
  )
}
