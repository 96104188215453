import View from './view'
import { connect } from 'react-redux'
import { toggleRolePopup, getRole, getScopes, createRole, updateRole, getRoles } from '../../../shared/actions'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '../../../selectors/me'
import { isLoadingScopes, getRoles as getRolesSelector, getFormattedReadScopesInfo, getFormattedScopesInfo, getRoleInfo, isLoadingRoleInfo } from '../../../selectors/roles'
import { getIsSmallScreen } from '../../../selectors/ui'
import { SCOPES_PERMISSIONS } from '../../../constants'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { isOpen, idRole, isReadOnly } = state.ui.rolePopup

  const defaultRoleInfo = {
    name: '',
    description: '',
    scopes: getFormattedReadScopesInfo(state)
  }

  const formattedRoleInfo = {
    ...getRoleInfo(state),
    scopes: getFormattedScopesInfo(state)
  }

  const roles = getRolesSelector(state)

  const roleInfo = idRole && !isLoadingRoleInfo(state) ? formattedRoleInfo : defaultRoleInfo

  const loading = isLoadingScopes(state) || (idRole && isLoadingRoleInfo(state))

  return {
    isOpen,
    idOrg,
    idRole,
    isReadOnly,
    loading,
    roleInfo,
    roles,
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  toggleRolePopup,
  getRole,
  getRoles,
  getScopes,
  createRole,
  updateRole
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleRolePopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleRolePopup({ isOpen: false })
  },
  createRole: ({ name, description, scopes }) => {
    return dispatchProps.createRole({ idOrg: stateProps.idOrg, name, description, scopes })
  },
  updateRole: ({ name, description, scopes }) => {
    const { idRole } = stateProps
    return dispatchProps.updateRole({ idOrg: stateProps.idOrg, name, description, scopes, idRole })
  },
  onSuccess: () => {
    const SUCCESS_DELAY = 1000
    setTimeout(dispatchProps.toggleRolePopup({ isOpen: false }), SUCCESS_DELAY)
  },
  createRoleScopesConfig: (scopesInfo) => {
    return scopesInfo.reduce((scopes, scopeInfo) => {
      if (scopeInfo.permission === SCOPES_PERMISSIONS.NONE) {
        scopes.push(`${scopeInfo.id}:${SCOPES_PERMISSIONS.NONE}`)
      } else {
        scopes.push(`${scopeInfo.id}:${SCOPES_PERMISSIONS.READ}`)
      }

      if (scopeInfo.permission === SCOPES_PERMISSIONS.WRITE) {
        scopes.push(`${scopeInfo.id}:${SCOPES_PERMISSIONS.WRITE}`)
      }

      return scopes
    }, [])
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
