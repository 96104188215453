import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getLifecycleApps,
  getLifecyclePreview, getOrg,
  toggleUserLifecycleSettings,
  updateLifecycleSettings
} from '@shared/actions'
import { getIsUserLifecycleSettingsOpen } from '@selectors/ui'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { getCurrentOrg } from '@selectors/me'
import { getLifecycleSettings } from '@selectors/org'
import { SCOPES } from '@root/constants'
import { UserLifecycleSettingsContext, UserLifecycleSettingsProvider } from './UserLifecycleSettingsContext'
import Analytics from '@helpers/analytics'
import * as Style from './style'
import { UserLifecycleSelect } from '@components/popups/userLifecycleSettingsPopup/userLifecycleSelect'
import { UserLifecyclePreview } from '@components/popups/userLifecycleSettingsPopup/userLifecyclePreview'
import { UserLifecycleComplete } from '@components/popups/userLifecycleSettingsPopup/userLifecycleComplete'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'

const Popup = ({ userLifecycleConfig }) => {
  const isUserLifecycleSettingsOpen = useSelector(getIsUserLifecycleSettingsOpen)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const dispatch = useDispatch()

  const { isPreviewEnable, selectedLifecycleApps } = useContext(UserLifecycleSettingsContext)

  const [step, setStep] = useState(0)

  useEffect(() => {
    if (idOrg) {
      dispatch(getLifecycleApps({ idOrg }))
    }
  }, [dispatch, idOrg])

  const onBack = () => setStep(0)

  const onPreview = () => {
    Analytics.track('Click on edit user lifecycle preview')
    dispatch(getLifecyclePreview({ idOrg, lifecycleApps: selectedLifecycleApps }))
    setStep(1)
  }

  const onApply = () => {
    Analytics.track('Click on edit user lifecycle apply changes')
    dispatch(updateLifecycleSettings({ idOrg, userLifecycleConfig: selectedLifecycleApps }))
    dispatch(getOrg({ idOrg }))
    setStep(2)
  }

  const onCancel = () => {
    dispatch(toggleUserLifecycleSettings(false))
  }

  const onClose = () => {
    dispatch(toggleUserLifecycleSettings(false, false))
  }

  const onSuccess = () => {
    const SUCCESS_DELAY = 1000
    setTimeout(onClose, SUCCESS_DELAY)
  }

  const headerInfo = [
    {
      header: 'Select an app to base user lifecycle status on',
      subHeader: 'Users will be set as past users based on their status in the selected apps. This will add them to the offboarding to-do list and mark their licenses as inactive'
    },
    {
      header: 'Preview changes'
    },
    {
      header: 'Changes applied'
    }
  ]

  const footerInfo = [
    {
      isBackButtonDisabled: true,
      showCancelButton: true,
      cancelText: 'Cancel',
      mainButtonText: 'Preview changes',
      mainButtonAction: onPreview
    },
    {
      isBackButtonDisabled: false,
      showCancelButton: true,
      cancelText: 'Cancel',
      mainButtonText: 'Apply changes',
      mainButtonAction: onApply
    },
    {
      isBackButtonDisabled: true,
      showCancelButton: false,
      mainButtonText: 'Close',
      mainButtonAction: onSuccess
    }
  ]

  return (
    <ToriiPopup isOpen={isUserLifecycleSettingsOpen} onCloseAction={onCancel} styles={Style.Popup} contentAreaStyle={Style.MainArea}>
      <ToriiPopup.Header header={headerInfo[step].header} subHeader={headerInfo[step].subHeader} />
      <ToriiPopup.Content>
        {step === 0 && <UserLifecycleSelect userLifecycleConfig={userLifecycleConfig} />}
        {step === 1 && <UserLifecyclePreview selectedLifecycleApps={selectedLifecycleApps} />}
        {step === 2 && <UserLifecycleComplete />}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        scopes={[SCOPES.SETTINGS_WRITE]}
        showPageIndex
        currentPage={step + 1}
        numberOfPages={3}
        showBackButton
        backButtonAction={onBack}
        isBackButtonDisabled={footerInfo[step].isBackButtonDisabled}
        showCancelButton={footerInfo[step].showCancelButton}
        cancelButtonText={'Cancel'}
        mainButtonText={footerInfo[step].mainButtonText}
        mainButtonAction={footerInfo[step].mainButtonAction}
        isMainButtonDisabled={!isPreviewEnable || isEqual(sortBy(userLifecycleConfig, 'idApp'), sortBy(selectedLifecycleApps, 'idApp'))}
        turnOverOutlineButtonWithLinkButton
      />
    </ToriiPopup>
  )
}

const UserLifecycleSettingsPopup = () => {
  const userLifecycleConfig = useSelector(getLifecycleSettings)

  return (
    <UserLifecycleSettingsProvider userLifecycleConfig={userLifecycleConfig}>
      <Popup userLifecycleConfig={userLifecycleConfig} />
    </UserLifecycleSettingsProvider>
  )
}

export default UserLifecycleSettingsPopup
