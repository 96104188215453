import styled from 'styled-components'

export namespace Styles {
  export const Wrapper = styled.div``

  export const RuleAppDetails = styled.div`
    flex-grow: 1;
    font-size: 13px;
  `

  export const RuleApp = styled.div`
    display: flex;
    align-items: center;
    padding: 14px 18px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
    &:hover button > div {
      opacity: 1;
    }
  `

  export const ActionButtons = styled.div`
    & > button > div {
      display: flex;
      opacity: 0;
    }
  `
}
