import styled from 'styled-components'
import { margin } from '@shared/style/sizes'

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center
`

export const EmptyStateText = styled.div`
  margin-top: ${margin.large};
  color: ${({ theme }) => theme.palette.text.tertiary}
`

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`
