import { PopupStepConfig } from '../../wizardPopup/types'
import { AddOrEditContract } from './addOrEditContract'
import NewContractAI from './newContractAI'
import { SharedProps, SharedState } from './types'

export const stepsConfig = (isNewContract: boolean, hasContractAIInPlan: boolean): PopupStepConfig<SharedState, SharedProps>[] => {
  const steps = [{ stepRenderer: AddOrEditContract }]
  if (isNewContract && hasContractAIInPlan) {
    steps.unshift({ stepRenderer: NewContractAI })
  }
  return steps
}
