import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { css } from 'glamor'

const BAR_HEIGHT = '65px'
const SMALL_SCREEN_WIDTH = '1025px'

export const Main = css(texts.body, {
  paddingLeft: '47px',
  paddingRight: '60px',
  background: colors.white,
  color: colors.blue,
  height: BAR_HEIGHT,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderTopLeftRadius: '24px',
  [`@media(max-width: ${SMALL_SCREEN_WIDTH})`]: {
    paddingLeft: '0px',
    paddingRight: '20px',
    borderTopLeftRadius: '0px'
  },
  borderBottom: window.scrollY > 0 ? `1px solid ${colors.border}` : 'none'
})

export const Logo = css({
  position: 'absolute',
  left: 0,
  top: 0,
  height: BAR_HEIGHT,
  width: '200px',
  paddingLeft: '18px',
  display: 'flex',
  alignItems: 'center'
})

export const SearchContainer = css({
  margin: '0 auto',
  justifyContent: 'space-between',
  display: 'flex',
  width: '100%'
})

export const SearchContainerSmallScreen = css({
  padding: 0
})

export const RightBarButtons = css({
  display: 'flex',
  alignItems: 'center',
  '> *:not(:last-child)': {
    marginRight: '5px'
  }
})
