import { connect } from 'react-redux'
import View from './view'
import { searchUsers } from '@shared/actions'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/me'

const mapStateToProps = (state, ownProps) => {
  const currentOrg = getCurrentOrg(state)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10) || currentOrg.id

  return {
    idOrg
  }
}

const mapDispatchToProps = {
  searchUsers
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
