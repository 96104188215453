import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getWorkflowActionExecutionsById } from '@lenses/workflows'
import {
  getActionsConfigByType,
  getAppCatalogPoliciesByIdSelector,
  getWorkflowsById,
  isLoadingAppCatalogPoliciesSelector,
  isLoadingWorkflows
} from '@selectors/workflows'
import { getAppDetailsFieldsByIdField } from '@selectors/apps'
import range from 'lodash/range'
import { EMPTY_OBJECT, WORKFLOW_TYPES } from '@root/constants'
import { getWorkflowActionExecutions, getWorkflowsActionsConfig, getContractsFields } from '@shared/actions'
import { getContractsFieldsById } from '@selectors/contracts'
import { getWorkflow } from '@shared/actions/workflows'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idWorkflow = parseInt(ownProps.match.params.idWorkflow, 10)
  const idWorkflowExecution = parseInt(ownProps.match.params.idWorkflowExecution, 10)

  const workflow = (
    ownProps.isAppCatalogPoliciesView ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow] : getWorkflowsById(state)[idWorkflow]
  ) || EMPTY_OBJECT

  const loadingPolicies = isLoadingAppCatalogPoliciesSelector(state)
  const loadingWorkflows = isLoadingWorkflows(state)

  const actions = getWorkflowActionExecutionsById(state, idWorkflowExecution)
  const actionsByType = getActionsConfigByType(state) || EMPTY_OBJECT

  const appFields = getAppDetailsFieldsByIdField(state) || EMPTY_OBJECT
  const contractsFields = getContractsFieldsById(state) || EMPTY_OBJECT

  const loading = (loadingWorkflows || loadingPolicies) && (actions.length === 0)

  return {
    idOrg,
    idWorkflow,
    idWorkflowExecution,
    workflow,
    actionsByType,
    appFields,
    actions: loading ? range(3).map(() => ({})) : actions,
    loading,
    showTriggeredBy: Boolean(workflow && workflow.type === WORKFLOW_TYPES.manual),
    contractsFields
  }
}

const mapDispatchToProps = {
  getWorkflow,
  getWorkflowActionExecutions,
  getWorkflowsActionsConfig,
  getContractsFields
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
