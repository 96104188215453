import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  deprecatedGetApps,
  getAppsPermissions,
  getRiskLevel,
  getSourcesWithApps,
  isAppPermissionsLoading,
  isLoadingApps
} from '../../../selectors/apps'
import isEmpty from 'lodash/isEmpty'

const mapStateToProps = (state, ownProps) => {
  const sourcesWithApps = ownProps.idApp && getSourcesWithApps(state)
  const riskLevel = !ownProps.idApp && getRiskLevel(state)
  const hasData = Boolean(ownProps.idApp ? sourcesWithApps && Object.keys(sourcesWithApps).length > 0 : riskLevel && riskLevel.length)
  const loading = (isLoadingApps(state) && isEmpty(deprecatedGetApps(state))) || (isAppPermissionsLoading(state) && isEmpty(getAppsPermissions(state)))

  return {
    loading,
    hasData,
    sourcesWithApps,
    riskLevel
  }
}

export default withRouter(connect(mapStateToProps, null)(View))
