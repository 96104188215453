import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { USER_SETTINGS } from '../components/settings/settings'
import { EMAIL_NOTIFICATION_FREQUENCY } from '../constants'

export const getSettings = createSelector(
  state => get(state, ['userSettings', 'userSettings'], {}),
  identity
)

export const getMappedSettings = createSelector(
  getSettings,
  settings => USER_SETTINGS.map(setting => ({
    ...setting,
    frequency: settings[setting.id] || EMAIL_NOTIFICATION_FREQUENCY.DISABLED
  }))
)
