import React, { useState } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import * as Style from './style'
import EnableFor from '@components/enableFor'
import { EMPTY_ARRAY, SCOPES, TRIGGER_TYPE_TO_ITEM } from '@root/constants'
import { RadioButton, toast, ToastType } from '@toriihq/design-system'
import isUndefined from 'lodash/isUndefined'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { getTriggerPreview as getTriggerPreviewSelector, isLoadingTriggerPreview } from '@selectors/workflows'
import {
  toggleActivateWorkflowPopup,
  toggleWorkflowStartedPopup
} from '@shared/actions'
import pluralize from 'pluralize'
import Analytics from '@helpers/analytics'
import emptyImage from '@components/workflows/workflowsTable/images/workflows.svg'
import TriggerPreviewTable from '@components/popups/triggerPreviewPopup/triggerPreviewTable'
import { getTriggerPreviewPropsByTriggerType } from '@lenses/workflows'
import Spinner from '@media/spinner.gif'
import { updateWorkflow } from '@shared/actions/workflows'

const ActivateWorkflowPopup = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, idWorkflow, workflowName, triggerType } = useSelector(state => state.ui.activateWorkflowPopup)

  const { data: previewData } = (useSelector(getTriggerPreviewSelector) || {})[idWorkflow] || { data: EMPTY_ARRAY }

  const { getTheadThProps, getPreviewCountText } = getTriggerPreviewPropsByTriggerType(triggerType)

  const previewEntity = TRIGGER_TYPE_TO_ITEM[triggerType].toLowerCase()
  const previewEntitiesAmount = previewData.length

  const pluralizedPreviewEntities = pluralize(previewEntity)

  const loading = useSelector(isLoadingTriggerPreview)

  const dispatch = useDispatch()

  const [shouldRunWorkflowRetroactively, setShouldRunWorkflowRetroactively] = useState(undefined)

  const regularWorkflowRun = `No, only enroll ${pluralizedPreviewEntities} that will meet the criteria after the workflow is activated`
  const retroactivelyWorkflowRun = `Yes, run the workflow on ${pluralizedPreviewEntities} that meet the criteria now and on future ${pluralizedPreviewEntities}`

  const workflowRunOptions = [
    { value: false, label: regularWorkflowRun },
    { value: true, label: retroactivelyWorkflowRun }
  ]

  const popupHeader = 'Activate Workflow'

  const onActivation = async () => {
    Analytics.track(`Activate workflow-from-dialog`, {
      'Entities': previewEntitiesAmount,
      'Entity type': previewEntity,
      'Trigger name': triggerType,
      'Radio button selection': shouldRunWorkflowRetroactively ? 'Yes' : 'No',
      'Workflow ID': idWorkflow
    })

    const onUpdateSuccess = () => {
      dispatch(toggleActivateWorkflowPopup({ isOpen: false }))
      toast({
        message: shouldRunWorkflowRetroactively ? 'The workflow was activated' : 'The workflow was activated. It will run on entities that will meet the trigger criteria in the future.',
        type: ToastType.SUCCESS
      })

      if (shouldRunWorkflowRetroactively) {
        dispatch(toggleWorkflowStartedPopup({ isOpen: true, idWorkflow, workflowName, triggerType, referrer: popupHeader, entitiesAmount: previewEntitiesAmount }))
      }
    }

    const workflowRunParams = shouldRunWorkflowRetroactively ? { executeWorkflow: true, previewEntitiesAmount } : { previewEntitiesAmount }
    return dispatch(updateWorkflow({
      idOrg,
      idWorkflow,
      workflow: { isActive: 1 },
      ...workflowRunParams
    })).then(() => setTimeout(onUpdateSuccess, 1000))
  }

  const onCancel = ({ buttonLabel }) => {
    Analytics.track(`Close activate-workflow-dialog`, {
      'Trigger name': triggerType,
      'Button label': buttonLabel,
      'Workflow ID': idWorkflow
    })

    dispatch(toggleActivateWorkflowPopup({ isOpen: false }))
  }

  const loadingState =
    <div {...Style.LoadingContainer}>
      <img alt='Loading...' src={Spinner} width='30px' height='30px' />
      <div {...Style.LoadingText}>Torii is thinking...</div>
    </div>

  const emptyState =
    <div {...Style.EmptyStateContainer}>
      <span {...Style.EmptyStateTextContainer}>
        <div {...Style.EmptyStateTitle}>One more thing you should know</div>
        <div>
          <div {...Style.EmptyStateNoEntitiesSentence}>{`Torii did not find any ${pluralizedPreviewEntities} that meet the criteria now.`}</div>
          <div>{`When activated, the workflow will wait for ${pluralizedPreviewEntities} that meet the criteria and enroll them once they appear.`}</div>
        </div>
      </span>
      <img src={emptyImage} alt={`No ${pluralizedPreviewEntities} meet the criteria now`} height='85px' width='137px' />
    </div>

  const workflowRunSelection = <div>
    <div {...Style.SelectionQuestion}>{`Torii found ${pluralizedPreviewEntities} that meet the trigger criteria now.  Do you want to enroll them in the workflow?`}</div>
    <div {...Style.SelectionsContainer}>
      {workflowRunOptions.map((option, i) => (<span key={i} {...Style.SelectionBox} onClick={() => setShouldRunWorkflowRetroactively(option.value)}>
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <RadioButton
            id={`activation-option-${option.value}`}
            checked={shouldRunWorkflowRetroactively === option.value}
            labelSize='small'
            label={option.label}
          />
        </EnableFor>
      </span>))
      }
    </div>
    <div {...Style.PreviewCountText}>
      {getPreviewCountText({ previewEntity, previewEntitiesAmount })}
    </div>
    <TriggerPreviewTable idWorkflow={idWorkflow} showTableTitle={false} overrideTdStyle={Style.PreviewTableTd} overrideTableStyle={Style.PreviewTable} usePaging={false} getTheadThProps={getTheadThProps} />
  </div>

  return <ToriiPopup
    isOpen={isOpen}
    onCloseAction={() => onCancel({ buttonLabel: 'X' })}
    styles={loading || !previewEntitiesAmount ? Style.LoadingOrNoEntitiesPopup : Style.WorkflowActivationSelectionPopup}
  >
    <ToriiPopup.Header header={popupHeader} subHeader={workflowName} />
    <ToriiPopup.Content contentAreaStyle={Style.PopupContent}>
      {loading ? loadingState : (previewEntitiesAmount ? workflowRunSelection : emptyState)}
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      showCancelButton
      cancelButtonText='Cancel'
      cancelButtonAction={() => onCancel({ buttonLabel: 'Cancel' })}
      mainButtonAction={onActivation}
      mainButtonText={'Activate'}
      isMainButtonDisabled={loading || (Boolean(previewEntitiesAmount) && isUndefined(shouldRunWorkflowRetroactively))}
    />
  </ToriiPopup>
}

export default ActivateWorkflowPopup
