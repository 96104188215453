import styled from 'styled-components'
import { css } from 'glamor'

export const CurrencyAndDate = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  padding: 0;
  height: 68px;
  margin-bottom: 15px;
`

export const DateSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 1px;
`

export const CurrencySelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const LabelStyle = css({
  height: '20px',
  '> div': {
    display: 'flex',
    alignItems: 'center'
  }
})
