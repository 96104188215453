import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '../types'
import { Content } from './style'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { useDispatch } from 'react-redux'
import { getSyncDataForAppAccount, toggleImportUsers, updateImportAppUsersStatus } from '@root/shared/actions'
import useEffectOnce from '@root/shared/hooks/useEffectOnce'
import { Spacer, Spinner, SpinnerColor, Subtitle2 } from '@toriihq/design-system'
import { IMPORT_APP_USERS_STATUS } from '@root/reducers/appUsers/types'
import { UsePolling } from '../usePolling'
import { SYNC_STATUS } from '@root/constants'

const LoadingAfterImport = ({
  sharedState, sharedProps
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const dispatch = useDispatch()

  const onPollFailure = () => {
    clearPolling()
  }

  const { startPolling, clearPolling } = UsePolling({
    onPollFailure
  })

  const pollLogic = async ({ idOrg, idAppAccount, lastSyncTime }) => {
    const response = await dispatch(getSyncDataForAppAccount({ idOrg, idAppAccount }))
    const { syncStatus } = response
    const { syncStatus: appAccountSyncStatus, lastSyncTime: newSyncTime } = syncStatus[0]
    const isFinishLaterSync = lastSyncTime ? lastSyncTime < newSyncTime : true
    if (appAccountSyncStatus === SYNC_STATUS.FINISHED_SUCCESSFULLY && isFinishLaterSync) {
      const SECONDS_TO_WAIT_AFTER_FINISH = 3
      clearPolling()
      dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_IN_PROGRESS))
      setTimeout(() => {
        dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED))
        closePopup()
      }, SECONDS_TO_WAIT_AFTER_FINISH * 1000)
    } else if (appAccountSyncStatus === SYNC_STATUS.FINISHED_FAILED) {
      throw new Error('error parsing')
    }
  }

  useEffectOnce(() => {
    const fetchData = async () => {
      const { idOrg, lastSyncTime } = sharedProps
      const { idAppAccount } = sharedState
      try {
        await startPolling(() => pollLogic({ idOrg, lastSyncTime, idAppAccount: idAppAccount! }))
      } catch (error) {
        throw error
      }
    }
    fetchData()
  })

  const closePopup = () => {
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  return (
    <>
      <ToriiPopup.Header header='Importing users' />
      <ToriiPopup.Content>
        <Content>
          <Spinner color={SpinnerColor.active} />
          <Spacer top='space-200'>
            <Subtitle2>Importing your users list...</Subtitle2>
          </Spacer>
        </Content>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showMainButton={false}
        showCancelButton={false}
      />
    </>
  )
}

export default LoadingAfterImport
