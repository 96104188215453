import {
  GET_UPLOAD,
  GET_UPLOADS,
  DELETE_UPLOAD
} from '../constants'
import { mergeArraysByKey } from '../lenses/utils'

const initialState = {
  loading: false,
  uploads: [],
  resources: { users: {} }
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_UPLOAD}_PENDING`:
    case `${GET_UPLOADS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_UPLOAD}_FAILED`:
    case `${GET_UPLOADS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_UPLOAD}_RESPONSE`: {
      const { upload, resources } = action.payload
      return {
        ...state,
        loading: false,
        uploads: mergeArraysByKey(state.syncStatus, [upload], 'id'),
        resources
      }
    }
    case `${GET_UPLOADS}_RESPONSE`: {
      const { uploads, resources } = action.payload
      return {
        ...state,
        loading: false,
        uploads: mergeArraysByKey(state.syncStatus, uploads, 'id'),
        resources
      }
    }
    case `${DELETE_UPLOAD}_RESPONSE`: {
      const { idUpload } = action.meta
      return {
        ...state,
        uploads: state.uploads.filter(upload => upload.id !== idUpload)
      }
    }
    default: {
      return state
    }
  }
}

export default reducer
