import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { SCOPES } from '@root/constants'
import { Button } from '@pages/workflow_v2/workflowEditor/workflowGraph/components/edges/edgeWithAddButton/styles'
import { Icon } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import { getNewEmptyAction } from '@shared/workflows/actions/utils/getNewEmptyAction'
import { addActionNode } from '@shared/workflows/actions/utils/addActionNode'
import { EMPTY_ACTIONS_MODEL } from '@shared/workflows/actions/consts'
import { updateWorkflow } from '@shared/actions/workflows'
import { isUpdatingWorkflows } from '@selectors/workflows'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { getNodeDescendants } from '@shared/workflows/actions/utils/getNodeDescendants'
import { analytics } from '@shared/services/workflows/analytics'

interface Props {
  idParentNode: string
}

export const AddActionButton = ({ idParentNode }: Props): ReactElement => {
  const { idWorkflow, idOrg } = useParams()
  const workflow = useSelectedWorkflow()
  const isUpdateInProgress = useSelector(isUpdatingWorkflows)
  const dispatch = useDispatch()
  const { selectNode } = useWorkflowNavigation()
  const { setIdNewNode } = useWorkflowEditorContext()

  const handleClick = () => {
    if (!isUpdateInProgress) {
      addNewAction()
    }
  }

  const addNewAction = () => {
    const actions = workflow.actions ?? EMPTY_ACTIONS_MODEL
    const newAction = getNewEmptyAction()
    const {
      idNewActionNode,
      actions: updatedActions
    } = addActionNode({
      actions,
      idParentUiNode: idParentNode,
      action: newAction
    })
    const updatedWorkflow = { ...workflow, actions: updatedActions }
    dispatch(updateWorkflow({
      idOrg: Number(idOrg),
      idWorkflow: Number(idWorkflow),
      workflow: updatedWorkflow
    }))
    selectNode(idNewActionNode)
    setIdNewNode(idNewActionNode)

    const nodeDescendants = getNodeDescendants(idNewActionNode, updatedWorkflow.actions.nodes)
    analytics.action.add({
      actionLocation: nodeDescendants.length > 1 ? 'In the middle' : 'Last'
    })
  }

  return (
    <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <Button onClick={handleClick} isUpdateInProgress={isUpdateInProgress}>
        <Icon name='Plus' />
      </Button>
    </EnableFor>
  )
}
