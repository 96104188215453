import View from './view'
import { connect } from 'react-redux'
import { toggleSelectFieldTypePopup } from '../../shared/actions'
import { getIsSmallScreen } from '../../selectors/ui'
import { getCurrentOrg } from '../../selectors/me'

const mapStateToProps = (state, ownProps) => {
  const { allowedScopes = [] } = ownProps
  const { id: idOrg } = getCurrentOrg(state)

  return {
    isSmallScreen: getIsSmallScreen(state),
    allowedScopes,
    idOrg
  }
}

const mapDispatchToProps = {
  toggleSelectFieldTypePopup
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
