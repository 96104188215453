import View from './view'
import { connect } from 'react-redux'
import {
  getServicesSyncData,
  getServicesConfig,
  toggleConnectService,
  deprecatedGetApps,
  toggleConnectCustomService,
  toggleConnectSCIMService,
  getSupportedFeatures,
  toggleConnectSource,
  getAppsV2 as getAppsV2Action
} from '@shared/actions'
import { getServicesWithConnectStatusMultipleAccounts, getSyncStatus, getNeedsAttentionServices } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import { INTEGRATION_CATEGORY } from '@root/constants'
import { getAppsV2 } from '@selectors/apps'
import { hasTestConnectionInUrl } from '@components/testConnection/utils'
import { getTestConnectionInfo } from '@shared/actions/testConnection'
import { range } from 'lodash'
import AccessControl from '@lenses/accessControl'
import { FEATURES } from '@shared/features'

const loadingServices = () => {
  const connected = range(4).map((service, index) => ({ source: index.toString(), name: index.toString(), integrationCategory: INTEGRATION_CATEGORY.OTHER, isConnected: true }))
  const identityProviders = range(4).map((service, index) => ({ source: index.toString(), name: index.toString(), integrationCategory: INTEGRATION_CATEGORY.IDENTITY_PROVIDER }))
  const humanResource = range(4).map((service, index) => ({ source: index.toString(), name: index.toString(), integrationCategory: INTEGRATION_CATEGORY.HUMAN_RESOURCE }))
  const expenseSoftware = range(4).map((service, index) => ({ source: index.toString(), name: index.toString(), integrationCategory: INTEGRATION_CATEGORY.EXPENSE_SOFTWARE }))
  const others = range(4).map((service, index) => ({ source: index.toString(), name: index.toString(), integrationCategory: INTEGRATION_CATEGORY.OTHER }))

  return [...connected, ...identityProviders, ...humanResource, ...expenseSoftware, ...others]
}

const mapStateToProps = (state, ownProps) => {
  const idOrg = ownProps.idOrg || (ownProps.match.params.idOrg && parseInt(ownProps.match.params.idOrg)) || null
  const { config } = state.services
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const isAllDataLoaded = (isServicesLoaded && isConfigLoaded)
  const services = !isAllDataLoaded ? loadingServices() : getServicesWithConnectStatusMultipleAccounts(state)
  const filteredServices = ownProps.hideBeta ? services.filter(service => service.mode !== 'beta') : services
  const { apps } = getAppsV2(state)
  const needsAttentionServices = getNeedsAttentionServices(state)
  const afterRedirect = hasTestConnectionInUrl()
  const isCustomIntegrationEnabledInPlan = AccessControl.isFeatureEnabledInPlan({ feature: FEATURES.INTEGRATIONS.TYPES.CUSTOM, state })

  return {
    services: filteredServices,
    idOrg,
    needsAttentionServices,
    loading: !isAllDataLoaded,
    allServices: config,
    afterRedirect,
    isCustomIntegrationEnabledInPlan,
    apps
  }
}

const mapDispatchToProps = {
  toggleConnectService,
  toggleConnectSource,
  toggleConnectCustomService,
  toggleConnectSCIMService,
  getServicesSyncData,
  getServicesConfig,
  deprecatedGetApps,
  getSupportedFeatures,
  getTestConnectionInfo,
  getAppsV2Action
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
