import View from './view'
import { connect } from 'react-redux'
import { updateContractDetails } from '../../shared/actions'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '../../selectors/me'
import { getContractStatusOptions } from '../../selectors/contracts'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const options = getContractStatusOptions(state)

  return {
    idOrg,
    options
  }
}

const mapDispatchToProps = {
  updateContractDetails
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
