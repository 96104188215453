import {
  GET_USAGE_TRENDS,
  GET_ACTIVE_USERS_TRENDS, GET_UNDERUTILIZED_LICENSES_TRENDS, GET_USERS_DISTRIBUTION_TRENDS, GET_USERS_BY_COUNTRY
} from '../constants'

const initialState = {
  loadingSingleAppUsageTrend: false,
  usage: {},
  loadingSingleAppActiveUsersTrend: false,
  activeUsers: {},
  underutilizedLicenses: {
    loading: false,
    isLoaded: false,
    apps: []
  },
  usersDistribution: {
    loading: false,
    isLoaded: false,
    countries: [],
    usersByCountry: {
      users: [],
      loading: false
    }
  }
}

const trendsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_USAGE_TRENDS}_PENDING`: {
      return {
        ...state,
        loadingSingleAppUsageTrend: true
      }
    }
    case `${GET_USAGE_TRENDS}_FAILED`: {
      return {
        ...state,
        loadingSingleAppUsageTrend: false
      }
    }
    case `${GET_USAGE_TRENDS}_RESPONSE`: {
      const { usage } = action.payload
      const { idApp } = action.meta
      return {
        ...state,
        loadingSingleAppUsageTrend: false,
        usage: { ...state.usage, [idApp]: usage }
      }
    }
    case `${GET_ACTIVE_USERS_TRENDS}_PENDING`: {
      return {
        ...state,
        loadingSingleAppActiveUsersTrend: true
      }
    }
    case `${GET_ACTIVE_USERS_TRENDS}_FAILED`: {
      return {
        ...state,
        loadingSingleAppActiveUsersTrend: false
      }
    }
    case `${GET_ACTIVE_USERS_TRENDS}_RESPONSE`: {
      const { activeUsers } = action.payload
      const { idApp } = action.meta
      return {
        ...state,
        loadingSingleAppActiveUsersTrend: false,
        activeUsers: { ...state.activeUsers, [idApp]: activeUsers }
      }
    }
    case `${GET_UNDERUTILIZED_LICENSES_TRENDS}_PENDING`: {
      return {
        ...state,
        underutilizedLicenses: {
          ...state.underutilizedLicenses,
          loading: true
        }
      }
    }
    case `${GET_UNDERUTILIZED_LICENSES_TRENDS}_FAILED`: {
      return {
        ...state,
        underutilizedLicenses: {
          ...state.underutilizedLicenses,
          loading: false
        }
      }
    }
    case `${GET_UNDERUTILIZED_LICENSES_TRENDS}_RESPONSE`: {
      const { apps } = action.payload
      return {
        ...state,
        underutilizedLicenses: {
          apps,
          isLoaded: true,
          loading: false
        }
      }
    }
    case `${GET_USERS_DISTRIBUTION_TRENDS}_PENDING`: {
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          loading: true
        }
      }
    }
    case `${GET_USERS_DISTRIBUTION_TRENDS}_FAILED`: {
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          loading: false
        }
      }
    }
    case `${GET_USERS_DISTRIBUTION_TRENDS}_RESPONSE`: {
      const { countries } = action.payload
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          countries,
          isLoaded: true,
          loading: false
        }
      }
    }
    case `${GET_USERS_BY_COUNTRY}_PENDING`: {
      const { offset } = action.meta
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          usersByCountry: {
            ...state.usersDistribution.usersByCountry,
            loadingMore: offset !== 0,
            loading: offset === 0
          }
        }
      }
    }
    case `${GET_USERS_BY_COUNTRY}_FAILED`: {
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          usersByCountry: {
            ...state.usersDistribution.usersByCountry,
            loadingMore: false,
            loading: false
          }
        }
      }
    }
    case `${GET_USERS_BY_COUNTRY}_RESPONSE`: {
      const { users, total, offset } = action.payload
      return {
        ...state,
        usersDistribution: {
          ...state.usersDistribution,
          usersByCountry: {
            ...state.usersDistribution.usersByCountry,
            users: offset === 0 ? users : (state.usersDistribution.usersByCountry.users || []).concat(users),
            loadingMore: false,
            totalUsers: total,
            loading: false
          }
        }
      }
    }
    default: {
      return state
    }
  }
}

export default trendsReducer
