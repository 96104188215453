import { sectionWidth } from './../style'
import styled from 'styled-components'
import { css } from 'glamor'

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${sectionWidth};
`

export const FromGroupStyle = css({
  width: `${sectionWidth}px`
})

export const LabelStyle = css({
  height: '25px',
  '> div': {
    display: 'flex',
    alignItems: 'center'
  }
})
