import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { SCOPES } from '@root/constants.t'
import * as Styles from './styles'
import { toggleDiscoveredAppsPreviewPopup } from '@shared/actions'
import { DiscoveredAppsPreviewPopupProps, PreviewDataRaw } from '@components/popups/discoveredAppsPreviewPopup/types'
import { getPendingChangesPreview } from '@selectors/discoveredApps'
import { DATA_CHANGE_TYPE_TO_PREVIEW_TEXT } from '@components/popups/discoveredAppsPreviewPopup/utils'
import Analytics from '@helpers/analytics'
import { getSourceByType } from '@root/sourcesConfig'

const DiscoveredAppsPreviewPopup = (): ReactElement => {
  const dispatch = useDispatch()

  const { isOpen, onMainActionButton, onCancelButton }: DiscoveredAppsPreviewPopupProps = useSelector((state: { ui: { discoveredAppsPreviewPopup: DiscoveredAppsPreviewPopupProps } }) => state.ui.discoveredAppsPreviewPopup)
  const data: PreviewDataRaw[] = useSelector(getPendingChangesPreview)

  const renderContent = (): ReactElement | null => {
    if (!data) {
      return null
    }

    return (
      <Styles.MultilineText>
        <Styles.BoldText>{'Please note, your changes will also have the following effects.'}</Styles.BoldText>
        {data.map(({ id, originalAppName, appAccountName, source, useCase }) => {
          const previewText = DATA_CHANGE_TYPE_TO_PREVIEW_TEXT[useCase]
          return (
            <div key={id}>
              <Styles.BoldText>{`User data for ${originalAppName} from ${appAccountName} in ${getSourceByType(source).name}:`}</Styles.BoldText>
              <Styles.RegularText>{previewText}</Styles.RegularText>
            </div>
          )
        })}
      </Styles.MultilineText>
    )
  }

  const onClickAnalytics = (buttonLabel: string): void => Analytics.track('Refine tab popup', { 'Button': buttonLabel })

  const onClose = (): void => {
    onClickAnalytics('X')
    dispatch(toggleDiscoveredAppsPreviewPopup(false))
  }

  const onCancel = (): void => {
    onClickAnalytics('Cancel')
    onCancelButton && onCancelButton()
    onClose()
  }

  const onApply = (): void => {
    onClickAnalytics('Apply')
    onMainActionButton && onMainActionButton()
    onClose()
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} closeOnOverlayClick={false}>
      <ToriiPopup.Header header={'Manually update app mapping'} />
      <ToriiPopup.Content>
        {renderContent()}
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonText={'Cancel'}
        cancelButtonAction={onCancel}
        mainButtonText={'Apply'}
        isMainButtonDisabled={false}
        mainButtonAction={onApply}
        mainButtonVisibilityScopes={[SCOPES.APPLICATIONS_WRITE]}
      />
    </ToriiPopup>
  )
}

export default DiscoveredAppsPreviewPopup
