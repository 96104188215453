import React from 'react'
import { Row, Col } from '@toriihq/design-system'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import Setting from './setting'
import map from 'lodash/map'

const CSS = {
  subHeader: css(texts.heading, {
    textTransform: 'uppercase',
    marginBottom: '19px'
  }),
  setting: css({
    maxWidth: '600px',
    paddingTop: '60px'
  }),
  main: css({
    ' >div:not(:first-child)': {
      marginTop: '30px'
    },
    paddingBottom: '30px'
  })
}

class Settings extends React.Component {
  componentDidMount () {
    const { getUserSettings } = this.props
    getUserSettings()
  }

  renderSettingsByType = (type) => {
    const { userSettings, idOrg, loading, updateUserSettings } = this.props

    return (
      <div key={type.id}>
        <Row>
          <Col>
            <div {...CSS.subHeader}>{type.title}</div>
          </Col>
        </Row>
        <Row>
          <Col md={12} lg={7} >
            {userSettings.filter(setting => setting.type === type.id && !setting.disabled).map(matchedSetting => <Setting key={matchedSetting.id} {...CSS.setting} {...matchedSetting} idOrg={idOrg} loading={loading} onChange={updateUserSettings} />)}
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    const { userSettingsTypes } = this.props
    return (
      <div {...CSS.main}>
        {map(userSettingsTypes, this.renderSettingsByType)}
      </div>
    )
  }
}

export default Settings
