import colors from '@shared/style/colors'
import { css } from 'glamor'
import texts from '@shared/style/texts'

export const Container = css({
  display: 'flex',
  flex: 1,
  width: '100%',
  flexDirection: 'column'
})

export const Legend = css({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  marginRight: '15px'
})

export const Header = css(texts.headers.small, {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  lineHeight: '25px',
  margin: '5px 0px'
})

export const HeaderText = css({
  marginRight: '7px',
  color: colors.black
})

export const SubHeaderText = css(texts.subheading, {
  paddingTop: '5px'
})

export const SubHeader = css(texts.subheading, {
  borderColor: colors.lightBlue2,
  borderStyle: 'solid',
  borderWidth: '1px 0 0',
  paddingTop: '5px',
  margin: '0 0 25px'
})

export const SubtitlesContainer = css({
  position: 'relative'
})

export const TrendSubtitle = css({
  position: 'absolute',
  left: 265
})

export const Main = css({
  display: 'flex',
  flex: 1,
  width: '100%',
  flexDirection: 'row'
})

export const Graph = css({
  display: 'flex',
  flex: 1
})

export const AlertBox = css({
  marginBottom: '12px'
})

export const YAxisLabel = {
  fontSize: 13,
  fontFamily: 'Nunito',
  fill: colors.grey1
}
