import {
  UPDATE_USER_SETTINGS,
  GET_USER_SETTINGS
} from '../constants'

const initialState = {
  loading: false,
  userSettings: {}
}

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_USER_SETTINGS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_USER_SETTINGS}_FAILED`: {
      return {
        ...state,
        loading: false,
        userSettings: {}
      }
    }
    case `${GET_USER_SETTINGS}_RESPONSE`: {
      const { userSettings } = action.payload
      return {
        ...state,
        loading: false,
        userSettings
      }
    }
    case `${UPDATE_USER_SETTINGS}_PENDING`: {
      const { idField, frequency } = action.meta
      const { userSettings } = state
      return {
        ...state,
        userSettings: {
          ...userSettings,
          [idField]: frequency
        }
      }
    }
    case `${UPDATE_USER_SETTINGS}_FAILED`: {
      const { idField, prevFrequency } = action.meta
      const { userSettings } = state
      return {
        ...state,
        userSettings: {
          ...userSettings,
          [idField]: prevFrequency
        }
      }
    }
    case `${UPDATE_USER_SETTINGS}_RESPONSE`: {
      const { isSuccess } = action.payload
      const { idField, frequency, prevFrequency } = action.meta
      const { userSettings } = state

      return {
        ...state,
        userSettings: {
          ...userSettings,
          [idField]: isSuccess ? frequency : prevFrequency
        }
      }
    }
    default: {
      return state
    }
  }
}

export default userSettingsReducer
