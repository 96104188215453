import React, { ReactElement } from 'react'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'
import { IfThenLabelContainer } from './styles'
import capitalize from 'lodash/capitalize'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { ID_TRIGGER_NODE } from '@shared/workflows/actions/consts'

interface Props {
  idParentNode: IdWorkflowUiNode
}

export const ActionIfThenLabelRenderer = ({ idParentNode }: Props): ReactElement => {
  const workflow = useSelectedWorkflow()
  const { getActionConfiguration } = useActionsConfiguration({ workflow })
  const isParentNodeAction = idParentNode !== ID_TRIGGER_NODE
  const actionConfiguration = isParentNodeAction && getActionConfiguration(idParentNode)
  const { thenText } = actionConfiguration || {}

  return (<>{thenText &&
    <IfThenLabelContainer>
      {capitalize(thenText)}
    </IfThenLabelContainer>
  }</>)
}
