import React from 'react'
import getColumns from './columns'
import isEqual from 'lodash/isEqual'
import Table from '../table'
import contractImage from './images/contract.svg'
import PropTypes from 'prop-types'
import { getAppContracts } from '@shared/actions'
import { css } from 'glamor'
import { SCOPES, formFieldTypes, CONTRACT_TABLE_HEADERS } from '@root/constants'
import Placeholder from '@components/placeholder'
import { getContractCurrencyCode, saveContractCurrencyValue } from './utils'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import compact from 'lodash/compact'
import { Button, ButtonType, ButtonSize, AlertBox, AlertBoxType, Link, EmptyState, toast, ToastType } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'
import Analytics from './analytics'
import RelativeTeamLink from '@components/relativeTeamLink'
import moment from 'moment'

const CSS = {
  alertBoxWrapper: css({
    marginBottom: '30px'
  })
}

class Contracts extends React.Component {
  constructor (props) {
    super(props)

    this.toggleDeleteContract = this.toggleDeleteContract.bind(this)

    this.state = this.getContractTableObjectForComponentState(true)
  }

  getContractTableObjectForComponentState (init = false) {
    const { idOrg, idApp, contractStatusOptions, contractStatusOptionsOrder, usersById, appsById, onEditContract, contractsById, onDuplicateContract, tableInfo, contractsFields } = this.props
    const { columnsConfiguration, defaultSort, filters, filtersOptions, key, name, preDefinedColumnsMapping, specialColumnsInfo, dynamicColumnsInfo, allDynamicColumnsInfo } = tableInfo
    const getColumnsByDynamicColumnsInfo = (dynamicColumnsInfo) => getColumns({
      contractStatusOptions: contractStatusOptions,
      contractStatusOptionsOrder: contractStatusOptionsOrder,
      usersById: usersById,
      appsById: appsById,
      onEditContract: onEditContract,
      contractsById: contractsById,
      onDuplicateContract: onDuplicateContract,
      contractsFields,
      idOrg: idOrg,
      idApp: idApp,
      onDeleteContract: this.toggleDeleteContract,
      columnsConfiguration: columnsConfiguration,
      defaultSort: defaultSort,
      dynamicColumnsInfo,
      filters: filters,
      filterOptions: filtersOptions,
      key: key,
      name: name,
      preDefinedColumnsMapping: preDefinedColumnsMapping,
      specialColumnsInfo: specialColumnsInfo
    })

    const columns = getColumnsByDynamicColumnsInfo(dynamicColumnsInfo)
    const allColumns = getColumnsByDynamicColumnsInfo(allDynamicColumnsInfo)

    return {
      columns: columns,
      allColumns: allColumns,
      isDeleteOpen: init ? false : undefined
    }
  }

  componentDidUpdate (prevProps) {
    const { contractStatusOptions, usersById, appsById, tableInfo, idOrg, contractsById, onEditContract,
      contracts, unmatchedContracts, idContractToOpenPopup, getUnmatchedContracts, isDoneAppMatching,
      setDoneContractsAppMatching, history } = this.props
    const columnsConfigurationWasChanged = !isEqual(tableInfo.columnsConfiguration, prevProps.tableInfo.columnsConfiguration)
    const dataWasChanged = (
      !isEqual(tableInfo.dynamicColumnsInfo, prevProps.tableInfo.dynamicColumnsInfo) ||
      !isEqual(tableInfo.specialColumnsInfo, prevProps.tableInfo.specialColumnsInfo) ||
      !isEqual(contractStatusOptions, prevProps.contractStatusOptions) ||
      !isEqual(usersById, prevProps.usersById) ||
      !isEqual(appsById, prevProps.appsById) ||
      !isEqual(contractsById, prevProps.contractsById)
    )

    if (idOrg !== prevProps.idOrg) {
      this.fetchData()
      getUnmatchedContracts({ idOrg })
    }

    if (columnsConfigurationWasChanged || dataWasChanged) {
      this.setState(prevState => ({ ...prevState, ...this.getContractTableObjectForComponentState() }))
    }

    if (idContractToOpenPopup && idContractToOpenPopup !== this.state.idContractToOpenPopup) {
      if (contracts?.find(contract => contract.id === idContractToOpenPopup)) {
        this.setState({ idContractToOpenPopup })
        onEditContract(idContractToOpenPopup)
      } else if (idOrg && unmatchedContracts?.find(contract => contract.id === idContractToOpenPopup)) {
        history.push(`/team/${idOrg}/renewals/unmatchedContracts/${idContractToOpenPopup}`)
      }
    }

    if (isDoneAppMatching) {
      toast({
        message: 'All contracts matched successfully',
        type: ToastType.SUCCESS
      })
      setDoneContractsAppMatching(false)
    }
  }

  fetchData = () => {
    const { idOrg, getContracts, idApp } = this.props
    idApp ? getAppContracts({ idApp, idOrg }) : getContracts({ idOrg })
  }

  searchFilterMethod = (row, search) => {
    const { appsById, contractsFields } = this.props
    const { columns } = this.state
    const app = appsById[row.idApp]

    const basicSearchValues = [
      row.name,
      app?.name,
      row.id
    ]
    const textAndNumberFields = contractsFields.filter(field => [formFieldTypes.singleLine, formFieldTypes.multiLine, formFieldTypes.number].includes(field.type))
    const textAndNumberTypeColumns = columns.filter(column => textAndNumberFields.some(field => field.name === column.Header))
    const advancedSearchValues = textAndNumberTypeColumns.map(column => row[column.id || column.accessor])
    const values = compact(basicSearchValues.concat(advancedSearchValues)).map(value => value.toString())

    return values.some(value => value && value.toLowerCase().includes(search))
  }

  addColumnsToDefaultExport = ({ columnNames, data }) => {
    const { contracts, contractsFields, orgDefaultCurrency } = this.props

    const currencyFields = contractsFields.filter(field => field.type === formFieldTypes.currency)
    const columnNamesWithAddedColumn = columnNames.concat(CONTRACT_TABLE_HEADERS.currency)

    const addConvertedCurrencyFieldColumn = (currencyField) => {
      const newCurrencyFieldName = `${currencyField.name} (${orgDefaultCurrency})`
      const currencyFieldIndex = columnNamesWithAddedColumn.indexOf(currencyField.name)
      if (currencyFieldIndex !== -1) {
        columnNamesWithAddedColumn.splice(currencyFieldIndex, 0, newCurrencyFieldName)
      }
      return newCurrencyFieldName
    }

    const getUserFromId = (idUser) => {
      const user = this.props.usersById[idUser]
      return user ? `${user?.firstName ?? ''} ${user?.lastName ?? ''} ${user?.email ? `<${user.email}>` : ''}` : ''
    }

    currencyFields.forEach(currencyField => {
      const indexOfContractId = columnNamesWithAddedColumn.indexOf(CONTRACT_TABLE_HEADERS.id)
      const convertedCurrencyFieldName = addConvertedCurrencyFieldColumn(currencyField)
      const currencyFieldIndex = columnNamesWithAddedColumn.indexOf(convertedCurrencyFieldName)

      data.forEach(row => {
        const contract = contracts.find(contract => contract.id === row[indexOfContractId])
        const convertedValue = contract[currencyField.systemKey]?.convertedValue
        if (currencyFieldIndex !== -1) {
          row.splice(currencyFieldIndex, 0, convertedValue ? (convertedValue / 100).toFixed(2) : null)
        }
      })
    })

    const indexOfContractId = columnNamesWithAddedColumn.indexOf(CONTRACT_TABLE_HEADERS.id)
    const indexOfCreatedBy = columnNamesWithAddedColumn.indexOf(CONTRACT_TABLE_HEADERS.createdBy)
    const indexOfCreationTime = columnNamesWithAddedColumn.indexOf(CONTRACT_TABLE_HEADERS.creationTime)

    data.forEach(row => {
      const contract = contracts.find(contract => contract.id === row[indexOfContractId])
      const currency = compact(currencyFields.map(field => getContractCurrencyCode(contract[field.systemKey])))[0]

      const createdBy = contract.createdBy
      row[indexOfCreatedBy] = getUserFromId(createdBy)

      row[indexOfCreationTime] = moment(contract.creationTime).format('YYYY-MM-DD')

      row.push(currency)
    })

    return { columnNames: columnNamesWithAddedColumn, data }
  }

  onBulkSubmit = async (data, idContracts) => {
    const { setContractsDetails, idOrg, idApp, editableContractsFields } = this.props
    const fieldKey = Object.keys(data)[0]
    const fieldType = editableContractsFields.find(field => field.systemKey === fieldKey)?.type
    if (fieldType === formFieldTypes.currency) {
      data[fieldKey] = saveContractCurrencyValue(data[fieldKey])
    }
    await setContractsDetails({ idOrg, idContracts, details: data, belongsToIdApp: idApp })
    this.fetchData()
  }

  onBulkDelete = async (idContracts) => {
    const { deleteContracts, idOrg, idApp } = this.props
    await deleteContracts({ idOrg, idContracts, idApp })
    this.fetchData()
  }

  addContractButton = {
    button: <Button size={ButtonSize.small} icon='Plus' onClick={this.props.onAddContract} />,
    tooltipMessage: 'Add contract',
    scopes: [SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, this.props.idOrg, this.props.idApp)]
  }

  renderNoContracts = () => {
    return <EmptyState
      image={<img src={contractImage} alt='no-contracts' />}
      title='No contracts yet'
      description={<span>Adding your contracts here will help you track <br /> costs and renewals.</span>}
      buttons={[<Button type={ButtonType.primary} onClick={this.props.onAddContract} label='Add contract' />]}
    />
  }

  toggleDeleteContract ({ idContract, contractName, buttonLabel }) {
    const isDeleteOpen = !this.state.isDeleteOpen

    Analytics.onDeleteContract(isDeleteOpen ? 'Open' : 'Close', { 'Button Label': buttonLabel })
    this.setState({ isDeleteOpen, idContract, contractName })
  }

  onDelete = async () => {
    const { idOrg, deleteContracts, idApp } = this.props
    const { idContract } = this.state
    await deleteContracts({ idOrg, idContracts: [idContract], idApp })
    this.fetchData()
    this.toggleDeleteContract(idContract)
    Analytics.onDeleteContract('Delete', { 'Button label': 'Delete' })
  }

  render () {
    const {
      contracts,
      loading,
      tableInfo,
      configurableColumnsOptions,
      editableContractsFields,
      filterOptionsValuesPerKey,
      supportViews,
      filterable,
      idApp,
      showAddContractButton,
      defaultSort,
      idOrg,
      isMultiSelectAllowed,
      unmatchedContractsCount
    } = this.props
    const { columns, allColumns, isDeleteOpen, idContract, contractName } = this.state

    const filterProps = filterable ? { filterable, filtersOptions: tableInfo.filtersOptions, filterOptionsValuesPerKey: filterOptionsValuesPerKey } : {}

    const getTableData = (contracts) => idApp ? contracts.filter(contract => contract.idApp === idApp) : contracts

    const deleteContractConfirmation = <Confirmation
      isOpen={isDeleteOpen}
      header={`Delete ${contractName} contract?`}
      text={`It will be permanently removed.`}
      confirmText='Delete'
      declineText='Cancel'
      decline={() => this.toggleDeleteContract({ idContract, buttonLabel: 'Cancel' })}
      confirm={this.onDelete}
      close={() => this.toggleDeleteContract({ idContract, buttonLabel: 'Close' })}
      mainButtonType={ButtonType.destructive}
    />

    const unmatchedContractAlertBox =
      <div {...CSS.alertBoxWrapper}>
        <AlertBox
          type={AlertBoxType.NOTICE}
          title={<>You have {unmatchedContractsCount} unmatched contracts. Please <RelativeTeamLink to={`/renewals/unmatchedContracts`} onClick={Analytics.clickedOnReviewUnmatchedContracts}><Link>review and match</Link></RelativeTeamLink> an application to each</>}
        />
      </div>

    return (
      <>
        <Placeholder loading={loading} rows={10}>
          {isDeleteOpen && deleteContractConfirmation}
          {!idApp && unmatchedContractsCount > 0 && unmatchedContractAlertBox}
          <Table
            tableKey={tableInfo.key}
            data={getTableData(contracts)}
            columns={columns}
            allColumns={allColumns}
            loading={loading}
            exportable
            addColumnsToDefaultExport={this.addColumnsToDefaultExport}
            configurableColumns
            configurableColumnsOptions={configurableColumnsOptions}
            searchable
            searchFilterMethod={this.searchFilterMethod}
            customButton={showAddContractButton ? this.addContractButton : null}
            emptyStateMessage={this.renderNoContracts()}
            supportBulkEdit
            bulkEditFields={editableContractsFields}
            bulkEditFieldKey='systemKey'
            bulkEditFieldName='systemKey'
            onBulkSubmit={this.onBulkSubmit}
            showBulkDelete
            onBulkDelete={this.onBulkDelete}
            allowedScopes={[SCOPES.CONTRACTS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, idApp)]}
            itemsName='contract'
            selectable={isMultiSelectAllowed}
            supportViews={supportViews}
            {...filterProps}
            clientPaging
            pageSize={50}
            defaultSort={defaultSort}
          />
        </Placeholder>
      </>
    )
  }
}

Contracts.propsTypes = {
  tableKey: PropTypes.string.isRequired,
  supportViews: PropTypes.bool.isRequired,
  filterable: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  showAddContractButton: PropTypes.bool
}

Contracts.defaultProps = {
  supportViews: false,
  filterable: false,
  showAddContractButton: false
}

export default Contracts
