import { PopupStepConfig } from '@components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import { SelectRule } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/selectRule'
import { ApplyMerge } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/applyMerge'
import { ChangeApplied } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/changeApplied'
import { RevokeMerge } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/RevokeMerge'

export const createStepsConfig: PopupStepConfig<SharedState, SharedProps>[] = [
  {
    stepRenderer: SelectRule,
    stepWidth: '946px'
  },
  {
    stepRenderer: ApplyMerge,
    stepWidth: '690px'
  },
  {
    stepRenderer: ChangeApplied,
    stepWidth: '518px'
  }
]

export const revokeStepsConfig: PopupStepConfig<SharedState, SharedProps>[] = [
  {
    stepRenderer: RevokeMerge,
    stepWidth: '946px'
  },
  {
    stepRenderer: ChangeApplied,
    stepWidth: '518px'
  }
]
