import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg, isToriiAdmin, isTrial as isTrialSelector, isPaidAccount as isPaidAccountSelector } from '@selectors/me'
import {
  getOrgDomains as getOrgDomainsSelector,
  isOrgDomainsLoaded,
  getOrgIsDisabled as getOrgIsDisabledSelector,
  getOrgPlanDetails,
  getOrgAllowOnlyYearlySubscription
} from '@selectors/org'
import { getAllPlans as getAllPlansSelector } from '@selectors/settings'
import moment from 'moment'
import {
  updateOrgPaidPlanEndTime,
  getOrgDomains,
  updateOrgDomains,
  updateOrgTrialEndTime,
  updateOrgIsDisabled,
  getAllPlans,
  updateOrgPlan,
  updateOrgAllowOnlyYearlySubscription
} from '@shared/actions'
import mergePropsWithHandlers from '@helpers/mergePropsWithHandlers'
import { EMPTY_ARRAY } from '@root/constants'

const mapStateToProps = (state) => {
  const { trialEndTime, paidPlanEndTime, id: idOrg, domain: primaryDomain } = getCurrentOrg(state) || {}

  const isTrial = isTrialSelector(state)
  const isPaidAccount = isPaidAccountSelector(state)
  const domains = getOrgDomainsSelector(state) || EMPTY_ARRAY
  const orgPlanDetails = getOrgPlanDetails(state)
  const allPlans = getAllPlansSelector(state) || EMPTY_ARRAY
  const isDomainsLoaded = isOrgDomainsLoaded(state)
  const orgAllowOnlyYearlySubscription = getOrgAllowOnlyYearlySubscription(state)
  const isOrgDisabled = getOrgIsDisabledSelector(state) === 1
  const isTrialExpired = trialEndTime && moment() > moment(trialEndTime)
  const isPaidPlanExpired = paidPlanEndTime && moment() > moment(paidPlanEndTime)
  const isPaidPlanExpiredAfterDelay = paidPlanEndTime && moment.utc().subtract(24, 'h') > moment(paidPlanEndTime)
  const orgHasRestrictions = isOrgDisabled || isTrialExpired || isPaidPlanExpiredAfterDelay

  return {
    idOrg,
    canManageAccount: isToriiAdmin(state),
    isTrial,
    trialEndTime: trialEndTime ? moment(trialEndTime).utc().format('MM/DD/YYYY').toString() : null,
    isPaidAccount,
    paidPlanEndTime: paidPlanEndTime ? moment(paidPlanEndTime).utc().format('MM/DD/YYYY').toString() : null,
    primaryDomain,
    domains,
    isDomainsLoaded,
    isOrgDisabled,
    isTrialExpired,
    isPaidPlanExpired,
    isPaidPlanExpiredAfterDelay,
    orgHasRestrictions,
    orgPlanDetails,
    allPlans,
    orgAllowOnlyYearlySubscription
  }
}

const mapDispatchToProps = {
  updateOrgPaidPlanEndTime,
  updateOrgTrialEndTime,
  getOrgDomains,
  updateOrgDomains,
  updateOrgIsDisabled,
  getAllPlans,
  updateOrgPlan,
  updateOrgAllowOnlyYearlySubscription
}

const mergeProps = mergePropsWithHandlers(props => ({
  updatePaidPlanEndTime: async (paidPlanEndTime) => {
    const { idOrg, updateOrgPaidPlanEndTime } = props
    await updateOrgPaidPlanEndTime({ idOrg, paidPlanEndTime })
  },
  updateTrialEndTime: async (trialEndTime) => {
    const { idOrg, updateOrgTrialEndTime } = props
    await updateOrgTrialEndTime({ idOrg, trialEndTime })
  }
}))

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
