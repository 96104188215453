import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUserLifecycleStatus, toggleOffboardingPreviewPopup } from '../../../shared/actions'
import omit from 'lodash/omit'
import { getIsSmallScreen } from '../../../selectors/ui'

const mapStateToProps = (state) => {
  return {
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  updateUserLifecycleStatus,
  toggleOffboardingPreviewPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser } = ownProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUserLifecycleStatus']),
    updateUserLifecycleStatus: (lifecycleStatus) =>
      dispatchProps.updateUserLifecycleStatus({ idOrg, idUser, lifecycleStatus }),
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
