import {
  runServicesSync,
  markSyncAsRun,
  updateAppAccountName,
  getOrg,
  getAppDetailsFields,
  getStateDetails,
  getServicesSyncData,
  disconnectService,
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService,
  toggleSyncStatusPopup
} from '@shared/actions'
import moment from 'moment'
import pluralize from 'pluralize'
import AccessControl from '@lenses/accessControl'
import { getCompanyName } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const companyName = getCompanyName(state)

  const isServiceEnabledInPlan = AccessControl.isFeatureEnabledInPlan({ feature: ownProps.feature, state })

  return {
    idOrg,
    isServiceEnabledInPlan,
    companyName
  }
}

const mapDispatchToProps = {
  runServicesSync,
  markSyncAsRun,
  updateAppAccountName,
  disconnectService,
  getOrg,
  getAppDetailsFields,
  getStateDetails,
  getServicesSyncData,
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService,
  toggleSyncStatusPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg } = stateProps
  const { appName, appAccountName, idAppToken, source, idApp, idAppAccount } = ownProps

  return {
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
    disconnectService: dispatchProps.disconnectService.bind(null, { idOrg, source, appName, accountName: appAccountName, idApp, idAppToken, idAppAccount })
  }
}

export const getSyncedText = ({ lastSyncTime }) => {
  if (!lastSyncTime) {
    return 'Never fully synced'
  }

  const hours = moment().diff(lastSyncTime, 'hours')
  const days = moment().diff(lastSyncTime, 'days')

  if (days > 0) {
    return `Successfully synced ${pluralize('day', days, true)} ago`
  }

  if (hours === 0) {
    return 'Successfully synced in the last hour'
  }

  return `Successfully synced ${pluralize('hour', hours, true)} ago`
}

const exported = {
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
}

export default exported
