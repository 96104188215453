import { UiAction, UiState } from '@reducers/ui/types'

const uiReducerExpander = (state: UiState, action: UiAction): UiState => {
  switch (action.type) {
    case 'TOGGLE_DELETE_IMPORTED_USERS_POPUP': {
      const { isOpen, idApp } = action.payload
      return {
        ...state,
        deleteImportedUsersPopup: {
          isOpen,
          idApp
        }
      }
    }

    default: {
      return state
    }
  }
}

export default uiReducerExpander
