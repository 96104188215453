import React, { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import Input from '@components/form/input'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { useDispatch } from 'react-redux'
import { updateWorkflowName } from '@shared/actions/workflows'
import { HiddenWorkflowTitle, WorkflowNameContainer } from './styles'
import { analytics } from '@shared/services/workflows/analytics'
import { blurInputWhenPressingEnter } from '@shared/utils'

export const WorkflowNameHeader = (): ReactElement => {
  const dispatch = useDispatch()
  const { idOrg } = useParams()
  const workflow = useSelectedWorkflow()
  const [workflowName, setWorkflowName] = useState(workflow.name)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setWorkflowName(e.target.value)
  }

  const handleBlur = () => {
    const isInputValid = workflowName && workflowName.trim()

    if ((workflowName !== workflow.name) && isInputValid) {
      dispatch(updateWorkflowName({
        idOrg: Number(idOrg),
        idWorkflow: workflow.id,
        workflow,
        name: workflowName
      }))
      analytics.updateName({
        idWorkflow: workflow.id,
        workflowName: workflow.name,
        workflowNewName: workflowName
      })
    } else {
      setWorkflowName(workflow.name)
    }
  }

  useEffect(() => {
    setWorkflowName(workflow.name)
  }, [workflow.name])

  return (
    <WorkflowNameContainer>
      {Boolean(workflow.isHiddenForCustomers) && <HiddenWorkflowTitle>Hidden</HiddenWorkflowTitle>}
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <Input
          onChange={handleChange}
          value={workflowName}
          onBlur={handleBlur}
          onKeyDown={blurInputWhenPressingEnter}
          border={false}
        />
      </EnableFor>
    </WorkflowNameContainer>
  )
}
