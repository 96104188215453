import View from './view'
import { connect } from 'react-redux'
import { getUnderutilizedLicenses } from '../../../shared/actions'
import { getCurrentOrg } from '../../../selectors/me'
import { isLoadingUnderutilizedLicensesTrends, getUnderutilizedLicensesTrends, isLoadedUnderutilizedLicensesTrends } from '../../../selectors/trends'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const apps = getUnderutilizedLicensesTrends(state)
  const isLoaded = isLoadedUnderutilizedLicensesTrends(state)
  const loading = !isLoaded || (isLoadingUnderutilizedLicensesTrends(state) && apps.length === 0)

  return {
    loading,
    apps,
    idOrg
  }
}

const mapDispatchToProps = {
  getUnderutilizedLicenses
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
