import React, { useEffect, useMemo, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getRecommendations as getOrgRecommendations, getRecommendationsAppsList } from '@shared/actions'
import { getRecommendations } from '@root/selectors/recommendations'
import LicenseRecommendation from './licenseRecommendation'
import * as Style from './style'
import { deprecatedGetAppsByIds } from '@root/selectors/apps'
import Select from '@components/select'
import { flatten, uniqBy } from 'lodash'
import noRecommendationsImage from './images/noRecommendations.svg'
import Placeholder from '@root/components/placeholder'
import Analytics from './analytics'
import { getIncludeAppsInPreview } from './utils'
import { Link, EmptyState } from '@toriihq/design-system'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'
import AccessControl from '@lenses/accessControl'
import { SCOPES } from '@root/constants'

const LicensesRecommendations = withRouter((props) => {
  const dispatch = useDispatch()

  const hasLicenseCostAndChargebackAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.USERS_READ] })

  const { match, fromPage } = props

  const idApp = match.params.idApp ? parseInt(match.params.idApp, 10) : undefined
  const idOrg = parseInt(match.params.idOrg, 10)
  const includeAppsInPreview = getIncludeAppsInPreview()

  const [appFilterValue, setAppFilterValue] = useState(idApp || -1)
  const [accountFilterValue, setAccountFilterValue] = useState(-1)

  useEffect(() => {
    if (idApp) {
      setAppFilterValue(idApp)
    }
    dispatch(getOrgRecommendations({ idOrg, idApp: (appFilterValue !== -1) ? appFilterValue : idApp, idAppAccount: (accountFilterValue !== -1) ? accountFilterValue : undefined, includeAppsInPreview }))
  }, [dispatch, idOrg, idApp, appFilterValue, accountFilterValue, includeAppsInPreview])

  useEffect(() => {
    dispatch(getRecommendationsAppsList({ idOrg, includeAppsInPreview }))
  }, [dispatch, idOrg, includeAppsInPreview])

  const appsById = useSelector(deprecatedGetAppsByIds)
  const { recommendations, loadingRecommendations, loadingAppsList, appsList = [] } = useSelector(getRecommendations)

  const appName = useMemo(() => {
    if (fromPage === 'Applications' && idApp && appsList && appsList[idApp]) {
      return appsList[idApp].name
    } else {
      return null
    }
  }, [appsList, idApp, fromPage])

  const getAppList = [
    { label: 'All apps', value: -1 },
    ...uniqBy(appsList.map(app => ({ label: app.appName, value: app.idApp })), 'value')
  ]

  const getAppAccountList = useMemo(() => {
    const selectedApps = appsList.filter(app => appFilterValue === -1 ? true : app.idApp === String(appFilterValue))

    return [
      { label: 'All accounts', value: -1 },
      ...uniqBy(flatten(selectedApps.map(app => app.appAccounts.map(appAccount => ({ label: appAccount.appAccountName, value: appAccount.idAppAccount })), 'value')))
    ]
  }, [appFilterValue, appsList])

  const onLearnMoreClick = () => {
    Analytics.onLearnMoreClickAnalytics(fromPage, appName)
  }

  const LearnMoreLink = <Link href='https://support.toriihq.com/hc/en-us/articles/4577306621595-Introduction-to-Licenses-Cost-Saving-in-Torii-#heading-4:~:text=if%20those%20exist-,Recommendations%20tab' target='_blank' onClick={onLearnMoreClick}>Learn more</Link>

  const emptyState =
    <div {...Style.EmptyStateContainer}>
      <EmptyState
        orientation='horizontal'
        align='center'
        image={<img src={noRecommendationsImage} alt='no-recommendations' />}
        title='No recommendations found at this moment'
        description='Check in again soon as we’re constantly adding recommendations.'
        link={LearnMoreLink}
      />
    </div>

  const onAppFilterChange = (e) => {
    setAppFilterValue(e.value)
    Analytics.onFilterChangeAnalytics(fromPage, appName, 'Application')
  }

  const onAccountFilterChange = (e) => {
    setAccountFilterValue(e.value)
    Analytics.onFilterChangeAnalytics(fromPage, appName, 'Account')
  }

  const loading = (loadingRecommendations || loadingAppsList)

  return (
    !loading && !recommendations.length ? emptyState
      : <div>
        <div {...Style.Header}>
          <div {...Style.HeaderText}>
            <Placeholder rows={1} loading={loading}>
              <div>
                We have {recommendations.length} recommendations for you
              </div>
            </Placeholder>
          </div>
          <div {...Style.FilterContainer}>
            <div>View recommendations for:</div>
            <div {...Style.SelectFiltersContainer}>
              {!idApp && <Select
                value={appFilterValue}
                onChange={onAppFilterChange}
                options={getAppList}
                searchable
                fullSize
                clearable={false}
              />}
              <Select
                value={accountFilterValue}
                onChange={onAccountFilterChange}
                options={getAppAccountList}
                searchable
                disabled={appFilterValue === -1}
                clearable={false}
              />
            </div>
            <ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.RECOMMENDATIONS} />
          </div>
        </div>
        <div {...Style.RecommendationsContainer}>
          {recommendations.map((recommendation, index) => {
            return <LicenseRecommendation
              key={index}
              idOrg={idOrg}
              recommendation={recommendation}
              app={appsById[recommendation.idApp] || {}}
              showTakeActionButton
              clickable={hasLicenseCostAndChargebackAccess}
              loading={loading}
              appName={appName}
              fromPage={fromPage}
            />
          })}
        </div>
      </div>
  )
})

export default LicensesRecommendations
