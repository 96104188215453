import View from '../detailsConfiguration'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import {
  addContractDetailsGroup,
  getContractsFields,
  getContractsGroups as getContractsGroupsFromServer,
  updateContractDetailsField,
  toggleUpdateContractDetailsField,
  deleteContractDetailsField,
  deleteContractDetailsGroup,
  editContractDetailsGroup,
  reorderContractDetailsGroups,
  addContractDetailsField,
  reorderContractDetailsFields
} from '@shared/actions'
import range from 'lodash/range'
import {
  getConfigurableContractDetailsFields,
  getContractsGroups,
  getConfigurableContractDetailsFieldsByGroup,
  getContractsGroupsForSelectGroup,
  isLoadingContractsFields,
  isLoadingContractsGroups
} from '@selectors/contracts'
import { EMPTY_OBJECT, SCOPES } from '@root/constants'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const fields = getConfigurableContractDetailsFields(state)
  const groups = getContractsGroups(state)
  const groupsForSelectGroup = getContractsGroupsForSelectGroup(state)
  const fieldsByGroups = getConfigurableContractDetailsFieldsByGroup(state)

  const isLoading = isLoadingContractsFields(state) || isLoadingContractsGroups(state)
  const hasData = fields.length !== 0 && groups.length !== 0
  const loadingDetails = range(10)
  const loadingGroups = { 'loading': loadingDetails }
  const loadingContractDetails = isLoading && !hasData

  const { isOpen, idField } = state.ui.updateContractDetailFieldTypePopup
  const selectedField = idField ? fields.find(field => field.id === idField) : undefined
  const editableField = selectedField || EMPTY_OBJECT

  return {
    fields: loadingContractDetails ? loadingDetails : fields,
    fieldsByGroups: loadingContractDetails ? loadingGroups : fieldsByGroups,
    groups: loadingContractDetails ? [{ id: 'loading' }] : groups,
    groupsForSelectGroup,
    idOrg,
    loading: loadingContractDetails,
    editableField,
    isEditFieldOpen: isOpen,
    itemName: 'contract',
    colorableField: ['status'].includes(editableField.systemKey),
    alwaysShownFields: ['status'],
    subHeader: 'Contract Details',
    allowedScopes: [SCOPES.CONTRACTS_WRITE],
    visibilityScopes: [SCOPES.CONTRACTS_READ]
  }
}

const mapDispatchToProps = {
  addContractDetailsGroup,
  getContractsFields,
  getContractsGroupsFromServer,
  updateContractDetailsField,
  toggleUpdateContractDetailsField,
  deleteContractDetailsField,
  deleteContractDetailsGroup,
  editContractDetailsGroup,
  reorderContractDetailsGroups,
  addContractDetailsField,
  reorderContractDetailsFields
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, editableField, fields } = stateProps
  return {
    ...stateProps,
    ...dispatchProps,
    fetchData: () => {
      dispatchProps.getContractsFields({ idOrg })
      dispatchProps.getContractsGroupsFromServer({ idOrg })
    },
    addDetailsGroup: ({ label }) => dispatchProps.addContractDetailsGroup({ idOrg, label }),
    updateDetailsField: dispatchProps.updateContractDetailsField,
    toggleUpdateDetailsField: dispatchProps.toggleUpdateContractDetailsField,
    editDetailsGroup: dispatchProps.editContractDetailsGroup,
    deleteDetailsField: dispatchProps.deleteContractDetailsField,
    deleteDetailsGroup: dispatchProps.deleteContractDetailsGroup,
    reorderDetailsGroups: ({ sourceGroup, destinationGroup, reorderedGroups, changes }) => dispatchProps.reorderContractDetailsGroups({ idOrg, sourceGroup, destinationGroup, reorderedGroups, changes }),
    addDetailsField: dispatchProps.addContractDetailsField,
    updateField: ({ name, idGroup, formQuestion, options }) => {
      dispatchProps.updateContractDetailsField({ idOrg, idGroup, idField: editableField.id, name, options, formQuestion })
    },
    onRowsOrderChanged: ({ reorderedData }) => {
      const changes = reorderedData.map((row, index) => ({ idField: row.id, position: index }))
      const reorderedDataWithCorrectPosition = reorderedData.map((row, index) => ({ ...row, position: index }))

      return dispatchProps.reorderContractDetailsFields({ idOrg, changes, reorderedData: reorderedDataWithCorrectPosition, previousData: fields })
    },
    ...ownProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
