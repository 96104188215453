import {
  GET_CHARGE_BACK_REPORT,
  GET_LICENSE_TRENDS,
  GET_LICENSES_TYPES,
  SET_LICENSE_TREND_FILTER,
  UPDATE_LICENSE,
  GET_APP_INACTIVE_LICENSES,
  BULK_UPDATE_LICENSES_TYPES, DELETE_APP_CHARGEBACK_CONFIG,
  GET_APP_LICENSE_RECOMMENDATION_USERS,
  GET_APP_USER_LICENSES_FIELD_VALUES
} from '../constants'
import { TIME_PERIOD_OPTIONS, USAGE } from '../components/licenseTrendsChart/consts'
import { mergeArraysByKey } from '../lenses/utils'

const initialState = {
  loading: false,
  loadingTypes: false,
  loadedTypes: false,
  licenses: {},
  licensesTypes: {},
  licenseTrends: {
    hasLoaded: false,
    loading: false,
    licenses: [],
    appLicenses: []
  },
  licenseTrendFilters: {
    selectedUsageIdsFilter: Object.values(USAGE).map(usage => usage.id),
    selectedLicenseIdsFilter: [],
    selectedTimePeriod: TIME_PERIOD_OPTIONS[1].value
  },
  chargeBackReportV1: {
    data: [],
    config: {
      apps: []
    }
  },
  chargeBackReport: {
    data: [],
    loading: false
  },
  inactiveAppLicenses: {
    loading: false,
    licenses: {},
    state: {}
  },
  licenseRecommendations: {
    loading: false,
    licenses: []
  },
  fieldValuesPerAppUserLicenses: {}
}

const licensesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_LICENSES_TYPES}_PENDING`: {
      return {
        ...state,
        loadingTypes: true
      }
    }
    case `${GET_LICENSES_TYPES}_FAILED`: {
      return {
        ...state,
        loadingTypes: false
      }
    }
    case `${GET_LICENSES_TYPES}_RESPONSE`: {
      const { licenses } = action.payload
      const oldLicenses = state.licensesTypes
      return {
        ...state,
        loadingTypes: false,
        loadedTypes: true,
        licensesTypes: {
          ...oldLicenses,
          ...licenses
        }
      }
    }

    case `${UPDATE_LICENSE}_PENDING`: {
      const { idLicense, pricePerUser, idApp } = action.meta
      const license = { id: idLicense, pricePerUser, idApp, isUpdatingLicense: true }
      const licensesTypes = state.licensesTypes
      const newAppLicenses = mergeArraysByKey(licensesTypes[license.idApp], [license], 'id')
      licensesTypes[license.idApp] = newAppLicenses

      return {
        ...state,
        licensesTypes: {
          ...licensesTypes
        }
      }
    }
    case `${UPDATE_LICENSE}_RESPONSE`: {
      const { license } = action.payload
      const licensesTypes = state.licensesTypes
      const newAppLicenses = mergeArraysByKey(licensesTypes[license.idApp], [{ ...license, isUpdatingLicense: false }], 'id')
      licensesTypes[license.idApp] = newAppLicenses
      return {
        ...state,
        licensesTypes: {
          ...licensesTypes
        }
      }
    }

    case `${GET_LICENSE_TRENDS}_PENDING`: {
      return {
        ...state,
        licenseTrends: {
          ...state.licenseTrends,
          loading: true
        }
      }
    }
    case `${GET_LICENSE_TRENDS}_FAILED`: {
      return {
        ...state,
        licenseTrends: {
          ...state.licenseTrends,
          loading: false
        }
      }
    }
    case `${GET_LICENSE_TRENDS}_RESPONSE`: {
      const licenseTrends = action.payload

      return {
        ...state,
        licenseTrends: {
          licenses: licenseTrends.licenses,
          appLicenses: licenseTrends.appLicenses,
          loading: false
        }
      }
    }

    case `${SET_LICENSE_TREND_FILTER}`: {
      const { selectedUsageIdsFilter, selectedLicenseIdsFilter, selectedTimePeriod } = action.payload
      return {
        ...state,
        licenseTrendFilters: {
          selectedUsageIdsFilter: selectedUsageIdsFilter || state.licenseTrendFilters.selectedUsageIdsFilter,
          selectedLicenseIdsFilter: selectedLicenseIdsFilter || state.licenseTrendFilters.selectedLicenseIdsFilter,
          selectedTimePeriod: selectedTimePeriod || state.licenseTrendFilters.selectedTimePeriod
        }
      }
    }

    case `${GET_CHARGE_BACK_REPORT}_PENDING`: {
      return {
        ...state,
        chargeBackReport: {
          ...state.chargeBackReport,
          loading: true
        }
      }
    }
    case `${GET_CHARGE_BACK_REPORT}_FAILED`: {
      return {
        ...state,
        chargeBackReport: {
          ...state.chargeBackReport,
          loading: false
        }
      }
    }
    case `${GET_CHARGE_BACK_REPORT}_RESPONSE`: {
      return {
        ...state,
        chargeBackReport: {
          data: action.payload,
          loading: false
        }
      }
    }

    case `${GET_APP_INACTIVE_LICENSES}_PENDING`: {
      const { reset = true, idApp, idLicense } = action.meta
      const key = idLicense ? `${idApp}_${idLicense}` : idApp
      return {
        ...state,
        inactiveAppLicenses: {
          ...state.inactiveAppLicenses,
          loading: true,
          state: {
            ...state.inactiveAppLicenses.state,
            [key]: {
              ...state.inactiveAppLicenses.state[key],
              loadingMore: !reset
            }
          }
        }
      }
    }
    case `${GET_APP_INACTIVE_LICENSES}_FAILED`: {
      return {
        ...state,
        inactiveAppLicenses: {
          ...state.inactiveAppLicenses,
          loading: false
        }
      }
    }
    case `${GET_APP_INACTIVE_LICENSES}_RESPONSE`: {
      const { licenses, total } = action.payload
      const { idApp, idLicense, reset = true } = action.meta
      const key = idLicense ? `${idApp}_${idLicense}` : idApp
      return {
        ...state,
        inactiveAppLicenses: {
          ...state.inactiveAppLicenses,
          loading: false,
          licenses: {
            ...state.inactiveAppLicenses.licenses,
            [key]: reset ? licenses : (state.inactiveAppLicenses.licenses[key] || []).concat(licenses)
          },
          state: {
            ...state.inactiveAppLicenses.state,
            [key]: {
              ...state.inactiveAppLicenses.state[key],
              loadingMore: false,
              totalLicenses: total
            }
          }
        }
      }
    }
    case `${BULK_UPDATE_LICENSES_TYPES}_RESPONSE`: {
      const { LicensesFullDetails, idApp } = action.meta
      const oldLicenses = state.licensesTypes
      return {
        ...state,
        licensesTypes: {
          ...oldLicenses,
          [idApp]: LicensesFullDetails
        }
      }
    }
    case `${DELETE_APP_CHARGEBACK_CONFIG}_RESPONSE`: {
      const { idApp } = action.meta
      const updatedChargebackReportData = state.chargeBackReport.data.filter(appChargebackData => appChargebackData.idApp !== idApp)

      return {
        ...state,
        chargeBackReport: {
          data: updatedChargebackReportData,
          loading: false
        }
      }
    }
    case `${GET_APP_LICENSE_RECOMMENDATION_USERS}_PENDING`: {
      const { reset = true } = action.meta
      return {
        ...state,
        licenseRecommendations: {
          ...state.licenseRecommendations,
          hasLoaded: false,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_APP_LICENSE_RECOMMENDATION_USERS}_RESPONSE`: {
      const { results, total } = action.payload
      const { reset = true } = action.meta
      return {
        ...state,
        licenseRecommendations: {
          ...state.licenseRecommendations,
          hasLoaded: true,
          loading: false,
          loadingMore: false,
          licenses: reset ? results : (state.licenseRecommendations.licenses).concat(results),
          total
        }
      }
    }
    case `${GET_APP_LICENSE_RECOMMENDATION_USERS}_FAILED`: {
      return {
        ...state,
        licenseRecommendations: {
          ...state.licenseRecommendations,
          hasLoaded: true,
          loading: false,
          loadingMore: false
        }
      }
    }
    default: {
      return state
    }
    case `${GET_APP_USER_LICENSES_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        loading: false,
        fieldValuesPerAppUserLicenses: {
          ...action.payload
        }
      }
    }
  }
}

export default licensesReducer
