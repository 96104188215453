import View from './view'
import { connect } from 'react-redux'
import { toggleUploadExpenses } from '../../shared/actions'
import { getInsightsStats, isLoadedInsightsStats } from '../../selectors/stats'

const mapStateToProps = (state) => {
  const { expenses, yearlyCost } = getInsightsStats(state)
  const isLoaded = isLoadedInsightsStats(state)
  const loading = !isLoaded

  return {
    expenses,
    yearlyCost,
    loading
  }
}

const mapDispatchToProps = {
  toggleUploadExpenses
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
