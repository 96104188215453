import { withRouter } from 'react-router-dom'
import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '../../selectors/me'

const mapStateToProps = (state, ownProps) => {
  const idOrgFromRouter = parseInt(ownProps.match.params.idOrg, 10)
  const { id: idOrgFromState } = getCurrentOrg(state)

  return {
    idOrg: idOrgFromRouter || idOrgFromState
  }
}

export default withRouter(connect(mapStateToProps)(View))
