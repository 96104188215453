import React, { useState } from 'react'
import Analytics from '../../helpers/analytics'
import * as Style from './style'
import get from 'lodash/get'
import { Icon } from '@toriihq/design-system'

type Option = {
  linkSuffix: string | number,
  label: string
}

type Props = {
  label: string,
  options: Option[],
  optionIndex: number,
  linkPrefix: string,
  analyticsEvent: string
  searchParams?: string
}

const BackAndForwardNavigator = ({ label, options, optionIndex, linkPrefix, analyticsEvent, searchParams }: Props): JSX.Element => {
  const [selectedIndex, setIndex] = useState(optionIndex)

  const lastIndex = options.length - 1
  const backIndex = selectedIndex === 0 ? 0 : selectedIndex - 1
  const nextIndex = selectedIndex === lastIndex ? selectedIndex : selectedIndex + 1
  const backLink = get(options, [backIndex, 'linkSuffix'])
  const nextLink = get(options, [nextIndex, 'linkSuffix'])

  const isBackDisabled = selectedIndex === 0
  const isNextDisabled = selectedIndex === lastIndex

  const goBack = (e): void => {
    if (isBackDisabled) {
      e.preventDefault()
      return
    }

    setIndex(selectedIndex - 1)
    Analytics.track(analyticsEvent, {
      'Direction': 'Back'
    })
  }

  const goNext = (e): void => {
    if (isNextDisabled) {
      e.preventDefault()
      return
    }

    setIndex(selectedIndex + 1)
    Analytics.track(analyticsEvent, {
      'Direction': 'Next'
    })
  }

  const backTo = searchParams ? `${linkPrefix}` : `${linkPrefix}${backLink}`
  const backSearch = searchParams ? `${searchParams}${backLink}` : ''

  const nextTo = searchParams ? `${linkPrefix}` : `${linkPrefix}${nextLink}`
  const nextSearch = searchParams ? `${searchParams}${nextLink}` : ''

  return (
    <Style.Wrapper>
      <Style.Button supportDisabledLink disabled={isBackDisabled} nav to={backTo} onClick={goBack} search={backSearch}>
        <Icon name='ChevronLeft' />
      </Style.Button>
      <Style.Label>{`${label}${get(options, [selectedIndex, 'label'])}`}</Style.Label>
      <Style.Button supportDisabledLink disabled={isNextDisabled} nav to={nextTo} onClick={goNext} search={nextSearch}>
        <Icon name='ChevronRight' />
      </Style.Button>
    </Style.Wrapper>
  )
}

export default BackAndForwardNavigator
