import View from './view'
import { connect } from 'react-redux'
import {
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  toggleConfigureBrowserActivationEmailPopup,
  getBrowserExtensionActivationUsers,
  toggleSentEmailsPopup
} from '@shared/actions'
import {
  createWorkflow,
  updateWorkflow
} from '@shared/actions/workflows'
import { getCurrentOrg } from '../../../selectors/me'
import { getFieldsDefaultValues, getActionsConfigByType, getFlattenActionsConfig } from '../../../selectors/workflows'
import {
  EMPTY_OBJECT, WORKFLOW_ACTION_TYPES,
  WORKFLOW_TRIGGER_TYPES,
  WORKFLOW_TYPES
} from '../../../constants'
import { getSourceByType } from '../../../sourcesConfig'
import moment from 'moment'
import { getBrowserActivationEmailSampleUsers, getBrowserActivationEmailUsersCount } from '../../../selectors/reports'
import omit from 'lodash/omit'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'
import { createActionsWithASingleAction } from '@shared/workflows/actions/utils/createActionsWithASingleAction'

const mapStateToProps = state => {
  const { isOpen } = state.ui.configureBrowserExtensionActivationEmailPopup
  const { id: idOrg } = getCurrentOrg(state)
  const actionsConfig = getFlattenActionsConfig(state)
  const fieldsDefaultValues = getFieldsDefaultValues(state)
  const actionsByType = getActionsConfigByType(state)
  const triggerType = WORKFLOW_TRIGGER_TYPES.USER_TO_ACTIVATE_EXTENSION
  const actionType = WORKFLOW_ACTION_TYPES.SEND_BROWSER_ACTIVATION_EMAIL
  const usersCount = getBrowserActivationEmailUsersCount(state)
  const sampleUsers = getBrowserActivationEmailSampleUsers(state)

  return {
    fieldsDefaultValues,
    actionsConfig,
    actionsByType,
    triggerType,
    actionType,
    idOrg,
    isOpen,
    action: EMPTY_OBJECT,
    usersCount,
    sampleUsers
  }
}

const mapDispatchToProps = {
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  createWorkflow,
  updateWorkflow,
  toggleConfigureBrowserActivationEmailPopup,
  getBrowserExtensionActivationUsers,
  toggleSentEmailsPopup
}

const WORKFLOW_ID_APP = getSourceByType('torii').idApp

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...dispatchProps,
  ...omit(stateProps, ['fieldsDefaultValues', 'actionsByType']),
  cancel () {
    dispatchProps.toggleConfigureBrowserActivationEmailPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleConfigureBrowserActivationEmailPopup({ isOpen: false })
  },
  async setupRunActionWorkflow ({ action }) {
    const { idOrg, triggerType, sampleUsers, usersCount } = stateProps
    const { workflow } = await dispatchProps.createWorkflow({ idOrg, type: WORKFLOW_TYPES.manual })
    workflow.triggerType = triggerType
    workflow.isActive = true
    workflow.name = `Run actions on users ${moment().format('DD MMM, YYYY')}`
    workflow.triggerConfiguration = []
    workflow.idApp = WORKFLOW_ID_APP
    workflow.actions = createActionsWithASingleAction(action)
    await dispatchProps.updateWorkflow({ idOrg, idWorkflow: workflow.id, workflow, executeWorkflow: true })
    return dispatchProps.toggleSentEmailsPopup({ sampleUsers, usersCount, isOpen: true, idWorkflow: workflow.id })
  },
  createAction (actionType) {
    if (!actionType) {
      return {}
    }

    const { actionsConfig, fieldsDefaultValues, actionsByType } = stateProps
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === actionType)

    const { fields } = getStepsAndFieldsWithDefaultValues(WORKFLOW_TRIGGER_TYPES.EXECUTE_ON_USERS, actionConfig, fieldsDefaultValues, actionsByType)

    return { id: new Date().getTime(), type: actionType, idApp: actionConfig.idApp, fields }
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
