import {
  CREATE_USER_TYPE_SOURCE,
  DELETE_USER_TYPE_SOURCE,
  GET_USER_TYPE_SOURCES,
  GET_USER_TYPE_SOURCE_PREVIEW,
  UPDATE_USER_TYPE_SOURCE,
  ASK_TO_UPGRADE_PLAN,
  GET_MERGE_USERS_RULES,
  UPDATE_MERGE_USERS_RULE,
  CREATE_MERGE_USERS_RULES,
  DELETE_MERGE_USERS_RULE,
  SEND_MERGE_USERS_PREVIEW_REPORT,
  SEND_USER_LIFECYCLE_CHANGES_REPORT,
  SEND_USER_TYPE_SOURCE_CHANGES_REPORT
} from '@root/constants'
import send from '@shared/redux-fetch'
import { UserTypeSource, UserType } from '@root/components/userTypeSources/types'
import { ActionForUserTypePreview } from '@shared/actions/org.types'
import { RuleData } from '@components/mergeUsersRules/types'

export const getUserTypeSourcePreview = ({ userTypeSource, action }: { userTypeSource: UserTypeSource, action: ActionForUserTypePreview }) => {
  const { id, idOrg, idApp, type, filter } = userTypeSource
  return send(GET_USER_TYPE_SOURCE_PREVIEW, {
    url: `/api/orgs/${idOrg}/userTypeSources/preview`,
    method: 'POST',
    body: JSON.stringify({
      userTypeSource: {
        id,
        idApp,
        type,
        filter
      },
      action
    })
  })
}

export const createUserTypeSource = ({
  idOrg,
  idApp,
  type,
  filter
}: Omit<UserTypeSource, 'id'>) => {
  return send(CREATE_USER_TYPE_SOURCE, {
    url: `/api/orgs/${idOrg}/userTypeSources`,
    method: 'POST',
    body: JSON.stringify({
      userTypeSource: {
        idApp,
        type,
        filter
      }
    })
  })
}

export const updateUserTypeSource = ({
  idOrg,
  idApp,
  idSource,
  filter
}: {
  idOrg: number,
  idApp: number,
  idSource: number,
  filter: UserTypeSource['filter']
}) => {
  return send(UPDATE_USER_TYPE_SOURCE, {
    url: `/api/orgs/${idOrg}/userTypeSources/${idSource}`,
    method: 'PUT',
    body: JSON.stringify({
      filter,
      idApp
    })
  })
}

export const getUserTypeSources = ({ idOrg }: { idOrg: number }) => {
  return send(GET_USER_TYPE_SOURCES, {
    url: `/api/orgs/${idOrg}/userTypeSources`,
    method: 'GET'
  })
}

export const getMergeUsersRules = ({ idOrg }: { idOrg: number }) => {
  return send(GET_MERGE_USERS_RULES, {
    url: `/api/orgs/${idOrg}/mergeUsersRules`
  })
}

export const createMergeUsersRules = ({ idOrg, data }: { idOrg: number, data: RuleData }) => {
  return send(CREATE_MERGE_USERS_RULES, {
    url: `/api/orgs/${idOrg}/mergeUsersRules`,
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const updateMergeUsersRule = ({ idOrg, idRule, data }: { idOrg: number, idRule: number, data: RuleData}) => {
  return send(UPDATE_MERGE_USERS_RULE, {
    url: `/api/orgs/${idOrg}/mergeUsersRules/${idRule}`,
    method: 'PUT',
    body: JSON.stringify(data)
  })
}

export const deleteMergeUsersRule = ({ idOrg, idRule }: { idOrg: number, idRule: number }) => {
  return send(DELETE_MERGE_USERS_RULE, {
    url: `/api/orgs/${idOrg}/mergeUsersRules/${idRule}`,
    method: 'DELETE'
  })
}

export const sendMergeUsersPreviewReport = ({ idOrg, previewRule, isRevokeMode }: { idOrg: number, previewRule?: RuleData, isRevokeMode: boolean }) => {
  return send(SEND_MERGE_USERS_PREVIEW_REPORT, {
    url: `/api/orgs/${idOrg}/mergeUsersRules/preview`,
    method: 'POST',
    body: JSON.stringify({
      previewRule,
      isRevokeMode
    })
  })
}

export const sendUserLifecycleChangesReport = ({ idOrg, lifecycleApps }: { idOrg: number, lifecycleApps: Array<{ idApp: number, source: string }> }) => {
  return send(SEND_USER_LIFECYCLE_CHANGES_REPORT, {
    url: `/api/orgs/${idOrg}/lifecyclePreview/report`,
    method: 'POST',
    body: JSON.stringify({
      lifecycleApps
    })
  })
}

export const sendUserTypeSourceChangesReport = ({ idOrg, userTypeSource, action }: { idOrg: number, userTypeSource: UserTypeSource, action: ActionForUserTypePreview }) => {
  return send(SEND_USER_TYPE_SOURCE_CHANGES_REPORT, {
    url: `/api/orgs/${idOrg}/userTypeSources/report`,
    method: 'POST',
    body: JSON.stringify({
      userTypeSource,
      action
    })
  })
}

export const deleteUserTypeSource = ({
  idOrg,
  idSource,
  type
}: {
  idOrg: number,
  idSource: number,
  type: UserType
}) => {
  return send(DELETE_USER_TYPE_SOURCE, {
    url: `/api/orgs/${idOrg}/userTypeSources/${idSource}`,
    method: 'DELETE',
    body: JSON.stringify({
      type
    })
  })
}

export const askToUpgrade = ({ idOrg, feature }: {
  idOrg: number,
  feature: string,
}) => {
  return send(ASK_TO_UPGRADE_PLAN, {
    url: `/api/orgs/${idOrg}/AskToUpgradePlan`,
    method: 'POST',
    body: JSON.stringify({
      feature
    })
  })
}
