import ToriiSelect from '@components/select'
import { IMPORT_USERS_COLUMNS, SharedState } from '../types'
import { capitalize } from 'lodash'
import { Icon } from '@toriihq/design-system'

export const DONT_IMPORT_OPTION = { value: 'dontImport', label: `Don't import column` }

const getColumns = (fileHeaders: string[], sharedState: SharedState, onSelectColumn: (id: IMPORT_USERS_COLUMNS, selection: { label: string, value: string }) => void) => {
  return [
    {
      accessor: 'id',
      show: false
    },
    {
      Header: 'Torii field',
      accessor: 'field',
      Cell: ({ value }) => value,
      width: 130,
      resizable: false,
      sortable: true
    },
    {
      Header: 'CSV column',
      accessor: 'column',
      Cell: ({ original }) => {
        const { id } = original
        const { previewDataMapping: selectedMapping } = sharedState
        const selectedColumn = selectedMapping[id] ?? null

        const options: ({ value: string, label: string } | { type: string })[] = []
        if (id !== IMPORT_USERS_COLUMNS.EMAIL) {
          options.push({ value: DONT_IMPORT_OPTION.value, label: DONT_IMPORT_OPTION.label })
          options.push({ type: 'divider' })
        }
        fileHeaders.forEach(header => options.push({ value: header, label: capitalize(header) }))

        return (
          <ToriiSelect
            clearable={false}
            options={options}
            placeholder='Select'
            value={selectedColumn}
            onChange={(selection) => onSelectColumn(id, selection as { label: string, value: string })}
            menuWidth={180}
            selectWidth={180}
          />
        )
      },
      width: 210,
      resizable: false,
      sortable: true
    },
    {
      Header: 'Sample column content',
      accessor: 'csvColumn',
      Cell: ({ value }) => {
        const { filePreview } = sharedState
        return filePreview[value] ?? '-'
      },
      width: 200,
      resizable: false,
      sortable: true
    },
    {
      Header: 'Include',
      accessor: 'include',
      Cell: ({ original }) => {
        const { id } = original
        const { selectedColumnsToImport } = sharedState
        if (selectedColumnsToImport.includes(id)) {
          return <Icon name='CheckCircleFill' color='interactive' />
        }
      },
      width: 85,
      resizable: false,
      sortable: true
    }
  ]
}

export default getColumns
