import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'

export const REGULAR_SCREEN_DIFF = 475
export const BASE_SCREEN_WIDTH = 1215
export const MEDIUM_SCREEN_WIDTH = BASE_SCREEN_WIDTH + REGULAR_SCREEN_DIFF
export const LARGE_SCREEN_WIDTH = BASE_SCREEN_WIDTH + 2 * REGULAR_SCREEN_DIFF

export const SMALL_SCREEN_DIFF = 160
export const SMALL_SCREEN_BASE_WIDTH = 310
export const XS_SMALL_SCREEN_WIDTH = SMALL_SCREEN_BASE_WIDTH + SMALL_SCREEN_DIFF
export const SMALL_SCREEN_WIDTH = SMALL_SCREEN_BASE_WIDTH + 2 * SMALL_SCREEN_DIFF

export const SourceLine = css(texts.subheading, {
  display: 'flex',
  alignItems: 'center',
  padding: '20px',
  borderTop: `1px solid ${colors.border}`
})

export const SourceName = css(texts.subheading, {
  marginRight: '30px'
})

export const SourceItem = css({
  whiteSpace: 'nowrap',
  marginLeft: '10px',
  display: 'flex',
  alignItems: 'center',

  ' & > *': {
    display: 'inline-flex !important'
  }
})

export const SourcesList = css({
  marginLeft: 'auto',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-end'
})

export const SourceIcon = css(texts.subheading, {
  height: '17px',
  marginRight: '5px',
  marginLeft: '5px'
})

export const Sources = css({
  color: colors.blue,
  transform: 'translateY(1px)',
  display: 'inline-block'
})

export const Disabled = css({
  opacity: 0.5
})
