import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  uploadFile,
  parseExpenseFile,
  parseExpenseFileManually,
  getParsingStatus,
  deprecatedGetApps
} from '../../shared/actions'

const mapStateToProps = state => {
  const { id: idOrg } = state.org
  return {
    idOrg
  }
}

const mapDispatchToProps = {
  uploadFile,
  parseExpenseFile,
  parseExpenseFileManually,
  getParsingStatus,
  deprecatedGetApps
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
    onSuccess () {
      dispatchProps.deprecatedGetApps({ idOrg: stateProps.idOrg })
    },
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
