import React, { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  AppFilter,
  BranchActionFieldValue,
  BRANCH_FILTER_ENTITY,
  ConditionalBranch,
  ContractFilter,
  WorkflowActionsModel
} from '@shared/workflows/types'
import * as Style from './styles'
import { Button, ButtonSize, ButtonType, Tooltip } from '@toriihq/design-system'
import { getBranchActionFieldsOptions, getBranchingFilterOptionsValues, updateWorkflow } from '@shared/actions/workflows'
import { WORKFLOW_TRIGGER_TYPE } from '@shared/types'
import { compact, groupBy, isEqual } from 'lodash'
import { KeyOptionsByEntity, OnChangeBranchFilterProps, KeyOptionValueByEntity } from '@components/filters/ifElseBranchFilters/types'
import BranchFilters from '@components/filters/ifElseBranchFilters/branchFilters'
import { addBranchFilter } from './utils/addBranchFilter'
import { replaceBranchFilter } from './utils/replaceBranchFilter'
import { removeBranchFilter } from './utils/removeBranchFilter'
import { getUpdatedBranchFilter } from './utils/getUpdatedBranchFilter'
import Confirmation from '@components/confirmation'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { removeBranchAndDescendants } from '@components/filters/ifElseBranchFilters/utils/removeBranchAndDescendants'
import { DEFAULT_BRANCH_LABEL } from '@pages/workflow_v2/workflowEditor/consts'
import { BranchLabelInput } from './branchLabelInput'
import { UserEntityCriterion } from '@shared/filters/userFilters/types'
import { usePrevious } from '@shared/hooks'
import { analytics } from '@shared/services/workflows/analytics'
import { getNewBranchFilter } from './utils/getNewBranchFilter'
import { MAX_BRANCHES_PER_IF_ELSE_ACTION } from '@root/constants'
import { getTriggerBranchingEntityOptions } from '@selectors/workflows'
import { useParamSelector } from '@shared/utils'

interface Props {
  branches: BranchActionFieldValue
  triggerType: WORKFLOW_TRIGGER_TYPE
  onChange: (value: BranchActionFieldValue) => void
  disabled: boolean
}

const IfElseBranchFilters = ({
  branches: allBranches,
  triggerType,
  onChange,
  disabled
}: Props): ReactElement => {
  const { branches = [], default: defaultBranch } = allBranches
  const onlyOneBranchConfigured = branches.length === 1

  const dispatch = useDispatch()
  const { idOrg } = useParams()
  const workflow = useSelectedWorkflow()
  const entityOptions = useParamSelector(getTriggerBranchingEntityOptions, { triggerType })
  const { idSelectedNode } = useWorkflowNavigation()

  const [keyOptionsByEntityType, setKeyOptionsByEntityType] = useState<KeyOptionsByEntity | undefined>(undefined)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)
  const [branchIndexToDelete, setBranchIndexToDelete] = useState<number | undefined>(undefined)
  const [keyOptionValuesByEntityType, setKeyOptionValuesByEntityType] = useState<KeyOptionValueByEntity | undefined>(undefined)

  const canAddNewBranches = branches && branches.length < MAX_BRANCHES_PER_IF_ELSE_ACTION

  useEffect(() => {
    const fetchFilterOptions = async () => {
      const { filterOptionsByOutputType } = await dispatch(getBranchActionFieldsOptions({ idOrg, triggerType }))

      setKeyOptionsByEntityType(filterOptionsByOutputType)
    }

    if (idOrg && triggerType) {
      fetchFilterOptions()
    }
  }, [dispatch, idOrg, triggerType])

  const prevBranches = usePrevious(branches)

  useEffect(() => {
    const fetchFiltersOptionValues = async () => {
      const allFilters = branches.map(branch => branch.filters).flat()
      const filtersGroupedByEntity = groupBy(allFilters, 'entity')

      const userFilters = compact((filtersGroupedByEntity[BRANCH_FILTER_ENTITY.USER] || [])
        .map(filter => (filter.filter as UserEntityCriterion).filters))

      const appFilters = compact((filtersGroupedByEntity[BRANCH_FILTER_ENTITY.APP] || [])
        .map(filter => (filter.filter as AppFilter)))

      const contractFilters = compact((filtersGroupedByEntity[BRANCH_FILTER_ENTITY.CONTRACT] || [])
        .map(filter => (filter.filter as ContractFilter)))

      const response = await dispatch(
        getBranchingFilterOptionsValues({
          idOrg,
          filters: {
            user: userFilters,
            app: appFilters,
            contract: contractFilters
          }
        }))

      setKeyOptionValuesByEntityType(response)
    }

    const shouldFetchFilterOptionValues = !isEqual(prevBranches, branches)

    if (shouldFetchFilterOptionValues && idOrg) {
      fetchFiltersOptionValues()
    }
  }, [dispatch, idOrg, branches, prevBranches])

  const handleChange = (updatedBranches: ConditionalBranch[]) => onChange({ branches: updatedBranches, default: defaultBranch })

  const onRemoveBranchFilter = (branchIndex: number, branchFilterIndex: number) => {
    const updatedBranches = removeBranchFilter(branches, branchIndex, branchFilterIndex)
    handleChange(updatedBranches)
  }

  const onAddBranchFilter = (branchIndex: number) => {
    if (!entityOptions) {
      return
    }

    const updatedBranches = addBranchFilter({
      branches,
      branchIndex,
      entityOptions
    })
    handleChange(updatedBranches)
  }

  const onChangeBranchFilter = ({ branchIndex, branchFilterIndex, key, newValue }: OnChangeBranchFilterProps): void | undefined => {
    const newBranchFilter = getUpdatedBranchFilter({ branches, branchIndex, branchFilterIndex, key, newValue })
    if (!newBranchFilter) return
    const updatedBranches = replaceBranchFilter({ branches, branchIndex, branchFilterIndex, newBranchFilter })
    handleChange(updatedBranches)
  }

  const onAddBranch = (): void => {
    if (!entityOptions) {
      return
    }

    const newBranchFilter = getNewBranchFilter({ entityOptions })

    const newBranch: ConditionalBranch = {
      label: `Branch ${branches.length + 1}`,
      next: null,
      isValid: true,
      errorMsg: '',
      filters: [newBranchFilter]
    }
    const updatedBranches = branches.concat(newBranch)
    handleChange(updatedBranches)

    const currentNumberOfBranches = updatedBranches.length + 1
    analytics.branch.add({ triggerType, currentNumberOfBranches })
  }

  const openDeleteBranchConfirmation = (branchIndex: number) => {
    setBranchIndexToDelete(branchIndex)
    setIsConfirmationOpen(true)
    analytics.branch.delete.popupOpened({ triggerType })
  }

  const closeDeleteBranchConfirmation = (isCancelButton = false) => {
    handleCloseDeleteBranchConfirmation()
    analytics.branch.delete.popupClosed({ buttonLabel: isCancelButton ? 'Cancel' : 'X' })
  }

  const handleCloseDeleteBranchConfirmation = () => {
    setBranchIndexToDelete(undefined)
    setIsConfirmationOpen(false)
  }

  const onDeleteBranch = (): void => {
    handleCloseDeleteBranchConfirmation()

    if (onlyOneBranchConfigured) {
      return
    }

    const { nodes } = workflow.actions
    const { newNodes, removedIdNodes } = removeBranchAndDescendants({
      idNode: idSelectedNode!,
      branchIndexToDelete: branchIndexToDelete!,
      nodes
    })

    if (!newNodes) {
      return
    }

    const updatedActions: WorkflowActionsModel = {
      ...workflow.actions,
      nodes: newNodes
    }
    dispatch(updateWorkflow({
      idOrg,
      idWorkflow: workflow.id,
      workflow: { ...workflow, actions: updatedActions }
    }))

    analytics.branch.delete.deleted({
      triggerType,
      numberOfDescendants: removedIdNodes.length
    })
  }

  const updateBranchLabel = (
    { label, branchIndex }: { label: string, branchIndex: number }
  ) => {
    const updatedBranches = branches.map((branch, index) =>
      index === branchIndex ? { ...branch, label } : branch
    )
    handleChange(updatedBranches)
  }

  return (
    <Style.Wrapper>
      <Style.Branches>
        {branches.map((branch, index) => {
          const isFirstBranch = index === 0
          const { label, filters } = branch
          return (
            <Style.BranchWrapper key={index}>
              <Style.BranchWrapperTitle>{`${isFirstBranch ? 'First' : 'Then'}, check if:`}</Style.BranchWrapperTitle>
              <Style.Branch className={Style.BRANCH_CLASS_NAME}>
                <Style.BranchTitle>
                  <BranchLabelInput
                    label={label}
                    onBlurAfterChange={label => updateBranchLabel({ label, branchIndex: index })}
                    disabled={disabled}
                  />
                  <Style.DeleteButtonContainer>
                    <Button type={ButtonType.compact} size={ButtonSize.small}
                      htmlButtonType='button'
                      onClick={() => openDeleteBranchConfirmation(index)}
                      icon={'Trash'}
                      label={'Delete'}
                      disabled={disabled || onlyOneBranchConfigured}
                    />
                  </Style.DeleteButtonContainer>
                </Style.BranchTitle>
                <Style.BranchFilterWrapper>
                  <BranchFilters
                    filters={filters}
                    branchIndex={index}
                    entityOptions={entityOptions}
                    keyOptionsByEntityType={keyOptionsByEntityType}
                    keyOptionValuesByEntityType={keyOptionValuesByEntityType}
                    onRemove={onRemoveBranchFilter}
                    onChange={onChangeBranchFilter}
                    disabled={disabled}
                  />
                </Style.BranchFilterWrapper>
                <Style.BranchFiltersAction>
                  <Button type={ButtonType.compact} size={ButtonSize.small}
                    onClick={() => onAddBranchFilter(index)}
                    label='Add filter to branch'
                    disabled={disabled}
                    htmlButtonType='button'
                  />
                </Style.BranchFiltersAction>
              </Style.Branch>
            </Style.BranchWrapper>
          )
        })}
      </Style.Branches>
      <Style.BranchesActions>
        <Tooltip hide={canAddNewBranches} label={'You cannot add more than 20 branches'}>
          <Button
            type={ButtonType.primary}
            size={ButtonSize.small}
            onClick={onAddBranch}
            disabled={disabled || !canAddNewBranches}
            label='Add branch'
            htmlButtonType='button'
            icon={'Plus'}
          />
        </Tooltip>
      </Style.BranchesActions>
      <Style.Divider />
      <Style.DefaultBranch>
        <Style.DefaultBranchTitle>If none of the criteria are met, go here:</Style.DefaultBranchTitle>
        <Style.DefaultBranchLabel>{DEFAULT_BRANCH_LABEL}</Style.DefaultBranchLabel>
      </Style.DefaultBranch>
      <Confirmation
        isOpen={isConfirmationOpen}
        header={'Delete branch?'}
        text='By clicking “Delete”, all actions under this branch will be deleted and their configuration will no longer be available. The logs will not be affected.'
        confirmText='Delete branch'
        confirm={onDeleteBranch}
        close={closeDeleteBranchConfirmation}
        declineText='Cancel'
        decline={() => closeDeleteBranchConfirmation(true)}
        mainButtonType={ButtonType.primary}
      />
    </Style.Wrapper>
  )
}

export default IfElseBranchFilters
