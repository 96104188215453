import { DATE_FORMAT } from '@root/constants'
import { IMPORT_USERS_COLUMNS, USER_ERROR_TYPES } from '../types'
import moment from 'moment'
import { Icon, Spacer, Tooltip } from '@toriihq/design-system'

export const sharedEmailColumn = {
  Header: 'Email address',
  accessor: 'email',
  sortable: true
}

export const validUsersColumns = ({ columnsToShow, isFirstTimeImport, columnTooltips }) => [
  {
    ...sharedEmailColumn,
    Header: getColumnHeaderWithTooltip('Email address', columnTooltips[IMPORT_USERS_COLUMNS.EMAIL]),
    width: 190
  },
  {
    Header: getColumnHeaderWithTooltip('Licenses', columnTooltips[IMPORT_USERS_COLUMNS.LICENSES]),
    accessor: 'licenses',
    Cell: ({ value: licenses }) => licenses?.map(license => license.name).join(', '),
    sortable: false,
    show: columnsToShow.includes(IMPORT_USERS_COLUMNS.LICENSES)
  },
  {
    Header: getColumnHeaderWithTooltip('Last used date', columnTooltips[IMPORT_USERS_COLUMNS.LAST_VISIT_TIME]),
    accessor: 'lastVisitTime',
    Cell: ({ value: date }) => date ? moment.utc(date).format(DATE_FORMAT) : null,
    width: 170,
    sortable: true,
    show: columnsToShow.includes(IMPORT_USERS_COLUMNS.LAST_VISIT_TIME)
  },
  {
    Header: 'Status',
    accessor: 'alreadyInApp',
    Cell: ({ value: alreadyInApp }) => alreadyInApp ? 'Updated' : 'New',
    width: 100,
    sortable: true,
    show: !isFirstTimeImport
  }
]

export const invalidUsersColumns = [
  {
    ...sharedEmailColumn,
    resizable: false
  },
  {
    Header: 'Issue',
    id: 'error',
    accessor: 'errorType',
    Cell: ({ value }: { value: USER_ERROR_TYPES }) => USER_ERROR_TYPE_TO_UI_ERROR[value],
    resizable: false,
    sortable: true
  }
]

export const missingUsersColumns = [
  {
    ...sharedEmailColumn,
    resizable: false
  }
]

const USER_ERROR_TYPE_TO_UI_ERROR = {
  [USER_ERROR_TYPES.INVALID_EMAIL]: 'Invalid email address',
  [USER_ERROR_TYPES.DUPLICATE_EMAIL]: 'Duplicate email',
  [USER_ERROR_TYPES.INVALID_LAST_VISIT_TIME]: 'Invalid last used date'
}

export const getColumnHeaderWithTooltip = (header: string, tooltipText: string) =>
  <div style={{ alignItems: 'center', display: 'flex' }}>
    <span>{header}</span>
    { tooltipText && <Spacer left={'space-075'}>
      <Tooltip label={tooltipText}>
        <Icon name='Info' color='tertiary' />
      </Tooltip>
    </Spacer>}
  </div>
