import {
  TOGGLE_NAVIGATION,
  TOGGLE_SMALL_SCREEN,
  TOGGLE_INVITE_USER,
  TOGGLE_ASSIGN_USER,
  TOGGLE_CONNECT_SOURCE,
  TOGGLE_UPLOAD_EXPENSES,
  TOGGLE_IMPORT_CONTRACTS_FILE,
  TOGGLE_ALL_EXPENSE_CATEGORIES,
  TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS,
  TOGGLE_CONNECT_SERVICE,
  SORT_TABLE,
  FILTER_TABLE,
  TOGGLE_FILTER_TABLE,
  SET_HEADER_HEIGHT,
  LOAD_USER_PREFERENCES,
  TABLES,
  CONFIGURE_TABLE_COLUMNS,
  TOGGLE_SELECT_FIELD_TYPE_POPUP,
  TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP,
  TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING,
  TOGGLE_HIDE_APP_POPUP,
  TOGGLE_OFFBOARDING_PREVIEW_POPUP,
  TOGGLE_OFFBOARDING_STARTED_POPUP,
  GET_TABLE_VIEWS,
  GET_CONNECT_INTEGRATION_LINK,
  UPDATE_USER_PREFERENCES,
  EDIT_TABLE_VIEW,
  ADD_TABLE_VIEW,
  DELETE_TABLE_VIEW,
  TABLE_CUSTOM_SELECT_CHANGE,
  TOGGLE_TRIGGER_PREVIEW_POPUP,
  TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS,
  TOGGLE_MANUAL_ACTION_STARTED_POPUP,
  TOGGLE_APP_DETAIL_HISTORY_POPUP,
  TOGGLE_ADD_APPLICATION,
  TOGGLE_MATCHING_RULES_POPUP,
  TOGGLE_CONTRACT_POPUP,
  TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP,
  TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP,
  TOGGLE_CONFIGURE_APP_CHARGEBACK,
  TOGGLE_SHOW_HIDDEN_WORKFLOWS,
  TOGGLE_UNSAFE_MODE,
  TOGGLE_WORKFLOW_PREVIEW_POPUP,
  TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP,
  TOGGLE_SENT_EMAILS_POPUP,
  TOGGLE_CSV_EXPORT_POPUP,
  TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP,
  TOGGLE_ROLE_POPUP,
  TOGGLE_USER_LIFECYCLE_SETTINGS,
  TOGGLE_ADD_USER,
  TOGGLE_CUSTOM_ACTION_RESPONSE,
  TOGGLE_USERS_OVERLAP_POPUP,
  TOGGLE_CONNECT_CUSTOM_SERVICE,
  TOGGLE_POPUP,
  TOGGLE_CONNECT_SCIM_SERVICE,
  APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS,
  TOGGLE_ACTIVATE_WORKFLOW_POPUP,
  TOGGLE_WORKFLOW_STARTED_POPUP,
  TOGGLE_ADD_APP_CATALOG_POLICY_POPUP,
  TOGGLE_WORKFLOW_AUDIT_LOGS,
  TOGGLE_VIEW_ONLY_MODE,
  TOGGLE_IMPORT_USERS_POPUP,
  GET_CURRENCIES,
  TOGGLE_SYNC_STATUS_POPUP,
  TOGGLE_LICENSE_EDIT_POPUP,
  WORKFLOW_TYPES,
  EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS,
  LICENSES_CUSTOM_SELECT_OPTIONS,
  TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP,
  TOGGLE_NAVIGATION_COLLAPSE,
  TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP,
  TOGGLE_EDIT_WORKFLOW_TAGS_POPUP,
  TOGGLE_SUBSCRIBE_POPUP,
  TOGGLE_CREATE_WORKFLOW_TAGS_POPUP,
  RENEWALS_SUMMARY_BASE_YEAR_CHANGE,
  TOGGLE_EDIT_WORKFLOW_TAG_POPUP,
  INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS,
  INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS
} from '@root/constants'
import update from 'immutability-helper'
import keyBy from 'lodash/keyBy'
import qs from 'query-string'
import uiReducerExpander from './expander'

const DEFAULT_USER_DETAILS_TO_SHOW = ['gsuite_orgUnitPath', 'azure_department']

const initialState = {
  showHiddenWorkflows: qs.parse(window.location.search).hidden === 'true',
  unsafeMode: false,
  viewOnlyMode: false,
  headerHeight: {
    top: 0,
    height: 0
  },
  isActionsListVisible: true,
  isNavigationVisible: false,
  forceNavigationCollapse: false,
  isSmallScreen: false,
  isInviteOpen: false,
  isUserLifecycleOpen: false,
  isConnectSourceOpen: false,
  hideConnectByLink: false,
  isUploadExpensesOpen: false,
  isImportContractsOpen: false,
  isAllExpenseCategoriesOpen: false,
  importUsersPopup: {
    isOpen: false,
    idApp: null,
    appName: null
  },
  syncStatusPopup: {
    isOpen: false,
    idAppAccount: null,
    connectByMessage: null
  },
  connectService: {
    isOpen: false,
    idApp: null,
    appName: null
  },
  connectCustomService: {
    isOpen: false,
    coordinate: 0
  },
  connectSCIMService: {
    isOpen: false,
    idApp: undefined
  },
  addApplication: {
    isOpen: false,
    editMode: false,
    app: null,
    onSuccessCB: () => null,
    openFrom: ''
  },
  appDetailsAttachments: {
    isOpen: false,
    idApp: undefined,
    idField: undefined
  },
  selectFieldTypePopup: {
    isOpen: false,
    idGroup: undefined,
    addDetailsField: undefined
  },
  subscribePopup: {
    isOpen: false
  },
  updateAppDetailFieldTypePopup: {
    isOpen: false,
    idField: undefined
  },
  updateContractDetailFieldTypePopup: {
    isOpen: false,
    idField: undefined
  },
  configureAppForOffboardingPopup: {
    isOpen: false
  },
  configureExecuteActionOnUsersPopup: {
    isOpen: false
  },
  offboardingPreviewPopup: {
    isOpen: false,
    users: []
  },
  offboardingStartedPopup: {
    isOpen: false,
    idUsers: undefined
  },
  appDetailHistoryPopup: {
    isOpen: false,
    idApp: undefined,
    idField: undefined
  },
  contractDetailHistoryPopup: {
    isOpen: false,
    idContract: undefined,
    systemKey: undefined
  },
  manualActionStartedPopup: {
    isOpen: false,
    users: [],
    idWorkflow: undefined,
    triggerType: undefined
  },
  triggerPreviewPopup: {
    isOpen: false,
    users: undefined,
    isLoading: true
  },
  discoveredAppsPreviewPopup: {
    isOpen: false,
    onMainActionButton: undefined,
    onCancelButton: undefined
  },
  assignUser: {
    isOpen: false,
    idApp: undefined,
    onSelectUser: undefined,
    suggestOwner: undefined,
    actionDescription: '',
    componentPage: undefined,
    actionNotes: undefined
  },
  addUser: {
    isOpen: false,
    email: '',
    fieldId: ''
  },
  matchingRulesPopup: {
    isOpen: false
  },
  contractPopup: {
    isOpen: false,
    idContract: undefined,
    idApp: undefined,
    initialValues: {}
  },
  licenseEditPopup: {
    isOpen: false
  },
  askToUpdateLicenseCostPopup: {
    isOpen: false
  },
  configureAppChargebackPopup: {
    isOpen: false,
    idApp: null
  },
  workflowPreviewPopup: {
    isOpen: false
  },
  csvExportPopup: {
    isOpen: false
  },
  manuallyRunWorkflowPopup: {
    isOpen: false,
    triggerType: undefined,
    idWorkflow: undefined,
    workflowName: undefined,
    workflowType: WORKFLOW_TYPES.regular,
    formInitialValues: {}
  },
  connectIntegrationLink: {
    loading: false,
    error: false
  },
  configureBrowserExtensionActivationEmailPopup: {
    isOpen: false
  },
  sentEmailsPopup: {
    isOpen: false,
    sampleUsers: undefined,
    usersCount: 0,
    idWorkflow: undefined
  },
  rolePopup: {
    isOpen: false,
    idRole: undefined,
    isReadOnly: false
  },
  customActionResponsePopup: {
    isOpen: false,
    action: null
  },
  usersOverlapPopup: {
    isOpen: false,
    apps: [],
    idUsers: [],
    withLicenses: false
  },
  activateWorkflowPopup: {
    isOpen: false,
    idWorkflow: undefined,
    workflowName: undefined,
    triggerType: undefined
  },
  workflowStartedPopup: {
    isOpen: false,
    idWorkflow: undefined,
    workflowName: undefined,
    triggerType: undefined,
    referrer: undefined,
    entitiesAmount: undefined,
    workflowType: WORKFLOW_TYPES.regular
  },
  addAppCatalogPolicyPopup: {
    isOpen: false,
    isViewOnly: false
  },
  workflowAuditLogs: {
    isOpen: false,
    idWorkflow: null,
    workflowName: '',
    isAppCatalogPoliciesView: false
  },
  editWorkflowTagsPopup: {
    isOpen: false,
    idWorkflow: null
  },
  createWorkflowTagsPopup: {
    isOpen: false
  },
  editWorkflowTagPopup: {
    isOpen: false,
    idTag: null
  },
  tableViews: {},
  userPreferences: {
    [TABLES.appsV2Table.key]: {
      defaultSort: [
        { id: 'expenses', desc: true },
        { id: 'activeUsersCount', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', 'activeUsersCount', 'expenses', 'score', 'state']
    },
    [TABLES.appUsersTable.key]: {
      defaultSort: [
        { id: 'score', desc: true }
      ],
      columnsConfiguration: ['fullName', 'originEmail', 'appAccountName', 'lastVisitTime', 'licenses.name', 'score', 'externalStatus', 'sources', 'role', 'annualCostConverted'].concat(DEFAULT_USER_DETAILS_TO_SHOW),
      defaultCustomSelectOption: APP_USERS_CUSTOM_SELECT_OPTIONS_FOR_NON_CONNECTED_APPS.active
    },
    [TABLES.appActiveUsersListPopup.key]: {
      defaultSort: [
        { id: 'score', desc: true }
      ],
      columnsConfiguration: ['fullName', 'licenses.name', 'score', 'lastVisitTime']
    },
    [TABLES.usersListPopup.key]: {
      defaultSort: [
        { id: 'name', desc: true }
      ]
    },
    [TABLES.inactiveUsersListPopup.key]: {
      defaultSort: [
        { id: 'licenseName', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.inactiveUsersListPopup.preDefinedColumnsMapping).concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.teamUsersTable.key]: {
      defaultSort: []
    },
    [TABLES.expensesTable.key]: {
      defaultSort: [
        { id: 'total', desc: true }
      ]
    },
    [TABLES.allExpensesTable.key]: {
      defaultSort: [
        { id: 'transactionDate', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.allExpensesTable.preDefinedColumnsMapping),
      defaultCustomSelectOption: EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS.matched
    },
    [TABLES.appExpensesTable.key]: {
      defaultSort: [
        { id: 'transactionDate', desc: true }
      ],
      columnsConfiguration: Object.keys(TABLES.appExpensesTable.preDefinedColumnsMapping),
      defaultCustomSelectOption: EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS.matched
    },
    [TABLES.allDiscoveredAppsTable.key]: {
      defaultSort: [
        { id: 'mappedIdApp', desc: false }
      ],
      columnsConfiguration: ['name', 'source', 'mappingLogic', 'mappedIdApp', 'creationTime', 'accountName']
    },
    [TABLES.appDiscoveredAppsTable.key]: {
      defaultSort: [
        { id: 'mappedIdApp', desc: false }
      ],
      columnsConfiguration: ['name', 'mappingLogic', 'mappedIdApp', 'creationTime', 'accountName']
    },
    [TABLES.monthlyExpensesReportTable.key]: {
      defaultSort: [
        {
          id: 'total',
          desc: true
        }
      ]
    },
    [TABLES.extensionRolloutReportTable.key]: {
      defaultSort: [
        {
          id: 'lastReportedTime',
          desc: true
        }
      ]
    },
    [TABLES.thirdPartyReportTable.key]: {
      defaultSort: [
        {
          id: 'riskLevel',
          desc: true
        }
      ]
    },
    [TABLES.inactiveUsersReportV2Table.key]: {
      defaultSort: [
        {
          id: 'lastVisitTime',
          desc: true
        }
      ],
      defaultCustomSelectOption: INACTIVE_USERS_REPORT_TABLE_CUSTOM_SELECT_OPTIONS.all
    },
    [TABLES.userAppsTable.key]: {
      defaultSort: [
        {
          id: 'score',
          desc: true
        }
      ]
    },
    [TABLES.usersTable.key]: {
      defaultSort: [
        {
          id: 'fullName',
          desc: false
        }
      ],
      columnsConfiguration: ['fullName', 'additionalEmails', 'activeAppsCount'].concat(DEFAULT_USER_DETAILS_TO_SHOW)
    },
    [TABLES.catalogUsersList.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.deletedUsersTable.key]: {
      defaultSort: [
        {
          id: 'identitySourcesDeletionTime',
          desc: true
        }
      ],
      columnsConfiguration: ['fullName', 'email', 'additionalEmails', 'identitySourcesDeletionTime']
    },
    [TABLES.externalUsersTable.key]: {
      defaultSort: [
        {
          id: 'fullName',
          desc: false
        }
      ]
    },
    [TABLES.transactionsTable.key]: {
      defaultSort: [
        {
          id: 'transactionDate',
          desc: false
        }
      ]
    },
    [TABLES.licensesTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ],
      defaultCustomSelectOption: LICENSES_CUSTOM_SELECT_OPTIONS.paid
    },
    [TABLES.ssoAuditTable.key]: {
      defaultSort: [
        {
          id: 'managedUsers',
          desc: true
        }
      ]
    },
    [TABLES.workflowsTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.customAppsTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.hiddenAppsTable.key]: {
      defaultSort: [
        {
          id: 'hiddenTime',
          desc: false
        }
      ]
    },
    [TABLES.notConfiguredAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.configuredAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.needsAttentionAppsTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.automaticOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.delegateOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.ignoredOffboardingTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.offboardingTodoTable.key]: {
      defaultSort: [
        {
          id: 'identitySourcesDeletionTime',
          desc: true
        }
      ]
    },
    [TABLES.offboardingInprogressTable.key]: {
      defaultSort: [
        {
          id: 'status',
          desc: true
        }
      ]
    },
    [TABLES.offboardingDoneTable.key]: {
      defaultSort: [
        {
          id: 'offboardingEndTime',
          desc: true
        }
      ]
    },
    [TABLES.chargeBackReportTableV2.key]: {
      defaultSort: [{}]
    },
    [TABLES.appsListPopupTable.key]: {
      defaultSort: [
        {
          id: 'name',
          desc: false
        }
      ]
    },
    [TABLES.appDetailHistoryPopupTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.contractDetailHistoryPopupTable.key]: {
      defaultSort: [
        {
          id: 'creationTime',
          desc: false
        }
      ]
    },
    [TABLES.userTasksTable.key]: {
      defaultSort: [
        {
          id: 'requestedOn',
          desc: false
        }
      ]
    },
    [TABLES.contractsTable.key]: {
      defaultSort: [
        { id: 'endDate', desc: true }
      ],
      columnsConfiguration: ['name', 'idApp', 'status', 'endDate', 'amount', 'owner', 'documents']
    },
    [TABLES.unmatchedContractsTable.key]: {
      defaultSort: [
        { id: 'id', desc: true }
      ],
      columnsConfiguration: ['name', 'source', 'supplier', 'documents']
    },
    [TABLES.appContractsTable.key]: {
      defaultSort: [
        { id: 'endDate', desc: true }
      ],
      columnsConfiguration: ['name', 'idApp', 'status', 'endDate', 'amount', 'owner', 'documents']
    },
    [TABLES.appChargebackTable.key]: {
      defaultSort: [
        { id: 'totalCost', desc: true }
      ]
    },
    [TABLES.catalogAppsPopup.key]: {
      defaultSort: [
        { id: 'creationTime', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', 'activeUsersCount', 'creationTime', 'score', 'state', 'appearsInCatalog', 'sources']
    },
    [TABLES.workflowsActionExecutionsTable.key]: {},
    [TABLES.apiKeysTable.key]: {},
    [TABLES.licenseCostTable.key]: {},
    [TABLES.detailsGroupTable.key]: {},
    [TABLES.expensesUploadActivityTable.key]: {},
    [TABLES.triggerPreviewPopup.key]: {},
    [TABLES.mapUsersTable.key]: {},
    [TABLES.uploadsTable.key]: {},
    [TABLES.workflowsExecutionsTable.key]: {},
    [TABLES.comparisonRecommendationsTable.key]: {
      defaultSort: [
        {
          id: 'overlappingUsers',
          desc: true
        },
        {
          id: 'similarApps',
          desc: false
        }
      ]
    },
    [TABLES.integrationsTable.key]: {
      defaultSort: [
        { id: 'name', desc: false }
      ],
      defaultCustomSelectOption: INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS.all
    },
    [TABLES.licenseRecommendationsTable.key]: {
      defaultSort: [],
      columnsConfiguration: Object.keys(TABLES.licenseRecommendationsTable.preDefinedColumnsMapping).concat(['gsuite_department', 'okta_department', 'azure_department'])
    },
    renewalsSummary: {
      baseYear: { value: 0, label: '' }
    },
    [TABLES.monthlyExpensesReport.key]: {
      defaultSort: [
        { id: 'total', desc: true }
      ],
      columnsConfiguration: ['name', 'owner', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', 'total']
    },
    [TABLES.workflowsExecutionsAudit.key]: {
      defaultSort: [{ id: 'id', desc: true }]
    }
  },
  openPopups: [],
  deleteImportedUsersPopup: { isOpen: false }
}

const uiReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_TABLE_VIEWS}_PENDING`: {
      return {
        ...state,
        tablesViews: {
          ...state.tablesViews,
          loading: true
        }
      }
    }
    case `${GET_TABLE_VIEWS}_FAILED`: {
      return {
        ...state,
        tablesViews: {
          ...state.tablesViews,
          loading: false
        }
      }
    }
    case `${GET_TABLE_VIEWS}_RESPONSE`: {
      const { tableKey } = action.meta
      const { tableViews, users } = action.payload
      const tableViewsById = keyBy(tableViews, 'id')
      const usersById = keyBy(users, 'id')

      const { id } = state.userPreferences[tableKey] || {}
      const selectedView = id && tableViewsById[id] ? tableViewsById[id] : tableViews[0]

      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [tableKey]: { $merge: { ...selectedView.preferences, id: selectedView.id, shareStatus: selectedView.shareStatus, idUser: selectedView.idUser } }
        }),
        tablesViews: {
          ...state.tablesViews,
          loading: false,
          [tableKey]: tableViewsById,
          usersById
        }
      }
    }

    case `${ADD_TABLE_VIEW}_RESPONSE`: {
      const { tableKey } = action.meta
      const { view } = action.payload

      const table = state.tablesViews[tableKey]
      const newPreferences = { ...view.preferences, id: view.id, shareStatus: view.shareStatus, idUser: view.idUser }

      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [tableKey]: { $merge: newPreferences }
        }),
        tablesViews: {
          ...state.tablesViews,
          [tableKey]: {
            ...table,
            [view.id]: {
              ...view
            }
          }
        }
      }
    }

    case `${EDIT_TABLE_VIEW}_RESPONSE`: {
      const { tableKey, isSelectedView } = action.meta
      const { view } = action.payload

      const table = state.tablesViews[tableKey]
      const newPreferences = { ...view.preferences, id: view.id }

      if (!isSelectedView) {
        return state
      }

      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [tableKey]: { $merge: newPreferences }
        }),
        tablesViews: {
          ...state.tablesViews,
          [tableKey]: {
            ...table,
            [view.id]: {
              ...view
            }
          }
        }
      }
    }

    case `${DELETE_TABLE_VIEW}_RESPONSE`: {
      const { tableKey, view } = action.meta

      const table = state.tablesViews[tableKey]
      delete table[view.id]

      return {
        ...state,
        tablesViews: {
          ...state.tablesViews,
          [tableKey]: {
            ...table
          }
        }
      }
    }
    case RENEWALS_SUMMARY_BASE_YEAR_CHANGE: {
      const { renewalsSummaryBaseYear } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          renewalsSummary: {
            baseYear: { $set: renewalsSummaryBaseYear }
          }
        })
      }
    }
    case TABLE_CUSTOM_SELECT_CHANGE: {
      const { table, selectedOption, isSecondaryCustomSelect } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [table]: { $merge: { [isSecondaryCustomSelect ? 'defaultSecondaryCustomSelectOption' : 'defaultCustomSelectOption']: selectedOption } }
        })
      }
    }
    case SORT_TABLE: {
      const { table, sortingInfo: sort } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [table]: { $merge: { defaultSort: sort } }
        })
      }
    }
    case FILTER_TABLE: {
      const { table, filters } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [table]: { $merge: { filters } }
        })
      }
    }
    case CONFIGURE_TABLE_COLUMNS: {
      const { table, columns } = action.payload

      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [table]: { $merge: { columnsConfiguration: columns, sortedOnce: true } }
        })
      }
    }
    case TOGGLE_FILTER_TABLE: {
      const { table, isOpen } = action.payload
      return {
        ...state,
        userPreferences: update(state.userPreferences, {
          [table]: { $merge: { isFiltersOpen: isOpen } }
        })
      }
    }
    case TOGGLE_NAVIGATION: {
      return {
        ...state,
        isNavigationVisible: !state.isNavigationVisible
      }
    }
    case TOGGLE_NAVIGATION_COLLAPSE: {
      const { forceNavigationCollapse } = action.payload
      return {
        ...state,
        forceNavigationCollapse
      }
    }
    case TOGGLE_SMALL_SCREEN: {
      const { isSmallScreen } = action.payload
      return {
        ...state,
        isSmallScreen
      }
    }
    case TOGGLE_INVITE_USER: {
      const { isInviteOpen } = action.payload
      return {
        ...state,
        isInviteOpen
      }
    }
    case TOGGLE_USER_LIFECYCLE_SETTINGS: {
      const { isUserLifecycleSettingsOpen } = action.payload
      return {
        ...state,
        isUserLifecycleSettingsOpen
      }
    }
    case TOGGLE_ADD_APPLICATION: {
      const { isAddApplicationOpen, editMode, app, customApp, onSuccessCB, openFrom } = action.payload
      return {
        ...state,
        addApplication: {
          isOpen: isAddApplicationOpen,
          editMode,
          app,
          customApp,
          onSuccessCB,
          openFrom
        }
      }
    }
    case TOGGLE_CONFIGURE_APP_CHARGEBACK: {
      const { isConfigureAppChargebackOpen, idApp, callback } = action.payload
      return {
        ...state,
        configureAppChargebackPopup: {
          isOpen: isConfigureAppChargebackOpen,
          idApp,
          callback
        }
      }
    }
    case TOGGLE_CONNECT_SERVICE: {
      const { isConnectServiceOpen, idApp, appName, isReconnect, showReconnectAlertInfo } = action.payload
      return {
        ...state,
        connectService: {
          isOpen: isConnectServiceOpen,
          idApp,
          appName,
          isReconnect,
          showReconnectAlertInfo
        }
      }
    }
    case TOGGLE_CONNECT_CUSTOM_SERVICE: {
      const { isOpen, coordinate } = action.payload
      return {
        ...state,
        connectCustomService: {
          isOpen,
          coordinate: coordinate || state.connectCustomService.coordinate
        }
      }
    }
    case TOGGLE_CONNECT_SCIM_SERVICE: {
      const { isOpen, idApp, isReconnect, showReconnectAlertInfo, idAppAccount, idAppToken } = action.payload
      return {
        ...state,
        connectSCIMService: {
          isOpen,
          idApp,
          isReconnect,
          showReconnectAlertInfo,
          idAppAccount,
          idAppToken
        }
      }
    }
    case TOGGLE_CONNECT_SOURCE: {
      const { isConnectSourceOpen, sourceId, sourceIdApp, onConnect, isReconnect, showReconnectAlertInfo, idAppToken, idAppAccount, validTestInProgress, hideConnectByLink } = action.payload
      return {
        ...state,
        isConnectSourceOpen,
        sourceId,
        sourceIdApp,
        onConnect,
        isReconnect,
        showReconnectAlertInfo,
        idAppToken,
        idAppAccount,
        validTestInProgress,
        hideConnectByLink
      }
    }
    case TOGGLE_ASSIGN_USER: {
      const { isSelectUserOpen = !state.assignUser.isOpen, idApp, onSelectUser, suggestOwner, actionDescription, assignUserCB, componentPage, actionNotes, isMultipleSelect } = action.payload
      return {
        ...state,
        assignUser: {
          isOpen: isSelectUserOpen,
          idApp: isSelectUserOpen ? idApp : undefined,
          onSelectUser: isSelectUserOpen ? onSelectUser : undefined,
          suggestOwner: isSelectUserOpen ? suggestOwner : undefined,
          assignUserCB: isSelectUserOpen ? assignUserCB : undefined,
          actionDescription: isSelectUserOpen ? actionDescription : '',
          componentPage,
          actionNotes: isSelectUserOpen ? actionNotes : undefined,
          isMultipleSelect: isSelectUserOpen ? isMultipleSelect : undefined
        }
      }
    }
    case TOGGLE_ADD_USER: {
      const { isOpen = !state.addUser.isOpen, email, fieldId } = action.payload
      return {
        ...state,
        addUser: {
          isOpen,
          email: isOpen ? email : '',
          fieldId
        }
      }
    }
    case TOGGLE_HIDE_APP_POPUP: {
      const { open, idApp, name } = action.payload
      return {
        ...state,
        hideAppPopup: {
          open,
          idApp,
          name
        }
      }
    }
    case TOGGLE_UPLOAD_EXPENSES: {
      const { isUploadExpensesOpen } = action.payload
      return {
        ...state,
        isUploadExpensesOpen
      }
    }
    case TOGGLE_IMPORT_CONTRACTS_FILE: {
      const { isImportContractsOpen } = action.payload
      return {
        ...state,
        isImportContractsOpen
      }
    }
    case TOGGLE_UPLOAD_APP_DETAILS_ATTACHMENTS: {
      const { isOpen, idApp, idField } = action.payload
      return {
        ...state,
        appDetailsAttachments: {
          isOpen,
          idApp: isOpen ? idApp : undefined,
          idField: isOpen ? idField : undefined
        }
      }
    }
    case TOGGLE_TRIGGER_PREVIEW_POPUP: {
      const { isOpen, idWorkflow, workflowName, triggerPreviewConfig } = action.payload
      return {
        ...state,
        triggerPreviewPopup: {
          isOpen,
          idWorkflow: isOpen ? idWorkflow : undefined,
          workflowName: isOpen ? workflowName : undefined,
          triggerPreviewConfig: isOpen ? triggerPreviewConfig : undefined
        }
      }
    }
    case TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP: {
      const { isOpen, onMainActionButton, onCancelButton } = action.payload

      return {
        ...state,
        discoveredAppsPreviewPopup: {
          isOpen,
          onMainActionButton,
          onCancelButton
        }
      }
    }
    case TOGGLE_ALL_EXPENSE_CATEGORIES: {
      const { isAllExpenseCategoriesOpen } = action.payload
      return {
        ...state,
        isAllExpenseCategoriesOpen
      }
    }
    case TOGGLE_SELECT_FIELD_TYPE_POPUP: {
      const { isOpen, idGroup, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType } = action.payload
      return {
        ...state,
        selectFieldTypePopup: {
          isOpen,
          idGroup,
          addDetailsField,
          groupsForSelectGroup,
          hideFormQuestion,
          entityType
        }
      }
    }
    case TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP: {
      const { idField } = action.payload
      const isOpenNext = !state.updateAppDetailFieldTypePopup.isOpen
      return {
        ...state,
        updateAppDetailFieldTypePopup: {
          isOpen: isOpenNext,
          idField: isOpenNext ? idField : undefined
        }
      }
    }
    case TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP: {
      const { idField } = action.payload
      const isOpenNext = !state.updateContractDetailFieldTypePopup.isOpen
      return {
        ...state,
        updateContractDetailFieldTypePopup: {
          isOpen: isOpenNext,
          idField: isOpenNext ? idField : undefined
        }
      }
    }
    case TOGGLE_CONFIGURE_APP_FOR_OFFBOARDING: {
      const { isOpen, idApp, idAppAccount, appAndAccountName, actions, onConfig, onClose, pendingOffboardingAmount, showAlertBox } = action.payload
      return {
        ...state,
        configureAppForOffboardingPopup: {
          isOpen,
          idApp,
          idAppAccount,
          appAndAccountName,
          actions,
          onConfig,
          onClose,
          pendingOffboardingAmount,
          showAlertBox
        }
      }
    }
    case TOGGLE_CUSTOM_ACTION_RESPONSE: {
      const { isOpen, onClose, action: customAction } = action.payload
      return {
        ...state,
        customActionResponsePopup: {
          isOpen,
          onClose,
          action: customAction
        }
      }
    }
    case TOGGLE_CONFIGURE_EXECUTE_ACTION_ON_USERS: {
      const { isOpen, idApp, users, onConfig, actionToRun, extraFieldsDefaultValues, fieldsToValue, executionFlow, flowType, idRecommendation } = action.payload
      return {
        ...state,
        configureExecuteActionOnUsersPopup: {
          isOpen,
          idApp,
          users,
          onConfig,
          actionToRun,
          extraFieldsDefaultValues,
          fieldsToValue,
          flowType,
          executionFlow,
          idRecommendation
        }
      }
    }
    case TOGGLE_OFFBOARDING_PREVIEW_POPUP: {
      const { isOpen, users } = action.payload
      return {
        ...state,
        offboardingPreviewPopup: {
          isOpen,
          users
        }
      }
    }
    case TOGGLE_OFFBOARDING_STARTED_POPUP: {
      const { isOpen, users } = action.payload
      return {
        ...state,
        offboardingStartedPopup: {
          isOpen,
          users
        }
      }
    }
    case TOGGLE_APP_DETAIL_HISTORY_POPUP: {
      const { isOpen, idApp, detail } = action.payload
      return {
        ...state,
        appDetailHistoryPopup: {
          isOpen,
          idApp,
          detail
        }
      }
    }
    case TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP: {
      const { isOpen, idContract, detail, idApp } = action.payload
      return {
        ...state,
        contractDetailHistoryPopup: {
          isOpen,
          idContract,
          detail,
          idApp
        }
      }
    }
    case TOGGLE_MANUAL_ACTION_STARTED_POPUP: {
      const { isOpen, users, idWorkflow, triggerType } = action.payload
      return {
        ...state,
        manualActionStartedPopup: {
          isOpen,
          users,
          idWorkflow,
          triggerType
        }
      }
    }
    case TOGGLE_MATCHING_RULES_POPUP: {
      const { isOpen } = action.payload
      return {
        ...state,
        matchingRulesPopup: {
          isOpen
        }
      }
    }
    case TOGGLE_CONTRACT_POPUP: {
      const { isOpen, isByUser, idContract, idApp, initialValues, isDuplicated, isAppMatchingMode, onSuccessCB } = action.payload

      return {
        ...state,
        contractPopup: {
          isOpen,
          isByUser,
          idContract: isOpen && idContract ? idContract : undefined,
          idApp: isOpen && idApp ? idApp : undefined,
          initialValues,
          isDuplicated,
          isAppMatchingMode,
          onSuccessCB
        }
      }
    }
    case TOGGLE_LICENSE_EDIT_POPUP: {
      const { isOpen, idApp, idLicense, appName, licenses, selectedColumn, conversionDateFromContract, currencyFromContract, openedFromContractFlow } = action.payload
      return {
        ...state,
        licenseEditPopup: {
          isOpen,
          idApp,
          idLicense,
          appName,
          licenses,
          selectedColumn,
          conversionDateFromContract,
          currencyFromContract,
          openedFromContractFlow
        }
      }
    }
    case TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP: {
      const { isOpen, idApp, appName, licenses, conversionDate, currency } = action.payload
      return {
        ...state,
        askToUpdateLicenseCostPopup: {
          isOpen,
          idApp,
          appName,
          licenses,
          conversionDate,
          currency
        }
      }
    }
    case SET_HEADER_HEIGHT: {
      const { height, top } = action.payload
      return {
        ...state,
        headerHeight: { height, top }
      }
    }
    case LOAD_USER_PREFERENCES: {
      const { userPreferences } = action.payload || {}
      const nextUserPreferences = Object
        .keys(userPreferences || {})
        .reduce((result, key) => ({
          ...result,
          [key]: update(state.userPreferences[key] || {}, {
            $merge: userPreferences[key]
          })
        }), {})

      return {
        ...state,
        userPreferences: {
          ...state.userPreferences,
          ...nextUserPreferences
        }
      }
    }
    case UPDATE_USER_PREFERENCES: {
      const { table, userPreference } = action.payload || {}

      state.userPreferences[table] = userPreference
      return {
        ...state,
        userPreferences: {
          ...state.userPreferences
        }
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK}_PENDING`: {
      return {
        ...state,
        connectIntegrationLink: {
          loading: true,
          error: false
        }
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK}_FAILED`: {
      return {
        ...state,
        connectIntegrationLink: {
          loading: false,
          error: true
        }
      }
    }
    case `${GET_CONNECT_INTEGRATION_LINK}_RESPONSE`: {
      return {
        ...state,
        connectIntegrationLink: {
          loading: false,
          error: false
        }
      }
    }

    case TOGGLE_SHOW_HIDDEN_WORKFLOWS: {
      const { showHiddenWorkflows } = action.meta

      return {
        ...state,
        showHiddenWorkflows
      }
    }

    case TOGGLE_UNSAFE_MODE: {
      const { unsafeMode } = action.meta

      return {
        ...state,
        unsafeMode
      }
    }

    case TOGGLE_WORKFLOW_PREVIEW_POPUP: {
      const { isOpen, actionType } = action.payload

      return {
        ...state,
        workflowPreviewPopup: {
          isOpen,
          actionType
        }
      }
    }

    case TOGGLE_CSV_EXPORT_POPUP: {
      const { isOpen } = action.payload

      return {
        ...state,
        csvExportPopup: {
          isOpen
        }
      }
    }

    case TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP: {
      const { isOpen } = action.payload

      return {
        ...state,
        configureBrowserExtensionActivationEmailPopup: {
          isOpen
        }
      }
    }

    case TOGGLE_SENT_EMAILS_POPUP: {
      const { isOpen, sampleUsers, usersCount, idWorkflow } = action.payload

      return {
        ...state,
        sentEmailsPopup: {
          isOpen,
          sampleUsers,
          usersCount,
          idWorkflow
        }
      }
    }

    case TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP: {
      const { isOpen, idWorkflow, triggerType, workflowName, workflowType, formInitialValues, triggerConfiguration } = action.payload

      return {
        ...state,
        manuallyRunWorkflowPopup: {
          isOpen,
          idWorkflow,
          triggerType,
          workflowName,
          workflowType,
          formInitialValues,
          triggerConfiguration
        }
      }
    }

    case TOGGLE_ROLE_POPUP: {
      const { isOpen, isReadOnly, idRole } = action.payload
      return {
        ...state,
        rolePopup: {
          ...state.rolePopup,
          isOpen: isOpen,
          isReadOnly,
          idRole
        }
      }
    }

    case TOGGLE_USERS_OVERLAP_POPUP: {
      const { apps, idUsers, withLicenses } = action.payload
      return {
        ...state,
        usersOverlapPopup: {
          isOpen: !state.usersOverlapPopup.isOpen,
          apps: Object.values(apps),
          idUsers,
          withLicenses
        }
      }
    }
    case TOGGLE_POPUP: {
      const { popupName, isOpen } = action.payload
      const isPopupAlreadyOpen = state.openPopups.includes(popupName)

      let newOpenPopups = [...state.openPopups]
      if (isOpen && !isPopupAlreadyOpen) {
        newOpenPopups = state.openPopups.concat(popupName)
      }
      if (!isOpen && isPopupAlreadyOpen) {
        newOpenPopups = state.openPopups.filter(name => name !== popupName)
      }

      return {
        ...state,
        openPopups: newOpenPopups
      }
    }

    case TOGGLE_ACTIVATE_WORKFLOW_POPUP: {
      const { isOpen, idWorkflow, workflowName, triggerType } = action.payload
      return {
        ...state,
        activateWorkflowPopup: {
          ...state.activateWorkflowPopup,
          isOpen,
          idWorkflow,
          workflowName,
          triggerType
        }
      }
    }

    case TOGGLE_WORKFLOW_STARTED_POPUP: {
      const { isOpen, idWorkflow, workflowName, triggerType, referrer, entitiesAmount, workflowType } = action.payload
      return {
        ...state,
        workflowStartedPopup: {
          ...state.workflowStartedPopup,
          isOpen,
          idWorkflow,
          workflowName,
          triggerType,
          referrer,
          entitiesAmount,
          workflowType
        }
      }
    }

    case TOGGLE_ADD_APP_CATALOG_POLICY_POPUP: {
      const { isOpen, isViewOnly, idWorkflow } = action.payload
      return {
        ...state,
        addAppCatalogPolicyPopup: {
          ...state.addAppCatalogPolicyPopup,
          isOpen,
          isViewOnly,
          idWorkflow
        }
      }
    }

    case TOGGLE_IMPORT_USERS_POPUP: {
      const { isOpen, idApp, appName } = action.payload
      return {
        ...state,
        importUsersPopup: {
          isOpen,
          idApp,
          appName
        }
      }
    }

    case TOGGLE_SYNC_STATUS_POPUP: {
      const { isOpen, idAppAccount, connectByMessage, connectedAt } = action.payload
      return {
        ...state,
        syncStatusPopup: {
          isOpen,
          idAppAccount,
          connectByMessage,
          connectedAt
        }
      }
    }

    case TOGGLE_WORKFLOW_AUDIT_LOGS: {
      const { isOpen, idWorkflow, workflowName, isAppCatalogPoliciesView } = action.payload
      return {
        ...state,
        workflowAuditLogs: {
          isOpen,
          idWorkflow,
          workflowName,
          isAppCatalogPoliciesView: isAppCatalogPoliciesView
        }
      }
    }

    case TOGGLE_VIEW_ONLY_MODE: {
      const { viewOnlyMode } = action.meta

      return {
        ...state,
        viewOnlyMode
      }
    }

    case `${GET_CURRENCIES}_RESPONSE`: {
      const { currencies, displayCurrencyOptions } = action.payload

      return {
        ...state,
        currencies,
        displayCurrencyOptions
      }
    }

    case TOGGLE_EDIT_WORKFLOW_TAGS_POPUP: {
      const { isOpen, idWorkflow } = action.payload
      return {
        ...state,
        editWorkflowTagsPopup: {
          isOpen,
          idWorkflow
        }
      }
    }

    case TOGGLE_SUBSCRIBE_POPUP: {
      const { isOpen } = action.payload
      return {
        ...state,
        subscribePopup: {
          isOpen
        }
      }
    }

    case TOGGLE_EDIT_WORKFLOW_TAG_POPUP: {
      const { isOpen, idTag } = action.payload

      return {
        ...state,
        editWorkflowTagPopup: {
          isOpen,
          idTag
        }
      }
    }

    case TOGGLE_CREATE_WORKFLOW_TAGS_POPUP: {
      const { isOpen } = action.payload
      return {
        ...state,
        createWorkflowTagsPopup: {
          isOpen
        }
      }
    }

    default: {
      return uiReducerExpander(state, action)
    }
  }
}

export default uiReducer
