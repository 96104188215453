import React from 'react'
import PropTypes from 'prop-types'
import Analytics from '../../helpers/analytics'
import { Route, Redirect } from 'react-router-dom'
import { AUTH_STATE } from '../../constants'
import Layout from '../layout'
import moment from 'moment'
import VisibleFor from '../visibleFor'
import InaccessiblePage from '@pages/inaccessiblePage/inaccessiblePage'

class AuthenticatedRoute extends React.Component {
  constructor (props) {
    super(props)
    this.renderMatch = this.renderMatch.bind(this)
    this.renderUnauthorized = this.renderUnauthorized.bind(this)
  }

  async componentDidMount () {
    const { authState } = this.props
    if (authState === 'BEFORE_AUTH' || authState === 'NOT_AUTHENTICATED') {
      const me = await this.props.getMe()
      Analytics.identify(me)
    }
  }

  renderMatch () {
    const { component: Component, me, previewMode, withoutLayout, accessScopes, feature, idOrg } = this.props
    const shouldFetchInitialData = (me.isToriiAdmin || me.isAdmin)
    const isLoading = idOrg === undefined

    const component = isLoading ? <Component /> : (
      <VisibleFor feature={feature} scopes={accessScopes} fallback={<InaccessiblePage />} fallbackToSuggestToUpgrade>
        <Component />
      </VisibleFor>
    )

    if (previewMode || withoutLayout) {
      return component
    }

    return (
      <Layout shouldFetchInitialData={shouldFetchInitialData}>
        {component}
      </Layout>
    )
  }

  renderUnauthorized () {
    const { unauthorizedComponent: Component } = this.props
    return <Component />
  }

  isUnauthorized () {
    const { adminOnly, me, paidPlanExpired, adminAndAppOwner } = this.props
    const { isDisabled, trialEndTime } = me.org || {}

    return (
      (adminOnly && !me.isToriiAdmin && !me.isAdmin) ||
      (adminAndAppOwner && !me.isToriiAdmin && !me.isAdmin && !me.isAppOwner) ||
      (isDisabled) ||
      (trialEndTime && moment() > moment(trialEndTime)) ||
      (paidPlanExpired)
    )
  }

  render () {
    const { authState, me, path } = this.props
    if (authState === AUTH_STATE.AUTHENTICATED || me.id) {
      if (this.isUnauthorized()) {
        return <Route path={path} render={this.renderUnauthorized} />
      } else {
        return <Route path={path} render={this.renderMatch} />
      }
    }

    if (authState === AUTH_STATE.NOT_AUTHENTICATED) {
      const to = {
        pathname: '/login',
        search: `?next=${encodeURIComponent(window.location.href)}`
      }
      return <Redirect to={to} />
    }

    return null
  }
}

AuthenticatedRoute.propTypes = {
  adminOnly: PropTypes.bool,
  adminAndAppOwner: PropTypes.bool,
  unauthorizedComponent: PropTypes.func,
  me: PropTypes.object,
  feature: PropTypes.string,
  location: PropTypes.object,
  path: PropTypes.string,
  authState: PropTypes.oneOf(Object.keys(AUTH_STATE)),
  accessScopes: PropTypes.array
}

AuthenticatedRoute.defaultProps = {
  accessScopes: []
}

export default AuthenticatedRoute
