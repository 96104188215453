import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getYearlyGetCategoriesForPie, getYearlyRenewalsByAppsCategory } from '../../../selectors/contracts'
import { getCurrencySymbol } from '../../../selectors/org'
import sumBy from 'lodash/sumBy'
import { toggleAllExpenseCategories } from '../../../shared/actions'

const mapStateToProps = (state, ownProps) => {
  const { loadingRenewals } = ownProps

  const currencySymbol = getCurrencySymbol(state)
  const yearlyRenewalsByCategory = getYearlyRenewalsByAppsCategory(state)
  const yearlyRenewalsByCategoryForPie = getYearlyGetCategoriesForPie(state)
  const total = sumBy(yearlyRenewalsByCategory, 'value')

  const loading = loadingRenewals && yearlyRenewalsByCategoryForPie.length === 0

  return {
    loading,
    currencySymbol,
    yearlyRenewalsByCategoryForPie,
    yearlyRenewalsByCategory,
    total
  }
}

const mapDispatchToProps = {
  toggleAllExpenseCategories
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
