import View from '../detailsConfiguration'
import { connect } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import {
  getAppDetailsGroups,
  getAppDetailsGroupsForSelectGroup,
  getConfigurableAppDetailsFields,
  getConfigurableAppDetailsFieldsByGroup,
  isAppDetailsFieldsLoading,
  isAppDetailsGroupsLoading
} from '@selectors/apps'
import {
  addAppDetailsGroup,
  reorderAppDetailsGroups,
  getAppDetailsFields as getAppDetailsFieldsFromServer,
  getAppDetailsGroups as getAppDetailsGroupsFromServer,
  updateAppDetailsField,
  toggleUpdateAppDetailsField,
  deleteAppDetailsField,
  deleteAppDetailsGroup,
  editAppDetailsGroup,
  addAppDetailsField,
  reorderAppDetailsFields
} from '@shared/actions'
import range from 'lodash/range'
import { EMPTY_OBJECT, SCOPES } from '@root/constants'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const fields = getConfigurableAppDetailsFields(state)
  const groups = getAppDetailsGroups(state)
  const groupsForSelectGroup = getAppDetailsGroupsForSelectGroup(state)
  const fieldsByGroups = getConfigurableAppDetailsFieldsByGroup(state)
  const isLoading = isAppDetailsFieldsLoading(state) || isAppDetailsGroupsLoading(state)
  const hasData = fields.length !== 0 && groups.length !== 0
  const loadingDetails = range(10)
  const loadingGroups = { 'loading': loadingDetails }
  const loadingAppDetails = isLoading && !hasData

  const { isOpen, idField } = state.ui.updateAppDetailFieldTypePopup
  const selectedField = idField ? fields.find(field => field.idField === idField) : undefined
  const editableField = selectedField || EMPTY_OBJECT

  return {
    fields: loadingAppDetails ? loadingDetails : fields,
    fieldsByGroups: loadingAppDetails ? loadingGroups : fieldsByGroups,
    groups: loadingAppDetails ? [{ id: 'loading' }] : groups,
    groupsForSelectGroup,
    idOrg,
    loading: loadingAppDetails,
    editableField,
    isEditFieldOpen: isOpen,
    itemName: 'app',
    colorableField: ['state'].includes(editableField.systemKey),
    alwaysShownFields: ['state'],
    subHeader: 'Application Details',
    allowedScopes: [SCOPES.APPLICATIONS_WRITE]
  }
}

const mapDispatchToProps = {
  addAppDetailsGroup,
  reorderAppDetailsGroups,
  getAppDetailsFieldsFromServer,
  getAppDetailsGroupsFromServer,
  updateAppDetailsField,
  toggleUpdateAppDetailsField,
  deleteAppDetailsField,
  deleteAppDetailsGroup,
  editAppDetailsGroup,
  addAppDetailsField,
  reorderAppDetailsFields
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, editableField, fields } = stateProps
  return {
    ...stateProps,
    ...dispatchProps,
    fetchData: () => Promise.all([
      dispatchProps.getAppDetailsFieldsFromServer({ idOrg }),
      dispatchProps.getAppDetailsGroupsFromServer({ idOrg })
    ]),
    addDetailsGroup: ({ label }) => dispatchProps.addAppDetailsGroup({ idOrg, label }),
    reorderDetailsGroups: ({ sourceGroup, destinationGroup, reorderedGroups, changes }) => dispatchProps.reorderAppDetailsGroups({ idOrg, sourceGroup, destinationGroup, reorderedGroups, changes }),
    updateDetailsField: dispatchProps.updateAppDetailsField,
    toggleUpdateDetailsField: dispatchProps.toggleUpdateAppDetailsField,
    editDetailsGroup: dispatchProps.editAppDetailsGroup,
    deleteDetailsField: dispatchProps.deleteAppDetailsField,
    deleteDetailsGroup: dispatchProps.deleteAppDetailsGroup,
    addDetailsField: dispatchProps.addAppDetailsField,
    updateField: ({ name, idGroup, formQuestion, options }) => {
      return dispatchProps.updateAppDetailsField({ idOrg, idGroup, idField: editableField.idField, name, formQuestion, options })
    },
    onRowsOrderChanged: ({ reorderedData }) => {
      const changes = reorderedData.map((row, index) => ({ idField: row.idField, position: index }))
      const reorderedDataWithCorrectPosition = reorderedData.map((row, index) => ({ ...row, position: index }))

      return dispatchProps.reorderAppDetailsFields({ idOrg, changes, reorderedData: reorderedDataWithCorrectPosition, previousData: fields, idGroup: reorderedData[0].idGroup })
    },
    ...ownProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
