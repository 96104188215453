import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import SourceIcon from '../sourceIcon'
import startCase from 'lodash/startCase'
import debounce from 'lodash/debounce'
import Table from '../table'
import { Icon, Tooltip, ButtonSize, SelectComponents } from '@toriihq/design-system'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import {
  TABLES,
  ITEMS_PER_PAGE,
  fieldTypes,
  SCOPES,
  NO_APP_ACCOUNT_ID,
  MANUAL_WORKFLOW_EXECUTION_FLOW
} from '../../constants'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import UserDetails from '../userDetails'
import UsersImportPopover from '@components/appUsers/usersImportPopover'
import colors from '../../shared/style/colors'
import UsageIcon from '../usageIcon'
import { getDisplayName } from '@lenses/users'
import UsersEmptyState from './usersEmptyState'
import userToolTip from '../../shared/userToolTip'
import config from '../../config'
import getColumnByFieldType from '../table/columnFieldTypes'
import EnableFor from '../enableFor'
import uniq from 'lodash/uniq'
import isEqual from 'lodash/isEqual'
import { sortColumns } from '../table/view'
import VisibleFor from '../visibleFor'
import { getFormattedDate } from '@lenses/utils'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import * as Style from './styles'
import { FEATURES } from '@root/shared/features'
import ButtonOfFeature from '@components/buttonOfFeature'

const getNoDataProps = () => {
  return {
    className: 'rt-noData',
    style: {
      pointerEvents: 'all',
      top: '40%'
    }
  }
}

class AppUsers extends React.Component {
  state = {
    columns: this.getColumns({ ...this.props.tableInfo }),
    selectedUsers: [],
    view: this.props.defaultCustomSelectOption,
    isLoadingViews: this.props.isLoadingViews
  }

  componentDidMount () {
    const { deletedUsersMessage, idOrg, idApp, getServicesConfig, getAppUsersFieldValues } = this.props
    getServicesConfig({ idOrg })
    getAppUsersFieldValues({ idOrg, idApp, fields: ['role', 'externalStatus', 'licenses.name', 'appAccountName', 'idAppAccount'] })
    if (!this.props.isLoadingViews) {
      this.fetchUsersData(true)
    }
    this.renderNoUsersComponent = this.renderNoUsers(deletedUsersMessage)
  }

  componentDidUpdate (prevProps, prevState) {
    const columnsChanged = ['appName', 'tableInfo'].some(prop => !isEqual(prevProps[prop], this.props[prop]))
    if (columnsChanged) {
      this.setState({ columns: this.getColumns({ ...this.props.tableInfo }) })
    }

    if (prevState.view.value !== this.state.view.value) {
      this.getAppUsers({ reset: true })
    }

    if (this.state.isLoadingViews && !this.props.isLoadingViews) {
      this.getAppUsers({ reset: true })
      this.setState({ isLoadingViews: this.props.isLoadingViews })
    }

    if (prevProps.defaultCustomSelectOption.label !== this.props.defaultCustomSelectOption.label) {
      this.setState({ view: this.props.defaultCustomSelectOption })
    }

    const { importAppUsersStatus } = this.props
    if (importAppUsersStatus !== prevProps.importAppUsersStatus &&
        importAppUsersStatus === IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED) {
      this.getAppUsers({ reset: true })
    }
  }

  getColumns ({ columnsConfiguration, dynamicColumnsInfo }) {
    const { appName } = this.props
    return [
      {
        Header: 'User',
        id: 'fullName',
        accessor: ({ firstName, lastName, email }) => [firstName, lastName, email].join(' ').trim().toLowerCase(),
        minWidth: 220,
        Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl, lifecycleStatus, isDeletedInIdentitySources } }) => (
          <UserDetails
            firstName={firstName}
            lastName={lastName}
            email={email}
            idUser={idUser}
            isExternal={isExternal}
            photoUrl={photoUrl}
            lifecycleStatus={lifecycleStatus}
            isDeletedInIdentitySources={isDeletedInIdentitySources}
            showPastUserBadge
          />
        )
      },
      {
        id: 'originEmail',
        Header: 'Email',
        accessor: 'originEmail',
        Cell: ({ row: { originEmail } }) => originEmail,
        show: Boolean(columnsConfiguration['originEmail'])
      },
      {
        Header: 'Has license',
        idForColumnsOrder: 'licenses.name',
        accessor: 'licenses',
        style: { display: 'flex', alignItems: 'center' },
        Cell: ({ row: { licenses = [] } }) => {
          if (!licenses.length) {
            return '-'
          }
          return <Tooltip
            label={licenses.map(license => <div key={license.type}>{license.name}</div>)}
            placement='top'>
            <Icon name='CheckCircleFill' color='interactive' />
          </Tooltip>
        },
        show: Boolean(columnsConfiguration['licenses.name'])
      },
      {
        id: 'licenseName',
        Header: 'Licenses',
        Cell: ({ row: { licenses = [] } }) => {
          if (!licenses.length) {
            return '-'
          }
          return licenses.map(license => <div key={license.type}>{license.name}</div>)
        },
        show: Boolean(columnsConfiguration['licenseName'])
      },
      {
        Header: (
          <Tooltip
            label={<Style.UsageHeaderTooltipLabel>Usage is based on visits to {appName} in the last 30 days</Style.UsageHeaderTooltipLabel>}
          >
            Usage
          </Tooltip>
        ),
        textHeader: 'Usage',
        accessor: 'score',
        style: { display: 'flex', alignItems: 'center' },
        Cell: ({ value: score, row: { lastVisitTime } }) => <UsageIcon isUserUsage score={score} lastVisitTime={lastVisitTime} />,
        show: Boolean(columnsConfiguration.score)

      },
      {
        Header: 'Account',
        accessor: 'appAccountName',
        Cell: ({ row: { appAccountName } }) => appAccountName || '-',
        show: Boolean(columnsConfiguration.appAccountName)
      },
      {
        Header: 'Last used date',
        accessor: 'lastVisitTime',
        Cell: ({ value: lastVisitTime }) => getFormattedDate({ date: lastVisitTime }),
        minWidth: 200,
        show: Boolean(columnsConfiguration.lastVisitTime)
      },
      {
        Header: 'Sources',
        accessor: 'sources',
        Cell: ({ row: { firstName, lastName, sources, email } }) => (
          <div>
            {(sources || []).map(source => (<SourceIcon key={source} sourceType={source} tooltipText={userToolTip({ displayName: getDisplayName({ firstName, lastName, email }), appName, source })} />))}
          </div>
        ),
        show: Boolean(columnsConfiguration.sources)
      },
      {
        Header: 'Status in app',
        accessor: 'externalStatus',
        Cell: ({ value: status }) => status || '-',
        show: Boolean(columnsConfiguration.externalStatus)
      },
      {
        Header: 'Is past user',
        accessor: 'isDeletedInIdentitySources',
        Cell: ({ value: isDeletedInIdentitySources }) => isDeletedInIdentitySources ? 'Yes' : 'No',
        show: Boolean(columnsConfiguration.isDeletedInIdentitySources)
      },
      {
        Header: 'Role',
        id: 'role',
        accessor: ({ role }) => {
          const roleSplit = (role || '-').split(',')
          return roleSplit.map(role => startCase(role).replace('_', ' ')).join(', ')
        },
        show: Boolean(columnsConfiguration.role)
      },
      {
        Header: 'User type',
        accessor: 'userType',
        Cell: ({ row: { userType } }) => userType || '-',
        show: Boolean(columnsConfiguration.userType)
      },
      {
        accessor: 'reason',
        show: false
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'idUser',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      },
      {
        accessor: 'docId',
        show: false
      },
      {
        accessor: 'lifecycleStatus',
        show: false
      }
    ].concat(getColumnByFieldType({ columnsInfo: dynamicColumnsInfo, fieldIdentifier: 'key' }))
  }

  renderNoUsers = deletedUsersMessage =>
    deletedUsersMessage || <UsersEmptyState />

  exportToCsv = ({ sort, query, filters }) => {
    const { idOrg, idApp, userTablePreferences } = this.props
    const { columns, view } = this.state

    sortColumns(columns, userTablePreferences)

    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',').replace('fullName', 'firstName,lastName')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters.concat(view.filters)))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/${idApp}/users/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  fetchUsersData = debounce((reset = false) => {
    const { users } = this.props

    this.getAppUsers({ offset: reset ? 0 : users.length, reset })
  }, 500, { leading: true, trailing: false })

  onSortedChange = (sort) => {
    this.getAppUsers({ sort, reset: true })
  }

  onFilterChange = debounce((filters) => {
    this.getAppUsers({ filters, reset: true })
  }, 250)

  onStatusChange = (view) => {
    this.setState({ view })
  }

  fetchFieldValues = (field) => {
    const { idOrg, idApp, getAppUsersFieldValues, tableInfo } = this.props
    const options = this.getFilterOptions(tableInfo)
    const selectedFilterOption = options.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      getAppUsersFieldValues({ idOrg, idApp, fields: [field] })
    }
  }

  fetchFields () {
    const { getUserDetailsFields, idOrg } = this.props
    getUserDetailsFields({ idOrg })
  }

  getAppUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = this.props.defaultSort, q = this.query, reset = false, filters = this.props.tableInfo.filters }) => {
    const { view } = this.state
    const { idOrg, idApp, getAppUsers } = this.props
    if (reset) {
      this.fetchFields()
    }

    getAppUsers({ idOrg, idApp, limit, offset, sort: this.sortStringFromArray(sort), q, reset, filters: view.filters.concat(filters).filter(Boolean) })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.getAppUsers({ reset: true, q })
  }, 250)

  onSelectChange = selection => {
    this.setState({ selectedUsers: selection })
  }

  customHeaderOnSelect = () => {
    const { users, toggleConfigureExecuteActionOnUsers, appName, idApp, isSupportsWritePermission, idOrg } = this.props
    const { selectedUsers } = this.state
    const selectedUsersInfo = users.filter(u => selectedUsers.includes(u.docId))
    const idAppAccounts = uniq(selectedUsersInfo.map(user => user.idAppAccount))
    const isValidAccountSelection = idAppAccounts.length === 1 && idAppAccounts[0] !== NO_APP_ACCOUNT_ID

    const isActionButtonDisabled = !isSupportsWritePermission || !isValidAccountSelection
    const actionButtonDisabledTooltipLabel = <Style.ActionButtonDisabledTooltipLabel>
      {!isSupportsWritePermission
        ? `Actions are not supported for ${appName}`
        : `All users must belong to the same ${appName} account`
      }
    </Style.ActionButtonDisabledTooltipLabel>

    return <Style.TableHeader>
      <Tooltip
        placement='top'
        hide={!isActionButtonDisabled}
        label={actionButtonDisabledTooltipLabel}>
        <VisibleFor scopes={[SCOPES.AUTOMATION_READ, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_READ, idOrg, idApp)]}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.AUTOMATION_WRITE, idOrg, idApp)]}>
            <ButtonOfFeature
              tooltipPlacement={isActionButtonDisabled ? 'bottom' : 'top'}
              feature={FEATURES.WORKFLOWS.PAGE}
              onClick={async () => {
                await toggleConfigureExecuteActionOnUsers({ isOpen: true, idApp, users: selectedUsersInfo, onConfig: () => this.setState({ selectedUsers: [] }), executionFlow: MANUAL_WORKFLOW_EXECUTION_FLOW.appUsers, flowType: 'App user' })
              }}
              disabled={isActionButtonDisabled}
              label='Run action'
              size={ButtonSize.small}
            />
          </EnableFor>
        </VisibleFor>
      </Tooltip>
    </Style.TableHeader>
  }

  getFilterOptions = (tableInfo) => {
    const filterOptions = tableInfo.filtersOptions
    return filterOptions.concat({
      label: 'User type',
      value: 'userType',
      type: fieldTypes.text
    })
  }

  render () {
    const {
      appName,
      users,
      loading,
      loadingMore,
      totalUsers,
      tableInfo,
      configurableColumnsOptions,
      filterValues,
      canSelectRows,
      customSelectOptions
    } = this.props
    const { columns, selectedUsers } = this.state
    return (
      <Fragment>
        <Table
          getNoDataProps={getNoDataProps}
          getTdProps={setTdStyle}
          tableKey={TABLES.appUsersTable.key}
          data={users}
          columns={columns}
          emptyStateMessage={this.renderNoUsersComponent}
          loading={loading}
          loadingMore={loadingMore}
          exportable
          showImportCSV
          importCSVPopoverComponent={UsersImportPopover}
          onCSVUsersImport={ImportUsersAnalytics.onImportUsersClickAnalytics}
          exportFunction={this.exportToCsv}
          searchable
          selectedItems={selectedUsers}
          selectId='docId'
          onSelectChange={this.onSelectChange}
          customHeaderOnSelect={this.customHeaderOnSelect}
          manual
          filterable
          selectable={canSelectRows}
          configurableColumnsOptions={configurableColumnsOptions}
          configurableColumns
          filtersOptions={this.getFilterOptions(tableInfo)}
          fetchData={this.fetchUsersData}
          forceShowSearch
          onSearch={this.onSearch}
          onSortedChangeCB={this.onSortedChange}
          onFilterChange={this.onFilterChange}
          filterOptionsValuesPerKey={filterValues}
          fetchFieldValues={this.fetchFieldValues}
          totalCount={totalUsers}
          customSelect
          customSelectOptions={customSelectOptions}
          customSelectSelectedValue={this.state.view}
          customSelectOnChange={this.onStatusChange}
          customSelectOptionRenderer={{ Option: SelectComponents.OptionWithDescription }}
          appName={appName}
        />
      </Fragment>
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

AppUsers.propTypes = {
  title: PropTypes.string,
  hideExternal: PropTypes.bool,
  hideNative: PropTypes.bool,
  appName: PropTypes.string,
  apps: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    lastUsed: PropTypes.string.isRequired,
    isCore: PropTypes.bool.isRequired,
    normalizedScore: PropTypes.number.isRequired,
    users: PropTypes.number.isRequired,
    category: PropTypes.string.isRequired
  })),
  isSmallScreen: PropTypes.bool,
  isSupportsWritePermission: PropTypes.bool,
  deletedUsersMessage: PropTypes.node,
  importAppUsersStatus: PropTypes.oneOf(Object.values(IMPORT_APP_USERS_STATUS))
}

AppUsers.defaultProps = {
  title: 'users',
  hideExternal: false,
  hideNative: false,
  isSmallScreen: false
}

export default AppUsers
