import React, { ReactElement, useMemo } from 'react'
import { Controls, ReactFlow } from 'reactflow'
import 'reactflow/dist/style.css'
import { getReactflowGraphData } from './utils/getReactflowGraphData'
import { nodeTypes } from './utils/nodeTypes'
import { edgeTypes } from './utils/edgeTypes'
import { Workflow } from '@shared/workflows/types'
import { useWorkflowGraphPositioning } from './hooks/useWorkflowGraphPositioning'

interface Props {
  workflow: Workflow
}

export const WorkflowGraph = ({ workflow }: Props): ReactElement => {
  const { nodes, edges } = useMemo(
    () => getReactflowGraphData({ workflow }),
    [workflow]
  )
  useWorkflowGraphPositioning()

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      minZoom={0.1}
      maxZoom={1}
      elementsSelectable={false}
      proOptions={{ hideAttribution: true }}
      panOnScroll
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      nodesDraggable={false}
    >
      <Controls
        position='top-left'
        showInteractive={false}
      />
    </ReactFlow>
  )
}
