import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import moment from 'moment'

export const getSelf = createSelector(
  state => state.me,
  identity
)

export const getCurrentOrg = createSelector(
  state => state.org,
  identity
)

export const getMyOrg = createSelector(getSelf, me => me.idOrg)

export const getUserScopes = createSelector(
  getSelf,
  me => get(me, 'scopes', [])
)

export const isToriiAdmin = createSelector(
  getSelf,
  me => Boolean(me.isToriiAdmin)
)

export const isTrial = createSelector(
  getCurrentOrg,
  org => Boolean(org && org.trialEndTime)
)

export const isPaidAccount = createSelector(
  getCurrentOrg,
  org => Boolean(org && org.paidPlanEndTime)
)

export const isPaidPlanExpired = createSelector(
  getSelf,
  me => {
    const paidPlanEndTime = get(me, ['org', 'paidPlanEndTime'])
    return (paidPlanEndTime && moment.utc().subtract(24, 'h') > moment(me.org.paidPlanEndTime))
  }
)

export const getIsOnlyAppOwner = createSelector(
  state => state.me.isAppOwner && !state.me.isAdmin,
  identity
)
