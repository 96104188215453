import React, { useState } from 'react'
import { Button, ButtonSize, ButtonType, Popover } from '@toriihq/design-system'
import * as Style from './style'
import EnableFor from '@components/enableFor'

type Props = {
  onClick?: () => void
  scopes?: string[]
  popoverComponent?: (props: { onClose: () => void }) => JSX.Element
}

const TableImportButton = ({
  onClick,
  scopes = [],
  popoverComponent: PopoverComponent
}: Props) : JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleClick = () => {
    if (PopoverComponent) {
      const newIsPopoverOpen = !isPopoverOpen
      if (newIsPopoverOpen && onClick) {
        onClick()
      }
    } else {
      onClick?.()
    }
  }

  const closePopover = () => setIsPopoverOpen(false)

  const button = (
    <Button
      dataTestId='table-import-btn'
      onClick={handleClick}
      icon='FileImport'
      label='Import'
      type={ButtonType.secondary}
      size={ButtonSize.small}
    />
  )

  return (
    <Style.ButtonWrapper data-intercom-target='Import CSV button'>
      {PopoverComponent ? (
        <Popover
          isOpen={isPopoverOpen}
          position='bottom'
          align='end'
          openOnClick
          onToggle={() => setIsPopoverOpen(prevIsPopoverOpen => !prevIsPopoverOpen)}
          content={<PopoverComponent onClose={closePopover} />}
        >
          {button}
        </Popover>
      ) : (
        <EnableFor scopes={scopes}>
          {button}
        </EnableFor>
      )
      }
    </Style.ButtonWrapper>
  )
}

export default TableImportButton
