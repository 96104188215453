import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import SimilarApps from '../similarApps'
import { Icon, Tooltip, Spacer, Button, ButtonType, ButtonSize, Tag, TagSize, TagTheme, Link, Divider } from '@toriihq/design-system'
import Placeholder from '../placeholder'
import { fontWeight } from '@shared/style/sizes'
import { getFullUrl } from '@shared/utils'
import OverflowTooltip from '@components/overflowTooltip'
import { AppCompliance } from '@components/appAboutBox/appCompliance'

const CSS = {
  main: css(texts.body, {
    boxSizing: 'border-box',
    border: '1px solid #E6EBF4',
    borderRadius: '4px',
    backgroundColor: '#F7FAFF',
    padding: '20px',
    color: colors.black
  }),
  icon: css({
    height: '17px',
    width: '17px',
    backgroundColor: colors.lightBlue1,
    borderRadius: '50%',
    color: colors.white,
    lineHeight: '17px',
    textAlign: 'center',
    marginRight: '10px',
    transform: 'none',
    fontSize: '10px'
  }),
  header: css(texts.heading, {
    display: 'flex'
  }),
  description: css({
    paddingTop: '20px',
    paddingBottom: '23px',
    display: 'flex'
  }),
  sectionText: css({
    verticalAlign: 'middle',
    lineHeight: '25px',
    display: 'inline-block'
  }),
  dataLine: css({
    paddingBottom: '14px',
    display: 'flex',
    alignItems: 'center'
  }),
  tags: css({
    paddingTop: '14px'
  }),
  tagsHeader: css(texts.subheading, {
    color: colors.black,
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px'
  }),
  similar: css({
    paddingTop: '14px',
    display: 'flex'
  }),
  divider: css({
    paddingTop: '14px'
  }),
  tagsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '12px 6px'
  }),
  showMoreButton: css({
    backgroundColor: 'transparent',
    color: colors.blue,
    fontWeight: fontWeight.normal,
    padding: '0',
    height: '24px',
    margin: '10px 10px 0 0'
  })
}

const NUMBER_OF_TAGS_TO_SHOW = 3

class AppAboutBox extends React.Component {
  state = {
    visibleTags: NUMBER_OF_TAGS_TO_SHOW
  }

  componentDidMount () {
    this.fetchData(this.props)
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idApp, isAppComplianceEnabledInPlan } = this.props

    if (isAppComplianceEnabledInPlan !== prevProps.isAppComplianceEnabledInPlan || idApp !== prevProps.idApp || idOrg !== prevProps.idOrg) {
      this.fetchData(this.props)
    }
  }

  fetchData (props) {
    const { idOrg, getAppCompliance, idApp, isAppComplianceEnabledInPlan } = props
    isAppComplianceEnabledInPlan && getAppCompliance({ idOrg, idApp })
  }

  toggleShowMore = (showMore) => {
    this.setState({
      visibleTags: showMore ? this.state.visibleTags + 100 : NUMBER_OF_TAGS_TO_SHOW
    })
  }

  renderTags (tags) {
    const tagsToShow = tags.slice(0, this.state.visibleTags)
    const restTagsNumber = tags.length - NUMBER_OF_TAGS_TO_SHOW
    const shouldShowLinks = restTagsNumber > 0
    const shouldShowMore = this.state.visibleTags === NUMBER_OF_TAGS_TO_SHOW

    return (
      <div {...CSS.tagsContainer}>
        {tagsToShow.map(tag => <Tag key={tag.name} color='grey' theme={TagTheme.Light} size={TagSize.Medium}><OverflowTooltip label={tag.name}>{tag.name}</OverflowTooltip></Tag>)}
        {shouldShowLinks &&
          (<Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.toggleShowMore(shouldShowMore)} label={shouldShowMore ? `+${restTagsNumber}` : 'Show less'} />)
        }
      </div>
    )
  }

  render () {
    const { app, loggedInUser, similarApps, loading, overrideStyle, appComplianceData, isAppComplianceEnabledInPlan } = this.props
    const { tags = [] } = app
    return (<div {...css(CSS.main, overrideStyle)}>
      <div {...CSS.header} >
        <div>ABOUT</div>
      </div>

      <Placeholder loading={loading} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
        <div {...CSS.description}>
          {app.description}
        </div>
        {app.category && (
          <div {...CSS.dataLine}>
            <Spacer right={'space-100'}>
              <Tooltip placement='top' label='Category'>
                <Icon name='Category' color='secondary' />
              </Tooltip>
            </Spacer>
            <div {...CSS.sectionText}> {app.category} </div>
          </div>
        )}
        {app.vendor && (
          <div {...CSS.dataLine}>
            <Spacer right={'space-100'}>
              <Tooltip placement='top' label='Vendor'>
                <Icon name='Company' color='secondary' />
              </Tooltip>
            </Spacer>
            <div {...CSS.sectionText}> {app.vendor} </div>
          </div>
        )}

        <div {...CSS.dataLine}>
          <Spacer right={'space-100'}>
            <Tooltip placement='top' label='Website'>
              <Icon name='World' color='secondary' />
            </Tooltip>
          </Spacer>
          <Link href={getFullUrl(app.url)} target='_blank'>{app.url}</Link>
        </div>
        {tags.length > 0 &&
          <div>
            <Divider orientation={'Vertical'} />
            <div {...CSS.tags}>
              <div {...CSS.tagsHeader}>
                <Spacer right={'space-100'}>
                  <Icon name='Tags' color='secondary' />
                </Spacer>
                <div {...CSS.sectionText}>TAGS</div>
              </div>
              <div> {this.renderTags(tags)} </div>
            </div>
          </div>
        }
        <div {...CSS.divider}>
          <Divider orientation={'Vertical'} />
          <AppCompliance data={appComplianceData} appName={app.name} loggedInUserEmail={loggedInUser.email} isAppComplianceEnabledInPlan={isAppComplianceEnabledInPlan} />
        </div>
        {similarApps.length > 0 &&
          <div {...CSS.divider}>
            <Divider orientation={'Vertical'} />
            <div {...CSS.similar}>
              <SimilarApps idApp={app.id} />
            </div>
          </div>
        }
      </Placeholder>
    </div>)
  }
}

AppAboutBox.propTypes = {
  loading: PropTypes.bool.isRequired,
  app: PropTypes.object.isRequired,
  similarApps: PropTypes.array,
  tags: PropTypes.array
}

AppAboutBox.defaultProps = {
  tags: [],
  similarApps: []
}

export default AppAboutBox
