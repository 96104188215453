import styled from 'styled-components'
import colors from '../../shared/style/colors'

export const ContainerDiv = styled.div<{ overrideStyle, boxHeight }>`
  border: 1px solid ${colors.border};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${props => props.overrideStyle};
  height: ${props => props.boxHeight}px;
`
