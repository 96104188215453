import {
  GET_RECOMMENDATION,
  GET_RECOMMENDATIONS,
  GET_RECOMMENDATION_APPS_ACCOUNTS
} from '../constants'

const initialState = {
  loadingRecommendations: false,
  loadingAppsList: false,
  recommendations: []
}

const recommendationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_RECOMMENDATIONS}_PENDING`: {
      return {
        ...state,
        loadingRecommendations: true
      }
    }
    case `${GET_RECOMMENDATIONS}_FAILED`: {
      return {
        ...state,
        loadingRecommendations: false
      }
    }
    case `${GET_RECOMMENDATIONS}_RESPONSE`: {
      const { results } = action.payload

      return {
        ...state,
        recommendations: results,
        loadingRecommendations: false
      }
    }
    case `${GET_RECOMMENDATION}_PENDING`: {
      return {
        ...state,
        loadingRecommendations: true
      }
    }
    case `${GET_RECOMMENDATION}_FAILED`: {
      return {
        ...state,
        loadingRecommendations: false
      }
    }
    case `${GET_RECOMMENDATION}_RESPONSE`: {
      const recommendation = action.payload

      return {
        ...state,
        recommendations: [{ ...recommendation, data: recommendation.data }],
        loadingRecommendations: false
      }
    }

    case `${GET_RECOMMENDATION_APPS_ACCOUNTS}_PENDING`: {
      return {
        ...state,
        loadingAppsList: true
      }
    }

    case `${GET_RECOMMENDATION_APPS_ACCOUNTS}_FAILED`: {
      return {
        ...state,
        loadingAppsList: false
      }
    }

    case `${GET_RECOMMENDATION_APPS_ACCOUNTS}_RESPONSE`: {
      const appsList = action.payload

      const idApps = Object.keys(appsList)
      const apps = idApps.map(idApp => ({ idApp, appName: appsList[idApp].appName, appAccounts: appsList[idApp].appAccounts }))
      return {
        ...state,
        loadingAppsList: false,
        appsList: apps
      }
    }
    default: {
      return state
    }
  }
}

export default recommendationsReducer
