import {
  GET_AUTOMATION_RULES,
  UPDATE_AUTOMATION_RULE
} from '../constants'

const initialState = {
  loading: false,
  automations: {}
}

const automationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_AUTOMATION_RULES}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_AUTOMATION_RULES}_FAILED`: {
      return {
        ...state,
        loading: false,
        automations: {}
      }
    }
    case `${GET_AUTOMATION_RULES}_RESPONSE`: {
      const { automations } = action.payload
      return {
        ...state,
        loading: false,
        automations
      }
    }
    case `${UPDATE_AUTOMATION_RULE}_PENDING`: {
      const { idRule, isEnabled } = action.meta
      const { automations } = state
      return {
        ...state,
        automations: {
          ...automations,
          [idRule]: isEnabled
        }
      }
    }
    case `${UPDATE_AUTOMATION_RULE}_FAILED`: {
      const { idRule, isEnabled } = action.meta
      const { automations } = state
      return {
        ...state,
        automations: {
          ...automations,
          [idRule]: !isEnabled
        }
      }
    }
    case `${UPDATE_AUTOMATION_RULE}_RESPONSE`: {
      const { isSuccess } = action.payload
      const { idRule, isEnabled } = action.meta
      const { automations } = state
      return {
        ...state,
        automations: {
          ...automations,
          [idRule]: isSuccess ? isEnabled : !isEnabled
        }
      }
    }
    default: {
      return state
    }
  }
}

export default automationsReducer
