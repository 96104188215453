import React from 'react'
import { Icon, Tooltip } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import PropTypes from 'prop-types'
import './animation.less'
import Analytics from '../../helpers/analytics'
import ClassNames from 'classnames'
import { clearButton } from '@shared/style/mixins'

const CSS = {
  productUpdatesButton: css(clearButton, {
    position: 'relative',
    height: '36px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background .2s',
    ':hover': {
      background: colors.lightBlue5,
      cursor: 'pointer'
    },
    ':focus': {
      background: colors.lightBlue5
    },
    ':active': {
      boxShadow: `0 0 0 2px ${colors.lightBlue5}`,
      background: colors.lightBlue5
    }
  }),
  updatesIcon: css({
    position: 'absolute',
    top: '21px',
    right: '17px',
    display: 'inline-block',
    height: '11px',
    width: '11px',
    backgroundColor: colors.lightBlue4,
    borderRadius: '50%',
    zIndex: 10,
    opacity: 0
  })
}
class ProductUpdatesButton extends React.Component {
  state = { hasProductUpdates: this.props.hasProductUpdates, isClicked: false }

  componentDidMount () {
    const { getProductUpdates } = this.props
    getProductUpdates()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.hasProductUpdates !== this.props.hasProductUpdates) {
      this.setState({ hasProductUpdates: this.props.hasProductUpdates })
    }
  }

  onClick = () => {
    const { updateUserLastSeenProductUpdatesTime } = this.props
    Analytics.track('Clicked to see product updates')
    updateUserLastSeenProductUpdatesTime()
    const newWindow = window.open('https://product-updates.toriihq.com', '_blank')
    newWindow.opener = null
    this.setState({ hasProductUpdates: false, isClicked: true })
  }

  render () {
    const { hasProductUpdates, isClicked } = this.state
    const className = ClassNames({
      'animated fadeInOut': !isClicked && hasProductUpdates
    })
    return (<Tooltip
      placement='bottom'
      label='Product updates'>
      <button data-testid='product updates button' {...CSS.productUpdatesButton} onClick={() => this.onClick()}>
        <Icon name='Gift' size='mobile' color='active' />
        <span className={className} {...CSS.updatesIcon} />
      </button>
    </Tooltip>)
  }
}

ProductUpdatesButton.propTypes = {
  hasProductUpdates: PropTypes.bool
}

export default ProductUpdatesButton
