import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'
import texts from '@shared/style/texts'

export const IdAppAccountContainer = css({
  display: 'flex',
  alignItems: 'center'
})

export const IdAppAccountBold = css({
  fontWeight: fontWeight.semiBold
})

export const CopyText = css(texts.body, IdAppAccountBold, {
  fontSize: 14,
  padding: '3.5px 0px 3.5px 14px'
})
