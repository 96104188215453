import View from './view'
import { connect } from 'react-redux'
import { getUsersByCountry } from '../../../shared/actions'
import { getCurrentOrg } from '../../../selectors/me'
import { getUsersByCountrySelector } from '../../../selectors/trends'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const { users, loading, loadingMore, totalUsers } = getUsersByCountrySelector(state)

  return ({
    idOrg,
    users,
    loading,
    loadingMore,
    totalUsers
  })
}

const mapDispatchToProps = {
  getUsersByCountry
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
