import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleDeleteImportedUsers, toggleImportUsers } from '@shared/actions'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { AppManualImportUsersStatus } from './types'
import {
  ButtonsContainer,
  Container,
  ImportStatusContainer,
  LastImportDate,
  LastImportDateTitle
} from '@components/appUsers/usersImportPopover/styles'
import { UsersImportPopoverButton } from '@components/appUsers/usersImportPopover/usersImportPopoverButton'
import { getAppManualImportUsersStatus, getCurrentApp } from '@selectors/apps'
import Placeholder from '@components/placeholder'
import moment from 'moment'
import { DATE_FORMAT, SCOPES } from '@root/constants'
import { getScopeByIdOrgAndIdApp } from '@lenses/scopes'
import { getCurrentOrg } from '@selectors/me'
import { getServicesResources } from '@root/selectors/services'
import { getDisplayName } from '@root/lenses/users'

type Props = {
  onClose: () => void
}

const UsersImportPopover = ({ onClose }: Props): JSX.Element | null => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const currentApp = useSelector(getCurrentApp)
  const manualSyncStatus = useSelector(getAppManualImportUsersStatus) as AppManualImportUsersStatus
  const { users } = useSelector(getServicesResources)

  const loading = currentApp.loading || manualSyncStatus.loading
  const idApp = currentApp?.app?.id
  const appName = currentApp?.app?.name
  const { hasManualSync, lastSyncTime, lastSyncBy } = manualSyncStatus
  const lastImportDate = hasManualSync && lastSyncTime ? moment(lastSyncTime).format(DATE_FORMAT) : 'No files imported'
  const scopes = [SCOPES.APPLICATIONS_WRITE, getScopeByIdOrgAndIdApp(SCOPES.APPLICATIONS_WRITE, idOrg, idApp)]

  const lasySyncByUserName = useMemo(() => {
    const lastSyncByUser = lastSyncBy && users[lastSyncBy]
    return hasManualSync && lastSyncTime && lastSyncByUser ? getDisplayName({ firstName: lastSyncByUser.firstName, lastName: lastSyncByUser.lastName, email: lastSyncByUser.email }) : ''
  }, [lastSyncBy, users, hasManualSync, lastSyncTime])

  const handleDeleteUsersClick = () => {
    dispatch(toggleDeleteImportedUsers({ isOpen: true, idApp }))
    ImportUsersAnalytics.onOpenDeletePopupAnalytics()
    onClose()
  }

  const handleFileImportClick = () => {
    dispatch(toggleImportUsers({ isOpen: true, idApp, appName }))
    ImportUsersAnalytics.onOpenImportPopupAnalytics('Import')
    onClose()
  }

  return (
    <Container>
      <Placeholder loading={loading}>
        <ButtonsContainer>
          <UsersImportPopoverButton
            icon='FileImport'
            text='Import users'
            onClick={handleFileImportClick}
            scopes={scopes}
          />
          <UsersImportPopoverButton
            icon='Trash'
            text='Delete all imported users'
            onClick={handleDeleteUsersClick}
            disabled={!hasManualSync}
            scopes={scopes}
          />
        </ButtonsContainer>
        <ImportStatusContainer>
          <LastImportDateTitle>Last import</LastImportDateTitle>
          <LastImportDate>{lastImportDate}{lasySyncByUserName && ` by ${lasySyncByUserName}`}</LastImportDate>
        </ImportStatusContainer>
      </Placeholder>
    </Container>
  )
}

export default UsersImportPopover
