import BackLink from '@root/components/backLink'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import PluginModel from '@pages/pluginMarketplace/types'
import PluginContent from './pluginContent'
import PluginDetails from './pluginDetails'
import { Styles } from './style'
import { get, isEmpty } from 'lodash'
import { useIsMounted } from '@root/shared/hooks'
import { sleep } from '@root/shared/utils'
import Placeholder from '@components/placeholder'
import { ButtonType, Col, Grid, Row, toast, ToastType } from '@toriihq/design-system'
import Confirmation from '@root/components/confirmation'
import { getPlugin, installPlugin, uninstallPlugin } from '@shared/actions/plugins'
import InstallPopup from './installPopup'

const PluginPage = (props) => {
  const isMounted = useIsMounted()
  const { idOrg, uuidPlugin } = useParams()
  const dispatch = useDispatch()

  const [plugin, setPlugin] = useState<PluginModel | null>(null)
  const [loading, setIsLoading] = useState(true)

  const [isUninstallPopUpOpen, setUninstallPopUpOpen] = useState(false)
  const [isInstallPopUpOpen, setInstallPopUpOpen] = useState(false)

  const fetchPlugin = useCallback(async () => {
    const res = await dispatch(getPlugin({ idOrg, uuidPlugin }))
    const { plugin, resources } = res || {}
    if (isMounted.current) {
      const users = Object.values(resources?.users || {})
      setPlugin({ ...plugin, installedBy: !isEmpty(users) ? users[0] : {}, vendor: get(resources, ['orgs', 'companyName'], 'Unknown') })
      setIsLoading(false)
    }
  }, [dispatch, idOrg, uuidPlugin, isMounted])

  const onSubmit = async (data, plugin: PluginModel) => {
    try {
      await dispatch(installPlugin({ idOrg, idPlugin: plugin.id, data }))
      toast({
        message: `${plugin.name} plugin was successfully installed.`,
        type: ToastType.SUCCESS
      })
      await sleep(500)
      await fetchPlugin()
      setInstallPopUpOpen(false)
    } catch (e: any) {
      const error = JSON.parse(e.message)
      toast({
        message: `Failed to install the ${plugin.name} plugin.\nReason: ${error.errorMessage || error.message}`,
        type: ToastType.ERROR
      })
      throw e
    }
  }

  const onActionButtonClick = () => {
    if (!plugin) {
      return
    }

    if (!plugin.isInstalled) {
      setInstallPopUpOpen(true)
    } else {
      setUninstallPopUpOpen(true)
    }
  }

  const onUninstallConfirm = async (plugin: PluginModel) => {
    await dispatch(uninstallPlugin({ idOrg, idPlugin: plugin.id }))
    await sleep(500)
    setUninstallPopUpOpen(false)
    toast({
      message: `${plugin.name} plugin was successfully uninstalled.`,
      type: ToastType.SUCCESS
    })
    await fetchPlugin()
  }

  useEffect(() => {
    fetchPlugin()
  }, [idOrg, uuidPlugin, fetchPlugin])

  const Content = () => {
    if (loading) {
      return <Grid>
        <Row>
          <Col sm={2}><Placeholder loading={loading} type='rect' style={{ height: '65vh', maxWidth: '100%' }} /></Col>
          <Col sm={10}>
            <Row>
              <Col sm={12}><Placeholder loading={loading} type='rect' style={{ height: '10vh', maxWidth: '100%' }} /></Col>
            </Row>
            <Row>
              <Col sm={12}><Placeholder loading={loading} type='rect' style={{ height: '52vh', maxWidth: '100%' }} /></Col>
            </Row>
          </Col>
        </Row>
      </Grid>
    }

    if (!plugin) {
      return null
    }

    return (
      <Styles.MainContent>
        {isInstallPopUpOpen ? <InstallPopup plugin={plugin} onSubmit={(data) => onSubmit(data, plugin)} setInstallPopUpOpen={setInstallPopUpOpen} /> : null}
        <Confirmation
          isOpen={isUninstallPopUpOpen}
          header={`Uninstall ${plugin.name} plugin`}
          text={(
            <div>
              You are about to uninstall the <strong>{plugin.name}</strong> plugin.
            </div>
          )}
          confirmText='Uninstall'
          confirm={() => onUninstallConfirm(plugin)}
          declineText='Cancel'
          decline={() => setUninstallPopUpOpen(false)}
          mainButtonType={ButtonType.destructive}
          close={() => setUninstallPopUpOpen(false)}
        />
        <PluginDetails
          plugin={plugin}
        />
        <PluginContent
          plugin={plugin}
          onAction={onActionButtonClick}
          idOrg={idOrg}
        />
      </Styles.MainContent>
    )
  }
  return (
    <Styles.Wrapper>
      <Styles.Header>
        <BackLink navigateTo={'/plugins'} linkText={'Browse Plugins'} overrideStyle={{ margin: '20px 0 15px 0' }} />
      </Styles.Header>
      <Content />
    </Styles.Wrapper>
  )
}

export default PluginPage
