import React, { useState } from 'react'
import { Button, ButtonType, ButtonSize, Link } from '@toriihq/design-system'
import UploadFileDropzone from '@root/components/uploadAppDetailsAttachments/uploadFileDropzone'
import { InstructionText, ManualOptionWrapper, UploadFileDropzoneWrapper } from './styles'
import { useSelector, useDispatch } from 'react-redux'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { getIdOrg } from '@root/selectors/org'
import { SharedProps, SharedState } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { getContractDetailsFromAI } from '@root/shared/actions/ai'
import { PDF_FILE_TYPES_AND_EXTENSIONS } from '@root/components/uploadAppDetailsAttachments/uploadFileDropzone/view'
import Analytics from './analytics'

const NewContractAI = ({
  onCancel, navigateToNextStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const dispatch = useDispatch()
  const idOrg = useSelector(getIdOrg)
  const { idApp } = sharedProps
  const { idUpload } = sharedState

  const [disabled, setDisabled] = useState(false)

  const onUpload = async (idUpload: number) => {
    Analytics.onUploadContract()
    const result = await dispatch(getContractDetailsFromAI({ idOrg, idUpload, idApp }))
    Analytics.onContractAIResults({ parsedFields: (result.successfullyParsedAmount ?? 0), contractDetails: result.parsedContractDetails })
    setState({ idUpload, isNewContract: false, contractDetails: result.parsedContractDetails, isAiRan: true, successfullyParsedAmount: result.successfullyParsedAmount, shouldShowBackButton: true, idAuditLog: result.idAuditLog, isAlreadyForcedToBeDirty: false })
    navigateToNextStep()
    setDisabled(false)
  }

  const onManualClick = () => {
    Analytics.onManualButtonClick()
    setState({ ...sharedState, shouldShowBackButton: true })
    navigateToNextStep()
  }

  return (
    <>
      <ToriiPopup.Header header={'Add contract'} />
      <ToriiPopup.Content>
        <div style={{ display: 'grid', gap: 25 }}>
          <InstructionText>Quick start by uploading your contract PDF and let Magic Auto-Fill (<Link href='https://support.toriihq.com/hc/en-us/articles/18661742876955-Torii-AI' target='_blank' rel='noopener noreferrer'>Powered by AI partners</Link>) enter contract details for you. You’ll be able to review and edit everything before saving.</InstructionText>
          <UploadFileDropzoneWrapper>
            <UploadFileDropzone
              uploadingText={'Magic in progress...'}
              supportedFileTypesAndExtensions={PDF_FILE_TYPES_AND_EXTENSIONS}
              additionalFailureText={'Please upload a PDF file.'}
              onPreUpload={() => setDisabled(true)}
              onPostUpload={({ idUpload }) => onUpload(idUpload)}
              isInsidePopup
            />
          </UploadFileDropzoneWrapper>
          <ManualOptionWrapper>
            <div>or</div>
            <Button type={ButtonType.compact} size={ButtonSize.small}
              onClick={onManualClick}
              label='Manually enter contract details'
              disabled={disabled}
            />
          </ManualOptionWrapper>
        </div>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={() => onCancel()}
        cancelButtonText={'Cancel'}
        isMainButtonDisabled={!idUpload}
        showMainButton={false}
      />
    </>
  )
}

export default NewContractAI
