import View from './view'
import { connect } from 'react-redux'
import { getAppStateInfo, isInUse } from '@lenses/apps'
import { EMPTY_OBJECT, SCOPES } from '@root/constants'
import sumBy from 'lodash/sumBy'
import isEmpty from 'lodash/isEmpty'
import { getAppDetailsFields, getAppDetailsValues, deprecatedGetAppsByIds, getCurrentApp } from '@selectors/apps'
import get from 'lodash/get'
import AccessControl from '@lenses/accessControl'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import { getAppConnectedIntegration, isServicesLoading as getIsServicesLoading, getConstantServices } from '@selectors/services'
import { getConnectedAppUsersStatusesBreakdown } from '@selectors/app'

const mapStateToProps = (state, ownProps) => {
  const { idOrg, idApp } = ownProps
  const app = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  const currentApp = getCurrentApp(state) || EMPTY_OBJECT
  const stateInfo = getAppStateInfo({ values: getAppDetailsValues(state), idApp, fields: getAppDetailsFields(state) })
  const isServicesLoading = getIsServicesLoading(state)

  const { lastVisitTime, assignedLicensesCount, unassignedLicensesCount, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated } = app
  const { activeUsersCount, integrationActiveUsersCount } = currentApp?.app || {
    activeUsersCount: 0,
    integrationActiveUsersCount: 0
  }

  const expenses = (app.expenses || [])
  const totalExpenses = sumBy(expenses, 'total')

  const showLicenseNumber = assignedLicensesCount > 0 || unassignedLicensesCount > 0
  const isContractsVisible = AccessControl.isAccessibleWithState(
    {
      scopes: [SCOPES.CONTRACTS_READ, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp)],
      state
    })
  const hasExpensesAccess = AccessControl.isAccessibleWithState(
    {
      scopes: [SCOPES.EXPENSE_READ, getScopeByIdOrgAndIdApp(SCOPES.EXPENSE_READ, idOrg, idApp)],
      state
    })

  const isConnected = Boolean(getAppConnectedIntegration(state, { idApp }))

  const getNumberOfUsersFromStatusesBreakdown = (statusesBreakdown) => {
    const statusArray = get(statusesBreakdown, 'allAppAccountsBreakdown.status', [])
    const activeStatuses = statusArray.find(statusObj => statusObj.value === 'active')
    if (activeStatuses) {
      const externalStatusArray = get(activeStatuses, 'externalStatus', [])
      return sumBy(externalStatusArray, 'count')
    }
    return 0
  }

  let numberOfUsers = activeUsersCount
  let connectedAppUsersStatusesBreakdown, integrationDocumentationLink
  if (isConnected) {
    connectedAppUsersStatusesBreakdown = getConnectedAppUsersStatusesBreakdown(state)
    const servicesConfig = getConstantServices(state)
    const serviceConfig = servicesConfig?.find(config => config.idApp === idApp)
    integrationDocumentationLink = serviceConfig?.connectFormConfiguration?.link?.url
    numberOfUsers = connectedAppUsersStatusesBreakdown
      ? getNumberOfUsersFromStatusesBreakdown(connectedAppUsersStatusesBreakdown)
      : integrationActiveUsersCount
  }

  return {
    ...app,
    score: get(currentApp, ['app', 'score'], app.score),
    isCore: get(currentApp, ['app', 'isCore'], app.isCore),
    isInUse: isInUse(app),
    stateInfo,
    numberOfUsers,
    numberOfAssignedLicenses: assignedLicensesCount,
    numberOfUnassignedLicenses: unassignedLicensesCount,
    isTotalAmountEnteredByUser,
    isAppTotalAmountPartialCalculated,
    showLicenseNumber,
    totalExpenses,
    lastVisitTime,
    loading: isEmpty(app) || get(currentApp, ['loading'], false) || isServicesLoading,
    activeContractsTotalValue: get(currentApp, ['app', 'activeContractsTotalValue'], null),
    isContractsVisible,
    hasExpensesAccess,
    isConnected,
    connectedAppUsersStatusesBreakdown,
    integrationDocumentationLink
  }
}

export default connect(mapStateToProps)(View)
