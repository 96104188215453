import { GET_INSIGHTS_STATS } from '../constants'

const initialState = {
  insights: {
    loading: false,
    isLoaded: false,
    data: {}
  }
}

const statsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_INSIGHTS_STATS}_PENDING`: {
      return {
        ...state,
        insights: {
          ...state.insights,
          loading: true
        }
      }
    }
    case `${GET_INSIGHTS_STATS}_FAILED`: {
      return {
        ...state,
        insights: {
          ...state.insights,
          loading: false
        }
      }
    }
    case `${GET_INSIGHTS_STATS}_RESPONSE`: {
      const data = action.payload
      return {
        ...state,
        insights: {
          ...state.insights,
          data,
          loading: false,
          isLoaded: true
        }
      }
    }
    default: {
      return state
    }
  }
}

export default statsReducer
