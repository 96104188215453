import colors from '@root/shared/style/colors'
import styled from 'styled-components'

export namespace Styles {
  export const Header = styled.div`
    font: ${({ theme }) => theme.typography.font.header04};
    padding-bottom: 24px;
  `

  export const Section = styled.div`
    width: 505px;
    padding-bottom: 40px;
  `

  export const SectionHeader = styled.div`
    display: flex;
    padding-bottom: 8px;
  `

  export const SectionTitle = styled.div`
    font: ${({ theme }) => theme.typography.font.subtitle01};
    flex-grow: 1;
  `

  export const Link = styled.a`
    color: ${colors.blue} !important;
    font-weight: normal;
    cursor: pointer;
    transition: color .2s;
    
    &:active,
    &:hover,
    &:focus {
      color: ${colors.lightBlue1} !important;
    }
  `

  export const SectionSubheader = styled.div`
    font: ${({ theme }) => theme.typography.font.body02};
    padding-bottom: 16px;
  `
}
