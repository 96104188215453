import View from './view'
import { connect } from 'react-redux'
import { getSelf } from '../../../selectors/me'
import Analytics from '../../../helpers/analytics'
import { getMe } from '../../../pages/login/actions'
import { getServicesConnectionStatus, isAnyServiceConnected } from '../../../selectors/org'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import { getOrg } from '@shared/actions'

const mapStateToProps = state => {
  const { idOrg, org } = getSelf(state) || {}
  const servicesConnectionStatus = getServicesConnectionStatus(state)
  const shouldSkipStep = !isEmpty(servicesConnectionStatus) && some(servicesConnectionStatus, serviceConnectionStatus => serviceConnectionStatus)
  return {
    idOrg,
    shouldSkipStep,
    planType: org?.plan?.type,
    isAnyServiceConnected: isAnyServiceConnected(state),
    onUserConvinced: () => {
      Analytics.track('Go back to connecting services on sign up')
    },
    onUserSkip: () => {
      Analytics.track('Skip connecting services on sign up')
    }
  }
}

const mapDispatchToProps = {
  getMe,
  getOrg
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
