import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import colors from '@shared/style/colors'
import { Field } from 'react-final-form'
import get from 'lodash/get'
import { FORM_ERROR } from 'final-form'
import SelectApps from '../../selectApps'
import FormGroup from '../../form/formGroup'
import InputField from '../../popups/inputPopup/input'
import Select from '../../select'
import SelectTags from '../../selectTags'
import SelectState from '../../selectState'
import { getFullUrl, getValue, urlValidator } from '@shared/utils'
import Analytics from '../../../helpers/analytics'
import {
  toggleAddApplication,
  addApplication as addApplicationAction,
  addCustomApplication as addCustomApplicationAction,
  editCustomApplication as editCustomApplicationAction,
  deprecatedGetApps,
  getStateDetails,
  searchAllApps,
  uploadOrgImage
} from '@shared/actions'
import { getCurrentOrg } from '@root/selectors/me'
import { getAddApplicationPopup } from '@root/selectors/ui'
import * as Style from './style'
import { SCOPES } from '@root/constants'
import { LOGO_TYPES } from '@root/constants.t'
import { getAppDetailsStateField } from '@selectors/apps'
import { Button, ButtonType, ButtonSize, AlertBox, AlertBoxType, Spacer, AppIcon, TextArea } from '@toriihq/design-system'
import { getDataAboutAppFromAI } from '@shared/actions/ai'
import BrowseUploadBox from '@components/browseUploadBox'
import LogoContainer from '@components/appCatalog/components/LogoContainer'

const CATEGORIES = [
  'Operations',
  'Sales & Marketing',
  'Developer Tools',
  'Design',
  'Project Management',
  'Customer Success',
  'Human Resource',
  'IT & Security',
  'Finance',
  'Productivity',
  'Analytics & BI'
]

const AddApplicationPopup = (props) => {
  const { close, cancel } = props
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, onSuccessCB, customApp, editMode, app, openFrom } = useSelector(getAddApplicationPopup)
  const stateInfo = useSelector(getAppDetailsStateField)

  const dispatch = useDispatch()

  const [customAppState, setCustomAppState] = useState(false)
  const [shouldShowNoSuccessMessage, setShouldShowNoSuccessMessage] = useState(false)
  const [appInfoAIResult, setAppInfoAIResult] = useState({})
  const [isEditLogoLoading, setIsEditLogoLoading] = useState(false)
  const nameRef = useRef('')

  useEffect(() => {
    if (!isOpen) {
      setCustomAppState(false)
      nameRef.current = ''
    }
  }, [isOpen])

  useEffect(() => {
    setCustomAppState(editMode || customApp || props.customApp)
  }, [customApp, editMode, props.customApp])

  const onFileSelected = async (input, file) => {
    setIsEditLogoLoading(true)

    const { success, url } = await dispatch(uploadOrgImage({ idOrg, file, imageType: LOGO_TYPES.CUSTOM_APP }))
    if (success) {
      input.onChange(url)
    }
    setIsEditLogoLoading(false)
  }

  const handleRemoveLogo = (input) => {
    input.onChange(null)
  }

  const addApplication = ({ app, state }) => {
    return dispatch(addApplicationAction({ idOrg, idApp: app.id, state }))
  }

  const addCustomApplication = ({ name, url, imageUrl, category, description, tags, state }) => {
    return dispatch(addCustomApplicationAction({ idOrg, name, url, imageUrl, category, description, tags, state }))
  }

  const editCustomApplication = ({ name, url, imageUrl, category, description, tags }) => {
    return dispatch(editCustomApplicationAction({ idOrg, idApp: app.id, name, url, imageUrl, category, description, tags }))
  }

  const onSuccess = (idApp) => {
    dispatch(deprecatedGetApps({ idOrg }))
    dispatch(getStateDetails({ idOrg }))
    onSuccessCB(idApp)
    const SUCCESS_DELAY = 1000
    setTimeout(close || onClose, SUCCESS_DELAY)
  }

  const submitForm = (form) => {
    const { reset } = props

    const clickedAIButton = appInfoAIResult.hasAnswer !== undefined
    Analytics.track(`Clicked to submit form: ${editMode ? 'edit' : 'add'} ${customAppState ? 'custom' : ''} application`, {
      'Open from': openFrom,
      'Clicked to get info by AI': clickedAIButton,
      'AI Description changed': clickedAIButton && appInfoAIResult.description !== form.description,
      'AI Category changed': clickedAIButton && appInfoAIResult.category !== form.category,
      'AI website changed': clickedAIButton && appInfoAIResult.website !== form.url
    })

    const func = customAppState ? (editMode ? editCustomApplication : addCustomApplication) : addApplication
    form = {
      ...form,
      category: getValue(form.category)
    }
    return Promise.resolve(func(form))
      .then(res => {
        const error = get(res, 'response.error')
        if (!error) {
          setTimeout(reset, 1000)
          return onSuccess(res.app.id)
        }
        return { [FORM_ERROR]: 'Error adding app, please try again' }
      })
      .catch(e => {
        return { [FORM_ERROR]: 'Error adding app, please try again' }
      })
  }

  const selectAppComponent = (props) => {
    const { input, label } = props
    return (
      <FormGroup label={label}>
        <SelectApps
          {...input}
          disableExistsApps
          disableHiddenApps
          value={input.value}
          onChange={(app, searchValue) => {
            if (app.value === 'customApp') {
              nameRef.current = searchValue
              setCustomAppState(true)
            } else {
              input.onChange(app)
            }
          }}
          specialOption={{
            value: 'customApp',
            render: (searchValue) => {
              return <div style={{ color: colors.blue }}>Add a custom app "{searchValue}"</div>
            }
          }}
        />
      </FormGroup>
    )
  }

  const selectTagsComponent = ({ input, label, meta: { touched, error } }) => {
    return (
      <FormGroup label={label} error={touched && error}>
        <SelectTags
          input={input}
        />
      </FormGroup>
    )
  }

  const selectStateComponent = ({ input, label, meta: { touched, error } }) => {
    return (
      <FormGroup label={label} error={touched && error}>
        <SelectState
          {...stateInfo}
          allowedScopes={[SCOPES.APPLICATIONS_WRITE]}
          allowHideApp={false}
          input={input}
          onChange={(selectedOption) => selectedOption ? input.onChange(selectedOption) : null}
          selectedValue={input.value}
        />
      </FormGroup>
    )
  }

  const selectImageUrlComponent = ({ input, label, meta: { touched, error } }) => {
    return (
      <FormGroup label={label} error={touched && error}>
        {
          input.value
            ? (
              <LogoContainer
                logo={input.value}
                imageComponent={<AppIcon size='large' appImageUrl={input.value} />}
                onEdit={(file) => onFileSelected(input, file)}
                onRemove={() => handleRemoveLogo(input)}
                isLoading={isEditLogoLoading}
                logoType={LOGO_TYPES.CUSTOM_APP}
                hasDeleteConfirmation={false}
              />
            )
            : <BrowseUploadBox onFileSelect={(file) => onFileSelected(input, file)} types={['image/png']} isLoading={isEditLogoLoading} scopes={[SCOPES.APPLICATIONS_WRITE]} />
        }
      </FormGroup>
    )
  }

  const selectCategory = ({ input, label, meta: { touched, error } }) => {
    return (
      <FormGroup label={label} error={touched && error}>
        <Select
          {...input}
          clearable={false}
          options={CATEGORIES.map(key => ({ label: key, value: key }))}
        />
      </FormGroup>
    )
  }

  const description = ({ input, label, meta: { touched, error } }) => {
    return (
      <FormGroup label={label} error={touched && error}>
        <TextArea {...input} />
      </FormGroup>
    )
  }

  const searchAppsFromDb = async (value) => {
    return dispatch(searchAllApps({ idOrg, limit: 1, q: value.trim(), exactAppName: true }))
  }

  const getAIInfoAboutApp = async (appName, form) => {
    Analytics.track(`Clicked to get App info by AI`, {
      'App Name': appName
    })

    const appDataByAI = await dispatch(getDataAboutAppFromAI({ appName }))

    setAppInfoAIResult(appDataByAI)
    if (!appDataByAI.hasAnswer || (!appDataByAI.category && !appDataByAI.url && !appDataByAI.description && !appDataByAI.tags)) {
      setShouldShowNoSuccessMessage(true)
    } else {
      form.mutators.setValueByAI(appDataByAI)
    }
  }

  const renderAddApplicationForm = (formProps) => {
    const { errors, handleSubmit, values, form } = formProps
    const appNameAlreadyExistError = (get(errors, 'name') === 'Application name already exists')

    return <form onSubmit={handleSubmit}>
      <div {...Style.Fields}>
        {!customAppState && <>
          <Field
            name='app'
            label='Application:'
            component={selectAppComponent}
            validate={value => value ? undefined : 'Select app'}
          />
          {values.app && <Field
            name='state'
            label='State:'
            component={selectStateComponent}
          />}
        </>}
        {customAppState && <>
          <Field
            name='name'
            autoFocus={false}
            errorAfterTouchOnly={false}
            label='Application name:'
            component={InputField}
            validate={async value => {
              const prevName = nameRef.current
              nameRef.current = value
              setShouldShowNoSuccessMessage(false)
              if (!value) {
                return 'Required'
              }

              if (value === prevName) {
                return formProps.errors.name
              }

              const { apps } = await searchAppsFromDb(value)
              return !apps[0] || apps[0].name.toLowerCase() !== value.trim().toLowerCase() || (app && apps[0].id === app.id) ? undefined : 'Application name already exists'
            }}
          />
          {appNameAlreadyExistError && <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => setCustomAppState(false)} label='Click here to choose application from catalog' />}
          { !appNameAlreadyExistError && <div {...Style.AutoFillByAIButton}>
            <span><Button disabled={!nameRef.current} onClick={() => getAIInfoAboutApp(nameRef.current, form)} icon='MagicWand' label='Magic auto-fill' /></span>
            { shouldShowNoSuccessMessage && <Spacer top='space-200'><AlertBox type={AlertBoxType.NEGATIVE} title={'Magic auto-fill was unsuccessful'} description={'Please enter app details manually'} /></Spacer>}
          </div> }
          <Field
            name='url'
            label='Website:'
            autoFocus={false}
            component={InputField}
            validate={(url) => urlValidator(url) ? undefined : 'Invalid url'}
            parse={(url) => getFullUrl(url)}
          />
          <Field
            name='category'
            label='Category:'
            component={selectCategory}
            validate={value => value ? undefined : 'Required'}
          />
          <Field
            name='description'
            label='Description:'
            component={description}
            validate={value => value ? undefined : 'Required'}
          />
          <Field
            name='tags'
            label='Tags:'
            component={selectTagsComponent}
          />
          {!editMode && <Field
            name='state'
            label='State:'
            component={selectStateComponent}
          />}
          <Field
            name='imageUrl'
            label='Logo:'
            component={selectImageUrlComponent}
          />
        </>}
      </div>
    </form>
  }

  const onCancel = () => {
    Analytics.track(`Clicked to close popup: ${editMode ? 'edit' : 'add'} ${customAppState ? 'custom' : ''} application`)
    dispatch(toggleAddApplication({ isAddApplicationOpen: false }))
  }

  const onClose = () => {
    dispatch(toggleAddApplication({ isAddApplicationOpen: false, isByUser: false }))
  }

  const renderTitleText = () => {
    return `${editMode ? 'Update' : 'Add'} application`
  }

  const initialValues = {
    name: nameRef.current,
    ...(app || {}),
    state: stateInfo?.options.find(({ value }) => value === 'discovered')
  }

  return (
    <ToriiPopup
      isOpen={props.isOpen || isOpen}
      onCloseAction={cancel || onCancel}
      closeOnOverlayClick={false}
      styles={{ modal: { display: 'flex', flexDirection: 'column', width: '550px' } }}
    >
      <ToriiPopup.Header header={renderTitleText()} subHeader={'Add application to your SaaS inventory'} />

      <ToriiPopup.Form
        onSubmit={submitForm}
        validateOnBlur={customAppState}
        initialValues={initialValues}
        render={renderAddApplicationForm}
        contentAreaStyle={customAppState ? Style.CustomAppContentArea : Style.ContentArea}
        mutators={{
          setValueByAI: (args, state, utils) => {
            const appData = args[0]
            const KEY_MAPPING = {
              website: 'url'
            }

            Object.keys(appData).forEach(key => {
              utils.changeValue(state, KEY_MAPPING[key] || key, () => appData[key])
            })
          }
        }}
        renderFooter={(formProps) => {
          const { submitError } = formProps
          return (<ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            mainButtonText={renderTitleText()}
            isMainSubmit
            formProps={formProps}
          >
            {submitError && <div {...Style.ErrorMessage}>{submitError}</div>}
          </ToriiPopup.Footer>
          )
        }} />

    </ToriiPopup>)
}

AddApplicationPopup.propTypes = {
  isOpen: PropTypes.bool,
  cancel: PropTypes.func,
  close: PropTypes.func,
  customApp: PropTypes.bool
}

export default AddApplicationPopup
