import {
  LOGIN,
  GET_SAML_URL,
  SIGNUP,
  LOGIN_IS_USING_SAML,
  TRIAL_SIGNUP
} from '../constants'
import ParseValidation from '../helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  data: {},
  redirect: false,
  SIGNUP: {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
  },
  TRIAL_SIGNUP: {
    loading: false,
    success: false
  },
  SAML: {
    loading: false,
    success: false,
    error: undefined,
    hasError: {},
    help: {}
  },
  SAMLCheck: {
    loading: false,
    error: undefined,
    email: undefined,
    hasError: {},
    data: {}
  }
}

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${LOGIN}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${LOGIN}_FAILED`:
    case `${LOGIN}_RESPONSE`: {
      const validation = ParseValidation(action.payload)
      const redirect = Boolean(action.payload.loginUrl)

      return {
        ...state,
        loading: false,
        success: !action.error,
        error: validation.error,
        hasError: validation.hasError,
        help: validation.help,
        data: validation.data,
        redirect
      }
    }

    case `${LOGIN_IS_USING_SAML}_PENDING`: {
      return {
        ...state,
        SAMLCheck: {
          ...state.SAMLCheck,
          loading: true
        }
      }
    }
    case `${LOGIN_IS_USING_SAML}_FAILED`: {
      const validation = ParseValidation(action.payload)
      return {
        ...state,
        SAMLCheck: {
          ...state.SAMLCheck,
          loading: false,
          error: validation.error || 'Unexpected error, please try again',
          data: validation.data
        }
      }
    }
    case `${LOGIN_IS_USING_SAML}_RESPONSE`: {
      const { isSAML, loginUrl, allowLoginsViaEmailAndPassword } = action.payload
      const { email } = action.meta
      const redirect = Boolean(loginUrl)

      return {
        ...state,
        SAMLCheck: {
          ...state.SAMLCheck,
          email,
          loading: false,
          error: undefined,
          data: {},
          isSAML,
          redirect,
          allowLoginsViaEmailAndPassword
        }
      }
    }

    case `${GET_SAML_URL}_PENDING`: {
      return {
        ...state,
        SAML: {
          ...state.SAML,
          loading: true
        }
      }
    }
    case `${GET_SAML_URL}_FAILED`:
    case `${GET_SAML_URL}_RESPONSE`: {
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        SAML: {
          ...state.SAML,
          loading: false,
          success: !action.error,
          error: validation.error,
          hasError: validation.hasError,
          help: validation.help
        }
      }
    }

    case `${SIGNUP}_PENDING`: {
      return {
        ...state,
        SIGNUP: {
          ...state.SIGNUP,
          loading: true
        }
      }
    }
    case `${SIGNUP}_FAILED`:
    case `${SIGNUP}_RESPONSE`: {
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        SIGNUP: {
          ...state.SIGNUP,
          loading: false,
          success: !action.error,
          error: validation.error,
          hasError: validation.hasError,
          help: validation.help,
          isSAML: Boolean(action.payload.loginUrl)
        }
      }
    }

    case `${TRIAL_SIGNUP}_PENDING`: {
      return {
        ...state,
        TRIAL_SIGNUP: {
          ...state.TRIAL_SIGNUP,
          loading: true
        }
      }
    }
    case `${TRIAL_SIGNUP}_FAILED`:
    case `${TRIAL_SIGNUP}_RESPONSE`: {
      const { success } = action.payload || {}
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        TRIAL_SIGNUP: {
          ...state.TRIAL_SIGNUP,
          loading: false,
          success: success,
          error: validation.error,
          hasError: validation.hasError,
          help: validation.help
        }
      }
    }

    default: {
      return state
    }
  }
}

export default loginReducer
