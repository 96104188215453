import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getLicensesTypes, deprecatedGetApps, updateLicense, getSupportedFeatures, toggleLicenseEditPopup } from '@shared/actions'
import { isLoadingLicensesTypes, getOrgLicenses } from '@selectors/licenses'
import isEmpty from 'lodash/isEmpty'
import { isAppsLoaded } from '@selectors/apps'
import AccessControl from '@lenses/accessControl'
import { SCOPES, TABLES } from '@root/constants'
import { getBenchmarkSettings } from '@selectors/org'
import { getUserPreferences } from '@selectors/ui'
import { FEATURES } from '@root/shared/features'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const isLoadedApps = isAppsLoaded(state)
  const isLoading = isLoadingLicensesTypes(state)
  const view = getUserPreferences(state)[TABLES.licensesTable.key].defaultCustomSelectOption
  const licenses = getOrgLicenses(state, { view })
  const hasLicensesData = !isEmpty(licenses)
  const hasLicensesCostAndChargebackAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state })
  const hasBenchmarkFeatureInPlan = AccessControl.isFeatureEnabledInPlan({ feature: FEATURES.LICENSES.COMPONENTS.BENCHMARK, state })
  const isBenchmarkAllowedInOrg = Boolean(getBenchmarkSettings(state))
  const isLicenseBenchmarkOnPreview = Boolean(window.location.search.toLowerCase().includes('preview'))

  return {
    idOrg,
    loading: (isLoading && !hasLicensesData) || !isLoadedApps,
    licenses,
    view,
    hasLicensesCostAndChargebackAccess,
    displayBenchmark: hasBenchmarkFeatureInPlan && hasLicensesCostAndChargebackAccess && (isBenchmarkAllowedInOrg || isLicenseBenchmarkOnPreview)
  }
}

const mapDispatchToProps = {
  getLicensesTypes,
  deprecatedGetApps,
  getSupportedFeatures,
  toggleLicenseEditPopupDispatch: toggleLicenseEditPopup,
  updateLicense
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
