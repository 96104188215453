import React from 'react'
import { Icon, Tooltip } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import Analytics from '../../helpers/analytics'
import { clearButton } from '@shared/style/mixins'

const CSS = {
  feedbackBoardButton: css(clearButton, {
    height: '36px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background .2s',
    ':hover': {
      background: colors.lightBlue5,
      cursor: 'pointer'
    },
    ':focus': {
      background: colors.lightBlue5
    },
    ':active': {
      boxShadow: `0 0 0 2px ${colors.lightBlue5}`,
      background: colors.lightBlue5
    }
  })
}

const FeedbackBoardButton = () => {
  const onClick = () => {
    Analytics.track('Clicked to open feedback board')
    const newWindow = window.open('https://api.toriihq.com/api/featurebase/login?return_to=https://feedback.toriihq.com', '_blank') || { opener: null }
    newWindow.opener = null
  }

  return <Tooltip
    placement='bottom'
    label='Give us feedback'>
    <button {...CSS.feedbackBoardButton} onClick={() => onClick()}>
      <Icon name='MessageCircle' size='mobile' color='interactive' />
    </button>
  </Tooltip>
}

export default FeedbackBoardButton
