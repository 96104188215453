import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'

export const Main = css(texts.body, {
  position: 'relative',
  alignItems: 'center',
  border: `1px solid ${colors.border}`,
  borderRadius: '4px',
  background: colors.white,
  color: colors.grey1
})

export const Body = css({
  display: 'flex',
  verticalAlign: 'middle',
  width: '100%',
  alignItems: 'center',
  height: '65px',
  padding: '0 20px',
  ':not(:last-child)': {
    borderBottom: `solid 1px ${colors.lightBlue2}`
  }
})

export const Header = css(texts.subheading, {
  color: colors.black,
  fontSize: '14px',
  lineHeight: '32px',
  marginRight: '10px',
  display: 'flex'
})

export const HeaderLabel = css({
  display: 'flex',
  width: '80%'
})

export const HeaderAction = css({
  display: 'inline-block'
})

export const Details = css({
  textAlign: 'center'
})

export const OwnerButtons = css({
  marginLeft: 'auto',
  display: 'flex'
})

export const HeaderButton = css({
  color: `${colors.black} !important`,
  backgroundColor: `${colors.white} !important`,
  padding: '12px 6px',
  border: `solid 1px ${colors.lightBlue2}`
})

export const HeaderWrapper = css({
  width: '100%',
  padding: '20px',
  borderBottom: 'solid 1px',
  borderColor: colors.lightBlue2,
  display: 'flex',
  justifyContent: 'space-between'
})

export const EditButton = css({
  opacity: '0 !important',
  [`.${Body}:hover &, [data-${Body}]:hover &`]: {
    opacity: '1 !important',
    ':disabled': {
      opacity: '0.5 !important'
    }
  }
})
