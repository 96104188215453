import { WORKFLOW_OUTPUT_FIELD_TYPES } from '@root/constants'
import { BaseTag } from '@shared/workflows/types'
import { capitalize, partition } from 'lodash'

const FIELD_TYPE_TO_LABEL = {
  [WORKFLOW_OUTPUT_FIELD_TYPES.APP]: 'Application',
  [WORKFLOW_OUTPUT_FIELD_TYPES.USER]: 'User'
}

export const mapOutputFieldToBranchingEntity = (outputField: any): { label: string, value: string } => {
  const { type } = outputField

  return {
    label: FIELD_TYPE_TO_LABEL[type] || capitalize(type),
    value: type
  }
}

const ACTION_DESCRIPTION_BY_TYPE: Record<string, string> = {
  'create_workflow': 'created',
  'update_workflow': 'updated',
  'delete_workflow': 'deleted'
}

export const getActionDescriptionByAuditType = ({
  type,
  isActionsChanged,
  isEnabled
}: {
  type: string
  isActionsChanged: boolean
  isEnabled: boolean,
  suffix?: string
}): string => {
  let actionDescription = ACTION_DESCRIPTION_BY_TYPE[type]

  if (actionDescription === 'updated' && !isActionsChanged) {
    actionDescription = isEnabled ? 'enabled' : 'disabled'
  }

  return `${actionDescription}`
}

export const getSortedTags = (tags: BaseTag[]): BaseTag[] => {
  const [orgCustomTags, predefinedTags] = partition(tags, tag => tag.idOrg)
  orgCustomTags.sort((a, b) => a.id - b.id)
  predefinedTags.sort((a, b) => a.label.localeCompare(b.label))

  return [...predefinedTags, ...orgCustomTags]
}
