import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import { clearButton, isDisabledCss } from '../../shared/style/mixins'
import noop from 'lodash/noop'
import TetherComponent from 'react-tether'
import EnableFor from '@components/enableFor'

const tetherStyle = { zIndex: 9999, top: '5px' }
const tetherConstraints = [
  { to: 'window', attachment: 'together', pin: false }
]

const CSS = {
  main: css({
    position: 'relative'
  }),
  ul: css({
    top: '20px',
    width: '220px',
    backgroundColor: colors.white,
    color: colors.darkText,
    boxShadow: '0 6px 7px 0 rgba(0, 0, 0, 0.11)',
    border: `1px solid ${colors.border}`,
    borderRadius: '2px',
    listStyle: 'none',
    margin: '5px 0',
    padding: '5px 0',
    opacity: 0,
    transform: 'translateY(-5px)',
    transition: 'opacity .2s, transform .1s',
    visibility: 'hidden',
    ' > li': {
      height: '35px',
      lineHeight: '35px',
      ' > a': {
        display: 'block',
        paddingLeft: '15px',
        transition: 'background-color .2s',
        ':hover': {
          backgroundColor: colors.background
        }
      }
    }
  }),
  isOpen: css({
    opacity: 1,
    visibility: 'visible',
    transform: 'translateY(0)'
  }),
  menuItem: css({
    fontSize: 12,
    padding: '0 10px',
    ':hover': {
      background: colors.lightBlue3
    }
  }),
  listItemButton: css({
    all: 'unset',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
    ':disabled': {
      opacity: 0.5,
      cursor: 'not-allowed'
    }
  })
}

class Menu extends React.Component {
  state = { isOpen: false }

  containers = {}

  componentDidMount () {
    const { hideOnScroll } = this.props
    hideOnScroll && document.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount () {
    const { hideOnScroll } = this.props
    hideOnScroll && document.removeEventListener('scroll', this.handleScroll, true)
  }

  handleScroll = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
      this.props.onMenuClick(false)
    }
  }

  onClick = () => {
    if (!this.state.isOpen) {
      document.addEventListener('touchend', this.handle, true)
      document.addEventListener('click', this.handle, true)
    } else {
      document.removeEventListener('touchend', this.handle, true)
      document.removeEventListener('click', this.handle, true)
    }
    this.setState({ isOpen: !this.state.isOpen })
    this.props.onMenuClick(!this.state.isOpen)
  }

  onClickOutside = () => {
    if (this.state.isOpen) {
      document.removeEventListener('touchend', this.handle, true)
      document.removeEventListener('click', this.handle, true)
      this.setState({ isOpen: false })
      this.props.onMenuClick(false)
    }
  }

  setButtonContainer = (ref) => {
    this.containers.button = ref
  }

  setListContainer = (ref) => {
    this.containers.list = ref
  }

  isClickOutside = (el, e) => el && !el.contains(e.target)
  handle = e => {
    if (e.type === 'touchend') {
      this.isTouch = true
    }

    if (e.type === 'click' && this.isTouch) {
      return
    }

    if (this.isClickOutside(this.containers.button, e) && this.isClickOutside(this.containers.list, e)) {
      this.onClickOutside(e)
    }
  }

  render () {
    const { options, children, optionRender, attachment, targetAttachment, onOptionClick, overrideStyle, disabled, ulOverrideStyle } = this.props
    const { isOpen } = this.state

    return (
      <div {...css(CSS.main, overrideStyle)}>
        <TetherComponent
          renderElementTo='body'
          ref='tethered-component'
          attachment={attachment}
          targetAttachment={targetAttachment}
          style={tetherStyle}
          constraints={tetherConstraints}
        >
          <div ref={this.setButtonContainer} {...clearButton} {...css(disabled ? isDisabledCss : '')} onClick={disabled ? noop : this.onClick}>
            {children}
          </div>
          {isOpen && <ul ref={this.setListContainer} {...css(CSS.ul, isOpen && CSS.isOpen, ulOverrideStyle)}>
            {options.map((option, i) => (
              <li key={i}>
                <EnableFor scopes={option.scopes} disabled={option.shouldDisable}>
                  <button
                    {...css(CSS.listItemButton)}
                    onClick={(e) => {
                      this.onClick()
                      onOptionClick(option, i, e)
                    }}>
                    <div {...css(CSS.menuItem)}>{optionRender(option) || option}</div>
                  </button>
                </EnableFor>
              </li>
            ))}
          </ul>}
        </TetherComponent>
      </div>
    )
  }
}

Menu.propTypes = {
  onOptionClick: PropTypes.func,
  optionRender: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  attachment: PropTypes.string,
  targetAttachment: PropTypes.string,
  overrideStyle: PropTypes.object,
  ulOverrideStyle: PropTypes.object,
  onMenuClick: PropTypes.func,
  hideOnScroll: PropTypes.bool
}

Menu.defaultProps = {
  onOptionClick: noop,
  optionRender: noop,
  attachment: 'top right',
  targetAttachment: 'bottom right',
  onMenuClick: noop,
  ulOverrideStyle: {},
  hideOnScroll: false
}

export default Menu
