import React, { Fragment, useState } from 'react'
import ToriiPopup from '../../../ToriiPopupV2'
import { SharedProps, SharedState } from '../types'
import { PopupRendererProps } from '../../../wizardPopup/types'
import BlueEyesLeft from '@media/blue-eyes-left.svg'
import { Styles } from '../styles'
import { useDispatch } from 'react-redux'
import { createUserTypeSource, deleteUserTypeSource, getUserTypeSources, updateUserTypeSource, sendUserTypeSourceChangesReport } from '@shared/actions/org'
import { SCOPES } from '@root/constants'
import { Button, ButtonType, Icon } from '@toriihq/design-system'
import analytics from '@helpers/analytics'

export const PreviewChanges = ({
  onCancel, navigateToNextStep, navigateToBackStep, sharedState, sharedProps
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const dispatch = useDispatch()
  const [reportSuccess, setReportSuccess] = useState(false)
  const { userTypeSource, action } = sharedState

  const onMainActionButton = async () => {
    const { userTypeSource, isDeleteSource } = sharedState
    const { sourceToEdit } = sharedProps

    if (!userTypeSource) {
      throw new Error('No user type source provided from previous state')
    }

    const { idOrg, id, idApp, type, filter } = userTypeSource
    if (isDeleteSource) {
      id && await dispatch(deleteUserTypeSource({ idSource: id, idOrg, type }))
    } else if (sourceToEdit) {
      sourceToEdit.id && idApp && await dispatch(updateUserTypeSource({ idOrg, idApp, idSource: sourceToEdit.id, filter }))
    } else {
      await dispatch(createUserTypeSource(userTypeSource))
    }

    await dispatch(getUserTypeSources({ idOrg }))
    navigateToNextStep()
  }

  const { changesPreview, isDeleteSource } = sharedState
  const from = changesPreview?.results?.currentUsersCount
  const to = changesPreview?.results?.totalMatchedUsersFoundCount

  const pastUsersFrom = changesPreview?.results?.currentPastUsersCount
  const pastUsersTo = changesPreview?.results?.totalMatchedPastUsersFoundCount

  const totalCurrentChanges = changesPreview?.results?.totalCurrentChanges
  const totalPastChanges = changesPreview?.results?.totalPastChanges

  const onReportClick = async () => {
    analytics.track('Click on send employee definition changes report button')
    userTypeSource && action && await dispatch(sendUserTypeSourceChangesReport({ idOrg: userTypeSource.idOrg, userTypeSource, action }))
    setReportSuccess(true)
  }

  return (<Fragment>
    <ToriiPopup.Header header={'Preview changes'} />
    <ToriiPopup.Content>
      <Styles.TextWithImageAside>
        <Styles.MultilineText insertEmptyLineBetween>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              <Styles.BoldText>
                {Number(from) === Number(to) ? `Number of current ${sharedProps.usersLabel} will not change and remain ${from}.` : `Number of current ${sharedProps.usersLabel} will change from ${from} to ${to}. (${totalCurrentChanges} changes)`}
              </Styles.BoldText>
            </li>
            <li>
              <Styles.BoldText>
                {Number(pastUsersFrom) === Number(pastUsersTo) ? `Number of past ${sharedProps.usersLabel} will not change and remain ${pastUsersFrom}.` : `Number of past ${sharedProps.usersLabel} will change from ${pastUsersFrom} to ${pastUsersTo}. (${totalPastChanges} changes)`}
              </Styles.BoldText>
            </li>
          </ul>
        </Styles.MultilineText>
        <Styles.SideImage alt='Preview changes' src={BlueEyesLeft} />
      </Styles.TextWithImageAside>
      { reportSuccess
        ? <Styles.RequestSent>
          <Icon name={'CheckCircle'} color={'secondary'} />
          We're on it! check your email in a few minutes
        </Styles.RequestSent>
        : <Button icon={'Mail'} label={'Email full changes report'} onClick={onReportClick} type={ButtonType.secondary} /> }
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Continue'}
      isMainButtonDisabled={false}
      mainButtonAction={() => onMainActionButton()}
      mainButtonVisibilityScopes={[SCOPES.SETTINGS_WRITE]}
      showBackButton={!isDeleteSource}
      backButtonText={'Back'}
      backButtonAction={() => navigateToBackStep()} />
  </Fragment>)
}
