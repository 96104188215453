import styled from 'styled-components'

export const MenuItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const MenuItemText = styled.span`
  margin-left: 8px;
  font-size: 14px;
`

export const MenuUlOverrideStyle = {
  width: '165px',
  borderRadius: '4px'
}
