import React, { ReactElement } from 'react'
import { SCOPES } from '@root/constants'
import SelectionBox from '@components/workflows/selectionBox'
import { AppTriggerOptions, TriggerTypeToTriggerOptionsValues } from '@selectors/workflows/types'
import { useSelector } from 'react-redux'
import {
  getFilteredTriggersOptionsByApp,
  getTriggersOptionsByApp,
  getTriggersOptionsValuesByTriggerType
} from '@selectors/workflows'
import { getSupportedFeatures } from '@selectors/org'
import { css } from 'glamor'

interface Props {
  triggerType: string | null
  onSelect: (e: { idApp: number, type: string }) => void
}

const TriggerSelector = ({ onSelect, triggerType }: Props): ReactElement => {
  const supportedFeatures = useSelector(getSupportedFeatures)
  const triggersOptionsByApp: AppTriggerOptions[] = useSelector(getTriggersOptionsByApp)
  const filteredTriggersOptionsByApp: AppTriggerOptions[] = useSelector(getFilteredTriggersOptionsByApp)
  const triggerTypeToValue: TriggerTypeToTriggerOptionsValues = useSelector(getTriggersOptionsValuesByTriggerType)

  const isUserStoppedUsingAppSupported = supportedFeatures?.userStoppedUsingApp
  const triggerOptionsByApp = isUserStoppedUsingAppSupported ? triggersOptionsByApp : filteredTriggersOptionsByApp

  return (
    <SelectionBox
      selectedValue={triggerType && triggerTypeToValue[triggerType]}
      groups={triggerOptionsByApp}
      onChange={onSelect}
      display='block'
      allowedScopes={[SCOPES.AUTOMATION_WRITE]}
      showComingSoonBadge
      hideHeader
      overrideStyle={css({ margin: 0, padding: 0, border: 'none' })}
    />
  )
}

export default TriggerSelector
