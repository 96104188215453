import View from './view'
import { connect } from 'react-redux'
import { getOffboardingApp, getWorkflowsTriggersConfig, toggleConfigureAppForOffboarding, getWorkflowsActionsConfig } from '@shared/actions'
import { getOffboardingApps, isOffboardingAppsLoading } from '@selectors/offboarding'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  const appsOffboardingConfig = getOffboardingApps(state)
  const appOffboardingConfig = appsOffboardingConfig.filter(app => app.id === idApp)
  const loading = isOffboardingAppsLoading(state) && appsOffboardingConfig.length === 0

  return {
    idOrg,
    appOffboardingConfig,
    loading
  }
}

const mapDispatchToProps = {
  getOffboardingApp,
  toggleConfigureAppForOffboarding,
  getWorkflowsTriggersConfig,
  getWorkflowsActionsConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
