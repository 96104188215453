import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '../../../../selectors/me'
import { withRouter } from 'react-router-dom'
import { TABLES } from '../../../../constants'

const mapStateToProps = (state) => {
  const { domain } = getCurrentOrg(state)
  const tableInfo = TABLES.usersListPopup
  return {
    domain,
    tableInfo
  }
}

export default withRouter(connect(mapStateToProps)(View))
