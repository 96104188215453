import get from 'lodash/get'
import isArray from 'lodash/isArray'
import map from 'lodash/map'
import config from '@root/config'
import qs from 'query-string'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import isNil from 'lodash/isNil'
import numeral from 'numeral'
import pluralize from 'pluralize'
import { useSelector } from 'react-redux'

export const sortStringFromArray = (sortArray) => {
  return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
}

export const mergeByProperty = (target, source, prop) => {
  source.forEach(sourceElement => {
    const targetElement = target.find(targetElement => {
      return sourceElement[prop] === targetElement[prop]
    })
    targetElement ? Object.assign(targetElement, sourceElement) : target.push(sourceElement)
  })
}

export const hasPropsChanged = (props1, props2, keys) => {
  return keys.some(key => props1[key] !== props2[key])
}

export const getValue = (object) => isArray(object) ? object.map(item => getValue(item)) : get(object, 'value', object)
export const getLabel = (object) => isArray(object) ? object.map(item => getValue(item)) : get(object, 'label', object)

export const isValidRedirectUrl = (url) => {
  url = url + '/'

  const validPrefixes = [
    window.location.origin + '/',
    config.appBaseUrl + '/',
    config.apiBaseUrl + '/',
    config.catalogBaseUrl + '/'
  ]

  return validPrefixes.some(prefix => url.startsWith(prefix))
}

export const safeRedirect = (url, { fallbackUrl = '/' } = {}) => {
  const to = isValidRedirectUrl(url) ? url : fallbackUrl
  window.location.href = to
}

export const urlValidator = (url) => (url && /^(http[s]?:\/\/).+$/.test(url))

export const getFullUrl = (url) => (!url || url.startsWith('https://') || url.startsWith('http://') ? url : `https://${url}`)

export const safeWindowOpen = (url) => {
  window.open(getFullUrl(url), '_blank', 'noopener,noreferrer')
}

export const objectToQueryString = object => map(object, (value, key) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')

export const getTableFiltersURLQueryParam = () => {
  try {
    const query = qs.parse(window.location.search)
    const filters = JSON.parse(query.filters)
    return filters.map(filter => (omitBy({
      key: { value: filter.key },
      op: { value: filter.op },
      value: filter.value
    }, isUndefined)))
  } catch (e) {
    return []
  }
}

export const formatNumber = (value) => {
  return numeral(value).format('0,')
}

export const pluralizeWithFormat = (word, count, inclusive) => {
  const [number, pluralWord] = pluralize(word, count, inclusive).split(' ')

  return `${formatNumber(number)} ${pluralWord}`
}

export const formatDateAsUTC = date => {
  return new Date(date).toLocaleDateString('en-US', { timeZone: 'UTC' })
}

export const convertFileTypesToAccept = (validFileTypes) => {
  return validFileTypes.reduce((validFileTypesObj, type) => ({ ...validFileTypesObj, [type]: [] }), {})
}

export const isLicenseTypePaid = licenseType => {
  const { pricePerUser, isPaid } = licenseType
  const isUserProvidedPrice = !isNil(pricePerUser)
  if (isUserProvidedPrice) {
    return Number(pricePerUser) > 0
  }

  return isNil(isPaid) || Boolean(isPaid)
}

export const getHasHave = (number) => number > 1 ? 'have' : 'has'

export const getWasWere = (number) => number > 1 ? 'were' : 'was'

export const getItsTheir = (number) => number > 1 ? 'their' : 'its'

export const createParameterSelector = (selector) => (_, params) => selector(params)

export const createIdAppParameterSelector = createParameterSelector(params => params.idApp)

export const createViewParameterSelector = createParameterSelector(params => params.view)

export const useParamSelector = (selector, ...params) => useSelector(state => selector(state, ...params))

export const sleep = (timeout) => new Promise(resolve => setTimeout(resolve, timeout))
