import AccessControl from '@lenses/accessControl'
import { getCurrentOrg } from '@selectors/me'

const accessControlMiddleware = ({ getState }) => next => action => {
  const scopes = action.scopes || []
  if (!scopes.length) {
    next(action)
    return true
  }
  const state = getState()
  const currentProvisionedOrg = getCurrentOrg(state)
  if (!currentProvisionedOrg.id) {
    next(action)
    return true
  }
  const isAccessible = AccessControl.isAccessibleWithState({ scopes, state })
  if (isAccessible) {
    next(action)
    return true
  }

  return false
}

export default accessControlMiddleware
