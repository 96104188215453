import React, { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { shouldShowIntegrationAlert, INTEGRATION_ALERT_THRESHOLD_IN_DAYS, onClickReviewAndFix } from '@components/_shared/integrationsAlert/utils'
import { IntegrationAlertWrapper } from '@components/_shared/integrationsAlert/style'
import { AlertBox, AlertBoxType, Link } from '@toriihq/design-system'
import { getConnectedServicesByIdApp } from '@selectors/services'
import { getServicesConfig } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import config from '@root/config'
import get from 'lodash/get'

const AppIntegrationsAlert = ({ idApp, appName }: { idApp: number, appName: string }): ReactElement | null => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)

  useEffect(() => {
    if (idOrg) {
      dispatch(getServicesConfig({ idOrg }))
    }
  }, [dispatch, idOrg])

  const connectedServices = get(useSelector(getConnectedServicesByIdApp), idApp, [])
  const showIntegrationAlert = shouldShowIntegrationAlert({ connectedServices })
  if (!showIntegrationAlert) {
    return null
  }

  return <IntegrationAlertWrapper>
    <AlertBox
      type={AlertBoxType.NOTICE}
      title={'User and license data might not be up to date'}
      description={<>
        <span>{`At least one of your ${appName} accounts hasn't successfully synced in more than ${INTEGRATION_ALERT_THRESHOLD_IN_DAYS} days. Please `}</span>
        <Link href={`${config.appBaseUrl}/team/${idOrg}/services/attention`} onClick={() => onClickReviewAndFix({ sourcePageName: 'App page' })}>review and fix</Link>
        <span> failed integrations to get updated data</span>
      </>}
    />
  </IntegrationAlertWrapper>
}

export default AppIntegrationsAlert
