import React, { useEffect } from 'react'
import pluralize from 'pluralize'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import Placeholder from '@components/placeholder'
import { getAppManualImportUsersStatus } from '@selectors/apps'
import { getAppUsersCount } from '@shared/actions'
import { getItsTheir } from '@shared/utils'
import PopupContent from '../popupContent'
import { Subtitle, Text } from '../styles'
import { getAppUsersCount as getAppUsersCountSelector } from '@selectors/appUsers'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'
import { ButtonType } from '@toriihq/design-system'

export type Props = {
  idOrg: number
  idApp: number
  onDeleteClick: () => void
  onCancelClick: () => void
}

const ConfirmDeletionStep = ({
  onDeleteClick,
  onCancelClick,
  idOrg,
  idApp
}: Props): JSX.Element => {
  const {
    loading: isImportStatusLoading,
    workflowsToInvalidate
  } = useSelector(getAppManualImportUsersStatus)
  const appUsersCount = useSelector(getAppUsersCountSelector)

  const dispatch = useDispatch()

  useEffect(() => {
    idOrg && idApp && dispatch(getAppUsersCount({
      idOrg,
      idApp,
      filters: [{ key: 'sources', op: 'equals', value: 'manual' }]
    }))
  }, [idOrg, idApp, dispatch])

  const getWorkflowsNotice = (workflowsToInvalidate?: { name: string }[]) => {
    if (!workflowsToInvalidate?.length) return null

    const workflowNames = workflowsToInvalidate.map(workflow => workflow.name).join(', ')
    const workflowsCount = workflowsToInvalidate.length

    return `
    ${pluralize('workflows', workflowsCount, true)} will be invalidated
    since ${getItsTheir(workflowsCount)} trigger depends on the manually imported account: ${workflowNames}.
  `
  }

  const appUsersCountData = appUsersCount[idApp]

  const loading =
    isImportStatusLoading ||
    !appUsersCountData || appUsersCountData.loading
  const usersCount = appUsersCountData?.count

  const workflowsNotice = getWorkflowsNotice(workflowsToInvalidate)

  const handleDeleteClick = () => {
    ImportUsersAnalytics.onDeleteImportedUsersAnalytics(usersCount)
    onDeleteClick()
  }

  return (<>
    <ToriiPopup.Header header='Are you sure you want to delete all imported users?' />
    <ToriiPopup.Content>
      <Placeholder loading={loading} rows={10}>
        <PopupContent>
          <Text>
            <Subtitle>You are about to delete all {usersCount} manually imported users.</Subtitle>
            <br />
            Both active users and users who have been marked as “no longer in app” will be deleted.
            Users discovered via multiple sources will only be deleted from the “file upload” source.
            You can always import more users in the future.
          </Text>
          {workflowsNotice && (
            <Text>
              <Subtitle>Notice: </Subtitle>
              <br />
              {workflowsNotice}
            </Text>
          )}
        </PopupContent>
      </Placeholder>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonType={ButtonType.destructive}
      mainButtonText='Delete users'
      mainButtonAction={handleDeleteClick}
      cancelButtonText='Cancel'
      cancelButtonAction={onCancelClick}
    />
  </>)
}

export default ConfirmDeletionStep
