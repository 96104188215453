import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Select from '../../components/select'
import { SCOPES } from '@root/constants'
import UserLifecycleApps from '@components/userLifecycleApps'
import { Props } from './types'
import UserTypeSources from '@components/userTypeSources'
import UsersAndEmployeesPageSection from './section'
import { SUPPORT_ARTICLES } from '@root/articles'
import { useDispatch } from 'react-redux'
import { getMergeUsersRules, getUserTypeSourcePreview, getUserTypeSources } from '@shared/actions/org'
import EditUserTypeSourcesPopup from '@components/popups/editUserTypeSourcesPopup'
import { deleteStepsConfig, stepsConfig } from '@components/popups/editUserTypeSourcesPopup/stepsConfig/config'
import { UserTypeSource } from '@components/userTypeSources/types'
import { ChangesPreviewResponse } from '@components/popups/editUserTypeSourcesPopup/stepsConfig/types'
import { ActionForUserTypePreview } from '@shared/actions/org.types'
import { MergeUsersRules } from '@components/mergeUsersRules'
import { ConfigureMergeUsersRulesPopup } from '@components/popups/configureMergeUsersRulesPopup'
import MergeUsersAnalytics from '@components/popups/configureMergeUsersRulesPopup/analytics'
import { H4, Spacer } from '@toriihq/design-system'

const INACTIVE_PERIOD_OPTIONS = [30, 45, 60, 90, 180, 365].map(value => ({ value, label: `${value} Days` }))

function UsersAndEmployeesPage ({
  loadingOrg,
  loadingUserTypeSources,
  loadingMergeUsersRules,
  appNotInUsePeriod,
  idOrg,
  updateOrg
}: Props) {
  const initialUserTypeSource: UserTypeSource = {
    idOrg,
    filter: null,
    type: 'employee'
  }
  const [isSourcePopupOpen, setIsSourcePopupOpen] = useState<boolean>(false)
  const [isDeleteState, setIsDeleteState] = useState<boolean>(false)
  const dispatch = useDispatch()
  const [userTypeSource, setUserTypeSource] = useState<UserTypeSource>()
  const [changesPreview, setChangesPreview] = useState<ChangesPreviewResponse>()
  const [sourceToEdit, setSourceToEdit] = useState<UserTypeSource | null>(null)
  const [isMergeUsersRulesPopupOpen, setIsMergeUsersRulesPopupOpen] = useState<boolean>(false)
  const [isMergeUsersRevokeMode, setIsMergeUsersRevokeMode] = useState<boolean>(false)

  useEffect(() => {
    if (idOrg) {
      dispatch(getUserTypeSources({ idOrg }))
      dispatch(getMergeUsersRules({ idOrg }))
    }
  }, [idOrg, dispatch])

  function onAppNotInUsePeriodChanged (option) {
    const appNotInUsePeriod = option.value
    updateOrg({ idOrg, appNotInUsePeriod })
  }

  const onRemoveSource = async ({ idOrg, idApp, idSource }: {
    idSource: number,
    idOrg: number,
    idApp: number
  }) => {
    const currentUserTypeSource = { ...initialUserTypeSource, idApp, idOrg, id: idSource }
    const changesPreview = await dispatch(getUserTypeSourcePreview({ userTypeSource: currentUserTypeSource, action: ActionForUserTypePreview.DELETE }))
    setIsDeleteState(true)
    setUserTypeSource(currentUserTypeSource)
    setChangesPreview(changesPreview)
    setIsSourcePopupOpen(true)
  }

  const onRemoveSucceeded = () => {
    setIsSourcePopupOpen(false)
    setIsDeleteState(false)
    setUserTypeSource(initialUserTypeSource)
    setChangesPreview(undefined)
  }

  const onClosePopup = () => {
    setIsSourcePopupOpen(false)
    setSourceToEdit(null)
    setIsMergeUsersRulesPopupOpen(false)
  }

  const onMergeUsersRulesSubmitSuccess = () => {
    setIsMergeUsersRulesPopupOpen(false)
  }

  const onConfigureMergeUsersClick = () => {
    setIsMergeUsersRevokeMode(false)
    setIsMergeUsersRulesPopupOpen(true)
  }

  const onRevokeMergeUsersClick = () => {
    setIsMergeUsersRevokeMode(true)
    setIsMergeUsersRulesPopupOpen(true)
  }

  const onEditSource = async ({ idOrg, idApp, source }: {
    source: UserTypeSource,
    idOrg: number,
    idApp: number
  }) => {
    const currentUserTypeSource = { ...initialUserTypeSource, idApp, idOrg, id: source.id, filter: source.filter }
    setUserTypeSource(currentUserTypeSource)
    setSourceToEdit(source)
    setIsDeleteState(false)
    setIsSourcePopupOpen(true)
  }

  const userLifecycleHelpArticleLink = SUPPORT_ARTICLES.USER_LIFECYCLE
  const employeeSourceOfTruthArticleLink = SUPPORT_ARTICLES.EMPLOYEES
  const mergeUsersArticleLink = SUPPORT_ARTICLES.MERGE_USERS

  return (
    <>
      <Spacer bottom={'space-300'}><H4>Users & Employees</H4></Spacer>

      <UsersAndEmployeesPageSection
        learnMoreLink={userLifecycleHelpArticleLink}
        loading={loadingOrg}
        scopes={[SCOPES.SETTINGS_WRITE]}
        subheader={'Select an identity provider or HR management system to define user lifecycle statuses (Employees & Non-employees).'}
        title={'User lifecycle'}
      >
        <UserLifecycleApps idOrg={idOrg} />
      </UsersAndEmployeesPageSection>

      <UsersAndEmployeesPageSection
        learnMoreLink={employeeSourceOfTruthArticleLink}
        loading={loadingUserTypeSources}
        scopes={[SCOPES.SETTINGS_WRITE]}
        subheader={'Select an identity provider or HR management system to define how current and past employees are configured in your organization.'}
        title={'Employee definition'}
      >
        <UserTypeSources
          type={'employee'}
          onAddSourceClick={() => setIsSourcePopupOpen(true)}
          onEditSource={onEditSource}
          onRemoveSource={onRemoveSource}
        />
      </UsersAndEmployeesPageSection>

      <UsersAndEmployeesPageSection
        learnMoreLink={mergeUsersArticleLink}
        loading={loadingMergeUsersRules}
        scopes={[SCOPES.SETTINGS_WRITE]}
        subheader={'Automatically merge users in Torii based on aliases coming from your organization\'s IDP'}
        title={'Merge users'}
        onLearnMoreClick={() => MergeUsersAnalytics.onLearnMoreClick({ idOrg, buttonLocation: 'settings menu' })}
      >
        <MergeUsersRules
          onConfigureMergeClick={onConfigureMergeUsersClick}
          onRevokeMergeUsersClick={onRevokeMergeUsersClick}
        />
      </UsersAndEmployeesPageSection>

      <UsersAndEmployeesPageSection
        loading={loadingOrg}
        scopes={[SCOPES.SETTINGS_WRITE]}
        subheader={'User will be considered inactive when not using an application for this period of time.'}
        title={'Inactivity period'}
      >
        <Select
          options={INACTIVE_PERIOD_OPTIONS}
          value={appNotInUsePeriod}
          onChange={onAppNotInUsePeriodChanged}
          clearable={false}
          searchable={false}
          key='inactive_period_fields_select'
          name='inactive_period_fields_select'
          openOnFocus
        />
      </UsersAndEmployeesPageSection>

      {isSourcePopupOpen && <EditUserTypeSourcesPopup
        isOpen={isSourcePopupOpen}
        onCancel={isDeleteState ? onRemoveSucceeded : onClosePopup}
        onSubmitSuccess={isDeleteState ? onRemoveSucceeded : onClosePopup}
        sharedProps={{
          userType: 'employee',
          usersLabel: 'employees',
          formHeader: `${sourceToEdit ? 'Edit' : 'Add'} employee source of truth`,
          formSubHeader: 'Select an identity provider or HR management system to define how current and past employees are configured in your organization',
          sourceToEdit
        }}
        initialSharedState={isDeleteState ? { userTypeSource, changesPreview, isDeleteSource: isDeleteState, action: ActionForUserTypePreview.DELETE } : {}}
        wizardSteps={isDeleteState ? deleteStepsConfig : stepsConfig}
      />}

      {isMergeUsersRulesPopupOpen && <ConfigureMergeUsersRulesPopup
        onCancel={onClosePopup}
        sharedProps={{
          idOrg,
          isRevokeMode: isMergeUsersRevokeMode
        }}
        onSubmitSuccess={onMergeUsersRulesSubmitSuccess}
      />}
    </>
  )
}

UsersAndEmployeesPage.propTypes = {
  loadingOrg: PropTypes.bool.isRequired,
  loadingUserTypeSources: PropTypes.bool.isRequired,
  loadingMergeUsersRules: PropTypes.bool.isRequired
}

export default UsersAndEmployeesPage
