import React, { Fragment, ReactElement } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { PopupRendererProps } from '@components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import { Styles } from './style'
import Success from '@media/success.svg'
import { Body2 } from '@toriihq/design-system'

export const ChangeApplied = ({
  onSubmitSuccess,
  sharedProps
}: PopupRendererProps<SharedState, SharedProps>): ReactElement => {
  const { isRevokeMode } = sharedProps

  const header = isRevokeMode ? 'Revoke merge applied' : 'Merge applied'
  const text = isRevokeMode ? 'Merge was successfully revoked' : 'Merge successfully applied'
  return (<Fragment>
    <ToriiPopup.Header header={header} />
    <ToriiPopup.Content>
      <Styles.TextWithImageAside>
        <Styles.MultilineText>
          <Body2>{text}</Body2>
          <Body2>The changes will take effect in a few minutes</Body2>
        </Styles.MultilineText>
        <img alt='Changes applied' src={Success} />
      </Styles.TextWithImageAside>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonAction={onSubmitSuccess}
      mainButtonText={'Close'}
      showCancelButton={false}
    />
  </Fragment>)
}
