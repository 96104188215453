import {
  GET_DISCOVERED_APPS,
  RESET_DISCOVERED_APPS,
  MODIFY_DISCOVERED_APP,
  RESET_MODIFY_DISCOVERED_APP,
  UPDATE_DISCOVERED_APPS,
  MODIFY_DISCOVERED_APP_RESOURCES,
  DISCOVERED_APP_MAPPING_LOGIC,
  UPDATE_DISCOVERED_APPS_PREVIEW
} from '../constants'
import { omit, keyBy } from 'lodash'

const initialState = {
  discoveredApps: [],
  totalDiscoveredApps: 0,
  resources: { users: {}, apps: {} },
  loading: true,
  loadingMore: false,
  pendingChanges: {},
  pendingChangesResources: {},
  pendingChangesPreview: {}
}

const discoveredAppsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_DISCOVERED_APPS}_PENDING`: {
      const { reset } = action.meta
      return {
        ...state,
        loading: true,
        loadingMore: !reset
      }
    }
    case `${GET_DISCOVERED_APPS}_RESPONSE`: {
      const { externalApps, resources, total } = action.payload
      const { reset = true } = action.meta
      return {
        ...state,
        discoveredApps: reset ? externalApps : (state.discoveredApps).concat(externalApps),
        resources: reset ? resources : {
          apps: { ...state.resources.apps, ...resources.apps },
          users: { ...state.resources.users, ...resources.users }
        },
        totalDiscoveredApps: total,
        loading: false,
        loadingMore: false
      }
    }
    case RESET_DISCOVERED_APPS: {
      return {
        ...state,
        discoveredApps: [],
        totalDiscoveredApps: 0
      }
    }
    case MODIFY_DISCOVERED_APP_RESOURCES: {
      const { app } = action.payload
      return {
        ...state,
        pendingChangesResources: { ...state.pendingChangesResources, ...app }
      }
    }
    case MODIFY_DISCOVERED_APP: {
      const { id, changes } = action.payload
      const discoveredApps = state.discoveredApps
      const pendingChanges = {
        ...state.pendingChanges,
        [id]: changes
      }
      const app = discoveredApps.find(app => app.id === id)
      const isChanged = (
        (changes.mappingLogic !== undefined && (changes.mappingLogic !== app?.mappingLogic)) ||
        (changes.mappedIdApp !== undefined && (changes.mappedIdApp !== app?.mappedIdApp))
      )
      if (isChanged) {
        return {
          ...state,
          pendingChanges
        }
      } else {
        return {
          ...state,
          pendingChanges: omit(state.pendingChanges, id)
        }
      }
    }
    case RESET_MODIFY_DISCOVERED_APP: {
      return {
        ...state,
        pendingChanges: {},
        pendingChangesPreview: {}
      }
    }
    case `${UPDATE_DISCOVERED_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${UPDATE_DISCOVERED_APPS}_RESPONSE`: {
      const { success } = action.payload
      const { externalApps } = action.meta
      const externalAppsWithFilter = externalApps.map(app => {
        if (app.mappingLogic === DISCOVERED_APP_MAPPING_LOGIC.IGNORED) {
          return {
            ...app,
            mappedIdApp: null
          }
        }
        return app
      })
      const externalAppsById = keyBy(externalAppsWithFilter, 'id')
      return {
        ...state,
        discoveredApps: success
          ? state.discoveredApps.map(app => ({
            ...app,
            ...externalAppsById[app.id]
          }))
          : state.discoveredApps,
        pendingChanges: {},
        pendingChangesPreview: {}
      }
    }
    case `${UPDATE_DISCOVERED_APPS_PREVIEW}_PENDING`: {
      return {
        ...state,
        pendingChangesPreview: {
          ...state.pendingChangesPreview,
          loading: true
        }
      }
    }
    case `${UPDATE_DISCOVERED_APPS_PREVIEW}_FAILED`: {
      return {
        ...state,
        pendingChangesPreview: {
          ...state.pendingChangesPreview,
          loading: false
        }
      }
    }
    case `${UPDATE_DISCOVERED_APPS_PREVIEW}_RESPONSE`: {
      return {
        ...state,
        pendingChangesPreview: {
          loading: false,
          previewData: action.payload
        }
      }
    }
    default: {
      return state
    }
  }
}

export default discoveredAppsReducer
