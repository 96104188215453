import View from './view'
import { connect } from 'react-redux'
import { getAppDetailsFields, getUsedApps } from '../../selectors/apps'
import { withRouter } from 'react-router-dom'
import sumBy from 'lodash/sumBy'
import moment from 'moment'

const getExpensesByDate = (app, date) => (
  app.expenses.find(month => month.month === date.month() + 1 && month.year === date.year()) || { date }
)

const mapStateToProps = (state, ownProps) => {
  const { month, year } = ownProps
  const { apps: { loading: appsLoading } } = state
  const allApps = getUsedApps(state)
  const stateInfo = getAppDetailsFields(state).find(field => field.systemKey === 'state')
  const loading = appsLoading && (allApps.length === 0)
  const selectedMonth = moment.utc(`15/${month}/${year}`, 'DD/MM/YYYY')
  const paidApps = allApps.filter(app => sumBy(app.expenses, month => month.total) !== 0)

  return {
    apps: paidApps.map(app => {
      return {
        id: app.id,
        name: app.name,
        firstMonth: getExpensesByDate(app, selectedMonth.clone().subtract(2, 'month')),
        secondMonth: getExpensesByDate(app, selectedMonth.clone().subtract(1, 'month')),
        thirdMonth: getExpensesByDate(app, selectedMonth),
        trendInfo: app.expenses.slice().reverse(),
        total: sumBy(app.expenses, month => month.total),
        imageUrl: app.imageUrl,
        category: app.category,
        path: `/app/${app.id}`,
        state: app.state
      }
    }),
    loading,
    stateInfo
  }
}

export default withRouter(connect(mapStateToProps)(View))
