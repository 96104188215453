import View from './view'
import { connect } from 'react-redux'
import { deprecatedGetAppsByIds } from '../../selectors/apps'

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps
  const app = deprecatedGetAppsByIds(state)[id] || {}
  return { ...app, ...ownProps }
}

export default connect(mapStateToProps)(View)
