import View from './view'
import { connect } from 'react-redux'
import { getShowHiddenWorkflows, getUnsafeMode } from '../../selectors/ui'
import { toggleShowHiddenWorkflows, toggleUnsafeMode } from '../../shared/actions'
import { isToriiAdmin } from '../../selectors/me'

const mapStateToProps = (state) => {
  const showActions = isToriiAdmin(state)
  const unsafeMode = getUnsafeMode(state)
  const idAudit = parseInt(new URLSearchParams(window.location.search).get('previewId'))

  const isWorkflowPage = window.location.pathname.includes('workflow')
  const showHiddenWorkflows = getShowHiddenWorkflows(state)

  return {
    isSmallScreen: state.ui.isSmallScreen,
    companyName: state.org.companyName,
    unsafeMode,
    isWorkflowPage,
    showHiddenWorkflows,
    showActions,
    isPreviewMode: Boolean(idAudit)
  }
}

const mapDispatchToProps = {
  toggleShowHiddenWorkflows,
  toggleUnsafeMode
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
