import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Icon, Tooltip, Counter, generateAttributeId } from '@toriihq/design-system'
import { toggleNavigation } from '@shared/actions'
import RelativeTeamLink from '@components/relativeTeamLink'
import PropTypes from 'prop-types'
import * as CSS from './styles'
import { getNavigationRoutes } from '@selectors/ui'
import Analytics from '@helpers/analytics'

const NavigationMenu = (props) => {
  const { isOpen } = props
  const routes = useSelector(getNavigationRoutes)

  const dispatch = useDispatch()

  const location = useLocation()

  const isRouteMatch = (route) => {
    const derivedPaths = [...route.derivedPaths]
    // eslint-disable-next-line no-useless-escape
    return location.pathname.match(new RegExp(`team\/\\d+\/(${derivedPaths.join('|')})`))
  }

  const toggleNavigationDispatcher = () => dispatch(toggleNavigation())

  const routeLinkContent = (route) => {
    const content = <><Icon name={route.icon} color='inherit' size='mobile' />
      {route.badge && (
        <div {...CSS.BadgeContainer}>
          <Counter size='small' value={route.badge} needsAttention selected notification />
        </div>
      )}</>

    const contentWithTooltip = <Tooltip
      placement='right'
      label={route.badgeTooltipText ? `${route.text}: ${route.badgeTooltipText}` : route.text}>
      {content}
    </Tooltip>

    return (
      <div {...CSS.routeLinkContainer}>
        {route.icon && <div {...CSS.RouteIcon}>
          {isOpen
            ? content
            : contentWithTooltip}
        </div>
        }
        {isOpen && (
          <div {...CSS.RouteText}>
            {route.text}
          </div>
        )}
        { route.isFeatureEnabledInPlan && isRouteMatch(route) && isOpen && <Icon name='ChevronRight' color='inherit' /> }
        { !route.isFeatureEnabledInPlan && isOpen && <div className='lockIcon' {...CSS.getLockIconCSS(isRouteMatch(route))}><Icon name='Lock' color='inherit' /></div> }
      </div>
    )
  }

  const onRouteLinkClick = (route) => {
    Analytics.track('Navigate to', {
      'Page name': route.text
    })
  }

  const routeLink = (route) => (
    <RelativeTeamLink nav to={route.path} className={isRouteMatch(route) ? CSS.Bubble.toString() : CSS.Route.toString()} onClick={() => onRouteLinkClick(route)}>
      {routeLinkContent(route)}
    </RelativeTeamLink>
  )

  const navItem = (route, toggleNavigation) => (
    <div key={route.path || route.key}>
      {route.topDivider && <hr />}
      <div onClick={toggleNavigation} data-intercom-target={generateAttributeId(`intercom-nav-${route.text}`)}>
        {routeLink(route)}
      </div>
    </div>
  )

  return (
    <div {...CSS.Menu}>
      {routes.map((route) =>
        navItem(route, toggleNavigationDispatcher)
      )}
    </div>
  )
}

NavigationMenu.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
}

export default React.memo(NavigationMenu)
