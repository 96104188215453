import View from './view'
import { connect } from 'react-redux'
import { deprecatedGetApps, isAppsLoading } from '../../../selectors/apps'
import sortBy from 'lodash/sortBy'

const mapStateToProps = (state) => {
  const apps = deprecatedGetApps(state)
  const options = sortBy(apps, ['name'])
  return {
    options,
    loading: isAppsLoading(state) && options.length === 0
  }
}
export default connect(mapStateToProps)(View)
