import { css } from 'glamor'
import texts from '@shared/style/texts'
import colors from '@shared/style/colors'
import { theme } from '@toriihq/design-system'

const headerAndDetailsMarginBottom = 20
const sectionHeight = 76

export const main = css({
  fontFamily: 'Nunito',
  marginBottom: `calc(-5px - ${headerAndDetailsMarginBottom}px)`,
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  lineHeight: 'normal'
})

export const title = css({
  display: 'inline-block'
})

export const header = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: headerAndDetailsMarginBottom
})

export const state = css({
  '>div': {
    marginRight: 0
  }
})

export const section = css({
  padding: '0 20px',
  height: sectionHeight,
  ':first-child': {
    paddingLeft: 0
  },
  ':not(:last-child)': {
    borderRight: `1px solid ${colors.border}`
  },
  ':last-child': {
    paddingRight: 0
  }
})

export const sectionTitle = css(texts.subheading, {
  textTransform: 'uppercase',
  color: colors.text,
  paddingBottom: 5,
  display: 'flex',
  alignItems: 'center',
  height: 25
})

export const sectionBody = texts.headers.large

export const details = css({
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: headerAndDetailsMarginBottom
})

export const tooltip = css({
  textAlign: 'center',
  maxWidth: 250
})

export const usersPopoverButtonOverrideStyle = css({
  padding: '0 20px',
  height: sectionHeight,
  borderRight: `1px solid ${colors.border}`
})

export const usersPopoverOverrideStyle = css({
  padding: 0,
  borderRadius: 10
})

export const editedLabel = css({
  color: theme.palette.text.tertiary,
  font: theme.typography.font.body02
})
