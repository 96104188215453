import { DELETE_APP_CHARGEBACK_CONFIG, GET_APP_CHARGEBACK_CONFIG, UPDATE_APP_CHARGEBACK_CONFIG } from '../constants'
import omit from 'lodash/omit'

const initialState = {
  loading: false,
  appChargebackConfig: {}
}

const chargebackReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_APP_CHARGEBACK_CONFIG}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_APP_CHARGEBACK_CONFIG}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_APP_CHARGEBACK_CONFIG}_RESPONSE`: {
      const { idApp } = action.meta
      const appChargebackConfig = action.payload

      return {
        ...state,
        loading: false,
        appChargebackConfig: {
          ...state.appChargebackConfig,
          [idApp]: appChargebackConfig
        }
      }
    }
    case `${UPDATE_APP_CHARGEBACK_CONFIG}_RESPONSE`: {
      const { idApp, allocationStrategy, allocationCost, allocateCostByUserField, config } = action.meta

      const newAppChargebackConfig = {
        ...state.appChargebackConfig[idApp],
        allocationStrategy,
        allocationCost,
        allocateCostByUserField,
        config
      }

      return {
        ...state,
        loading: false,
        appChargebackConfig: {
          ...state.appChargebackConfig,
          [idApp]: newAppChargebackConfig
        }
      }
    }
    case `${DELETE_APP_CHARGEBACK_CONFIG}_RESPONSE`: {
      const { idApp } = action.meta
      const appChargebackConfig = omit(state.appChargebackConfig, idApp)

      return {
        ...state,
        loading: false,
        appChargebackConfig
      }
    }
    default: {
      return state
    }
  }
}

export default chargebackReducer
