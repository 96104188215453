import React from 'react'
import { theme } from '@toriihq/design-system'
import FormGroup from '@components/form/formGroup'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'

interface Props {
  label: string
  children: JSX.Element | JSX.Element[]
}

export const ScheduleFormGroup = ({
  label,
  children
}: Props): JSX.Element => {
  return (
    <FormGroup
      label={label}
      isRequired
      overrideStyle={{ margin: '0' }}
      overrideErrorMessage={{ display: 'none' }}
      labelStyle={{
        color: theme.palette.text.primary,
        font: theme.typography.font.subtitle02
      }}
    >
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        {children}
      </EnableFor>
    </FormGroup>
  )
}
