import View from './view'
import { connect } from 'react-redux'
import { toggleManualActionStartedPopup } from '../../../shared/actions'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '../../../selectors/me'
import { EMPTY_ARRAY } from '../../../constants'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { users = EMPTY_ARRAY, isOpen, idWorkflow, triggerType } = state.ui.manualActionStartedPopup
  return {
    isOpen,
    users,
    idOrg,
    idWorkflow,
    triggerType
  }
}

const mapDispatchToProps = {
  toggleManualActionStartedPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleManualActionStartedPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleManualActionStartedPopup({ isOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
