import View from './view'
import { connect } from 'react-redux'
import { toggleSelectFieldTypePopup, addAppDetailsField } from '../../../../shared/actions'
import { getCurrentOrg } from '../../../../selectors/me'

const mapStateToProps = state => {
  const { idGroup } = state.ui.selectFieldTypePopup
  const { id: idOrg } = getCurrentOrg(state)
  return ({
    idGroup,
    idOrg
  })
}

const mapDispatchToProps = {
  toggleSelectFieldTypePopup,
  addAppDetailsField
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
