import send from '@shared/redux-fetch'
import { DELETE_MANUALLY_IMPORTED_USERS } from '@root/constants'
import { DeleteManuallyImportedUsersParams } from '@shared/actions/usersImport/types'

export const deleteManuallyImportedUsers = ({ idOrg, idApp }: DeleteManuallyImportedUsersParams) => (
  send(DELETE_MANUALLY_IMPORTED_USERS, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/import`,
    method: 'DELETE'
  })
)
