import React, { useState } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import PropTypes from 'prop-types'
import { Props } from './types'

export default function WizardPopup<TSharedState, TSharedProps> ({
  isOpen,
  width,
  stepsConfig,
  sharedProps,
  initialSharedState,
  onCancel,
  onClose,
  onSubmitSuccess,
  onNavigateToNextStep,
  onNavigateToBackStep
}: Props<TSharedState, TSharedProps>) {
  const [sharedState, setSharedState] = useState<TSharedState>(initialSharedState)
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0)

  function navigateToNextStep (steps?: number) {
    const stepsToGo = (steps && typeof steps === 'number') ? steps : 1
    const nextStepIndex = currentStepIndex + stepsToGo
    if (nextStepIndex < stepsConfig.length) {
      setCurrentStepIndex(nextStepIndex)
      onNavigateToNextStep?.()
    }
  }

  function navigateToBackStep (steps?: number) {
    const stepsToGoBack = (steps && typeof steps === 'number') ? steps : 1
    const previousStepIndex = currentStepIndex - stepsToGoBack
    if (previousStepIndex >= 0) {
      setCurrentStepIndex(previousStepIndex)
      onNavigateToBackStep?.()
    }
  }

  const {
    stepRenderer: StepRenderer,
    stepWidth
  } = stepsConfig[currentStepIndex]

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={() => onClose?.()} width={stepWidth || width}>
      <StepRenderer
        sharedState={sharedState}
        sharedProps={sharedProps}
        setState={setSharedState}
        navigateToNextStep={navigateToNextStep}
        navigateToBackStep={navigateToBackStep}
        onCancel={() => onCancel?.()}
        onSubmitSuccess={() => onSubmitSuccess?.()}
      />
    </ToriiPopup>
  )
}

WizardPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  stepsConfig: PropTypes.arrayOf(PropTypes.shape({
    stepRenderer: PropTypes.func.isRequired
  })).isRequired,
  initialSharedState: PropTypes.any.isRequired,
  sharedProps: PropTypes.any.isRequired,
  onCancel: PropTypes.func,
  onSubmitSuccess: PropTypes.func,
  onClose: PropTypes.func,
  onNavigateToNextStep: PropTypes.func,
  onNavigateToBackStep: PropTypes.func
}
