import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getSelf, isPaidPlanExpired } from '../../selectors/me'
import { getOrg } from '@shared/actions'

const mapStateToProps = (state) => {
  const me = getSelf(state)
  const paidPlanExpired = isPaidPlanExpired(state)
  return {
    me,
    paidPlanExpired
  }
}
const mapDispatchToProps = {
  getOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
