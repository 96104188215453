import { EditUserTypeSources } from './editUserTypeSources'
import { PreviewChanges } from './previewChanges'
import { ChangesApplied } from './changesApplied'
import { SharedProps, SharedState } from './types'
import { PopupStepConfig } from '../../wizardPopup/types'

export const stepsConfig: PopupStepConfig<SharedState, SharedProps>[] = [
  {
    stepRenderer: EditUserTypeSources
  },
  {
    stepRenderer: PreviewChanges
  },
  {
    stepRenderer: ChangesApplied
  }
]

export const deleteStepsConfig: PopupStepConfig<SharedState, SharedProps>[] = [
  {
    stepRenderer: PreviewChanges
  },
  {
    stepRenderer: ChangesApplied
  }
]
