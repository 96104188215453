import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import PopupContent from '@components/popups/deleteImportedUsersPopup/popupContent'
import { Subtitle, Text } from '../styles'

export type Props = {
  onConfirmClick: () => void
}

const FinalStep = ({ onConfirmClick }: Props): JSX.Element => (<>
  <ToriiPopup.Header header='Deleting users' />
  <ToriiPopup.Content>
    <PopupContent>
      <Text>
        <Subtitle>We are deleting the imported users.</Subtitle>
        <br />
        It might take a few minutes to complete.
      </Text>
    </PopupContent>
  </ToriiPopup.Content>
  <ToriiPopup.Footer
    mainButtonText='Got it'
    mainButtonAction={onConfirmClick}
    showCancelButton={false}
  />
</>)

export default FinalStep
