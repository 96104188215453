import { css } from 'glamor'
import { fontSize, fontWeight } from '@shared/style/sizes'

const INPUT_WIDTH = '200px'

export const placeholderStyle = { height: '32px', width: INPUT_WIDTH }

export const Select = css({
  width: INPUT_WIDTH
})

export const SettingSubTitle = css({
  fontWeight: fontWeight.normal,
  fontSize: fontSize.small
})
