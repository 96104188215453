import View from './view'
import { connect } from 'react-redux'
import omit from 'lodash/omit'
import { revokeMember, toggleRolePopup } from '@shared/actions'
import { getSelf } from '@selectors/me'
import { getEmployeeRole, getSuperAdminRole } from '@selectors/roles'

const mapStateToProps = state => {
  const superAdminRole = getSuperAdminRole(state)
  const employeeRole = getEmployeeRole(state)

  return {
    me: getSelf(state),
    superAdminRole,
    employeeRole
  }
}

const mapDispatchToProps = {
  revokeMember,
  toggleRolePopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, id, role: prevRole, trackRoleChange } = ownProps
  const { employeeRole } = stateProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['revokeMember']),
    revokeMember: () =>
      dispatchProps.revokeMember({ idOrg, idUser: id, idRole: employeeRole.id })
        .then(payload => trackRoleChange(payload.user, prevRole)),
    ...ownProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
