import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, RadioButton, Tooltip } from '@toriihq/design-system'
import ToriiPopup from '../ToriiPopupV2'
import Table from '../../table'
import { getDisplayName } from '@lenses/users'
import AppDetails from '@components/appDetails'
import { getStateDetails, getCatalogApps, toggleAddAppCatalogPolicyPopup } from '@shared/actions'
import { ANALYTICS_EVENT, SCOPES } from '@root/constants'
import { getIdOrg } from '@selectors/org'
import isEmpty from 'lodash/isEmpty'
import { getAppStateOptionsForEsFilters, getAppStateOrder, getDefaultPolicyCatalogAppsData, isLoadingAppDetailsValues as isLoadingAppDetailsValuesSelector, isLoadingCatalogApps as isLoadingCatalogAppsSelector } from '@selectors/apps'
import * as Style from './style'
import { formatNumber } from '@shared/utils'
import withCreatePolicy from '@root/shared/HOCs/withCreatePolicy'
import SelectState from '@root/components/selectState'
import analytics from '@helpers/analytics'

const AddAppCatalogPolicyPopup = ({ onCreateCustomPolicy }) => {
  const [selectedTriggerAppInfo, setSelectedTriggerAppInfo] = useState({})
  const [actionInprogress, setActionInprogress] = useState(false)
  const dispatch = useDispatch()

  const { isOpen, isViewOnly, idWorkflow } = useSelector(state => state.ui.addAppCatalogPolicyPopup)
  const idOrg = useSelector(getIdOrg)
  const stateOptions = useSelector(getAppStateOptionsForEsFilters)
  const stateOrder = useSelector(getAppStateOrder)
  const defaultPolicyCatalogApps = useSelector(getDefaultPolicyCatalogAppsData)
  const isLoadingCatalogApps = useSelector(isLoadingCatalogAppsSelector)
  const isLoadingAppDetailsValues = useSelector(isLoadingAppDetailsValuesSelector)
  const isLoading = isLoadingCatalogApps || isLoadingAppDetailsValues

  useEffect(() => {
    if (idOrg) {
      dispatch(getCatalogApps({ idOrg }))
      dispatch(getStateDetails({ idOrg }))
    }
  }, [dispatch, idOrg])

  const onCancel = ({ buttonLabel }) => {
    dispatch(toggleAddAppCatalogPolicyPopup({ isOpen: false, isByUser: true, buttonLabel, idWorkflow: null }))
  }

  const searchFilterMethod = (row, search) => (row.name && row.name.toLowerCase().includes(search))

  const getPopupHeaderText = () => {
    return isViewOnly
      ? 'Apps assigned with the default access request policy'
      : idWorkflow
        ? 'Duplicate custom policy'
        : 'Add new custom policy'
  }

  const getPopupSubHeaderText = () => {
    return isViewOnly
      ? 'The default policy will apply to the apps below when employees request access.'
      : `Select an app to ${idWorkflow ? 'duplicate' : 'add'} a custom access request policy for:`
  }

  const getPopupContentHeaderText = () => {
    return isViewOnly ? `Applications (${defaultPolicyCatalogApps.length})` : `Applications in the catalog with default access request policy (${defaultPolicyCatalogApps.length})`
  }

  const getColumns = () => {
    return [
      {
        id: 'selectedApp',
        sortable: false,
        resizable: false,
        width: 45,
        Cell: ({ row: { id } }) => {
          return (<RadioButton checked={selectedTriggerAppInfo.id === id} />)
        },
        style: {
          paddingLeft: '28px',
          paddingRight: '4px'
        },
        show: !isViewOnly
      },
      {
        Header: 'Name',
        accessor: 'name',
        width: 250,
        className: Style.NameAndIcon.toString(),
        Cell: ({ value: name, row: { category, imageUrl } }) => (
          <AppDetails
            name={name}
            category={category}
            imageUrl={imageUrl}
            component='Default policy catalog apps list'
          />
        ),
        show: true,
        style: {
          paddingLeft: '15px'
        }
      },
      {
        Header: 'Owner',
        accessor: 'owner',
        textValue: ({ value: owner }) => getDisplayName(owner || {}),
        width: 190,
        Cell: ({ value: owner }) => {
          const { firstName, lastName, photoUrl, email } = owner || {}
          const tooltipText =
            <div>
              <div>{getDisplayName(owner || {})}</div>
              <div>{email}</div>
            </div>
          return owner ? (
            <Tooltip label={tooltipText}>
              <Avatar
                firstName={firstName}
                lastName={lastName}
                imageUrl={photoUrl}
              />
            </Tooltip>
          ) : null
        },
        sortMethod: (a, b) => Table.sortMethods.users(a, b),
        show: true
      },
      {
        Header: 'Users',
        accessor: 'activeUsersCount',
        width: 185,
        Cell: ({ value: activeUsersCount }) => (formatNumber(activeUsersCount)),
        show: true
      },
      {
        Header: 'State',
        accessor: 'state',
        textValue: ({ state }) => state,
        Cell: ({ value: state }) => {
          const optionInfo = (stateOptions || []).find(option => state === option.label)

          return (
            <SelectState {...stateOptions} selectedValue={optionInfo} readOnly />
          )
        },
        sortMethod: (a, b) => (stateOrder[a] || 0) - (stateOrder[b] || 0),
        width: 200,
        show: true
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'imageUrl',
        show: false
      },
      {
        accessor: 'name',
        show: false
      },
      {
        accessor: 'category',
        show: false
      }
    ]
  }

  const onAppSelection = ({ row: { id, name } }) => {
    setSelectedTriggerAppInfo({ id, name })
  }

  const PopupFooter = () => {
    const buttonText = idWorkflow ? 'Duplicate custom policy' : 'Add new custom policy'
    if (isViewOnly) {
      return (
        <ToriiPopup.Footer
          scopes={[SCOPES.APPLICATION_CATALOG_READ]}
          showCancelButton={false}
          mainButtonAction={() => onCancel({ buttonLabel: 'Cancel' })}
          mainButtonText={'Close'}
        />
      )
    }
    return (
      <ToriiPopup.Footer
        scopes={[SCOPES.AUTOMATION_WRITE]}
        showCancelButton
        cancelButtonText='Cancel'
        cancelButtonAction={() => onCancel({ buttonLabel: 'Cancel' })}
        isMainButtonDisabled={actionInprogress || isEmpty(selectedTriggerAppInfo)}
        mainButtonAction={() => {
          setActionInprogress(true)
          onCreateCustomPolicy({
            idOrg,
            idApp: selectedTriggerAppInfo.id,
            appName: selectedTriggerAppInfo.name,
            idWorkflow,
            onAfterCreation: () => {
              analytics.track(ANALYTICS_EVENT.ADD_NEW_CUSTOM_POLICY, {
                'Open from': idWorkflow ? 'Duplicate policy' : 'Add new custom policy'
              })
              dispatch(toggleAddAppCatalogPolicyPopup({
                isOpen: false,
                isByUser: false,
                idWorkflow: null
              }))
            }
          })
        }}
        mainButtonText={buttonText}
      />

    )
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={() => onCancel({ buttonLabel: 'X' })} styles={Style.Popup} contentAreaStyle={Style.MainPopupArea}>
      <ToriiPopup.Header header={getPopupHeaderText()} subHeader={getPopupSubHeaderText()} />
      <ToriiPopup.Content>
        <Table
          style={Style.Table}
          data={defaultPolicyCatalogApps}
          header={<span>{getPopupContentHeaderText()}</span>}
          columns={getColumns()}
          loading={isLoading}
          clientPaging
          pageSize={50}
          scrollObjectId='contentArea'
          onTrClick={onAppSelection}
          overrideTrStyle={{ cursor: 'pointer' }}
          searchable
          searchFilterMethod={searchFilterMethod}
        />
      </ToriiPopup.Content>
      <PopupFooter />
    </ToriiPopup>
  )
}

export default withCreatePolicy(AddAppCatalogPolicyPopup)
