import View from './view'
import { connect } from 'react-redux'
import { toggleMatchingRulesPopup } from '../../../shared/actions'

const mapStateToProps = state => {
  const { matchingRulesPopup: { isOpen } } = state.ui
  return {
    isOpen
  }
}

const mapDispatchToProps = {
  toggleMatchingRulesPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleMatchingRulesPopup({ isOpen: false })
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
