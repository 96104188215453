import View from './view'
import { connect } from 'react-redux'
import {
  toggleUploadAppDetailsAttachments
} from '@shared/actions'

const mapStateToProps = state => {
  const { appDetailsAttachments: { isOpen, idApp, idField } } = state.ui
  return ({
    isOpen,
    idApp,
    idField
  })
}

const mapDispatchToProps = {
  toggleUploadAppDetailsAttachments
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleUploadAppDetailsAttachments(false, true)
  },
  close () {
    dispatchProps.toggleUploadAppDetailsAttachments(false, false)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps)(View)
