import View from './view'
import { getCurrentOrg } from '../../../selectors/me'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getTransactionsWithoutDeduplicationByAppAndSource } from '../../../selectors/transactions'
import { EMPTY_ARRAY } from '../../../constants'
import { getAppTransactionsBySource } from '../../../shared/actions'

const mapStateToProps = (state, ownProps) => {
  const { idApp, source } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const transactionsByAppAndSource = getTransactionsWithoutDeduplicationByAppAndSource(state)
  const transactions = transactionsByAppAndSource[`${idApp}-${source}`] || EMPTY_ARRAY

  return {
    idOrg,
    transactions
  }
}

const mapDispatchToProps = {
  getAppTransactionsBySource
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
