import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrencySymbol } from '../../../selectors/org'

const mapStateToProps = (state, ownProps) => {
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const currencySymbol = getCurrencySymbol(state)

  return {
    idApp,
    idOrg,
    currencySymbol
  }
}

export default withRouter(connect(mapStateToProps)(View))
