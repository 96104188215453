import React, { ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Page from '@components/page'
import { WorkflowEditor } from './workflowEditor'
import {
  getContracts,
  getContractsFields,
  getWorkflowAuditLogs,
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  toggleNavigationForceCollapse
} from '@shared/actions'
import { getWorkflowAuditLogInfo, getWorkflow } from '@shared/actions/workflows'
import { useIsMounted } from '@shared/hooks'
import { Workflow } from '@shared/workflows/types'

interface Props {
  idOrg: number
  idWorkflow: number
  workflow: Workflow
  loading: boolean
  isAppCatalogPoliciesView?: boolean
  isRequestNewAppPoliciesView?: boolean
  isPreviewMode: boolean
  idAudit?: number
}

const WorkflowV2Page = ({
  idOrg,
  idWorkflow,
  workflow,
  loading,
  isAppCatalogPoliciesView = false,
  isRequestNewAppPoliciesView = false,
  isPreviewMode,
  idAudit
}: Props): ReactElement => {
  const dispatch = useDispatch()

  const isMounted = useIsMounted()

  useEffect(() => {
    if (isMounted) {
      dispatch(toggleNavigationForceCollapse(true))
    }

    return () => {
      dispatch(toggleNavigationForceCollapse(false))
    }
  }, [isMounted, dispatch])

  useEffect(() => {
    const fetchWorkflowData = () => {
      dispatch(getWorkflow({ idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView }))
      dispatch(getWorkflowsTriggersConfig())
      dispatch(getWorkflowsActionsConfig(idOrg))
      dispatch(getContractsFields({ idOrg }))
      dispatch(getContracts({ idOrg }))
    }

    fetchWorkflowData()
  }, [dispatch, idOrg, idWorkflow, isAppCatalogPoliciesView, isRequestNewAppPoliciesView])

  useEffect(() => {
    isPreviewMode && dispatch(getWorkflowAuditLogs({ idOrg, idWorkflow }))
  }, [dispatch, idOrg, idWorkflow, isPreviewMode])

  useEffect(() => {
    idAudit && dispatch(getWorkflowAuditLogInfo({ idOrg, idWorkflow, idAudit }))
  }, [dispatch, idOrg, idWorkflow, idAudit])

  return (<>
    {!loading &&
      <Page title='Workflow' fillContainerHeight>
        <WorkflowEditor
          workflow={workflow}
          isAppCatalogPoliciesView={isAppCatalogPoliciesView}
          isRequestNewAppPoliciesView={isRequestNewAppPoliciesView}
          idAudit={idAudit}
        />
      </Page>
    }
  </>)
}

export default WorkflowV2Page
