import {
  REGISTER,
  SIGNUP
} from '../constants'
import ParseValidation from '../helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {},
  signup: {
    error: undefined,
    loading: false,
    success: false
  }
}

const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${REGISTER}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${REGISTER}_FAILED`:
    case `${REGISTER}_RESPONSE`: {
      const validation = ParseValidation(action.payload)

      return {
        ...state,
        loading: false,
        success: !action.error,
        error: validation.error,
        hasError: validation.hasError,
        help: validation.help
      }
    }
    case `${SIGNUP}_PENDING`: {
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: true
        }
      }
    }
    case `${SIGNUP}_RESPONSE`: {
      const { success } = action.payload
      return {
        ...state,
        signup: {
          ...state.signup,
          loading: false,
          success
        }
      }
    }
    default: {
      return state
    }
  }
}

export default signupReducer
