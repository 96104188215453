import { PopupStepConfig } from '../../wizardPopup/types'
import ImportMethod from './importMethod/importMethod'
import MapColumns from './mapColumns/mapColumns'
import { SharedProps, SharedState } from './types'
import UploadFile from './uploadFile'
import ReviewImportChanges from './reviewImportChanges'
import LoadingAfterImport from './loadingAfterImport'
import LoadingAfterMapColumns from './loadingAfterMapColumns'

export const stepsConfig = (): PopupStepConfig<SharedState, SharedProps>[] => {
  const steps = [
    { stepRenderer: UploadFile },
    { stepRenderer: ImportMethod },
    { stepRenderer: MapColumns },
    { stepRenderer: LoadingAfterMapColumns },
    { stepRenderer: ReviewImportChanges },
    { stepRenderer: LoadingAfterImport }
  ]
  return steps
}
