import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { SCOPES } from '@root/constants'
import { getDefaultOffboardingWorkflow as getDefaultOffboardingWorkflowSelector } from '@root/selectors/offboarding'
import { getDefaultOffboardingWorkflow } from '@root/shared/actions'
import AdvancedToggle from '@components/advancedToggle'
import Analytics from '@root/helpers/analytics'
import { updateWorkflow } from '@root/shared/actions/workflows'

export const DefaultOffboardingToggle = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { workflow, loading } = useSelector(getDefaultOffboardingWorkflowSelector) || {}
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDefaultOffboardingWorkflow({ idOrg }))
  }, [dispatch, idOrg])

  const onToggle = async () => {
    const newState = !workflow.isActive
    const updatedWorkflow = { ...workflow, isActive: newState }
    dispatch(updateWorkflow({ idOrg, idWorkflow: workflow.id, workflow: updatedWorkflow, executeWorkflow: false, isDefaultOffboarding: true }))
    Analytics.track('Update ignore-offboarding-by-default-switch', {
      'State': Number(newState)
    })
  }

  return <AdvancedToggle
    checked={workflow.isActive}
    loading={loading}
    toggleId={'default_offboarding_toggle'}
    text={'Ignore non-configured apps during offboarding'}
    tooltipText={'The configuration will be applied to future offboardings'}
    scopes={[SCOPES.AUTOMATION_WRITE]}
    onToggle={onToggle}
    subComponent={<div>Offboarding will be skipped and the user will only be removed from the app’s user list in Torii</div>}
  />
}

export default DefaultOffboardingToggle
