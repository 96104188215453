import View from './view'
import { connect } from 'react-redux'
import { isToriiAdmin } from '../../../selectors/me'

const mapStateToProps = (state) => ({
  isToriiAdmin: isToriiAdmin(state)
})

export { groupOptions } from './view'
export default connect(mapStateToProps)(View)
