import { ActionUiConfigStep } from '@pages/workflow_v2/hooks/useActionsConfiguration/types'
import { Action, WORKFLOW_NODE_TYPE } from '@shared/workflows/types'
import { WORKFLOW_VALIDATION_TYPES } from '@root/constants'
import { SubMenuItemState } from '@toriihq/design-system'
import { WORKFLOW_STEP_TYPE } from '@shared/types'

export const defaultActionConfig = {
  steps: [{
    type: WORKFLOW_STEP_TYPE.STEP_SETUP,
    label: 'Setup',
    fields: []
  }]
}

export const isFieldsValid = ({ step, actionInputSchema, workflowAction }: { step: ActionUiConfigStep, actionInputSchema: any, workflowAction: Action }) => {
  return step.fields.every(fieldId => {
    const fieldInputSchema = actionInputSchema[fieldId]
    const isRequired = fieldInputSchema?.validations.includes(WORKFLOW_VALIDATION_TYPES.REQUIRED)
    const configuredWorkflowActionField = workflowAction.fields.find(field => field.id === fieldId) || { isValid: !isRequired }
    return configuredWorkflowActionField.isValid
  })
}

export const getStepLabel = ({ currentStep, currentStepIndex, totalNumberOfSteps }) => {
  return totalNumberOfSteps === 1
    ? 'Setup'
    : `${currentStepIndex}. ${currentStep.label}`
}

export const getActionValidationObj = ({ isValidFields, workflowAction }: { isValidFields: boolean, workflowAction: Action }) => {
  const isValidData = isValidFields
    ? { isValid: true, errorMsg: '' }
    : { isValid: false, errorMsg: 'Some fields are missing or invalid' }

  return isValidFields && !workflowAction.isValid
    ? { isValid: false, errorMsg: workflowAction.errorMsg }
    : isValidData
}

export const getNodeStepState = (isSelectedNodeStep: boolean, isDisabled: boolean): SubMenuItemState =>
  isDisabled ? SubMenuItemState.disabled : (isSelectedNodeStep ? SubMenuItemState.active : SubMenuItemState.default)

export const isConfigStepDisabled = (workflowNodeType: WORKFLOW_NODE_TYPE) => {
  // 2023-10-01 - we currently do not allow action reselection in branch actions,
  // due to implementation complexities.
  return [WORKFLOW_NODE_TYPE.BRANCH_ACTION, WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES].includes(workflowNodeType)
}
