import View from './view'
import { connect } from 'react-redux'
import { updateAppCatalogSettings, getAppDetailsValues } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { isOrgLoading, getAppCatalogSettings, isLoadingAppCatalogSettings } from '@selectors/org'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { withRouter } from 'react-router-dom'
import { getCatalogUsersInfo } from '@selectors/users'
import { getCatalogApps as getCatalogAppsSelector, isLoadingCatalogApps as isLoadingCatalogAppsSelector, getCatalogPoliciesInfo, getCatalogRequestNewAppPoliciesAmount } from '@selectors/apps'
import { uploadOrgLogo, removeOrgLogo, getOrg } from '../../shared/actions/actions'

const personalization = [
  {
    'id': 'app.name',
    'display': 'Application name'
  },
  {
    'id': 'recipient.firstName',
    'display': 'Recipient: First name'
  },
  {
    'id': 'recipient.lastName',
    'display': 'Recipient: First name'
  },
  {
    'id': 'recipient.email',
    'display': 'Recipient: email'
  },
  {
    'id': 'user.firstName',
    'display': 'Requested by: First name'
  },
  {
    'id': 'user.lastName',
    'display': 'Requested by: Last name'
  },
  {
    'id': 'user.email',
    'display': 'Requested by: email'
  }
]

const specialUsers = [
  {
    value: 'appOwner',
    label: 'App Owner',
    description: 'The owner of the application'
  }
]

const appFieldsConfigKey = 'appFieldsConfig'
const appsFiltersConfigKey = 'appsFiltersConfig'

const mapStateToProps = (state) => {
  const org = getCurrentOrg(state)
  const catalogSettings = getAppCatalogSettings(state)
  const catalogSettingsWithoutResources = omit(catalogSettings, ['resources'])
  const requestNewAppConfig = get(catalogSettings, 'requestNewAppConfig', []) || []
  const newAppInitialValues = requestNewAppConfig.reduce((result, field) => {
    result[field.type] = field.value
    return result
  }, {})

  const appFieldsConfig = get(catalogSettings, appFieldsConfigKey, [])
  const appsFiltersConfig = get(catalogSettings, appsFiltersConfigKey, [])

  const { total: catalogUsersNum, loading: isLoadingCatalogUsers } = getCatalogUsersInfo(state)

  const catalogApps = getCatalogAppsSelector(state)
  const isLoadingCatalogApps = isLoadingCatalogAppsSelector(state)
  const orgLoading = isOrgLoading(state) && !org.id

  const policiesInfo = getCatalogPoliciesInfo(state)
  const requestNewAppPoliciesAmount = getCatalogRequestNewAppPoliciesAmount(state)

  return {
    org,
    catalogUsersNum,
    isLoadingCatalogUsers,
    personalization,
    specialUsers,
    catalogSettings,
    loading: orgLoading || isLoadingAppCatalogSettings(state) || isEmpty(catalogSettingsWithoutResources),
    newAppInitialValues,
    appFieldsConfig,
    appsFiltersConfig,
    catalogApps,
    isLoadingCatalogApps,
    policiesInfo,
    requestNewAppPoliciesAmount
  }
}

const mapDispatchToProps = {
  updateAppCatalogSettings,
  uploadOrgLogo,
  removeOrgLogo,
  getOrg,
  getAppDetailsValues
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { org, catalogSettings } = stateProps
  const idOrg = org.id
  return {
    ...stateProps,
    ...dispatchProps,
    onConfigUpdate: ({ fieldId, selectedValue, configKey }) => {
      if (configKey === appFieldsConfigKey || configKey === appsFiltersConfigKey) {
        catalogSettings[configKey] = selectedValue
      } else {
        const indexToUpdate = catalogSettings[configKey].findIndex(field => field.id === fieldId)
        catalogSettings[configKey][indexToUpdate] = { ...catalogSettings[configKey][indexToUpdate], value: selectedValue }
      }
      return dispatchProps.updateAppCatalogSettings({ idOrg, [configKey]: catalogSettings[configKey] })
    },
    onSelectActionUpdate: ({ config, configKey }) => {
      dispatchProps.updateAppCatalogSettings({ idOrg, [configKey]: config })
    },
    ...ownProps
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
