import React from 'react'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import range from 'lodash/range'
import PropTypes from 'prop-types'
import StackedBarChartBox from '../../stackedBarChartBox'
import ResizeDetector from 'react-resize-detector'
import texts from '../../../shared/style/texts'
import { fontWeight, fontSize } from '../../../shared/style/sizes'
import { formatNumber } from '@root/shared/utils'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const APPS_AMOUNT = {
  SMALL: 3,
  MEDIUM: 5,
  LARGE: 10
}
const SCREEN_WIDTH = {
  SMALL: 350,
  MEDIUM: 600
}

const CSS = {
  main: css({
    height: '100%',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  header: css({
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${colors.border}`
  }),
  container: css({
    display: 'flex',
    height: `calc(100% - ${HEADER_HEIGHT})`
  }),
  chart: css({
    alignSelf: 'center',
    padding: `0 ${BOX_PADDING} 0 0`,
    width: '100%'
  }),
  tooltip: css({
    width: '180px',
    padding: '12px 16px 2px 16px',
    border: `1px solid ${colors.border}`,
    backgroundColor: colors.white
  }),
  tooltipHeader: css(texts.subheading, {
    marginBottom: '8px',
    paddingBottom: '12px',
    color: colors.black,
    textTransform: 'uppercase',
    borderBottom: `1px solid ${colors.grey3}`
  }),
  tooltipRow: css({
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  nameContainer: css({
    display: 'flex',
    alignItems: 'center'
  }),
  categoryName: css(texts.subheading, {
    fontWeight: fontWeight.normal,
    color: colors.text
  }),
  amount: css(texts.subheading, {
    fontWeight: fontWeight.semiBold,
    color: colors.black,
    lineHeight: fontSize.small,
    height: fontSize.small
  }),
  square: css({
    display: 'inline-block',
    width: '10px',
    height: '10px',
    marginRight: '8px',
    borderRadius: '3px'
  })
}

const className = 'underutilizedLicensesChart'

const fill = [
  { key: 'leftOrg', color: colors.darkBlue3, name: 'Left the org' },
  { key: 'unassignedAmount', color: colors.blue, name: 'Unassigned' },
  { key: 'noAccess', color: colors.lightBlue4, name: 'No access' },
  { key: 'noUsage', color: colors.lightBlue1, name: 'No usage' }
]

class UnderutilizedLicenses extends React.Component {
  state = { idOrg: this.props.idOrg, appsAmount: APPS_AMOUNT.LARGE }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg !== prevProps.idOrg) {
      this.fetchData()
    }
  }

  fetchData = () => {
    const { idOrg, getUnderutilizedLicenses } = this.props
    idOrg && getUnderutilizedLicenses({ idOrg })
  }

  fillGaps () {
    const { apps } = this.props
    const { appsAmount } = this.state

    const missingAppsAmount = appsAmount - Math.min(apps.length, appsAmount)

    const missingApps = range(1, missingAppsAmount + 1).map(() => {
      return {
        name: '',
        imageUrl: ''
      }
    })
    return apps.concat(missingApps).slice(0, appsAmount)
  }

  onResize = (width) => {
    const appsAmount = width < SCREEN_WIDTH.SMALL ? APPS_AMOUNT.SMALL : width < SCREEN_WIDTH.MEDIUM ? APPS_AMOUNT.MEDIUM : APPS_AMOUNT.LARGE
    this.setState({ appsAmount })
  }

  renderToolTip = ({ payload, label }) => {
    if (!payload) {
      return null
    }

    return (
      <div {...CSS.tooltip}>
        <div {...CSS.tooltipHeader}>{label}</div>
        {payload.map(item => (
          <div key={item.dataKey} {...CSS.tooltipRow}>
            <div {...CSS.nameContainer}>
              <span {...css(CSS.square, { backgroundColor: item.color })} />
              <span {...CSS.categoryName}>{item.name}</span>
            </div>
            <div {...CSS.amount}>{formatNumber(item.value)}</div>
          </div>
        ))}
      </div>
    )
  }

  render () {
    const { loading } = this.props
    const data = this.fillGaps()

    return (
      <div className={className} {...CSS.main}>
        <ResizeDetector handleWidth onResize={this.onResize} />
        <HoverableHeader hoverClass={className} overrideStyle={CSS.header} outerHoverStyle={'#underutilizedLicenses:hover &'} header='underutilized application licenses' subHeader='Inactive and unassigned licenses' />
        <div {...CSS.container}>
          <div {...CSS.chart}>
            <StackedBarChartBox data={data} loading={loading} fill={fill} overrideStyle={{ border: 'none' }} barSize={10} chartHeight={290} boxHeight='300px' imagesAxis chartOverrideStyle={{ paddingBottom: 0 }} tooltipContent={this.renderToolTip} analyticsEvent={`Click on Underutilized application licenses / Insights`} formatter={formatNumber} />
          </div>
        </div>
      </div>
    )
  }
}

UnderutilizedLicenses.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    idApp: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    unassignedAmount: PropTypes.number.isRequired,
    noUsage: PropTypes.number.isRequired,
    leftOrg: PropTypes.number.isRequired,
    noAccess: PropTypes.number.isRequired
  })),
  loading: PropTypes.bool
}

export default UnderutilizedLicenses
