import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  toggleImportUsers
} from '@root/shared/actions'
import WizardPopup from '../wizardPopup'
import { stepsConfig } from './stepsConfig/config'
import { IMPORT_USERS_COLUMNS, SharedProps, SharedState } from './stepsConfig/types'
import { IMPORT_METHOD } from './stepsConfig/importMethod/types'
import { getAppManualImportUsersStatus } from '@root/selectors/apps'
import { getIdOrg } from '@selectors/org'
import Analytics from '@components/popups/importUsersPopup/analytics'

const ImportUsersPopup = (): JSX.Element => {
  const idOrg = useSelector(getIdOrg)
  const { isOpen, idApp, appName }: { isOpen: boolean, idApp: number, appName: string } = useSelector((state: { ui: { importUsersPopup: { isOpen: boolean, idApp: number, appName: string } } }) => state.ui.importUsersPopup)
  const { hasManualSync, lastSyncTime } = useSelector(getAppManualImportUsersStatus)
  const isFirstTimeImport = !(hasManualSync && lastSyncTime)

  const dispatch = useDispatch()

  const onCloseButton = () => {
    Analytics.onCloseImportPopupAnalytics('', 'X')
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  const onCancelButton = () => {
    dispatch(toggleImportUsers({ isOpen: false }))
  }

  const initialSharedState: SharedState = {
    importMethod: IMPORT_METHOD.ADD_UPDATE_REMOVE,
    filePreview: {},
    parsingPreviewChanges: {
      validUsers: [],
      invalidUsers: [],
      missingUsers: []
    },
    selectedColumnsToImport: [IMPORT_USERS_COLUMNS.EMAIL, IMPORT_USERS_COLUMNS.LICENSES],
    previewDataMapping: {
      email: '',
      licenses: '',
      lastVisitTime: ''
    },
    idUsersImportParsing: null,
    idAppAccount: null
  }

  return (
    <WizardPopup<SharedState, SharedProps>
      isOpen={isOpen}
      stepsConfig={stepsConfig()}
      initialSharedState={initialSharedState}
      sharedProps={{ idOrg, idApp, appName, isFirstTimeImport, lastSyncTime }}
      onCancel={onCancelButton}
      onClose={onCloseButton}
      width='710px'
      onSubmitSuccess={() => {}}
    />
  )
}

export default ImportUsersPopup
