import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  isOrgLoading,
  getAppNotInUsePeriod,
  isLoadingUserTypeSources,
  getIdOrg,
  isLoadingMergeUsersRules
}
  from '@selectors/org'
import { updateOrg } from '@shared/actions'

const mapStateToProps = (state, ownProps) => {
  const idOrg = getIdOrg(state)
  const loadingOrg = isOrgLoading(state)
  const loadingUserTypeSources = isLoadingUserTypeSources(state)
  const loadingMergeUsersRules = isLoadingMergeUsersRules(state)
  const appNotInUsePeriod = getAppNotInUsePeriod(state)

  return {
    idOrg,
    loadingOrg,
    loadingUserTypeSources,
    loadingMergeUsersRules,
    appNotInUsePeriod
  }
}

const mapDispatchToProps = {
  updateOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
