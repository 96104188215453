import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import {
  toggleLicenseEditPopup,
  toggleAskToUpdateLicenseCostPopup
} from '@shared/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getAskToUpdateLicenseCostPopup } from '@selectors/ui'
import Analytics from '@helpers/analytics'

const AskToUpdateLicenseCostPopup = (): JSX.Element => {
  const dispatch = useDispatch()
  const { isOpen, appName, licenses, idApp, conversionDate, currency } = useSelector(getAskToUpdateLicenseCostPopup)
  const mainButtonText = 'Enter license cost'
  const cancelButtonText = 'Cancel'

  const closePopup = () => {
    dispatch(toggleAskToUpdateLicenseCostPopup({ isOpen: false }))
  }

  const trackButtonClick = (buttonLabel: string) => {
    Analytics.track('Click on button', {
      'Dialog name': 'Streamlined license cost entry',
      'Button label': buttonLabel
    })
  }
  const onCancel = () => {
    trackButtonClick(cancelButtonText)
    closePopup()
  }

  const onContinue = async () => {
    dispatch(toggleLicenseEditPopup({ isOpen: true, idApp, appName, licenses, conversionDateFromContract: conversionDate, currencyFromContract: currency, openedFromContractFlow: true }))
    trackButtonClick(mainButtonText)
    closePopup()
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onCancel}
      styles={{ modal: { width: '518px' } }}>
      <ToriiPopup.Header
        header={`Enter ${appName} license cost?`}
      />
      <ToriiPopup.Content>
        <div>
          <span>Would you like to enter the license cost for this new contract?</span>
          <br />
          <span>Keeping this information up-to-date ensures accurate cost estimates and chargeback reports.</span>
        </div>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={onCancel}
        cancelButtonText={cancelButtonText}
        mainButtonAction={onContinue}
        mainButtonText={mainButtonText}
      />
    </ToriiPopup>
  )
}

export default AskToUpdateLicenseCostPopup
