export const padding = {
  large: '13px 24px',
  medium: '7px 14px',
  small: '4px 14px'
}

export const margin = {
  large: '30px',
  medium: '15px',
  smallMedium: '10px',
  small: '5px'
}

export const fontSize = {
  extraLarge: '25px',
  large: '18px',
  mediumLarge: '16px',
  medium: '14px',
  small: '13px',
  extraSmall: '9px'
}

export const fontWeight = {
  light: 300,
  normal: 400,
  semiBold: 600,
  bold: 700
}

export const zIndex = {
  toriiPopupModal: 5,
  zendeskWidget: 900,
  modal: 1000,
  max: 1000000
}

export const boxesMaxHeights = {
  large: '35px',
  medium: '30px',
  small: '25px'
}

export const CHECKBOX_SIZE = 13
