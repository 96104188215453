import React from 'react'
import { Icon, Tooltip } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import Analytics from '../../helpers/analytics'
import { clearButton } from '@shared/style/mixins'
import { SUPPORT_ARTICLES } from '@root/articles'

const CSS = {
  helpButton: css(clearButton, {
    height: '36px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background .2s',
    ':hover': {
      background: colors.lightBlue5,
      cursor: 'pointer'
    },
    ':focus': {
      background: colors.lightBlue5
    },
    ':active': {
      boxShadow: `0 0 0 2px ${colors.lightBlue5}`,
      background: colors.lightBlue5
    }
  })
}
class HelpButton extends React.Component {
  onClick = () => {
    const { isOnlyAppOwner } = this.props
    const url = isOnlyAppOwner ? SUPPORT_ARTICLES.APP_OWNER_INTRODUCTION : SUPPORT_ARTICLES.HELP_CENTER

    Analytics.track('Clicked to open help center')
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  render () {
    return (<Tooltip
      placement='bottom'
      label='Help center'>
      <button data-testid='help-button' {...CSS.helpButton} onClick={() => this.onClick()}>
        <Icon name='Help' size='mobile' color='active' />
      </button>
    </Tooltip>)
  }
}

export default HelpButton
