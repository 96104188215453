import styled, { css } from 'styled-components'
import colors from '@shared/style/colors'

export const Container = styled.div<{ fillContainerHeight?: boolean }>`
  width: 100%;
  background: ${colors.white};

  ${props => props.fillContainerHeight && css`
    height: 100%;
    display: flex;
    flex-direction: column;
  `}
`

export const Content = styled.div<{ fillContainerHeight?: boolean }>`
  flex: ${props => props.fillContainerHeight ? '1' : null};
`
