import { fontWeight, zIndex } from '@shared/style/sizes'
import styled, { css } from 'styled-components'
import colors from '@shared/style/colors'

const insideTableRowsClass = '.rt-tr:hover &'
const containerHoverStyle = '.userDetailsContainer:hover &'
const unsavedChangesZIndex = zIndex.toriiPopupModal - 1

const nameStyleHover = css`
  color: ${colors.blue};
  transform: translateY(0);
`
const emailStyleHover = css`
  opacity: 1;
`

const oneLiner = css`
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const subheading = css`
  font-size: 13px;
  line-height: 18px;
  font-weight: ${fontWeight.semiBold};
  color: ${colors.grey2};
`

export const Container = styled.div`
  ${subheading};
  flex: 1;
  overflow: hidden;
`

export const NameStyleHover = styled.div`
  ${oneLiner};
  color: ${colors.black};
  transition: color 0.2s, transform 0.2s;

  &:not(:last-child) {
    transform: translateY(13px);
    margin-bottom: 3px;
  }
  ${insideTableRowsClass} {
    ${nameStyleHover}
  }
  ${containerHoverStyle} {
    ${nameStyleHover}
  }
  `

export const ExternalId = styled.div`
  ${oneLiner};
  margin-bottom: 3px;
  font-weight: ${fontWeight.normal};
  opacity: 0;
  transition: opacity .2s;
  ${insideTableRowsClass} {
    ${emailStyleHover}
  }
  ${containerHoverStyle} {
    ${emailStyleHover}
  }
`

export const HeaderText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  `

export const AppSelectorWrapper = styled.div`
  width: 80%;
  `

export const UnsavedChangesWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: ${unsavedChangesZIndex};
  margin-bottom: 12px;
  `
