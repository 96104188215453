import React, { ReactElement } from 'react'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import * as Style from './style'
import { Icon } from '@toriihq/design-system'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import { IdWorkflowUiNode } from '@shared/workflows/actions/types'
import { handleExitDrawerAnalytics } from '@shared/services/workflows/analytics.utils'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'

interface Props {
  title: string
  idNode: IdWorkflowUiNode
  isValid: boolean
}

export const DrawerHeader = ({
  title,
  idNode,
  isValid = true
}: Props): ReactElement => {
  const { unselectNode } = useWorkflowNavigation()
  const { idNewNode, idAudit } = useWorkflowEditorContext()
  const workflow = useSelectedWorkflow()
  const { getActionLabel } = useActionsConfiguration({ workflow })
  const isNewNode = idNode === idNewNode

  const isPreviewMode = Boolean(idAudit)

  const onCloseDrawer = () => {
    unselectNode()

    const triggerType = workflow.triggerType!
    const actionName = getActionLabel(idNode)
    handleExitDrawerAnalytics({
      idNode,
      triggerType,
      actionName
    })
  }

  const InvalidConfigurationIcon = !isPreviewMode && !isValid && !isNewNode && <Icon color='error' name={'Danger'} />

  return (
    <Style.Layout>
      <Style.TitleFrame>
        {InvalidConfigurationIcon}
        <Style.Title title={title}>{title}</Style.Title>
      </Style.TitleFrame>
      <Style.CloseIconButton onClick={onCloseDrawer}>
        <Icon color='secondary' name={'Close'} />
      </Style.CloseIconButton>
    </Style.Layout>
  )
}
