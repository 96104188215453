import View from './view'
import { connect } from 'react-redux'
import { isInUse } from '../../lenses/apps'
import { deprecatedGetAppsByIds } from '../../selectors/apps'
import { orderBy } from 'lodash'

const mapStateToProps = (state, ownProps) => {
  const { idApp } = ownProps
  const { loading, similarApps: similarAppsFromStore = {} } = state.similarApps || {}
  const apps = deprecatedGetAppsByIds(state)

  const similarApps = (similarAppsFromStore[idApp] || [])
    .map(app => ({
      ...app,
      disabled: !isInUse(apps[app.id])
    }))
  const sortedSimilarApps = orderBy(similarApps, ['disabled'], ['asc'])
  const hasData = similarApps.length > 0

  return {
    similarApps: sortedSimilarApps,
    apps,
    loading: loading && !hasData
  }
}

export default connect(mapStateToProps)(View)
