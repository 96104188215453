import React from 'react'
import { IconName, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { ButtonContainer, ImportButtonContainer } from './styles'
import EnableFor from '@components/enableFor'

export type Props = {
  icon: IconName
  text: string
  onClick: () => void
  scopes: string[]
  disabled?: boolean
}

export const UsersImportPopoverButton = ({
  icon,
  text,
  onClick,
  scopes,
  disabled
}: Props) => (
  <ButtonContainer>
    <EnableFor scopes={scopes}>
      <ImportButtonContainer>
        <Button type={ButtonType.compact} size={ButtonSize.small}
          disabled={disabled}
          onClick={onClick}
          icon={icon}
          label={text}
        />
      </ImportButtonContainer>
    </EnableFor>
  </ButtonContainer>
)
