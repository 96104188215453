import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentApp } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'
import isEmpty from 'lodash/isEmpty'
import { getCurrencySymbol, getSupportedFeatures, isLoadedSupportedFeatures } from '@selectors/org'
import { EMPTY_OBJECT, SCOPES } from '@root/constants'
import { getConstantServices, getSyncStatus, getServicesWithConnectStatusMultipleAccounts } from '@selectors/services'
import { getServiceInfo } from '@shared/services'
import { toggleConnectService, toggleConnectSource, toggleConnectSCIMService } from '@shared/actions'
import get from 'lodash/get'
import AccessControl from '@lenses/accessControl'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const idApp = parseInt(ownProps.match.params.idApp, 10)
  const currencySymbol = getCurrencySymbol(state)

  const { app = EMPTY_OBJECT, loading: isLoadingApp } = getCurrentApp(state) || EMPTY_OBJECT

  const { name, appearsInCatalog } = app
  const isAppInUse = !isEmpty(app)

  const config = getConstantServices(state)
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const supportedFeatures = getSupportedFeatures(state)
  const services = (isServicesLoaded && isConfigLoaded) ? getServicesWithConnectStatusMultipleAccounts(state) : EMPTY_OBJECT
  const service = getServiceInfo({ services, idApp, appName: name, supportedFeatures })

  const { isConnected } = service
  const hasIntegration = !isEmpty(service)

  const isNewAppLoading = get(app, ['id']) && get(app, ['id']) !== idApp
  const loading = (isEmpty(app) || isEmpty(services)) && (isLoadingApp || isNewAppLoading)
  const showAlertBox = (isAppInUse && !loading) && hasIntegration && !isConnected

  const isLoadedFeatures = isLoadedSupportedFeatures(state)

  const hasExpensesAccess = AccessControl.isAccessibleWithState({ scopes: [SCOPES.EXPENSE_READ, getScopeByIdOrgAndIdApp(SCOPES.EXPENSE_READ, idOrg, idApp)], state })

  return {
    name,
    currencySymbol,
    idOrg,
    idApp,
    isSmallScreen: getIsSmallScreen(state),
    isInUse: isAppInUse,
    loading,
    appearsInCatalog,
    service,
    showAlertBox,
    hasExpensesAccess,
    isLoadedFeatures
  }
}

const mapDispatchToProps = {
  toggleConnectSource,
  toggleConnectService,
  toggleConnectSCIMService
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
