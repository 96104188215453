import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import range from 'lodash/range'
import maxBy from 'lodash/maxBy'
import colors from '@shared/style/colors'
import { getMostUsedApps } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { getMostUsedApps as getMostUsedAppsSelector } from '@selectors/apps'
import HoverableHeader from '@components/hoverableHeader'
import AnimatedBar from '@components/animatedBar'
import Placeholder from '@components/placeholder'
import RelativeTeamLink from '@components/relativeTeamLink'
import { Tooltip, AppIcon, Spacer } from '@toriihq/design-system'
import * as Style from './style'
import Analytics from '@helpers/analytics'
import { formatNumber } from '@root/shared/utils'

const APPS_AMOUNT = 10
const MAX_ACTIVE_USERS = 30

const className = 'mostUsedAppsChart'

const MostUsedApps = (props) => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { apps, loading } = useSelector(getMostUsedAppsSelector)
  const dispatch = useDispatch()

  React.useEffect(() => {
    idOrg && dispatch(getMostUsedApps({ idOrg }))
  }, [dispatch, idOrg])

  return <MostUsedAppsComponent loading={loading} apps={apps} />
}

export const MostUsedAppsComponent = React.memo(function MostUsedAppsComponent ({ loading = false, apps }) {
  const { activeUsers: maxActiveUsers = MAX_ACTIVE_USERS } = maxBy(apps, 'activeUsers') || {}

  const onAppIconClick = (appRank) => {
    Analytics.track('Click on Most used apps / Insights', {
      'Rank': appRank
    })
  }

  return (
    <div className={className} {...Style.Main}>
      <HoverableHeader
        hoverClass={className}
        overrideStyle={Style.Header}
        outerHoverStyle={'#mostUsedApps:hover &'}
        header='most used apps'
        subHeader={`Number of active users in ${moment().utc().startOf('month').subtract(1, 'month').format('MMMM')}`}
      />
      <div {...Style.Container}>
        <div {...Style.Indexing}>
          {range(1, APPS_AMOUNT + 1).map(index => <span key={index} {...Style.Index}>{index}</span>)}
        </div>
        <div {...Style.Separator} />
        <div {...Style.Chart}>
          {apps.map((app, index) => {
            const hasApp = Boolean(app.name)
            const barColor = hasApp ? colors.blue : colors.grey3
            const appRank = index + 1
            const appWithTooltip =
              <Tooltip label={app.name} hide={!hasApp}>
                <AppIcon size='small' appImageUrl={app.imageUrl} appName={app.name} />
              </Tooltip>
            return <Style.AppRow key={app.idApp || app.key}>
              <Placeholder loading={loading} rows={1} style={{ maxWidth: `${app.score}%`, padding: '20px' }}>
                <Spacer right={'space-300'}>
                  {app.idApp
                    ? <RelativeTeamLink to={`/app/${app.idApp}`} onClick={() => onAppIconClick(appRank)} style={{ display: 'block', height: '24px' }}>
                      {appWithTooltip}
                    </RelativeTeamLink>
                    : appWithTooltip
                  }
                </Spacer>
                <AnimatedBar amount={app.activeUsers} totalAmount={maxActiveUsers} percentageWidth={85} barHeight={10} barColor={barColor} minBarWidth={25} />
                {hasApp && (
                  <Spacer left={'space-100'} right={'space-200'}>
                    <Style.ActiveUsers>{formatNumber(app.activeUsers)}</Style.ActiveUsers>
                  </Spacer>
                )}
              </Placeholder>
            </Style.AppRow>
          })}
        </div>
      </div>
    </div>
  )
})

MostUsedApps.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    idApp: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    activeUsers: PropTypes.number,
    score: PropTypes.number
  })),
  loading: PropTypes.bool
}

export default MostUsedApps
