import { css } from 'styled-components'
import colors from './colors'
import { fontWeight } from './sizes'

export const link = css`
    color: ${colors.blue} !important;
    font-weight: ${fontWeight.normal};
    cursor: pointer;
    transition: color .2s;
    &:active, &:hover, &:focus {
        color: ${colors.lightBlue1} !important;
    }
`

export const body = css`
  color: ${colors.text};
  font-size: 13px;
  line-height: 18px;
  font-weight: ${fontWeight.normal};
    strong {
      font-weight: ${fontWeight.bold}
  }
`

export const title = css`
  color: ${colors.darkText};
  font-size: 20px;
  height: 32px;
  line-height: 32px;
  font-family: 'Nunito';
  font-weight: ${fontWeight.semiBold}
`
