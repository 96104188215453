import View from './view'
import { connect } from 'react-redux'
import { setAppsDetails, toggleHideAppPopup } from '../../shared/actions'
import { withRouter } from 'react-router-dom'
import { deprecatedGetAppsByIds } from '../../selectors/apps'
import { EMPTY_OBJECT } from '../../constants'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { idApp, readOnly } = ownProps
  const { name } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  return {
    idOrg,
    name,
    showConfirmation: state.ui.hideAppPopup === idApp,
    readOnly,
    options: (ownProps.options ?? []).filter(option => !option.isDeleted)
  }
}

const mapDispatchToProps = {
  setAppsDetails,
  toggleHideAppPopup
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
