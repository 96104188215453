import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ChartWidget from '../chartWidget'
import { useDispatch, useSelector } from 'react-redux'
import { getCompareUsage } from '@shared/actions'
import * as Style from './style'
import UsageIcon from '@components/usageIcon'
import keyBy from 'lodash/keyBy'
import { scoreSpecialValues } from '@root/constants'
import EmptyStateImage from '@media/availability.svg'
import { deprecatedGetAppsByIds, getExtensionOnlyApps, isLoadingAppsComparisonData } from '@selectors/apps'
import Analytics from '@helpers/analytics'
import { customJoin } from '@lenses/utils'
import isUndefined from 'lodash/isUndefined'
import moment from 'moment'
import SourcesTooltip from '@pages/appsComparison/components/sourcesTooltip'
import get from 'lodash/get'
import { USAGE_WIDGET } from '@pages/appsComparison/style'
import { Icon, Link, EmptyState, Popover } from '@toriihq/design-system'
import { withRouter } from 'react-router-dom'

const Usage = withRouter((props) => {
  const { idOrg = parseInt(props.match.params.idOrg, 10), idApps, getAlerts } = props

  const dispatch = useDispatch()

  const [usage, setUsage] = useState([])
  const [usageSources, setUsageSources] = useState({})

  const appsById = useSelector(deprecatedGetAppsByIds)
  const extensionOnlyApps = useSelector(getExtensionOnlyApps)
  const isLoading = useSelector(isLoadingAppsComparisonData)

  const extensionAppsWithData = extensionOnlyApps.filter(app => usage.find(appUsage => appUsage.idApp === app.id && !isUndefined(appUsage.averageDaysUsed)))

  const usageByIdApp = useMemo(() => {
    if (usage.length > 0) {
      return keyBy(usage, 'idApp')
    }
    return {}
  }, [usage])

  useEffect(() => {
    const getUsage = async () => {
      const { usage, usageSources } = await dispatch(getCompareUsage({ idOrg, idApps }))
      setUsage(usage)
      setUsageSources(keyBy(usageSources, 'idApp'))
    }

    getUsage()
  }, [idOrg, idApps, dispatch])

  const renderLegendInfo = ({ idApp }) => {
    const app = usageByIdApp[idApp]
    if (!app || isUndefined(app.score)) {
      return null
    }

    const getLabel = (score, isCore) => {
      if (isCore) {
        return 'Core app'
      }
      if (score > 0 && score <= 4) {
        return 'Rarely used'
      }
      if (score > 4 && score <= 9) {
        return 'Periodically used'
      }
      if (score > 9) {
        return 'Frequently used'
      }
      if (score === scoreSpecialValues.notCollectingUsage) {
        return 'Unknown'
      }
      return 'Not used'
    }

    const sourcesList = get(usageSources, [idApp, 'usageSources'], [])
    const appName = get(usageSources, [idApp, 'name'])

    return (
      <div {...Style.Legend}>
        <div {...Style.LegendIcon}><UsageIcon score={app.score} isCore={!!app.isCore} showLastVisit={false} /></div>
        <div {...Style.LegendLabel}>{getLabel(app.score, app.isCore)}</div>
        <SourcesTooltip appName={appName} sourcesList={sourcesList} header={'SOURCES'} />
      </div>
    )
  }

  const renderLegendDataSeparator = (missingDataArray) => {
    return (
      <span {...Style.MissingDataText}>
        Some data is missing...
        <Popover
          position='top'
          showArrow
          content={<div style={{ width: 230 }}>
            Cannot wait to discover how often employees use {customJoin(missingDataArray.map(app => app.name))}? <br />Connect
            the apps to your SSO provider or deploy the Torii browser extension.
            <br />
            <Link onClick={onReadMoreButtonClick} href='https://support.toriihq.com/hc/en-us/articles/5129309596955'
              target='_blank'>
              Read more in Torii help center
            </Link>
          </div>}
        >
          <Icon name='Info' color='secondary' />
        </Popover>
      </span>
    )
  }

  const renderTooltipInfo = ({ value }) => {
    return `${value} days`
  }

  const onReadMoreButtonClick = () => {
    Analytics.track('Click on Read more link / Comparison page / Compare tab / Applications', {
      widget: 'Usage'
    })
  }

  const EmptyStateComponent = () => {
    return <div {...Style.EmptyStateContainer}>
      <EmptyState
        size='small'
        image={<img src={EmptyStateImage} alt='no data' />}
        description={<span>Cannot wait to discover how often employees use {customJoin(idApps.map(idApp => appsById[idApp] && appsById[idApp].name))}?<br />Connect the apps to your SSO provider or deploy the Torii browser extension</span>}
        link={<Link onClick={onReadMoreButtonClick} href='https://support.toriihq.com/hc/en-us/articles/5129309596955' target='_blank'>
          Read more in Torii help center
        </Link>}
      />
    </div>
  }

  const trendTitle = `Application usage over time: ${moment.utc().subtract(11, 'months').format('MMM YYYY')}  - ${moment().format('MMM YYYY')}`

  return (
    <ChartWidget
      loading={isLoading}
      subHeaderText='Average number of days an app was used'
      data={usage}
      title='APPLICATION USAGE'
      tooltipTitle='USED ON AVERAGE'
      yAxisLabel='Days used on average'
      trendTitle={trendTitle}
      renderLegendInfo={renderLegendInfo}
      renderLegendDataSeparator={renderLegendDataSeparator}
      renderTooltipInfo={renderTooltipInfo}
      valueKey='averageDaysUsed'
      orderBy={idApps}
      alerts={!isLoading && getAlerts(USAGE_WIDGET, usageSources, extensionAppsWithData)}
      emptyStateComponent={EmptyStateComponent}
    />
  )
})

Usage.propTypes = {
  getAlerts: PropTypes.func,
  idOrg: PropTypes.number,
  idApps: PropTypes.arrayOf(PropTypes.number)
}

export default Usage
