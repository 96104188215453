import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Style from './style'
import { css } from 'glamor'
import EnableFor from '@components/enableFor'
import { SCOPES, WORKFLOW_STEP_TYPES, WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPES } from '@root/constants'
import ToriiToggle from '@components/toriiToggle'
import {
  getActionsConfigByType,
  getFieldsDefaultValues,
  getFlattenActionsConfig,
  getNotifyOnErrorsWorkflowsSelector,
  isAppsTriggersLoaded as isAppsTriggersLoadedSelector
} from '@selectors/workflows'
import {
  getNotifyOnErrorsWorkflows,
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig
} from '@shared/actions'
import { createWorkflow, updateWorkflow } from '@shared/actions/workflows'
import { getCurrentOrg } from '@selectors/me'
import { createAction } from '@lenses/workflows'
import { Tooltip, Icon, ExpandableSection, TextBadge } from '@toriihq/design-system'
import Analytics from '@root/helpers/analytics'
import get from 'lodash/get'
import SlackMessagePreviewOn from '@media/slackMessagePreviewOn.svg'
import SlackMessagePreviewOff from '@media/slackMessagePreviewOff.svg'
import { createActionsWithASingleAction } from '@root/shared/workflows/actions/utils/createActionsWithASingleAction'
import { getFirstAction } from '@root/shared/workflows/actions/utils/getFirstAction'
import { ActionConfiguration } from '@pages/workflow_v2/actionConfiguration' // TODO-OM REMOVE OR MOVE TO COMPONENTS

const ACTION_TYPE = 'slackSendNotification'
const TRIGGER_TYPE = WORKFLOW_TRIGGER_TYPES.WORKFLOW_ERROR_OCCURRED

const WorkflowsNotifyOnErrorsSettings = () => {
  const dispatch = useDispatch()
  const [workflow, setWorkflow] = useState()
  const [isValid, setIsValid] = useState(true)
  const [errorMsg, setErrorMsg] = useState('Some fields are missing or invalid')

  const { id: idOrg } = useSelector(getCurrentOrg)
  const workflows = useSelector(getNotifyOnErrorsWorkflowsSelector)
  const isEnabled = Boolean(workflow && workflow.isActive)

  const actionsConfig = useSelector(getFlattenActionsConfig)
  const fieldsDefaultValues = useSelector(getFieldsDefaultValues)
  const actionsByType = useSelector(getActionsConfigByType)
  const isAppsTriggersLoaded = useSelector(isAppsTriggersLoadedSelector)

  const isValidAccount = (action) => {
    const accountConfig = get(action.fields.find(field => field.id === 'account'), ['value', 'config'])
    return accountConfig.isValid
  }

  useEffect(() => {
    if (!workflow && Object.keys(actionsByType).length > 0) {
      const dummyAction = createAction(TRIGGER_TYPE, ACTION_TYPE, { actionsConfig, fieldsDefaultValues, actionsByType })
      const dummyActions = createActionsWithASingleAction(dummyAction)
      const dummyWorkflow = {
        type: WORKFLOW_TYPES.notifyOnErrors,
        name: `Notify on workflows errors for idOrg ${idOrg}`,
        idApp: 1,
        isActive: false,
        triggerType: TRIGGER_TYPE,
        actions: dummyActions
      }
      setWorkflow(dummyWorkflow)
    }
  }, [actionsByType, actionsConfig, fieldsDefaultValues, idOrg, workflow])

  useEffect(() => {
    if (idOrg) {
      dispatch(getWorkflowsTriggersConfig())
      dispatch(getWorkflowsActionsConfig(idOrg))
      dispatch(getNotifyOnErrorsWorkflows({ idOrg }))
    }
  }, [idOrg, dispatch])

  useEffect(() => {
    if (workflows.length > 0) {
      setWorkflow(workflows[0])
    }
  }, [workflows])

  useEffect(() => {
    if (workflow) {
      const firstWorkflowAction = getFirstAction(workflow.actions)
      setIsValid(workflow.isValid && !!firstWorkflowAction && isValidAccount(firstWorkflowAction))
      if (!firstWorkflowAction) {
        setErrorMsg('The workflow has no actions')
      } else {
        setErrorMsg(firstWorkflowAction.errorMsg || 'Some fields are missing or invalid')
      }
    }
  }, [workflow])

  const onToggle = async () => {
    const workflowExists = workflow.id
    if (workflowExists) {
      dispatch(updateWorkflow({ idOrg, idWorkflow: workflow.id, workflow: { isActive: !workflow.isActive } }))
    } else {
      const { workflow: newWorkflow } = await dispatch(createWorkflow({ idOrg, type: WORKFLOW_TYPES.notifyOnErrors }))
      dispatch(updateWorkflow({ idOrg, idWorkflow: newWorkflow.id, workflow: { ...workflow, isActive: true } }))
    }

    Analytics.track(`Toggle workflow notifications`, { isEnabled: !isEnabled })
  }

  return (
    <ExpandableSection
      title={(
        <>
          <span>Notification Settings</span>
          {!isValid && isEnabled &&
          <Tooltip label={errorMsg}>
            <Icon name='Danger' color='error' />
          </Tooltip>}
        </>
      )}
      rightSlot={<TextBadge size='Small' color='blue'>{isEnabled ? 'On' : 'Off'}</TextBadge>}
    >
      <div>
        <div {...css(Style.toggleBoxContainer, !isEnabled && Style.disabled)}>
          <div {...Style.explain}>Send a Slack notification every time a workflow or an access request policy fails or is completed with errors</div>
          <div {...Style.text}>Enable Notifications</div>
          <div {...Style.toggle}>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
              <ToriiToggle
                checked={Boolean(isEnabled)}
                onToggle={onToggle}
              />
            </EnableFor>
          </div>
        </div>
        <div>
          { workflow && workflow.idApp && isAppsTriggersLoaded && <div {...Style.collapsableContent}>
            <div>
              <ActionConfiguration
                workflow={workflow}
                stepType={WORKFLOW_STEP_TYPES.STEP_SETUP}
                stepIndex={1}
                idAction={workflow.actions.idRootNode}
                disabled={!isEnabled}
              />
            </div>
            {<img {...Style.image} alt={`Slack message ${isEnabled ? 'on' : 'off'} preview`} src={isEnabled ? SlackMessagePreviewOn : SlackMessagePreviewOff} />}
          </div>}
        </div>
      </div>
    </ExpandableSection>
  )
}

export default WorkflowsNotifyOnErrorsSettings
