import React from 'react'
import { useDispatch } from 'react-redux'
import { getWidgets } from '@shared/actions'
import Widgets from '@root/widgets'
import { Grid, Row, ExpandableSection, Counter } from '@toriihq/design-system'

const WidgetsBox = ({ idOrg, idApp }) => {
  const [widgets, setWidgets] = React.useState([])
  const dispatch = useDispatch()

  React.useEffect(() => {
    let isMounted = true
    const fetchWidgets = async () => {
      const response = await dispatch(getWidgets({ idOrg, idApp }))
      if (isMounted) {
        setWidgets(response.widgets.filter(widget => widget.fields.length > 0 || widget.emptyState))
      }
    }

    fetchWidgets()

    return () => {
      setWidgets([])
      isMounted = false
    }
  }, [idOrg, idApp, dispatch])

  if (!widgets.length) {
    return null
  }

  return (
    <ExpandableSection
      title={<span>Plugins <Counter value={widgets.length} /></span>}
    >
      <Grid>
        <Row>
          <Widgets widgets={widgets} columnsPerWidget={6} />
        </Row>
      </Grid>
    </ExpandableSection>
  )
}

export default WidgetsBox
