import View from './view'
import { connect } from 'react-redux'
import { inviteUser, toggleRolePopup } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { getAdminRoleOptionsForSelect, getSuperAdminRole } from '@selectors/roles'
import accessControl from '@root/lenses/accessControl'
import { FEATURES } from '@root/shared/features'

const mapStateToProps = state => {
  const org = (getCurrentOrg(state) || {})
  const domain = org.domain
  const idOrg = org.id
  const adminRoleOptions = getAdminRoleOptionsForSelect(state)
  const superAdminRole = getSuperAdminRole(state)
  const isRoleSelectionInPlan = accessControl.isFeatureEnabledInPlan({ feature: FEATURES.SETTINGS.TABS.MEMBERS.COMPONENTS.INVITE_MEMBERS_BY_ROLE, state })

  const formInitialValues = {
    idRole: superAdminRole.id
  }

  return {
    domain,
    idOrg,
    adminRoleOptions,
    formInitialValues,
    isRoleSelectionInPlan
  }
}

const mapDispatchToProps = {
  invite: inviteUser,
  toggleRolePopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  invite: dispatchProps.invite.bind(null, stateProps.idOrg)
})

export { validate } from './view'

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps)(View)
