import React from 'react'
import { useSelector } from 'react-redux'
import numeral from 'numeral'
import { getCurrentOrg } from '@selectors/me'
import { getInsightsStats, isLoadingInsightsStats, isLoadedInsightsStats } from '@selectors/stats'
import InfoBox from '../infoBox'
import emptyImage from '@media/applications.svg'
import { SCOPES } from '@root/constants'
import Analytics from '@helpers/analytics'
import { Button, ButtonType } from '@toriihq/design-system'
import { useHistory } from 'react-router-dom'

const TotalApps = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { apps, monthlyApps } = useSelector(getInsightsStats)
  const isLoaded = useSelector(isLoadedInsightsStats)
  const isLoading = useSelector(isLoadingInsightsStats)
  const loading = !isLoaded || (isLoading && apps === 0)
  const history = useHistory()

  const onClick = () => {
    Analytics.track('Click to go to services', {
      from: 'Empty state total products'
    })
    history.push(`/team/${idOrg}/services/all`)
  }
  const emptyStateInfo = {
    orientation: 'horizontal',
    size: 'small',
    align: 'center',
    title: 'Discover your apps',
    image: <img src={emptyImage} alt='No apps' />,
    buttons: [<Button type={ButtonType.secondary} onClick={onClick} label='Connect service providers' />]
  }

  const appsDisplay = numeral(apps).format('0,0')
  const monthlyAppsDisplay = numeral(monthlyApps).format('0,0')

  return (
    <InfoBox
      header={apps ? <span>{appsDisplay}</span> : null}
      subHeader='APPLICATIONS'
      description={monthlyApps > 0 ? <div><strong>+{monthlyAppsDisplay}</strong> Since last month</div> : <div>Same as last month</div>}
      emptyStateInfo={emptyStateInfo}
      loading={loading}
      navTo='/applications'
      navToNeededScopes={[SCOPES.APPLICATIONS_READ]}
      pageName='Insights'
      boxName='Applications'
    />
  )
}

export default TotalApps
