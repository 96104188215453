import View from './view'
import { connect } from 'react-redux'
import { getCurrency } from '../../../selectors/org'

const mapStateToProps = (state) => {
  const currency = getCurrency(state)
  return { currency }
}

export default connect(mapStateToProps)(View)
