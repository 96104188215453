import View from './view'
import { connect } from 'react-redux'
import isNumber from 'lodash/isNumber'
import { searchApps } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { deprecatedGetAppsByIds, getUsedAppsByIds } from '@selectors/apps'
import accessControl from '@lenses/accessControl'
import { SCOPES } from '@root/constants'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const usedAppsById = getUsedAppsByIds(state)
  const appsById = deprecatedGetAppsByIds(state)
  const value = isNumber(ownProps.value) ? appsById[ownProps.value] : ownProps.value
  const hasApplicationsTakeActionScope = accessControl.isAccessibleWithState({ state, scopes: [SCOPES.APPLICATIONS_WRITE] })

  return {
    idOrg,
    usedAppsById,
    value,
    hasApplicationsTakeActionScope
  }
}

const mapDispatchToProps = {
  searchApps
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
