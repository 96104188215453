import styled from 'styled-components'

export const Container = styled.div`
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SpinnerImg = styled.img`
  height: 77px;
  width: 77px;
`
