import React from 'react'
import PropTypes from 'prop-types'
import { FORM_ERROR } from 'final-form'
import copy from 'copy-to-clipboard'
import get from 'lodash/get'
import ConnectCustomIntegration, { NEW_APP_ACCOUNT_ID } from '../../service/connectCustomIntegration'
import ToriiPopup from '../ToriiPopupV2'
import { SCOPES } from '@root/constants'
import Analytics from '@helpers/analytics'
import * as Style from './style'
import { Button, ButtonType, ButtonSize, Spacer, Link } from '@toriihq/design-system'

const formError = { [FORM_ERROR]: 'We could not create the integration, please try again' }

class ConnectCustomIntegrationPopup extends React.Component {
  state = {
    showIntegrationConnected: false,
    idAppAccount: undefined,
    copied: false
  }

  onSubmit = async (values) => {
    const { idOrg, close, onConnect } = this.props
    const idAppAccount = values.idAppAccount === NEW_APP_ACCOUNT_ID ? undefined : values.idAppAccount

    Analytics.track('Click to create integration / Add integration popup / Integrations', {
      'Integration type': 'Custom'
    })

    return Promise.resolve(onConnect({ idOrg, idApp: values.idApp, idAppAccount })
      .then(res => {
        const error = get(res, 'response.error')
        if (error) {
          return formError
        }
        this.toggleCreateConfirm(true, res.idAppAccount)
        setTimeout(close, 1000)
      })
      .catch(e => formError))
  }

  toggleCreateConfirm = (open = false, idAppAccount = undefined) => {
    const { idOrg, getServicesSyncData, coordinate } = this.props
    this.setState({ showIntegrationConnected: open, idAppAccount })
    getServicesSyncData({ idOrg })
    if (!open) {
      const mainWrapper = document.getElementsByClassName('mainWrapper')
      if (mainWrapper && mainWrapper.length > 0) {
        mainWrapper[0].scrollTo({ behavior: 'smooth', top: coordinate - 60, left: 0 })
      }
    }
  }

  copyToClipboard = (idAppAccount) => {
    Analytics.track('Click on Copy API link / Integrations', {
      'Link location': 'Popup'
    })

    copy(idAppAccount)
    this.setState({ copied: true })
    setTimeout(() => this.setState({ copied: false }), 2000)
  }

  render () {
    const { copied, idAppAccount, showIntegrationConnected } = this.state
    const { cancel, isOpen, idOrg } = this.props

    const headerLink = <Link href='https://support.toriihq.com/hc/en-us/articles/5164749028507' target='_blank'>Learn more about custom integrations</Link>

    return (<>
      <ToriiPopup
        isOpen={isOpen}
        onCloseAction={cancel}
      >
        <ToriiPopup.Header header='Add Custom Integration' subHeader='Create integration for any app and upload data via API' children={headerLink} />
        <ToriiPopup.Form
          onSubmit={this.onSubmit}
          initialValues={{ idApp: undefined, idAppAccount: undefined }}
          contentAreaStyle={{ overflowY: 'unset' }}
          render={(formProps) => {
            return <ConnectCustomIntegration
              idOrg={idOrg}
              formProps={formProps}
            />
          }}
          renderFooter={(formProps) => {
            return <ToriiPopup.Footer
              cancelButtonText='Cancel'
              mainButtonText='Add integration'
              isMainSubmit
              recoveryTime={1000}
              formProps={formProps}
              scopes={[SCOPES.INTEGRATIONS_WRITE]}
            />
          }}
        />
      </ToriiPopup>
      <ToriiPopup
        isOpen={showIntegrationConnected}
        onCloseAction={this.toggleCreateConfirm}
      >
        <ToriiPopup.Header header='Integration Added' children={headerLink} />
        <ToriiPopup.Content>
          <div>
            Here is your App account ID.
            <br />Copy it and use it to sync users and licenses data into Torii.
            <br /><br />
            <div {...Style.IdAppAccountContainer}>
              <span {...Style.IdAppAccountBold}>{idAppAccount}</span>
              {copied ? <div {...Style.CopyText}>Copied!</div> : <Spacer left={'space-050'}><Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => this.copyToClipboard(idAppAccount)} label='Copy App account ID' /></Spacer>}
            </div>
          </div>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          mainButtonText='Close'
          mainButtonAction={() => this.toggleCreateConfirm(false)}
          showCancelButton={false}
        />
      </ToriiPopup>
    </>
    )
  }
}

ConnectCustomIntegrationPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default ConnectCustomIntegrationPopup
