import React, { ReactElement, useState } from 'react'
import { UploadArea } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'

interface Props {
  onFileSelect: (file: File) => void
  types?: string[]
  scopes: string[]
  isLoading?: boolean
}

const BrowseUploadBox = ({ onFileSelect, types, scopes, isLoading = false }: Props): ReactElement => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const onDrop = async (files: File[]) => {
    const file = files[0]

    if (!file) {
      setErrorMessage('Invalid file type.')
      return
    }

    onFileSelect(file)
  }
  return (
    <EnableFor scopes={scopes}>
      <UploadArea
        onFileSelect={onDrop}
        validFileTypes={types}
        state={isLoading ? 'loading' : errorMessage !== null ? 'error' : 'neutral'}
        errorMessage={errorMessage}
      />
    </EnableFor>
  )
}

export default BrowseUploadBox
