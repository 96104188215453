import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { ErrorSubtitle, ErrorText } from './styles'

export type Props = {
  onCloseClick: () => void
}

const ErrorStep = ({ onCloseClick }: Props): JSX.Element => (<>
  <ToriiPopup.Header header='Delete users' />
  <ToriiPopup.Content>
    <ErrorText>
      <ErrorSubtitle>Delete Users Failed</ErrorSubtitle>
      <br />
      Something went wrong, please try again later.
    </ErrorText>
  </ToriiPopup.Content>
  <ToriiPopup.Footer
    mainButtonText='Close'
    mainButtonAction={onCloseClick}
    showCancelButton={false}
  />
</>)

export default ErrorStep
