import React, { useMemo, useState } from 'react'
import { Props, Option, App } from './types'
import ToriiSelect from '@components/select'
import { Styles } from './style'
import PropTypes from 'prop-types'
import AppBadge from '@components/appBadge'
import { createFilter } from '@toriihq/design-system'

export default function SelectAppGroupDivided ({ appGroups, selectedApp: selectedAppProp, onAppSelected }: Props) {
  const [selectedApp, setSelectedApp] = useState<App | undefined>(selectedAppProp)

  const options = useMemo(() => appGroups.map((group, index) => ({
    label: group.groupName,
    options: group.apps.map(app => ({ ...app, value: app.idApp, label: app.name }))
  })), [appGroups])

  function onSelectedOptionChange (selectedOption: Option) {
    setSelectedApp(selectedOption)
    onAppSelected(selectedOption, [])
  }

  function renderOption (props) {
    const appOption = props.data as Option
    return <Styles.Option>
      <AppBadge name={appOption.name} imageUrl={appOption.imageUrl} />
      <Styles.AccountInfo>{appOption.isConnected ? '' : 'The account is not connected'}</Styles.AccountInfo>
    </Styles.Option>
  }

  function renderValue (props) {
    const appOption = props.data as Option
    return (
      <Styles.Value>
        <AppBadge
          name={appOption.name}
          imageUrl={appOption.imageUrl}
        />
        <Styles.AccountInfo>{appOption.isConnected ? '' : 'The account is not connected'}</Styles.AccountInfo>
      </Styles.Value>
    )
  }

  return (
    <ToriiSelect
      optionRenderer={renderOption}
      valueRenderer={renderValue}
      placeholder={'Select source of truth'}
      noResultsText='No apps found'
      value={selectedApp}
      valueKey={'idApp'}
      optionsWithGroups={options}
      filterOption={createFilter({
        ignoreAccents: true,
        ignoreCase: true,
        stringify: option => {
          return (option as { data: { name: string } }).data.name
        }
      })}
      loadingPlaceholder={'Loading...'}
      onChange={newValue => onSelectedOptionChange(newValue as Option)}
      clearable={false}
    />
  )
}

SelectAppGroupDivided.propTypes = {
  appGroups: PropTypes.arrayOf(PropTypes.shape({
    groupName: PropTypes.string.isRequired,
    apps: PropTypes.arrayOf(PropTypes.shape({
      idApp: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      imageUrl: PropTypes.string
    })).isRequired
  })).isRequired,
  onAppSelected: PropTypes.func.isRequired
}
