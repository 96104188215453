import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSelf } from '@root/selectors/me'
import { AUTH_STATE } from '@root/constants'
import { zIndex } from '@shared/style/sizes'

const Zendesk = () => {
  const me = useSelector(getSelf)

  useEffect(() => {
    const isZendeskEnabled = me.authState === AUTH_STATE.AUTHENTICATED && (me.isAdmin || me.isAppOwner)
    if (isZendeskEnabled) {
      const zendeskSnippet = document.createElement('script')
      zendeskSnippet.type = 'text/javascript'
      zendeskSnippet.id = 'ze-snippet'
      zendeskSnippet.async = true
      zendeskSnippet.onload = function () {
        if (window.zE) {
          window.zE('messenger', 'loginUser', loginCallback => {
            loginCallback(me.zendeskJWT)
          })
          window.zE('messenger:set', 'zIndex', zIndex.zendeskWidget)
        }
      }
      zendeskSnippet.src = 'https://static.zdassets.com/ekr/snippet.js?key=dcf82cf6-2ae4-4ede-ab89-9ae3746a754e'
      if (document.getElementById('ze-snippet') === null) {
        document.body.appendChild(zendeskSnippet)
      }
    }
  }, [me])

  return (<Fragment />)
}

export default Zendesk
