import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getOffboardingApps,
  getServicesConfig,
  getServicesSyncData
} from '@shared/actions'
import {
  getOffboardingConfiguredApps,
  getOffboardingNeedsAttentionApps,
  getOffboardingNonConfiguredApps,
  isOffboardingAppsLoading
} from '@selectors/offboarding'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const configuredApps = getOffboardingConfiguredApps(state)
  const nonConfiguredApps = getOffboardingNonConfiguredApps(state)
  const needsAttentionApps = getOffboardingNeedsAttentionApps(state)
  const loading = isOffboardingAppsLoading(state) && !configuredApps.length && !nonConfiguredApps.length && !needsAttentionApps.length

  return {
    idOrg,
    configuredApps,
    nonConfiguredApps,
    needsAttentionApps,
    loading
  }
}

const mapDispatchToProps = {
  getOffboardingApps,
  getServicesSyncData,
  getServicesConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
