import React, { ReactElement } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, ButtonType, ButtonSize, Link, LinkSize, Divider } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import { analytics } from '@shared/services/workflows/analytics'
import { SUPPORT_ARTICLES } from '@root/articles'
import * as Style from './styles'
import Analytics from '@helpers/analytics'

interface Props {
  isHidden: boolean
  scopes: string[]
}

const NewWorkflowButtonPopover = ({ isHidden, scopes = [] }: Props): ReactElement | null => {
  const history = useHistory()
  const { idOrg } = useParams()

  const onClickFromScratch = async (): Promise<void> => {
    return goToCreateWorkflowPage()
  }

  const goToCreateWorkflowPage = (): void => {
    !isHidden && analytics.create.selectCreationOption({ menuItem: 'From scratch' })
    history.push(`/team/${idOrg}/workflows/new`)
  }

  const goToWorkflowTemplatePage = (): void => {
    !isHidden && analytics.create.selectCreationOption({ menuItem: 'From template' })
    history.push(`/team/${idOrg}/workflows/template`)
  }

  return (
    <Style.Wrapper>
      <EnableFor scopes={scopes}>
        <Button type={ButtonType.compact} size={ButtonSize.small}
          onClick={onClickFromScratch}
          label={'From scratch'}
        />
      </EnableFor>
      <EnableFor scopes={scopes}>
        <Button type={ButtonType.compact} size={ButtonSize.small}
          onClick={goToWorkflowTemplatePage}
          label={'From template'}
        />
      </EnableFor>
      <Style.DropdownDivider>
        <Divider orientation='Vertical' />
      </Style.DropdownDivider>
      <Link
        size={LinkSize.Medium}
        href={SUPPORT_ARTICLES.WORKFLOWS_5_TO_START_FROM}
        onClick={() => {
          Analytics.track('Open workflow-examples-documentation', {
            'Open from': 'New workflow dropdown'
          })
        }}
        target='_blank'>
        <Link.Icon name='Workflows' />View 5 workflow examples
      </Link>
    </Style.Wrapper>
  )
}

export default NewWorkflowButtonPopover
