import { Tooltip, Button, ButtonType, ButtonSize, Icon } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'
import { EMPTY_OBJECT, SCOPES, WORKFLOW_TYPE_TO_ITEM_NAME, WORKFLOW_TYPES } from '@root/constants'
import React, { useState } from 'react'
import CSS from './style'
import PropTypes from 'prop-types'
import analytics from '@helpers/analytics'
import Menu from '@components/menu'
import { MenuItemWrapper, MenuItemText, MenuUlOverrideStyle } from './styles'
import { css } from 'glamor'
import { analytics as workflowsAnalytics } from '@shared/services/workflows/analytics'

export const WorkflowActions = ({
  idWorkflow,
  name,
  triggerType,
  workflowType,
  isValid,
  showDuplicateAction,
  showEditTagsAction,
  duplicateWorkflowClicked,
  toggleManuallyRunWorkflowPopup,
  onDeleteConfirmationOpen,
  disableDeleteActionReason,
  noBorder,
  referrer,
  manualRunFormInitialValues,
  toggleWorkflowAuditLogs,
  isWorkflowEditHistoryEnabled,
  isAppCatalogPoliciesView,
  toggleAddAppCatalogPolicyPopup,
  pageName,
  shouldShowMenu,
  toggleEditWorkflowTagsPopup,
  getWorkflow,
  idOrg
}) => {
  const customCSS = CSS(noBorder)
  const disableDeleteAction = Boolean(disableDeleteActionReason)
  const historyProps = {
    icon: 'History',
    onClick: () => toggleWorkflowAuditLogs({
      isOpen: true,
      idWorkflow,
      workflowName: name,
      isAppCatalogPoliciesView
    }),
    text: 'View history',
    scopes: [SCOPES.AUTOMATION_READ]
  }
  const runManuallyProps = {
    icon: 'Play',
    disabled: !isValid,
    onClick: () => {
      getWorkflow({ idOrg, idWorkflow })
      toggleManuallyRunWorkflowPopup({
        isOpen: true,
        idWorkflow,
        triggerType,
        workflowName: name,
        source: referrer,
        workflowType,
        formInitialValues: manualRunFormInitialValues
      })
    }
  }

  const copyProps = {
    icon: 'Duplicate',
    onClick: () => {
      if (isAppCatalogPoliciesView) {
        analytics.track('Duplicate policy', {
          'Page name': pageName
        })
        return toggleAddAppCatalogPolicyPopup({ isOpen: true, isByUser: true, idWorkflow })
      }
      return duplicateWorkflowClicked({ idWorkflow, name, triggerType, buttonLocation: referrer })
    },
    text: 'Duplicate',
    scopes: [SCOPES.AUTOMATION_WRITE]
  }

  const deleteProps = { icon: 'Trash', disabled: disableDeleteAction, onClick: () => onDeleteConfirmationOpen({ idWorkflow, workflowName: name }) }

  const editTagsProps = {
    icon: 'Edit',
    onClick: () => {
      workflowsAnalytics.tags.popupOpened({ openedFrom: 'Dropdown in Workflows list' })
      toggleEditWorkflowTagsPopup({
        isOpen: true,
        idWorkflow
      })
    },
    text: 'Edit tags',
    scopes: [SCOPES.AUTOMATION_WRITE]
  }

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const renderMenuItem = (item) => {
    return (
      <MenuItemWrapper onClick={item.onClick} >
        <Icon name={item.icon} />
        <MenuItemText>{item.text}</MenuItemText>
      </MenuItemWrapper>
    )
  }

  const getMenuOptions = () => {
    const options = []
    if (isWorkflowEditHistoryEnabled) {
      options.push(historyProps)
    }

    if (showDuplicateAction) {
      options.push(copyProps)
    }

    if (showEditTagsAction) {
      options.push(editTagsProps)
    }

    return options
  }

  const hoverCSS = isMenuOpen ? css(customCSS.workflowActions, { opacity: 1 }) : customCSS.workflowActions

  return <div {...hoverCSS}>
    {isWorkflowEditHistoryEnabled && !shouldShowMenu &&
    <Tooltip label={'View edit history'}>
      <EnableFor scopes={[SCOPES.AUTOMATION_READ]} allowForToriiAdmin>
        {noBorder ? <Button type={ButtonType.tertiary} size={ButtonSize.small} {...historyProps} /> : <Button type={ButtonType.secondary} {...historyProps} />}
      </EnableFor>
    </Tooltip>}
    <Tooltip label={isValid ? 'Run manually' : `The ${WORKFLOW_TYPE_TO_ITEM_NAME[workflowType]} has invalid configuration and cannot be run`}>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        {noBorder ? <Button type={ButtonType.tertiary} size={ButtonSize.small} {...runManuallyProps} /> : <Button type={ButtonType.secondary} {...runManuallyProps} />}
      </EnableFor>
    </Tooltip>
    {showDuplicateAction && !shouldShowMenu && <Tooltip label='Duplicate'>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        {noBorder ? <Button type={ButtonType.tertiary} size={ButtonSize.small} {...copyProps} /> : <Button type={ButtonType.secondary} {...copyProps} />}
      </EnableFor>
    </Tooltip>}
    <Tooltip label={disableDeleteAction ? disableDeleteActionReason : 'Delete'}>
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        {noBorder ? <Button type={ButtonType.tertiary} size={ButtonSize.small} {...deleteProps} /> : <Button type={ButtonType.secondary} {...deleteProps} />}
      </EnableFor>
    </Tooltip>
    {shouldShowMenu && <Menu
      options={getMenuOptions()}
      optionRender={renderMenuItem}
      ulOverrideStyle={MenuUlOverrideStyle}
      onMenuClick={(isOpen) => {
        workflowsAnalytics.table.openMoreActionsMenu({ isOpen })
        setIsMenuOpen(isOpen)
      }}
      hideOnScroll
    >
      <Tooltip label='More actions'>
        <Button type={ButtonType.tertiary} icon='DotsVertical' />
      </Tooltip>
    </Menu>}
  </div>
}

WorkflowActions.propTypes = {
  idWorkflow: PropTypes.number,
  name: PropTypes.string,
  triggerType: PropTypes.string,
  isValid: PropTypes.number,
  showDuplicateAction: PropTypes.bool,
  showEditTagsAction: PropTypes.bool,
  onDeleteConfirmationOpen: PropTypes.func,
  disableDeleteActionReason: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  noBorder: PropTypes.bool,
  referrer: PropTypes.string,
  workflowType: PropTypes.string,
  manualRunFormInitialValues: PropTypes.object,
  isAppCatalogPoliciesView: PropTypes.bool,
  shouldShowMenu: PropTypes.bool
}

WorkflowActions.defaultProps = {
  showDuplicateAction: true,
  showEditTagsAction: true,
  noBorder: false,
  workflowType: WORKFLOW_TYPES.regular,
  manualRunFormInitialValues: EMPTY_OBJECT,
  isAppCatalogPoliciesView: false,
  shouldShowMenu: false
}

export default WorkflowActions
