import View from './view'
import { getConnectIntegrationLink } from '../../../shared/actions'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getCurrentOrg, getUserScopes } from '../../../selectors/me'
import { getConnectIntegrationLinkData } from '../../../selectors/services'
import { SCOPES } from '../../../constants'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const userScopes = getUserScopes(state)
  const hasWriteScope = userScopes.includes(SCOPES.INTEGRATIONS_WRITE)
  const { error, loading } = getConnectIntegrationLinkData(state)
  return {
    hasWriteScope,
    idOrg,
    error,
    loading
  }
}

const mapDispatchToProps = {
  getConnectIntegrationLink
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
