import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getServicesConfig,
  getServicesSyncData,
  toggleConnectSCIMService,
  toggleConnectSource
} from '@shared/actions'
import { deprecatedGetAppsByIds } from '../../../selectors/apps'
import { getCurrentOrg } from '../../../selectors/me'
import { EMPTY_OBJECT } from '../../../constants'
import { isLoadingWorkflowActionDynamicFieldsOption } from '../../../selectors/workflows'
import { getConstantServices, getServicesWithConnectStatusMultipleAccounts, getSyncStatus } from '@selectors/services'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state)
  const isLoadingFieldOptions = isLoadingWorkflowActionDynamicFieldsOption(state)
  const { options, idApp } = ownProps

  const app = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT
  const isLoadingOptions = isLoadingFieldOptions && (!options || !options.length)

  const config = getConstantServices(state)
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const isAllDataLoaded = (isServicesLoaded && isConfigLoaded)
  const services = isAllDataLoaded ? getServicesWithConnectStatusMultipleAccounts(state) : []

  const service = services.find(service => service.idApp === idApp) || {}

  return {
    idOrg,
    app,
    isLoadingOptions,
    isConnectSourceOpen: state.ui.isConnectSourceOpen || state.ui.connectSCIMService.isOpen,
    service
  }
}

const mapDispatchToProps = {
  toggleConnectSource,
  toggleConnectSCIMService,
  getServicesSyncData,
  getServicesConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
