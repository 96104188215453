import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { EMPTY_ARRAY, EMPTY_OBJECT, TABLES } from '../../../constants'
import { getExternalUsers, getExternalUsersAmount, getExternalUsersFieldValues } from '../../../shared/actions'
import { getUserPreferences } from '../../../selectors/ui'
import { getUserTablesInfo } from '../../../selectors/tables'
import { getExternalUsersES, getExternalUsersFieldValues as getExternalUsersFieldValuesSelector } from '../../../selectors/users'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const userPreferences = getUserPreferences(state)
  const { defaultSort = EMPTY_ARRAY } = userPreferences[TABLES.externalUsersTable.key] || EMPTY_OBJECT
  const tableInfo = getUserTablesInfo(state)[TABLES.externalUsersTable.key]
  const externalUsers = getExternalUsersES(state)
  const fieldsValues = getExternalUsersFieldValuesSelector(state)

  return {
    idOrg,
    tableInfo,
    defaultSort,
    loading: (externalUsers.loading && !externalUsers.users.length) || ownProps.loadingApps,
    loadingMore: externalUsers.loadingMore,
    users: externalUsers.users,
    fieldsValues,
    total: externalUsers.total
  }
}

const mapDispatchToProps = {
  getExternalUsers,
  getExternalUsersAmount,
  getExternalUsersFieldValues
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
