import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from '../placeholder'
import AppIconWithBadge from '../appIconWithBadge'
import { H3, Tooltip, Icon, Spacer, Popover } from '@toriihq/design-system'
import SelectState from '../selectState'
import UsageIcon from '../usageIcon'
import { SCOPES } from '@root/constants'
import * as Style from './style'
import { formatNumber } from '@root/shared/utils'
import UsersStatusesBreakdown from './usersStatusesBreakdown'
import Currency from '@components/currency'

const showExpenses = (loading, totalExpenses) => (
  <div {...Style.section}>
    <div {...Style.sectionTitle}>
      Expenses
      <Spacer left={'space-100'}>
        <Tooltip label='Last 12 months'>
          <Icon name='Info' />
        </Tooltip>
      </Spacer>
    </div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        <Currency value={totalExpenses} />
      </div>
    </Placeholder>
  </div>
)

const showLicenses = (loading, numberOfAssignedLicenses, numberOfUnassignedLicenses, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated, name) => {
  const tooltipLabel = `This number does not take into account unassigned licenses since the ${name} integration does not support providing this data. To get the unassigned license count, please go the the Licenses page and enter the total purchased licenses.`
  return (
    <div {...Style.section}>
      <div {...Style.sectionTitle} >
        Licenses
        {isAppTotalAmountPartialCalculated && <Spacer left={'space-100'}>
          <Tooltip label={tooltipLabel}>
            <Icon name='Info' />
          </Tooltip>
        </Spacer>}
      </div>
      <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
        <div {...Style.sectionBody}>
          {formatNumber(numberOfAssignedLicenses + numberOfUnassignedLicenses)}

        </div>
        { isTotalAmountEnteredByUser && <div {...Style.editedLabel}>edited</div>}
      </Placeholder>
    </div>
  )
}

const showActiveContractsDetails = (loading, activeContractsTotalValue) => (
  <div {...Style.section} style={{ minWidth: '215px' }} >
    <div {...Style.sectionTitle}>Active contracts value</div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        <Currency value={activeContractsTotalValue} />
      </div>
    </Placeholder>
  </div>
)

const showUsers = (
  loading,
  numberOfUsers,
  isConnected,
  connectedAppUsersStatusesBreakdown,
  integrationDocumentationLink
) => {
  const isStatusesBreakdownAvailable =
    isConnected && connectedAppUsersStatusesBreakdown?.allAppAccountsBreakdown

  const usersCountBadge = (<div {...Style.section}>
    <div {...Style.sectionTitle}>Users
      {isStatusesBreakdownAvailable && <Spacer left={'space-100'}>
        <Icon name='ChartPie' />
      </Spacer>}
    </div>
    <Placeholder
      loading={loading} type='rect'
      style={{ height: '30px', width: '60px', maxWidth: '60px' }}>
      <div {...Style.sectionBody}>
        {formatNumber(numberOfUsers)}
      </div>
    </Placeholder>
  </div>)

  if (!isStatusesBreakdownAvailable) {
    return usersCountBadge
  }

  return <div {...Style.section}>
    <Popover
      position='bottom'
      align='center'
      showArrow
      content={<UsersStatusesBreakdown
        integrationDocumentationLink={integrationDocumentationLink}
        connectedAppUsersStatusesBreakdown={connectedAppUsersStatusesBreakdown} />}
    >
      {usersCountBadge}
    </Popover>
  </div>
}

const showState = (isInUse, loading, stateInfo) => (
  <div {...Style.section} style={{ marginLeft: 'auto' }}>
    <div {...Style.sectionTitle}>State</div>
    <Placeholder loading={loading} type='rect' style={{ height: '30px', width: '160px', maxWidth: '160px' }}>
      {isInUse &&
      <SelectState
        {...stateInfo}
        allowedScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}
        pageName='App page'
      />}
    </Placeholder>
  </div>
)

const AppPageHeader = ({
  loading,
  name,
  imageUrl,
  score,
  numberOfUsers,
  numberOfAssignedLicenses,
  numberOfUnassignedLicenses,
  isTotalAmountEnteredByUser,
  isAppTotalAmountPartialCalculated,
  isCustom,
  isInUse,
  isCore,
  totalExpenses,
  stateInfo,
  usersTooltip,
  lastVisitTime,
  showLicenseNumber,
  activeContractsTotalValue,
  isContractsVisible,
  hasExpensesAccess,
  isConnected,
  connectedAppUsersStatusesBreakdown,
  integrationDocumentationLink
}) => (
  <div {...Style.main}>
    <div {...Style.header}>
      <Placeholder loading={loading} type='rect' style={{ height: '50px', width: '50px' }}>
        <AppIconWithBadge name={name} src={imageUrl} badge={Boolean(isCustom) && 'CUSTOM'} size='x-large' />
      </Placeholder>
      <Spacer left='space-300'>
        <Placeholder loading={loading} type='rect' style={{ height: '25px', width: '150px' }}>
          <>
            <Spacer right='space-200'><H3>{name}</H3></Spacer>
            <UsageIcon
              score={score}
              width={6}
              initialHeight={10}
              diffHeight={4}
              margin={3}
              tooltipText='on average'
              lastVisitTime={lastVisitTime}
              isCore={isCore}
            />
          </>
        </Placeholder>
      </Spacer>
    </div>

    {(isInUse || loading) && (
      <div {...Style.details}>
        {showLicenseNumber && showLicenses(loading, numberOfAssignedLicenses, numberOfUnassignedLicenses, isTotalAmountEnteredByUser, isAppTotalAmountPartialCalculated, name)}
        {showUsers(loading, numberOfUsers, isConnected, connectedAppUsersStatusesBreakdown, integrationDocumentationLink)}
        {hasExpensesAccess && (totalExpenses || loading) && showExpenses(loading, totalExpenses)}
        {isContractsVisible && Boolean(activeContractsTotalValue) &&
                        showActiveContractsDetails(loading, activeContractsTotalValue)}
        {showState(isInUse, loading, stateInfo)}
      </div>
    )}
  </div>
)

AppPageHeader.propTypes = {
  idOrg: PropTypes.number.isRequired,
  idApp: PropTypes.number.isRequired,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  score: PropTypes.number,
  url: PropTypes.string,
  description: PropTypes.string,
  isCustom: PropTypes.number,
  isInUse: PropTypes.bool,
  loading: PropTypes.bool,
  stateInfo: PropTypes.object,
  numberOfUsers: PropTypes.number,
  totalExpenses: PropTypes.number,
  usersTooltip: PropTypes.string,
  activeContractsTotalValue: PropTypes.number,
  isContractsVisible: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool,
  connectedAppUsersStatusesBreakdown: PropTypes.object,
  integrationDocumentationLink: PropTypes.string
}

AppPageHeader.defaultProps = {}

export default AppPageHeader
