import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'

export const getApiKeys = createSelector(
  state => get(state, ['apiKeys', 'apiKeys'], []),
  identity
)

export const getApiKeysConfig = createSelector(
  state => get(state, ['apiKeys', 'config'], {}),
  identity
)

export const getApiKeysResources = createSelector(
  state => get(state, ['apiKeys', 'resources'], {}),
  identity
)

export const isLoadingApiKeys = createSelector(
  state => get(state, ['apiKeys', 'loading'], false),
  identity
)

export const isLoadedApiKeys = createSelector(
  state => get(state, ['apiKeys', 'isLoaded'], false),
  identity
)
