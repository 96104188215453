import React from 'react'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import WorkflowsTable from '../../components/workflows/workflowsTable'
import { WORKFLOW_TYPES, SCOPES } from '../../constants'
import { css } from 'glamor'
import { fontSize } from '@shared/style/sizes'
import colors from '../../shared/style/colors'
import { Spacer, Button, ButtonType, ButtonSize, Popover } from '@toriihq/design-system'
import NewWorkflowButtonPopover from '@pages/workflows/newWorkflowButtonPopover'
import { analytics } from '@shared/services/workflows/analytics'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import EnableFor from '@components/enableFor'
import noop from 'lodash/noop'
import { ActionsLogButton } from '@shared/workflows/actions/components/ActionsLogButton'
import { ACTIONS_LOG_TYPE } from '@shared/workflows/actions/components/ActionsLogButton/types'

const editAllowedScopes = [SCOPES.AUTOMATION_WRITE]

const CSS = {
  hiddenBadge: css({
    fontSize: fontSize.small,
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: colors.lightBlue1,
    width: 'fit-content'
  }),
  buttons: css({
    display: 'flex',
    fontSize: 'initial'
  })
}

class WorkflowsPage extends React.Component {
  fetchData = () => {
    const { getWorkflows, idOrg, isHidden, getWorkflowsTriggersConfig } = this.props
    getWorkflows({ idOrg, type: WORKFLOW_TYPES.regular, isHidden })
    getWorkflowsTriggersConfig()
  }

  componentDidMount () {
    this.fetchData()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.isHidden !== this.props.isHidden) {
      this.fetchData()
    }
  }

  onWorkflowsSettingsButtonClick = () => {
    const { idOrg, history } = this.props
    analytics.settingsPage.open()
    history.push(`/team/${idOrg}/workflows/settings`)
  }

  getNewWorkflowButton = () => {
    const { isHidden } = this.props
    const onOpenPopover = ({ isOpen }) => (isOpen && !isHidden) && analytics.create.click()

    return (
      <Popover
        position='bottom'
        align='end'
        openOnClick
        onToggle={onOpenPopover}
        content={<NewWorkflowButtonPopover isHidden={isHidden} scopes={editAllowedScopes} />}
      >
        <EnableFor scopes={editAllowedScopes}>
          <Button
            size={ButtonSize.small}
            type={ButtonType.primary}
            label={isHidden ? 'New hidden workflow' : 'New workflow'}
            onClick={noop}
          />
        </EnableFor>
      </Popover>
    )
  }

  render () {
    const { title, workflows, loading, isHidden, usersMap } = this.props

    const pathPrefix = getWorkflowPathPrefix({ workflowType: WORKFLOW_TYPES.regular })

    return <Page title='Workflows'>
      <PageHeader
        title={title}
        allowedScopes={editAllowedScopes}
        overrideStyle={{ padding: 0 }}
        contentRight={
          <div {...CSS.buttons}>
            <Spacer right={'space-150'}><Button type={ButtonType.secondary} onClick={this.onWorkflowsSettingsButtonClick} label='Workflow Settings' /></Spacer>
            <Spacer right={'space-150'}><ActionsLogButton actionsLogType={ACTIONS_LOG_TYPE.ALL} /></Spacer>
            {this.getNewWorkflowButton()}
          </div>
        }
        contentTop={isHidden && <div {...CSS.hiddenBadge}>Hidden</div>}
        menuButtonOutline={false}
      />

      <WorkflowsTable
        workflows={workflows}
        loading={loading}
        isHidden={isHidden}
        usersMap={usersMap}
        pathPrefix={pathPrefix}
      />
    </Page>
  }
}

export default WorkflowsPage
