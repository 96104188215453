import { createSelector } from 'reselect'
import moment from 'moment'
import identity from 'lodash/identity'
import get from 'lodash/get'
import map from 'lodash/map'
import keyBy from 'lodash/keyBy'
import { INTEGRATION_CATEGORY, SYNC_TYPE, SYNC_ERRORS } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import { getSourceByType } from '@root/sourcesConfig'
import { createIdAppParameterSelector } from '@shared/utils'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { isServiceConnected } from '@shared/services'
import { isDemoOrg } from '@selectors/org'

export const getSyncStatus = createSelector(
  state => get(state, ['services', 'syncStatus']),
  identity
)

export const syncStatusByIdAppAccount = (idAppAccount, connectedAt) => state => {
  const syncStatus = get(state, ['services', 'syncStatus'], []).find(syncStatus => syncStatus.idAppAccount === idAppAccount && syncStatus.connectedAt === connectedAt) ?? {}
  return { name: syncStatus.name, breakdown: syncStatus.breakdown, imageUrl: syncStatus.imageUrl }
}

export const isAppSyncStatusEnabled = (idApp) => state => {
  const syncStatus = get(state, ['services', 'syncStatus'], [])?.find(syncStatus => syncStatus.idApp === idApp && syncStatus.syncStatus === 'finished_successfully') ?? {}
  return !isEmpty(syncStatus)
}

export const getConstantServices = createSelector(state => get(state, ['services', 'config'], []),
  identity
)

export const getConstantServicesByIdApp = createSelector(
  [getConstantServices],
  servicesConfig => {
    return keyBy(servicesConfig, 'idApp')
  }
)

export const getToriiBotServicesStatus = createSelector(
  [getSyncStatus],
  syncStatus => {
    return (syncStatus || [])
      .filter(appSyncStatus => appSyncStatus.source === getSourceByType('toriiBot').source)
  }
)

export const getServicesResources = createSelector(
  state => get(state, ['services', 'resources'], {}),
  identity
)

export const getSyncStatusForNonManualSyncType = createSelector(
  [getSyncStatus],
  syncStatuses => {
    return (syncStatuses || [])
      .filter(syncStatus => syncStatus.source !== SYNC_TYPE.MANUAL)
  }
)

export const getSuccessfullyConnectedNativeIntegrations = createSelector(
  [getSyncStatus],
  syncStatuses => {
    return syncStatuses.filter(sync => sync.isEnabled && sync.lastSyncTime && sync.syncType === SYNC_TYPE.API)
  }
)

const connectStatusMultipleAccountsHandler = (syncStatus, constantServices, resources) => {
  const LOGO_FOLDER = 'https://res.cloudinary.com/dy38uvqxu/image/upload/Integrations%20-%20logos/'
  const allServices = constantServices.concat(syncStatus || [])
  const { users: usersById } = resources
  return map(allServices, service => {
    if ([SYNC_TYPE.CUSTOM, SYNC_TYPE.MANUAL].includes(service.syncType)) {
      return {
        ...service,
        isConnected: true,
        icon: service.imageUrl,
        integrationType: service.syncType,
        supportsMultipleAccounts: true,
        ...getServiceSyncStatus(service, usersById)
      }
    }

    const isServiceSyncInfo = Boolean(service.connectedAt)
    const serviceConfig = isServiceSyncInfo ? constantServices.find(constantService => constantService.idApp === service.idApp && constantService.syncType === service.syncType) : service
    const serviceSyncInfo = isServiceSyncInfo ? service : null
    const isAnyConnected = isServiceSyncInfo || allServices.filter(s => s.idApp === service.idApp && s.syncType === service.syncType && s.connectedAt).length > 0
    const workflowsToInvalidateOnDisconnect = service.workflowsToInvalidate || []

    if (serviceConfig) {
      return {
        ...serviceConfig,
        logo: serviceConfig.logo && `${LOGO_FOLDER}${serviceConfig.logo}`,
        isConnected: Boolean(serviceSyncInfo),
        displayName: serviceConfig.name,
        showConnectAnotherService: isAnyConnected,
        ...getServiceSyncStatus(serviceSyncInfo, usersById),
        appName: serviceConfig.name,
        workflowsToInvalidateOnDisconnect
      }
    }

    return {
      index: 100000,
      isConnected: true,
      integrationType: INTEGRATION_TYPE.TORII_BOT,
      isDisabled: false,
      icon: serviceSyncInfo.imageUrl,
      type: serviceSyncInfo.type,
      source: serviceSyncInfo.source,
      idApp: serviceSyncInfo.idApp,
      integrationCategory: INTEGRATION_CATEGORY.OTHER,
      showConnectAnotherService: isAnyConnected,
      ...getServiceSyncStatus(serviceSyncInfo, usersById)
    }
  })
    .sort((a, b) => (a.name || a.displayName || '').localeCompare(b.name || b.displayName || ''))
}

export const getServicesWithConnectStatusMultipleAccounts = createSelector(
  [getSyncStatusForNonManualSyncType, getConstantServices, getServicesResources],
  connectStatusMultipleAccountsHandler
)

export const getServicesWithConnectStatusMultipleAccountsWithManual = createSelector(
  [getSyncStatus, getConstantServices, getServicesResources],
  connectStatusMultipleAccountsHandler
)

const connectedAppsByAppIdsHandler = services => keyBy((services || []).filter(({ isConnected }) => isConnected), 'idApp')

export const getConnectedAppsIncludingManualSyncByAppIds = createSelector(
  [getServicesWithConnectStatusMultipleAccountsWithManual],
  connectedAppsByAppIdsHandler
)

export const getConnectedServices = createSelector(
  [getServicesWithConnectStatusMultipleAccounts, isDemoOrg],
  (services, isDemoOrg) => services.filter(service => isServiceConnected(service) && [SYNC_TYPE.CUSTOM, SYNC_TYPE.MANUAL].every(syncType => service.source !== syncType)).map(service => {
    const { lastSyncTime, connectedAt } = service
    return {
      ...service,
      nonSyncingPeriodInDays: isDemoOrg ? 0 : moment().utc().diff(moment(lastSyncTime ?? connectedAt).utc(), 'days')
    }
  })
)

export const getConnectedServicesByIdApp = createSelector(
  [getConnectedServices],
  services => groupBy(services, 'idApp')
)

export const getConnectedServicesByIntegrationCategory = createSelector(
  [getConnectedServices],
  services => groupBy(services, 'integrationCategory')
)

export const isServicesLoading = createSelector(
  state => state.services.loading,
  identity
)

export const getAppConnectedIntegration = createSelector(
  [getConnectedAppsIncludingManualSyncByAppIds, createIdAppParameterSelector],
  (connectedAppsByAppIds, idApp) => {
    const { type } = get(connectedAppsByAppIds, [idApp], { type: null })

    return Boolean(type)
  }
)

export const getNeedsAttentionServices = createSelector(
  [getServicesWithConnectStatusMultipleAccounts],
  services => {
    return (services || [])
      .filter(service => (service.syncError && SYNC_ERRORS[service.syncError]) || service.errorMessage)
  }
)

export const getActionableServices = createSelector(
  [getServicesWithConnectStatusMultipleAccounts],
  services => services.filter(service => service.syncType === SYNC_TYPE.API)
)

export const getConnectIntegrationLinkData = createSelector(
  state => get(state, ['ui', 'connectIntegrationLink']),
  identity
)

const getServiceSyncStatus = (serviceSyncStatus, usersById) => {
  if (!serviceSyncStatus) {
    return {}
  }

  const { lastSyncTime, syncStatus, connectedByIdUser, permission, syncError, errorMessage, isEnabled, connectedAt, connectedViaLink, appAccountName, name, idAppAccount, idAppToken, breakdown, hasMergeUsersRuleConfigured, hasMultipleConnectedAccounts } = serviceSyncStatus
  const specialErrors = ['multipleAccount', 'notImplementedYet']
  const isSpecialError = specialErrors.includes(errorMessage)
  const workflowsToInvalidateOnDisconnect = serviceSyncStatus.workflowsToInvalidate || []
  return {
    syncStatus,
    syncError,
    errorMessage: isSpecialError ? null : errorMessage,
    lastSyncTime,
    isSyncDisabled: !isSpecialError && !isEnabled,
    connectedBy: usersById[connectedByIdUser] || null,
    connectedAt,
    permission,
    connectedViaLink: Boolean(connectedViaLink),
    appName: name,
    displayName: `${name}${appAccountName ? ` - ${appAccountName}` : ''}`,
    appAccountName,
    workflowsToInvalidateOnDisconnect,
    idAppAccount,
    idAppToken,
    breakdown,
    hasMergeUsersRuleConfigured,
    hasMultipleConnectedAccounts
  }
}
