import View from './view'
import { connect } from 'react-redux'
import { APP_PERMISSIONS, EMPTY_OBJECT, SYNC_TYPE } from '@root/constants'
import { createSourceToken, getServicesSyncData, toggleConnectSCIMService } from '@shared/actions'
import {
  createTestConnectionEntry,
  testConnection,
  updateTestConnectionEntry,
  createSourceTokenAfterTestConnection
} from '@shared/actions/testConnection'
import { getConstantServices, getServicesWithConnectStatusMultipleAccounts, getSyncStatus } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/me'
import { getCapabilities } from '@components/testConnection/utils'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

const mapStateToProps = (state, ownProps) => {
  const { connectSCIMService = EMPTY_OBJECT } = state.ui
  const { idAppAccount, idAppToken, isReconnect } = connectSCIMService
  const { id: idOrg } = getCurrentOrg(state)
  const servicesConfig = getConstantServices(state)
  const isConfigLoaded = servicesConfig.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const isAllDataLoaded = (isServicesLoaded && isConfigLoaded)
  const services = isAllDataLoaded ? getServicesWithConnectStatusMultipleAccounts(state) : []
  const service = services.find(service => {
    if (isReconnect) {
      if (idAppToken) {
        return service.idAppToken === idAppToken
      }
      if (idAppAccount) {
        return service.idAppAccount === idAppAccount
      }
    }
    return service.idApp === connectSCIMService.idApp && (service.syncType === SYNC_TYPE.API)
  }) || {}
  const serviceConfig = isConfigLoaded ? servicesConfig.find(serviceConfig => serviceConfig.idApp === service.idApp) : {}
  const { capabilities = {}, integrationCategory } = serviceConfig || {}
  const capabilityList = !isEmpty(serviceConfig) ? getCapabilities(capabilities, integrationCategory) : []
  const hasTestConnection = get(serviceConfig, 'testConnection', true)

  return {
    idOrg,
    connectSCIMService,
    service,
    serviceName: service.name,
    serviceConfig,
    capabilityList,
    permission: ownProps.permission || service.permission || APP_PERMISSIONS.read,
    hasTestConnection,
    source: service.source,
    idApp: service.idApp
  }
}

const mapDispatchToProps = {
  toggleConnectSCIMService,
  getServicesSyncData,
  testConnection,
  createTestConnectionEntry,
  updateTestConnectionEntry,
  createSourceTokenAfterTestConnection,
  createSourceToken,
  getSyncStatus
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...dispatchProps,
  ...ownProps,
  ...stateProps,
  cancel () {
    dispatchProps.toggleConnectSCIMService({ isOpen: false })
  },
  close () {
    dispatchProps.toggleConnectSCIMService({ isOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
