import {
  GET_EMAIL
} from '../constants'
import ParseValidation from '../helpers/parse-validation'

const initialState = {
  loading: false,
  success: false,
  error: undefined,
  hasError: {},
  help: {}
}

const emailValidationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMAIL: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_EMAIL}_RESPONSE`:
    case `${GET_EMAIL}_FAILED`: {
      const validation = ParseValidation(action.payload)
      const { error, hasError, help } = validation
      const { email, id, key, user, org, isSAML, employeeIdRole, adminIdRole, trialEndTime } = action.payload

      return {
        ...state,
        loading: false,
        success: !action.error,
        error,
        hasError,
        help,
        email,
        user,
        org,
        isSAML,
        employeeIdRole,
        adminIdRole,
        trialEndTime,
        validation: {
          id,
          key
        }
      }
    }
    default: {
      return state
    }
  }
}

export default emailValidationReducer
