import View from './view'
import { connect } from 'react-redux'
import { getSuggestedOwners, toggleAssignUser } from '@shared/actions'
import { getSuggestedOwners as getSuggestedOwnersSelector, getLoadingSuggestedOwners } from '../../selectors/apps'
import { EMPTY_OBJECT } from '../../constants'
import { isOrgLoading } from '@selectors/org'
import { getCurrentOrg } from '@selectors/me'

const mapStateToProps = (state, ownProps) => {
  const { idApp, suggestOwner } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const suggestedOwners = (suggestOwner && getSuggestedOwnersSelector(state)[idApp]) || EMPTY_OBJECT

  const loading = (
    isOrgLoading(state) ||
    (Object.values(suggestedOwners).length === 0 && getLoadingSuggestedOwners(state))
  )

  return {
    idOrg,
    suggestedOwners,
    initialValues: { searchKey: '' },
    loading
  }
}

const mapDispatchToProps = {
  getSuggestedOwners,
  toggleAssignUser
}

export { validate } from './view'

export default connect(mapStateToProps, mapDispatchToProps)(View)
