import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import Table from '../../table'
import AppDetails from '../../appDetails'
import colors from '../../../shared/style/colors'
import { TABLES } from '../../../constants'
import { getFullUrl } from '../../../shared/utils'
import moment from 'moment'
import exportCSV from '@helpers/exportCSV'
import { Link } from '@toriihq/design-system'

const CSS = {
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  })
}

const columns = [
  {
    Header: 'Name',
    accessor: 'name',
    maxWidth: 250,
    className: CSS.nameAndIcon.toString(),
    Cell: ({ value: name, row: { idApp, category, imageUrl, isNew } }) => (
      <AppDetails
        id={idApp}
        name={name}
        category={category}
        imageUrl={imageUrl}
        isNew={isNew}
        component='Third Party Report'
      />
    )
  },
  {
    Header: 'URL',
    accessor: 'url',
    Cell: ({ value: url }) => <Link href={getFullUrl(url)} target='_blank'>{url}</Link>,
    maxWidth: 200
  },
  {
    Header: 'Users',
    accessor: 'activeUsers',
    Cell: ({ value: activeUsers }) => activeUsers,
    maxWidth: 80
  },
  {
    accessor: 'category',
    show: false
  },
  {
    accessor: 'imageUrl',
    show: false
  },
  {
    accessor: 'idApp',
    show: false
  }
]

class AppsListPopup extends Component {
  componentDidMount () {
    const { idOrg, categoryType, getUserDataAccessOfCategory, deprecatedGetApps } = this.props
    getUserDataAccessOfCategory({ idOrg, category: categoryType })
    deprecatedGetApps({ idOrg })
  }

  exportToCsv = async () => {
    const { apps, categoryType } = this.props

    const columnNames = ['Name', 'URL', 'Users']
    const dataForCSV = apps.map(appInfo => {
      return {
        'Name': appInfo.name,
        'URL': appInfo.url,
        'Users': appInfo.activeUsers
      }
    })

    exportCSV(`applications_with_access_to_${categoryType.toLowerCase()}_${moment().format('DD_MMMM_YYYY')}.csv`, dataForCSV, columnNames)
  }

  render () {
    const { cancel, isOpen, apps, loading, categoryType } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel}>
        <ToriiPopup.Header header={`Applications with access to ${categoryType}`} />
        <ToriiPopup.Content>
          <Table
            tableKey={TABLES.appsListPopupTable.key}
            data={apps}
            columns={columns}
            loading={loading}
            exportable
            exportFunction={this.exportToCsv}
            scrollObjectId='contentArea'
          />
        </ToriiPopup.Content>
        <ToriiPopup.Footer showMainButton={false} showCancelButton={false} />
      </ToriiPopup>
    )
  }
}

AppsListPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  categoryType: PropTypes.string,
  loading: PropTypes.bool
}

export default AppsListPopup
