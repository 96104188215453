import React, { Fragment } from 'react'
import { TABLES } from '@root/constants'
import AppExpensesChartBox from '../../../components/appExpensesChartBox'
import ExpensesTable from '../../../components/expensesTable'
import { css } from 'glamor'

const CSS = {
  separator: css({
    marginBottom: '30px'
  })
}
class Expenses extends React.Component {
  render () {
    const { idApp, idOrg, currencySymbol } = this.props
    return (
      <Fragment>
        <AppExpensesChartBox showSources={false} barSize={50} idApp={idApp} overrideStyle={CSS.separator} currencySymbol={currencySymbol} />
        <ExpensesTable idApp={idApp} idOrg={idOrg} tableKey={TABLES.appExpensesTable.key} />
      </Fragment>
    )
  }
}

export default Expenses
