import View from './view'
import { connect } from 'react-redux'
import {
  toggleInviteUser
} from '../../../shared/actions'

const mapStateToProps = state => {
  const { isInviteOpen } = state.ui
  return ({
    isOpen: isInviteOpen
  })
}

const mapDispatchToProps = {
  toggleInviteUser
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleInviteUser(false)
  },
  close () {
    dispatchProps.toggleInviteUser(false, false)
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps)(View)
