import React, { ReactElement } from 'react'
import { ReactFlowProvider } from 'reactflow'
import { Workflow } from '@shared/workflows/types'
import { WorkflowEditorProvider } from './context'
import { CanvasContainer, Container } from './styles'
import { WorkflowAuditToolbar } from './workflowAuditToolbar'
import { WorkflowDrawer } from './workflowDrawer'
import { WorkflowGraph } from './workflowGraph'
import { WorkflowHeader } from './workflowHeader'

interface Props {
  workflow: Workflow
  isAppCatalogPoliciesView: boolean
  isRequestNewAppPoliciesView: boolean
  idAudit?: number | null
}

export const WorkflowEditor = ({
  workflow,
  isAppCatalogPoliciesView,
  isRequestNewAppPoliciesView,
  idAudit = null
}: Props): ReactElement => (
  <WorkflowEditorProvider isAppCatalogPoliciesView={isAppCatalogPoliciesView} isRequestNewAppPoliciesView={isRequestNewAppPoliciesView} idAudit={idAudit}>
    <Container>
      <WorkflowAuditToolbar />
      <WorkflowHeader />
      <CanvasContainer>
        <ReactFlowProvider>
          <WorkflowGraph workflow={workflow} />
        </ReactFlowProvider>
      </CanvasContainer>
    </Container>
    <WorkflowDrawer workflow={workflow} />
  </WorkflowEditorProvider>
)
