import React from 'react'
import {
  CurrencyAndDate,
  DateSelectContainer,
  CurrencySelectContainer,
  LabelStyle
} from './style'
import CurrencySelector from '@components/currencySelector'
import moment from 'moment'
import DatePicker from '@components/datePicker'
import { Icon, Tooltip } from '@toriihq/design-system'
import LicenseCostAndTotalAmountTable from '@components/configureAppChargeback/licenseCostAndTotalAmountTable'
import { getCurrencySymbol } from '@selectors/org'
import { useSelector } from 'react-redux'
import { getCurrencySymbols } from '@selectors/ui'
import FormGroup from '@components/form/formGroup'

export enum LicenseEditSelectedColumn {
  PricePerUser,
  TotalAmount
}
type LicenseEditProps = {
    onCurrencyChange: (currency: { label: string, value: string }) => void,
    onDateChange: (date: string | undefined) => void,
    onLicensesChange: (licenses: any) => void,
    currency: string,
    date: string,
    licenses: any,
    idLicense: number,
    selectedColumn: LicenseEditSelectedColumn
}

const LicenseEdit = (props: LicenseEditProps): JSX.Element => {
  const { onCurrencyChange, onDateChange, onLicensesChange, currency, date, licenses, idLicense, selectedColumn } = props
  const orgDefaultCurrencySymbol = useSelector(getCurrencySymbol)
  const currencySymbols = useSelector(getCurrencySymbols)
  const currencySymbol = currencySymbols[currency] || orgDefaultCurrencySymbol

  return <div>
    <CurrencyAndDate>
      <CurrencySelectContainer>
        <FormGroup label='Currency' labelStyle={LabelStyle}>
          <CurrencySelector selectedCurrency={currency} onChange={onCurrencyChange} />
        </FormGroup>
      </CurrencySelectContainer>
      <DateSelectContainer>
        <FormGroup
          label='Conversion date'
          tooltip={<Tooltip label={'Your entered license cost will be converted based on this date'}><Icon name={'Info'} /></Tooltip>}
          labelStyle={LabelStyle}
        >
          <DatePicker
            value={date || moment().utc().subtract(1, 'day').format('MM/DD/YYYY').toString()}
            onDayChange={onDateChange}
            format={'MM/DD/YYYY'}
            disabled={false}
            placeholder={date}
            dayPickerProps={{
              disabledDays: {
                before: moment.utc('01/01/2016', 'MM/DD/YYYY').toDate(),
                after: moment.utc().subtract(1, 'day').toDate()
              }
            }}
          />
        </FormGroup>
      </DateSelectContainer>
    </CurrencyAndDate>
    <LicenseCostAndTotalAmountTable licenses={licenses} currencySymbol={currencySymbol} onChange={onLicensesChange} focusOnLicenseId={idLicense} selectedColumn={selectedColumn} />
  </div>
}

export default LicenseEdit
