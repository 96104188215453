import React, { useEffect, useState, VFC } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { useDispatch, useSelector } from 'react-redux'
import {
  deleteManuallyImportedUsers,
  getServicesSyncData,
  toggleDeleteImportedUsers,
  updateImportAppUsersStatus
} from '@root/shared/actions'
import ConfirmDeletionStep from './confirmDeletionStep'
import { POPUP_STEP } from '@components/popups/deleteImportedUsersPopup/types'
import { getCurrentOrg } from '@selectors/me'
import { getCurrentApp } from '@selectors/apps'
import LoadingStep from '@components/popups/deleteImportedUsersPopup/loadingStep'
import FinalStep from '@components/popups/deleteImportedUsersPopup/finalStep'
import ErrorStep from '@components/popups/deleteImportedUsersPopup/errorStep'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import ImportUsersAnalytics from '@components/popups/importUsersPopup/analytics'

const DELETE_USERS_DURATION_SECONDS = 7

const DeleteImportedUsersPopup = (): JSX.Element => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const currentApp = useSelector(getCurrentApp)
  const [isFirstLoaded, setIsFirstLoaded] = useState(false)
  const [step, setStep] = useState(POPUP_STEP.LOADING)
  const dispatch = useDispatch()

  useEffect(() => {
    const isCurrentAppLoaded = currentApp && !currentApp.loading
    if (isCurrentAppLoaded && !isFirstLoaded) {
      setStep(POPUP_STEP.CONFIRM_DELETION)
      setIsFirstLoaded(true)
    }
  }, [currentApp, isFirstLoaded])

  const handleXClick = () => {
    if (step === POPUP_STEP.CONFIRM_DELETION) {
      ImportUsersAnalytics.onCloseDeletePopupAnalytics('X')
    }
    closePopup()
  }

  const handleCancelClick = () => {
    ImportUsersAnalytics.onCloseDeletePopupAnalytics('Cancel')
    closePopup()
  }

  const closePopup = () => {
    dispatch(toggleDeleteImportedUsers({ isOpen: false }))
  }

  const handleDeleteClick = async () => {
    setStep(POPUP_STEP.LOADING)

    try {
      await dispatch(deleteManuallyImportedUsers({ idOrg, idApp }))
      changeImportUsersStatus()
      setStep(POPUP_STEP.FINAL)
    } catch (e) {
      setStep(POPUP_STEP.ERROR)
    }
  }

  const changeImportUsersStatus = () => {
    dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_IN_PROGRESS))
    setTimeout(() => {
      dispatch(updateImportAppUsersStatus(IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED))
      dispatch(getServicesSyncData({ idOrg }))
    }, DELETE_USERS_DURATION_SECONDS * 1000)
  }

  const idApp = currentApp?.app?.id

  const popupStepToComponentMap: Record<POPUP_STEP, VFC> = {
    [POPUP_STEP.LOADING]: () => <LoadingStep />,
    [POPUP_STEP.CONFIRM_DELETION]: () => (
      <ConfirmDeletionStep
        idOrg={idOrg}
        idApp={idApp}
        onDeleteClick={handleDeleteClick}
        onCancelClick={handleCancelClick}
      />
    ),
    [POPUP_STEP.FINAL]: () => <FinalStep onConfirmClick={closePopup} />,
    [POPUP_STEP.ERROR]: () => <ErrorStep onCloseClick={closePopup} />
  }

  const Content = popupStepToComponentMap[step]

  return (
    <ToriiPopup
      isOpen
      onCloseAction={handleXClick}
      closeOnOverlayClick={false}>
      <Content />
    </ToriiPopup>
  )
}

export default DeleteImportedUsersPopup
