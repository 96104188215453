import React from 'react'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import MapChart from './mapChart'
import PropTypes from 'prop-types'
import Analytics from '../../../helpers/analytics'
import UsersMapPopup from '../../popups/usersMapPopup'
import VisibleFor from '@components/visibleFor'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const className = 'usersMap'
const CSS = {
  main: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    height: '100%'
  }),
  header: css({
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${colors.border}`
  }),
  container: css({
    height: `calc(100% - ${HEADER_HEIGHT})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })
}

const fetchData = (props) => {
  const { idOrg, getUsersDistribution } = props
  idOrg && getUsersDistribution({ idOrg })
}

class UsersMap extends React.Component {
  state = { isOpen: false }

  componentDidMount () {
    fetchData(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      fetchData(this.props)
    }
  }

  togglePopup = () => {
    const nextPopUpState = !this.state.isOpen
    this.setState({ isOpen: nextPopUpState, country: undefined })
    Analytics.track(`Clicked ${nextPopUpState ? 'show' : 'hide'} users login`, {
    })
  }

  onCountryClick = (country) => {
    this.setState({ isOpen: true, country })
  }

  render () {
    const { data, loading, onCountryClick } = this.props
    const { isOpen, country } = this.state
    const onCountryClickMethod = onCountryClick || ((country) => this.onCountryClick(country))

    const seeAllButton = (
      <VisibleFor scopes={[SCOPES.USERS_READ]}>
        <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.togglePopup} label='See all' />
      </VisibleFor>
    )

    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={CSS.header} outerHoverStyle={'#usersMap:hover &'} header='Users Map' subHeader='Last 30 days usage locations' rightContent={seeAllButton} />
        <div {...CSS.container}>
          <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin showAsDisabled={false}>
            <MapChart data={data} loading={loading} onCountryClick={onCountryClickMethod} />
          </EnableFor>
        </div>
        {isOpen && <UsersMapPopup country={country} isOpen={isOpen} cancel={this.togglePopup} />}
      </div>
    )
  }
}

UsersMap.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool
}

export default UsersMap
