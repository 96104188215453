import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getWorkflowsActionsConfig, getWorkflowsTriggersConfig } from '@shared/actions'

const mapStateToProps = (state, ownProps) => {
  const loading = ownProps.loading
  const forceLoading = ownProps.forceLoading

  return {
    loading: Boolean(forceLoading || (loading && ownProps.apps.length === 0))
  }
}

const mapDispatchToProps = {
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
