import View from './view'
import { connect } from 'react-redux'
import {
  getAppAndDetailsResources,
  getAppStateOptionsForEsFilters,
  getAppStateOrder,
  getAppsWithExtraDataBySystemKey,
  getCatalogAppsWithExtraData,
  isLoadingCatalogApps as isLoadingCatalogAppsSelector
} from '@selectors/apps'
import { getCatalogFiltersOptions } from '@selectors/tables'
import { getCatalogApps, getStateDetails } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const tableInfo = getCatalogFiltersOptions(state)
  const apps = getAppsWithExtraDataBySystemKey(state)
  const stateOptions = getAppStateOptionsForEsFilters(state)
  const stateOrder = getAppStateOrder(state)
  const usersById = getAppAndDetailsResources(state).users
  const catalogApps = getCatalogAppsWithExtraData(state)
  const isLoadingCatalogApps = isLoadingCatalogAppsSelector(state)

  return {
    idOrg,
    tableInfo,
    apps,
    stateOptions,
    stateOrder,
    usersById,
    catalogApps,
    isLoadingCatalogApps
  }
}

const mapDispatchToProps = {
  getCatalogApps,
  getStateDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
