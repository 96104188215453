import { connect } from 'react-redux'
import View from './view'
import { getAppsLicenses } from '../../shared/actions'
import { getCurrentOrg } from '../../selectors/me'
import { getAppsLicensesSelector } from '../../selectors/apps'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const appsLicenses = getAppsLicensesSelector(state)

  return {
    idOrg,
    appsLicenses
  }
}

const mapDispatchToProps = {
  getAppsLicenses
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
