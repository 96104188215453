import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { getContractsFields, getContractsGroups, getUnmatchedContracts } from '@shared/actions/actions'
import UnmatchedContracts from '@root/components/contracts/unmatchedContracts'
import Page from '@root/components/page'
import { withRouter } from 'react-router-dom'
import BackLink from '@root/components/backLink'
import { TABLES } from '@root/constants'
import PageHeader from '@root/components/pageHeader'
import { Spacer, Body1 } from '@toriihq/design-system'

const UnmatchedContractsPage = withRouter(({ match }) => {
  const dispatch = useDispatch()
  const { idOrg } = useParams()
  const idContractToOpenPopup = parseInt(match.params.idContract, 10)

  useEffect(() => {
    if (idOrg) {
      dispatch(getUnmatchedContracts({ idOrg }))
      dispatch(getContractsFields({ idOrg }))
      dispatch(getContractsGroups({ idOrg, idApp: null }))
    }
  }, [idOrg, dispatch])

  return (
    <Page title='Unmatched contracts'>
      <BackLink navigateTo={`/renewals/contracts`} linkText={'Back to Renewals'} />
      <PageHeader title={(
        <div>
          <div>Unmatched contracts</div>
          <Spacer top={'space-200'}>
            <Body1 color='secondary'>Review the following contracts and match an application to each</Body1>
          </Spacer>
        </div>
      )} />
      <UnmatchedContracts idOrg={idOrg} tableKey={TABLES.unmatchedContractsTable.key} idContractToOpenPopup={idContractToOpenPopup} />
    </Page>
  )
})

export default UnmatchedContractsPage
