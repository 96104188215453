import {
  GET_BROWSER_COUNT,
  GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS,
  GET_SSO_AUDIT_REPORT,
  GET_SSO_AUDIT_UNMANAGED_USERS,
  GET_EXTENSION_REPORT_USERS
} from '../constants'

const initialState = {
  extensionStatus: {
    browserExtensionActivationEmail: {
      loading: false,
      loadingMore: false,
      usersCount: 0,
      sampleUsers: []
    },
    browsersCounts: {},
    users: [],
    loading: false,
    total: 0
  },
  ssoAudit: {
    loading: false,
    report: []
  },
  ssoAuditUnmanagedUsers: {
    loading: false,
    users: []
  }
}

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${GET_SSO_AUDIT_REPORT}_PENDING`: {
      return {
        ...state,
        ssoAudit: {
          ...state.ssoAudit,
          loading: true
        }
      }
    }
    case `${GET_SSO_AUDIT_REPORT}_RESPONSE`: {
      const { report } = action.payload
      return {
        ...state,
        ssoAudit: {
          ...state.ssoAudit,
          loading: false,
          report
        }
      }
    }
    case `${GET_SSO_AUDIT_UNMANAGED_USERS}_PENDING`: {
      return {
        ...state,
        ssoAuditUnmanagedUsers: {
          ...state.ssoAuditUnmanagedUsers,
          loading: true
        }
      }
    }
    case `${GET_SSO_AUDIT_UNMANAGED_USERS}_RESPONSE`: {
      const { users } = action.payload
      return {
        ...state,
        ssoAuditUnmanagedUsers: {
          ...state.ssoAuditUnmanagedUsers,
          loading: false,
          users
        }
      }
    }
    case `${GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS}_PENDING`: {
      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          browserExtensionActivationEmail: {
            ...state.extensionStatus.browserExtensionActivationEmail,
            loading: true
          }
        }
      }
    }
    case `${GET_BROWSER_EXTENSION_ACTIVATION_EMAIL_USERS}_RESPONSE`: {
      const { users, total } = action.payload

      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          browserExtensionActivationEmail: {
            loading: false,
            usersCount: total,
            sampleUsers: users
          }
        }
      }
    }

    case `${GET_BROWSER_COUNT}_PENDING`: {
      const { browser } = action.meta

      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          browsersCounts: {
            ...state.extensionStatus.browsersCounts,
            [browser]: {
              loading: true
            }
          }
        }
      }
    }

    case `${GET_BROWSER_COUNT}_RESPONSE`: {
      const { total } = action.payload
      const { browser } = action.meta

      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          browsersCounts: {
            ...state.extensionStatus.browsersCounts,
            [browser]: {
              loading: false,
              count: total
            }
          }
        }
      }
    }

    case `${GET_EXTENSION_REPORT_USERS}_PENDING`: {
      const { reset } = action.meta

      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          loading: true,
          loadingMore: !reset
        }
      }
    }

    case `${GET_EXTENSION_REPORT_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        extensionStatus: {
          ...state.extensionStatus,
          users: reset ? users : (state.extensionStatus.users).concat(users),
          total,
          loading: false,
          loadingMore: false
        }
      }
    }

    default: {
      return state
    }
  }
}

export default reportsReducer
