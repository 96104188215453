import React from 'react'
import { Container, SpinnerImg } from '@components/popups/deleteImportedUsersPopup/loadingStep/styles'
import Spinner from '@media/spinner.svg'

const LoadingStep = (): JSX.Element => (
  <Container>
    <SpinnerImg src={Spinner} />
  </Container>
)

export default LoadingStep
