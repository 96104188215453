import View from './view'
import { connect } from 'react-redux'
import { toggleOffboardingStartedPopup } from '../../../shared/actions'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '../../../selectors/me'
import { EMPTY_ARRAY } from '../../../constants'

const mapStateToProps = state => {
  const { id: idOrg } = getCurrentOrg(state)
  const { users = EMPTY_ARRAY, isOpen } = state.ui.offboardingStartedPopup
  return {
    isOpen,
    users,
    idOrg
  }
}

const mapDispatchToProps = {
  toggleOffboardingStartedPopup
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleOffboardingStartedPopup({ isOpen: false })
  },
  close () {
    dispatchProps.toggleOffboardingStartedPopup({ isOpen: false, isByUser: false })
  }
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps, mergeProps)(View))
