import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, connect, useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { get, groupBy, keyBy, debounce } from 'lodash'
import { TABLES, INTEGRATION_CATEGORY, INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS } from '@root/constants'
import { INTEGRATION_TYPE } from '@shared/types'
import exportCSV from '@helpers/exportCSV'
import Table from '@components/table'
import { getServicesCategory, getServicesConfig, getServicesImageUrl, getUserFields, getWorkflowsActionsConfig, getAppsV2 } from '@shared/actions'
import colors from '@shared/style/colors'
import { withRouter } from 'react-router-dom'
import * as Style from './style'
import OverflowTooltip from '@components/overflowTooltip'
import BackLink from '@components/backLink'
import PageHeader from '@components/pageHeader'
import Page from '@components/page'
import ReactDOM from 'react-dom'
import AppDetails from '@components/appDetails'
import { css } from 'glamor'
import { Icon, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { getSupportedFeatures as getSupportedFeaturesSelector } from '@selectors/org'
import { isSupportsUsage } from '@lenses/utils'
import { getUserPreferences } from '@selectors/ui'
import { getServicesWithConnectStatusMultipleAccounts } from '@selectors/services'
import uniqBy from 'lodash/uniqBy'

const CSS = {
  row: css({}).toString()
}

CSS.hoverActions = css({
  opacity: 0,
  [`.${CSS.row}:hover &, [data-${CSS.row}]:hover &`]: {
    opacity: 1
  }
})

const LIGHT_BLUE_COLUMNS = [
  'actions',
  'capabilities.licenses.enabled',
  'capabilities.usage',
  'capabilities.externalApps',
  'integrationCategory'
]

const IntegrationsTable = (props) => {
  const dispatch = useDispatch()
  const [imageUrls, setImageUrls] = useState({})
  const [loading, setLoading] = useState(true)
  const [allServices, setAllServices] = useState([])
  const [data, setData] = useState([])
  const supportedFeatures = useSelector(getSupportedFeaturesSelector)
  const { defaultCustomSelectOption } = useSelector(getUserPreferences)[TABLES.integrationsTable.key] || {}
  const [customSelectOption, setCustomSelectOption] = useState(defaultCustomSelectOption)
  const [search, setSearch] = useState('')
  const idOrg = props.idOrg || (props.match.params.idOrg && parseInt(props.match.params.idOrg)) || null
  const servicesConnectStatus = useSelector(getServicesWithConnectStatusMultipleAccounts)
  const [apps, setApps] = useState([])
  const appsToConnect = apps.filter(app => app.recommendedToConnect)
  const appsToConnectById = keyBy(appsToConnect, 'id')
  const recommendedServicesIdApps = uniqBy(servicesConnectStatus.filter(service => appsToConnectById[service.idApp]), 'idApp')
    .map(service => service.idApp)
  const connectedServicesIdApps = servicesConnectStatus.filter(service => service.isConnected && service.source !== INTEGRATION_TYPE.CUSTOM)
    .map(service => service.idApp)

  useEffect(() => {
    const getData = async () => {
      const [userFields, actions, services, imageUrls, categories, apps] = await Promise.all([
        dispatch(getUserFields({ idOrg })),
        dispatch(getWorkflowsActionsConfig(idOrg)),
        dispatch(getServicesConfig({ idOrg })),
        dispatch(getServicesImageUrl()),
        dispatch(getServicesCategory()),
        dispatch(getAppsV2({ idOrg }))
      ])
      const userFieldsByIdApp = groupBy(userFields.fields, 'sourceIdApp')
      const actionsByIdApp = keyBy(actions.appsActions, 'idApp')
      const data = services.map(service => {
        const serviceUserFields = userFieldsByIdApp[service.idApp] || []
        const serviceActions = get(actionsByIdApp, [`${service.idApp}`, 'actions'], [])
        const category = get(categories, [service.idApp, 'category'])
        return { ...service, userFields: serviceUserFields, actions: serviceActions, category }
      })

      ReactDOM.unstable_batchedUpdates(() => {
        setAllServices(data)
        setData(data)
        setImageUrls(imageUrls)
        setLoading(false)
        setApps(apps.apps)
      })
    }

    getData()
  }, [dispatch, idOrg])

  useDeepCompareEffect(() => {
    let filteredServices = allServices

    if (customSelectOption.value === INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS.recommended.value) {
      filteredServices = filteredServices.filter(service => recommendedServicesIdApps.includes(service.idApp))
    }

    if (customSelectOption.value === INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS.connected.value) {
      filteredServices = filteredServices.filter(service => connectedServicesIdApps.includes(service.idApp))
    }

    if (search) {
      filteredServices = filteredServices.filter(service => (service.name || service.appName).toLowerCase().includes(search.toLowerCase()))
    }

    setData(filteredServices)
  }, [search, customSelectOption.value, allServices, recommendedServicesIdApps, connectedServicesIdApps])

  const openHelpArticle = (url) => {
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  const getColumns = () => {
    return [
      {
        Header: () => <Style.GroupHeader />,
        columns: [
          {
            Header: () => <div>App name</div>,
            id: 'name',
            accessor: 'name',
            width: 200,
            sticky: 'left',
            Cell: ({ row: { name, idApp, category } }) => {
              const imageUrl = imageUrls[idApp]?.imageUrl
              return <AppDetails id={idApp} name={name} category={category} imageUrl={imageUrl} component='Integrations list' />
            }
          },
          {
            id: 'helpArticle',
            width: 35,
            className: CSS.hoverActions.toString(),
            Cell: ({ row }) => {
              const { connectFormConfiguration, scimConfig } = row
              const url = get(connectFormConfiguration, ['link', 'url']) || get(scimConfig, ['link'])
              return url
                ? (<Style.StyledTooltip label='Open help article'>
                  <Style.DisappearingButton >
                    <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => openHelpArticle(url)} icon='ExternalLink' />
                  </Style.DisappearingButton>
                </Style.StyledTooltip>)
                : null
            }
          },
          {
            Header: () => <div>Category</div>,
            accessor: 'category',
            width: 150,
            Cell: ({ row: { category } }) => {
              return <OverflowTooltip label={category}>{category}</OverflowTooltip>
            }
          },
          {
            Header: () => <div>Multiple<br />accounts</div>,
            accessor: 'supportsMultipleAccounts',
            style: { justifyContent: 'center' },
            Cell: ({ row: { supportsMultipleAccounts } }) => supportsMultipleAccounts ? <Icon
              name='CheckCircleFill' color='interactive' /> : null
          }
        ]
      },
      {
        Header: () => <Style.GroupHeader setBackground />,
        columns: [
          {
            Header: 'Actions',
            accessor: 'actions',
            style: { justifyContent: 'center' },
            Cell: ({ row: { actions } }) => {
              return actions.length ? <Style.StyledTooltip maxHeight={800} maxWidth={600} placement='left' label={<Style.List>{actions.map(action => <Style.ListItem>{action.uiConfig.label}</Style.ListItem>)}</Style.List>}>
                <Icon name='CheckCircleFillPlus' color='interactive' />
              </Style.StyledTooltip> : null
            }
          }
        ]
      },
      {
        Header: () => <Style.GroupHeader >User Data</Style.GroupHeader>,
        columns: [
          {
            Header: 'Role',
            accessor: 'capabilities.users.roles',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => get(capabilities, ['users', 'roles']) ? <Icon name='CheckCircleFill' color='interactive' /> : null
          },
          {
            Header: () => (<div>Status in<br />app</div>),
            accessor: 'capabilities.users.status',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => get(capabilities, ['users', 'status']) ? <Icon name='CheckCircleFill' color='interactive' /> : null
          },
          {
            Header: () => (<div>Last Used<br />Date</div>),
            accessor: 'capabilities.users.lastUsedDate',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => get(capabilities, ['users', 'lastUsedDate']) ? <Icon name='CheckCircleFill' color='interactive' /> : null
          },
          {
            Header: () => (<div>Additional<br />Fields </div>),
            accessor: 'userFields',
            style: { justifyContent: 'center' },
            Cell: ({ row: { userFields } }) => {
              return userFields.length ? <Style.StyledTooltip maxHeight={600} maxWidth={600} position='top' label={<Style.List>{userFields.map(field => <Style.ListItem>{field.name}</Style.ListItem>)}</Style.List>} >
                <Icon name='CheckCircleFillPlus' color='interactive' />
              </Style.StyledTooltip> : null
            }
          },
          {
            Header: () => (<div>Real-Time<br />Events</div>),
            accessor: 'capabilities.users.realTimeEvents',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => {
              const realTimeEvents = capabilities?.users?.realTimeEvents
              if (!realTimeEvents) {
                return null
              }

              const tooltipLabel = <Style.List>
                {realTimeEvents.map(eventName => <Style.ListItem>{eventName}</Style.ListItem>)}
              </Style.List>

              return <Style.StyledTooltip maxHeight={600} maxWidth={600} position='top' label={tooltipLabel} >
                <Icon name='CheckCircleFillPlus' color='interactive' />
              </Style.StyledTooltip>
            }
          }
        ]
      },
      {
        Header: () => <Style.GroupHeader setBackground>App Data</Style.GroupHeader>,
        columns: [
          {
            Header: 'Licenses',
            accessor: 'capabilities.licenses.enabled',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => {
              const licenses = get(capabilities, ['licenses'], {})
              if (!licenses.enabled) {
                return null
              }
              return licenses.supportsLastVisit ? <Style.StyledTooltip label='Last visit date supported'><Icon name='CheckCircleFillPlus' color='interactive' />
              </Style.StyledTooltip> : <Icon name='CheckCircleFill' color='interactive' />
            }
          },
          {
            Header: 'Usage',
            accessor: 'capabilities',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => {
              const isUsageEnabled = isSupportsUsage({ capabilities, supportedFeatures })
              if (!isUsageEnabled) {
                return null
              }

              const usage = get(capabilities, ['usage'], {})
              return usage.range || usage.comments ? <Style.StyledTooltip label={<div>{usage.range} days back on <strong>first</strong> sync<br />{usage.comments || ''}</div>}>
                <Icon name='CheckCircleFillPlus' color='interactive' /></Style.StyledTooltip> : <Icon name='CheckCircleFill' color='interactive' />
            },
            sortMethod: (firstCapabilities = {}, secondCapabilities = {}) => {
              const isFirstEnabled = isSupportsUsage({ capabilities: firstCapabilities, supportedFeatures })
              const isSecondEnabled = isSupportsUsage({ capabilities: secondCapabilities, supportedFeatures })
              if (!isFirstEnabled && !isSecondEnabled) {
                return 0
              }
              if (isFirstEnabled !== isSecondEnabled) {
                return isFirstEnabled ? 1 : -1
              }

              const firstRange = get(firstCapabilities, 'usage.range', 0)
              const secondRange = get(secondCapabilities, 'usage.range', 0)
              return firstRange - secondRange
            }
          },
          {
            Header: () => (<div>3rd Party<br />Apps</div>),
            accessor: 'capabilities.externalApps',
            style: { justifyContent: 'center' },
            Cell: ({ row: { capabilities } }) => get(capabilities, ['externalApps']) ? <Icon name='CheckCircleFill' color='interactive' /> : null
          },
          {
            Header: 'Expenses',
            accessor: 'integrationCategory',
            style: { justifyContent: 'center' },
            Cell: ({ row: { integrationCategory } }) => integrationCategory === INTEGRATION_CATEGORY.EXPENSE_SOFTWARE ? <Icon name='CheckCircleFill' color='interactive' /> : null
          },
          {
            Header: 'Contracts',
            accessor: 'integrationCategory',
            style: { textAlign: 'center' },
            Cell: ({ row: { integrationCategory } }) => integrationCategory === INTEGRATION_CATEGORY.CONTRACT_MANAGEMENT_SOFTWARE ? <Icon name='CheckCircleFill' color='interactive' /> : null
          }
        ]
      },
      {
        accessor: 'integrationCategory',
        show: false
      },
      {
        accessor: 'category',
        show: false
      },
      {
        accessor: 'actions',
        show: false
      },
      {
        accessor: 'logo',
        show: false
      },
      {
        accessor: 'idApp',
        show: false
      },
      {
        accessor: 'capabilities',
        show: false
      },
      {
        accessor: 'connectFormConfiguration',
        show: false
      },
      {
        accessor: 'scimConfig',
        show: false
      }
    ]
  }

  const exportToCsv = async () => {
    const columnNames = [
      'App Name',
      'Help article',
      'Integration Category',
      'Supports Multiple Accounts',
      'User Role',
      'User Status',
      'Last Used Date',
      'User Fields',
      'Real-Time Events',
      'Licenses',
      'Usage',
      '3rd Party Apps',
      'Expenses',
      'Actions'
    ]
    const sortedServices = data.sort((a, b) => (a.name.localeCompare(b.name, 'en')))
    const dataForCSV = sortedServices.map(service => {
      const usage = get(service, ['capabilities', 'usage'], {})
      const isUsageEnabled = isSupportsUsage({ capabilities: get(service, 'capabilities', {}), supportedFeatures })
      const helpArticleLink = get(service, ['connectFormConfiguration', 'link', 'url']) || get(service, ['scimConfig', 'link'])
      const licenses = get(service, ['capabilities', 'licenses'], {})
      const hasLicense = licenses.enabled
      const supportsLastVisit = licenses.supportsLastVisit
      const licensesString = `${hasLicense ? (`TRUE${supportsLastVisit ? ', Last visit date supported' : ''}`) : 'FALSE'}`

      const users = service['capabilities']?.users

      return {
        'App Name': service.name,
        'Help article': helpArticleLink || '',
        'Integration Category': get(service, ['category']),
        'Supports Multiple Accounts': service.supportsMultipleAccounts || false,
        'User Role': users?.roles ?? false,
        'User Status': users?.status ?? false,
        'Last Used Date': users?.lastUsedDate ?? false,
        'User Fields': get(service, ['userFields'], []).map(field => field.name).join(', '),
        'Real-Time Events': users?.realTimeEvents?.join(', ') ?? '',
        'Licenses': licensesString,
        'Usage': isUsageEnabled ? `${usage.range} days back on first sync` : 'Not supported',
        '3rd Party Apps': get(service, ['capabilities', 'externalApps']) || false,
        'Expenses': service.integrationCategory === INTEGRATION_CATEGORY.EXPENSE_SOFTWARE,
        'Actions': get(service, ['actions'], []).map(action => action.uiConfig.label).join(', ')
      }
    })

    exportCSV(`Torii_Integrations_${moment().format('MMMM_DD_YYYY')}.csv`, dataForCSV, columnNames)
  }

  const onSearch = debounce((q) => {
    setSearch(q)
  }, 500)

  const onCustomSelectOptionChange = async (option) => {
    setCustomSelectOption(option)
  }

  return (
    <Page>
      <BackLink navigateTo={'/services'} linkText={'Back to Integrations'} overrideStyle={{ margin: '20px 0 15px 0' }} />
      <PageHeader title={'Integrations Capabilities'} />
      <Table
        tableKey={TABLES.integrationsTable.key}
        getTheadThProps={injectTheadThStyle}
        getTrGroupProps={setTrGroupStyle}
        getTdProps={setTdStyle}
        data={data}
        groupedHeaders
        columns={getColumns()}
        totalCount={data.length}
        forceShowNumberOfResults
        getTbodyProps={() => ({ style: { maxHeight: '100vh' } })}
        loading={loading}
        searchable
        onSearch={onSearch}
        forceShowSearch
        exportable
        exportFunction={exportToCsv}
        customSelect
        customSelectOptions={Object.values(INTEGRATIONS_TABLE_CUSTOM_SELECT_OPTIONS)}
        customSelectSelectedValue={customSelectOption}
        customSelectOnChange={onCustomSelectOptionChange}
      />
    </Page>
  )
}

const setTrGroupStyle = () =>
  ({
    className: CSS.row.toString()
  })

const setTdStyle = (state, rowInfo, column) => {
  let specificStyle = {}

  if (LIGHT_BLUE_COLUMNS.includes(column.id)) {
    specificStyle.height = '60px'
    specificStyle.padding = '21px 20px'
    specificStyle.backgroundColor = `${colors.lightBlue3}`
  }

  if (['helpArticle'].includes(column.id)) {
    specificStyle.padding = 0
  }

  return {
    style: {
      alignItems: 'center',
      display: 'flex',
      padding: '10px 20px',
      color: colors.black,
      ...specificStyle
    }
  }
}

const injectTheadThStyle = (table, _, column) => {
  let specificStyle = {}
  const sortOrder = table.sorted.find(sorted => sorted.id === column.id)

  if (LIGHT_BLUE_COLUMNS.includes(column.id)) {
    specificStyle.background = `${colors.lightBlue3}`
  }

  if (['name', 'category'].includes(column.id)) {
    specificStyle.justifyContent = 'flex-start'
    specificStyle.padding = '21px 20px'
  }

  return {
    style: {
      alignItems: 'center',
      justifyContent: 'center',
      color: sortOrder && colors.darkText,
      padding: '10px',
      border: 0,
      display: 'flex',
      boxShadow: 'none',
      ...specificStyle
    },
    sortOrder: sortOrder && (sortOrder.desc ? 'desc' : 'asc')

  }
}

export default withRouter(connect(null, null)(IntegrationsTable))
