import View from './view'
import { connect } from 'react-redux'
import { createSourceToken, getServicesSyncData } from '@shared/actions'
import { getUsedApps } from '@selectors/apps'
import { getServicesWithConnectStatusMultipleAccounts } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import { INTEGRATION_TYPE } from '@shared/types'

const BITBUCKET_ID_APP = 2495
const JIRA_ID_APP = 102
const CONFLUENCE_ID_APP = 1259
const blacklistedApps = [BITBUCKET_ID_APP, JIRA_ID_APP, CONFLUENCE_ID_APP]

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const { idApp, appName } = ownProps
  const apps = getUsedApps(state)
  const servicesWithConnectStatus = getServicesWithConnectStatusMultipleAccounts(state)
  const connectedAndAPIIntegrationsIdApps = servicesWithConnectStatus
    .filter(service => service.isConnected || service.integrationType !== INTEGRATION_TYPE.TORII_BOT)
    .map(service => service.idApp)
  const allOrgApps = apps
    .filter(app => !connectedAndAPIIntegrationsIdApps.includes(app.id) && !blacklistedApps.includes(app.id))
    .map(app => ({ label: app.name, value: app.id }))
  const singleAppSelection = [{ label: appName, value: idApp }]
  const appOptions = idApp ? singleAppSelection : allOrgApps

  return {
    idOrg,
    appOptions
  }
}

const mapDispatchToProps = {
  onConnect: createSourceToken,
  getServicesSyncData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
