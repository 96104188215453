import send from '@shared/redux-fetch'
import { GET_RENEWALS_SUMMARY } from '@root/constants.t'
import { GetRenewalsSummary } from '@shared/actions/contracts/types'

export const getRenewalsSummary = ({ idOrg }: GetRenewalsSummary) => {
  return send(GET_RENEWALS_SUMMARY, {
    url: `/api/orgs/${idOrg}/contracts/renewalsSummary`,
    method: 'GET'
  })
}
