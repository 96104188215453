import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '../ToriiPopupV2'
import * as Style from './style'
import SelectUsers from '@components/selectUsers'
import { User } from './types'
import { compact, get, isEmpty } from 'lodash'
import { APP_OWNERS_FIELDS, SCOPES } from '@root/constants'
import PropTypes from 'prop-types'
import {
  getAppDetailsFields,
  getLoadingSuggestedOwners,
  getOwnersByIdApp,
  getSuggestedOwners as getSuggestedOwnersSelector
} from '@root/selectors/apps'
import { getSelf } from '@root/selectors/me'
import { assignOwners, getAppDetailsValues, getSuggestedOwners } from '@root/shared/actions'
import { getFieldBySystemKey } from '@root/lenses/apps'
import { getIdOrg, getIsAppOwnerActive, isOrgLoading as isOrgLoadingSelector } from '@root/selectors/org'
import { useParamSelector } from '@root/shared/utils'
import Placeholder from '@components/placeholder'
import { Link } from '@toriihq/design-system'

export type Props = {
  idApp: number
  isOpen: boolean
  onCancel: () => void
  onSubmitSuccess: (selectedOwners: {
    selectedPrimaryOwner: User
    selectedAdditionalOwners: User[]
  }) => void
}

export default function AssignAppOwners ({
  idApp,
  isOpen,
  onCancel,
  onSubmitSuccess
}: Props) {
  const dispatch = useDispatch()
  const currentUser = useSelector(getSelf)
  const isAppOwnerActive = useSelector(getIsAppOwnerActive)
  const appDetailsFields = useSelector(getAppDetailsFields)
  const idOrg = useSelector(getIdOrg)
  const suggestedOwners = useSelector(getSuggestedOwnersSelector)
  const isOrgLoading = useSelector(isOrgLoadingSelector)
  const isSuggestedOwnersLoading = useSelector(getLoadingSuggestedOwners)
  const loading = isOrgLoading || isSuggestedOwnersLoading
  const appOwners = useParamSelector(getOwnersByIdApp, { idApp })
  const existingPrimaryOwner = appOwners[APP_OWNERS_FIELDS.primary]?.values?.[0] || null
  const existingAppOwners = (appOwners[APP_OWNERS_FIELDS.appOwners]?.values || []).filter(owner => {
    if (!owner || isEmpty(owner)) {
      return false
    }

    if (!existingPrimaryOwner) {
      return true
    }

    return owner.id !== existingPrimaryOwner.id
  }) || []

  const [isFieldsDirty, setIsFieldsDirty] = useState<boolean>(false)
  const [selectedPrimaryOwner, setSelectedPrimaryOwner] =
    useState<User | null>(existingPrimaryOwner)
  const [selectedAdditionalOwners, setSelectedAdditionalOwners] =
    useState<User[]>(existingAppOwners)
  const [filteredAdditionalOwners, setFilteredAdditionalOwners] =
    useState<User[]>(compact([existingPrimaryOwner]))

  useEffect(() => {
    dispatch(getSuggestedOwners({ idOrg, idApp }))
  }, [idOrg, idApp, dispatch])

  function onSelectPrimaryOwner (selectedUser: User) {
    setSelectedPrimaryOwner(selectedUser)
    setFilteredAdditionalOwners(compact([selectedUser]))
    setIsFieldsDirty(true)
  }

  function onSelectAdditionalOwners (selectedUsers: User[]) {
    setSelectedAdditionalOwners(selectedUsers)
    setIsFieldsDirty(true)
  }

  async function submitAssignOwners () {
    const selectedAdditionalOwnersWithPrimary = selectedPrimaryOwner ? [selectedPrimaryOwner, ...selectedAdditionalOwners] : selectedAdditionalOwners

    const appOwnerField = getFieldBySystemKey({
      fields: appDetailsFields,
      systemKey: APP_OWNERS_FIELDS.appOwners
    })

    const primaryAppOwnerField = getFieldBySystemKey({
      fields: appDetailsFields,
      systemKey: APP_OWNERS_FIELDS.primary
    })

    const fieldsValues = [
      {
        idField: appOwnerField.idField,
        values: selectedAdditionalOwnersWithPrimary.map(({ id }) => id)
      },
      {
        idField: primaryAppOwnerField.idField,
        values: [get(selectedPrimaryOwner, ['id'], null)]
      }
    ]
    await dispatch(assignOwners({
      fieldsValues,
      idApp,
      idOrg,
      user: currentUser
    }))
    await dispatch(getAppDetailsValues({ idOrg, idApps: [idApp], idFields: null, systemKeys: null }))

    onSubmitSuccess({
      selectedPrimaryOwner: selectedPrimaryOwner!,
      selectedAdditionalOwners
    })
  }

  const documentationLink = 'https://support.toriihq.com/hc/en-us/articles/10782531198619--App-Owner-Access-For-New-App-Owners'
  const subHeader = (
    <Style.DescriptionLine>
      <Style.DescriptionText>
        {
          isAppOwnerActive
            ? 'The app owners you assign will get access to Torii to manage the apps they own.'
            : 'The app owners you assign will not get access to Torii to manage the apps they own.'
        }
      </Style.DescriptionText>{' '}
      <Link href={documentationLink} target='_blank'>Learn more</Link>
    </Style.DescriptionLine>
  )

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCancel}>
      <ToriiPopup.Header header={'Assign app owners'} subHeader={subHeader} />
      <ToriiPopup.Content>
        <Style.SelectSlot>
          <Style.SelectLabel>
            Primary app owner
          </Style.SelectLabel>
          <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '30px', maxWidth: '100%' }}>
            <SelectUsers
              placeholder='Search users'
              openOnFocus
              excludePastUsers
              value={selectedPrimaryOwner}
              onChange={onSelectPrimaryOwner}
              closeOnSelect
              specialUsers={suggestedOwners[idApp]}
            />
          </Placeholder>
        </Style.SelectSlot>
        <Style.SelectSlot>
          <Style.SelectLabel>Additional app owners</Style.SelectLabel>
          <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '30px', maxWidth: '100%' }}>
            <SelectUsers
              placeholder='Search users'
              openOnFocus
              excludePastUsers
              valueKey='firstName'
              multi
              value={selectedAdditionalOwners}
              onChange={onSelectAdditionalOwners}
              closeOnSelect={false}
              excludedOptions={filteredAdditionalOwners}
              specialUsers={suggestedOwners[idApp]}
            />
          </Placeholder>
        </Style.SelectSlot>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonText={'Cancel'}
        cancelButtonAction={() => onCancel()}
        mainButtonText={'Assign owners'}
        mainButtonAction={() => submitAssignOwners()}
        isMainButtonDisabled={!isFieldsDirty}
        mainButtonVisibilityScopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]} />
    </ToriiPopup>
  )
}

AssignAppOwners.propTypes = {
  idApp: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired
}
