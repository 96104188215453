import React, { Fragment, useEffect, useState } from 'react'
import qs from 'query-string'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import ApplicationsComparisonTable from './components/applicationsComparisonTable'
import ActiveUsers from './components/activeUsers'
import Usage from './components/usage'
import UsersOverlaps from './components/usersOverlaps'
import uniq from 'lodash/uniq'
import { getComparisonIdApps } from '@selectors/apps'
import Analytics from '@helpers/analytics'
import { customJoin } from '@lenses/utils'
import pluralize from 'pluralize'
import ComparisonRecommendationsTable from '@pages/appsComparison/components/comparisonRecommendationsTable'
import { getServicesConfig, getServicesSyncData, toggleConnectSource, updateComparisonIdApps } from '@shared/actions'
import * as Style from './style'
import { getServicesWithConnectStatusMultipleAccounts, getConstantServices as getConstantServicesSelector } from '@selectors/services'
import { getServiceInfo } from '@shared/services'
import ConnectDirectIntegrationPopup from '@components/popups/connectDirectIntegrationPopup'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@root/constants'
import { analyticsTrackConnectAppAccountClick } from '@shared/services/openConnectServicePopup/analytics'
import { Button, ButtonType, ButtonSize, Link, AlertBox, AlertBoxType, Stack } from '@toriihq/design-system'
import { getTestConnectionInfo } from '@shared/actions/testConnection'
import { validTestConnectionInProgress } from '@components/testConnection/utils'

const setURLIdAppsQueryParams = (idApps) => {
  window.history.replaceState(null, '', `${window.location.pathname}${idApps.length >= 2 ? `?idApps=${idApps.join(',')}` : ''}`)
}

const isQueryValid = query => {
  return /^[-,0-9]+$/.test(query.idApps)
}
const getURLIdApps = () => {
  const query = qs.parse(window.location.search)
  return query.idApps && isQueryValid(query) ? uniq(query.idApps.split(',').slice(0, 4).map(Number)) : []
}

const AppsComparisonPage = () => {
  const [showRecommendations, setShowRecommendations] = useState(true)
  const [services, setServices] = useState(EMPTY_ARRAY)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const comparisonIdApps = useSelector(getComparisonIdApps)
  const servicesWithConnectStatus = useSelector(getServicesWithConnectStatusMultipleAccounts)
  const config = useSelector(getConstantServicesSelector)
  const isConfigLoaded = config.length > 0

  const dispatch = useDispatch()

  const idApps = React.useMemo(() => {
    const URLIdApps = getURLIdApps()

    if (URLIdApps.length === 0 && comparisonIdApps.length >= 2) {
      setURLIdAppsQueryParams(comparisonIdApps)
      setShowRecommendations(false)
    } else if (URLIdApps.length >= 2) {
      setShowRecommendations(false)
    } else if (!isQueryValid(window.location.search)) {
      setURLIdAppsQueryParams([])
    }

    return URLIdApps.length === 0 ? comparisonIdApps : URLIdApps
  }, [comparisonIdApps])

  const onAppsChange = (idApps, showRecommendationsPage) => {
    setURLIdAppsQueryParams(idApps)
    dispatch(updateComparisonIdApps(idApps))
    if (showRecommendationsPage !== showRecommendations) {
      setShowRecommendations(showRecommendationsPage)
    }
  }

  useEffect(() => {
    const services = idApps.map(idApp => getServiceInfo({ services: servicesWithConnectStatus, idApp }))
    setServices(services)
  }, [idApps, servicesWithConnectStatus])

  useEffect(() => {
    const getServices = async ({ idOrg }) => {
      await Promise.all([
        dispatch(getServicesSyncData({ idOrg })),
        dispatch(getServicesConfig({ idOrg }))
      ])
    }

    if (idOrg) {
      getServices({ idOrg })
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    const fetchTestConnectionInfo = async () => {
      const urlParams = new URLSearchParams(window.location.search)
      const idTestConnection = urlParams.get('idTestConnection')
      const idApp = urlParams.get('idApp')

      if (idTestConnection && idApp) {
        const testConnectionInfo = await dispatch(getTestConnectionInfo({ idTestConnection, idOrg, idApp }))
        const validTestInProgress = validTestConnectionInProgress(idOrg, parseInt(idApp), testConnectionInfo)
        if (validTestInProgress) {
          dispatch(toggleConnectSource({ isConnectSourceOpen: true, sourceIdApp: parseInt(idApp), validTestInProgress }))
        }
      }
    }

    if (idOrg && isConfigLoaded) {
      fetchTestConnectionInfo()
    }
  }, [dispatch, idOrg, isConfigLoaded])

  useEffect(() => {
    return () => {
      dispatch(updateComparisonIdApps([]))
    }
  }, [dispatch])

  const onReadMoreClick = (Widget) => {
    Analytics.track('Click on Read more link / Comparison page / Compare tab / Applications', { Widget })
  }

  const onReadHowToSyncUsage = (usageType, appName) => {
    Analytics.track(`Click on Read how to sync app usage`, {
      'UI area': `${usageType} chart / app comparison page`,
      'Application': appName
    })
  }

  const openConnectPopup = (idApp, appName, source) => {
    analyticsTrackConnectAppAccountClick({
      appName,
      calledFrom: 'App comparison page',
      integrationTypeText: 'Native'
    })
    dispatch(toggleConnectSource({ isConnectSourceOpen: true, sourceId: source, sourceIdApp: idApp }))
  }

  const getConnectedAppUsageAlert = (WidgetName, supportsUsageServices, getAppUsageSources) => {
    const connectedServicesWithDisabledUsage = supportsUsageServices.filter(service => {
      const { isConnected, idApp, source } = service
      const usageSources = getAppUsageSources(idApp)
      return isConnected && !usageSources.includes(source)
    })

    return Boolean(connectedServicesWithDisabledUsage.length) && (
      <div>
        {`Get more insights by syncing ${customJoin(connectedServicesWithDisabledUsage.map(s => s.appName))} ${WidgetName} directly from API. `}
        {connectedServicesWithDisabledUsage.map(s => s.helpCenterLink && (
          <Link key={s.idApp} href={s.helpCenterLink} onClick={() => onReadHowToSyncUsage(WidgetName, s.appName)} target='_blank'>
            {`Read how to sync ${s.appName} ${WidgetName}. `}
          </Link>
        ))}
      </div>
    )
  }

  const getNonConnectedAppUsageAlert = (WidgetName, supportsUsageServices, getAppUsageSources) => {
    const nonConnectedServicesWithUsage = supportsUsageServices.filter(service => {
      const { isConnected, idApp } = service
      const usageSources = getAppUsageSources(idApp)
      return !isConnected && usageSources.length
    })

    return Boolean(nonConnectedServicesWithUsage.length) && (
      <Stack gap={'space-050'} direction={'row'}>
        {`Get more insights by connecting ${customJoin(nonConnectedServicesWithUsage.map(s => s.appName))} ${pluralize('integration', nonConnectedServicesWithUsage.length)} and syncing ${WidgetName} data from API. `}
        {nonConnectedServicesWithUsage.map(s => (
          <Fragment key={s.idApp}>
            <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => openConnectPopup(s.idApp, s.appName, s.source)} label={`Connect ${s.appName}.`} />
          </Fragment>
        ))}
      </Stack>
    )
  }

  const getExtensionOnlyAlert = (WidgetName, extensionOnlyApps) => Boolean(extensionOnlyApps.length) &&
    <div>
      {`Data for ${customJoin(extensionOnlyApps.map(app => app.name))} comes only from browser extension. To get richer insights, connect the ${pluralize('app', extensionOnlyApps.length)} to your SSO. `}
      <Link href='https://support.toriihq.com/hc/en-us/articles/5129309596955' onClick={() => onReadMoreClick(WidgetName)} target='_blank'>Read more in Torii help center</Link>
    </div>

  const getAlerts = (services = EMPTY_ARRAY) => (Widget, usageSourcesByIdApp = EMPTY_OBJECT, extensionAppsWithData = EMPTY_ARRAY) => {
    const supportsUsageServices = services.filter(service => service.isNativeUsersIntegration && service.supportsUsage)
    const getAppUsageSources = (idApp) => (usageSourcesByIdApp[idApp] || EMPTY_OBJECT).usageSources || EMPTY_ARRAY

    const connectedAppUsageAlert = getConnectedAppUsageAlert(Style.MAP_WIDGET_TO_NAME[Widget], supportsUsageServices, getAppUsageSources)
    if (connectedAppUsageAlert) {
      return <AlertBox type={AlertBoxType.INFORMATIVE} description={connectedAppUsageAlert} />
    }

    const nonConnectedAppUsageAlert = getNonConnectedAppUsageAlert(Style.MAP_WIDGET_TO_NAME[Widget], supportsUsageServices, getAppUsageSources)
    if (nonConnectedAppUsageAlert) {
      return <AlertBox type={AlertBoxType.INFORMATIVE} description={nonConnectedAppUsageAlert} />
    }

    const extensionOnlyAlert = getExtensionOnlyAlert(Widget, extensionAppsWithData)
    if (extensionOnlyAlert) {
      return <AlertBox type={AlertBoxType.INFORMATIVE} description={extensionOnlyAlert} />
    }

    return null
  }

  const getPopup = () => <ConnectDirectIntegrationPopup idApps={idApps} />

  const comparisonPage = <>
    <ApplicationsComparisonTable idOrg={idOrg} idApps={idApps} onAppsChange={onAppsChange} />
    {idApps.length >= 2 && <div>
      <div {...Style.WidgetContainer}>
        <ActiveUsers idOrg={idOrg} idApps={idApps} getAlerts={getAlerts(services)} />
      </div>
      <div style={{ marginBottom: '50px' }} />
      <div {...Style.WidgetContainer}>
        <Usage idOrg={idOrg} idApps={idApps} getAlerts={getAlerts(services)} />
      </div>
      <div style={{ marginBottom: '50px' }} />
      <div {...Style.WidgetContainer}>
        <UsersOverlaps idApps={idApps} idOrg={idOrg} />
      </div>
      <div style={{ height: '50px' }} />
    </div>}
    {getPopup()}
  </>

  const recommendationsPage = <ComparisonRecommendationsTable idOrg={idOrg} onAppsChange={onAppsChange} />

  return showRecommendations ? recommendationsPage : comparisonPage
}

export default AppsComparisonPage
