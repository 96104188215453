import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import ToriiPopup from '../ToriiPopupV2'
import colors from '../../../shared/style/colors'
import { Field } from 'react-final-form'
import SelectApps from '../../selectApps'
import FormGroup from '../../form/formGroup'
import Input from '../../form/input'
import FormField from '../../workflows/formField'
import moment from 'moment'
import numeral from 'numeral'
import CurrencySymbol from '../../currencySymbol'
import { fontSize, fontWeight } from '../../../shared/style/sizes'
import { formFieldTypes, TRANSACTION_MAPPING_STATUS } from '@root/constants'

const CSS = {
  info: css({
    fontWeight: 400
  }),
  details: css({
    marginTop: '5px',
    fontSize: fontSize.small,
    color: colors.grey1
  }),
  badge: css({
    borderRadius: '2px',
    padding: '2px 3px',
    color: colors.white,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    margin: '0 4px',
    backgroundColor: colors.darkBlue
  })
}
class EditTransactionPopup extends React.Component {
  submitForm = async ({ app, date, modifiedAmount }) => {
    const { submitButton, cancelButton, autoHideOnSubmit } = this.props

    await submitButton.onClick({ app: app || {}, date, modifiedAmount: Math.floor(modifiedAmount * 100), amount: this.getConvertedAmount(modifiedAmount * 100) })
    if (autoHideOnSubmit) {
      setTimeout(() => cancelButton.onClick(false), 1000)
    }
  }

  selectApp = ({ input }) => {
    const { input: { app, mappingStatus } } = this.props
    let initialOptions
    if (app && [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP, TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE].includes(mappingStatus)) {
      initialOptions = [{
        badge: <div {...CSS.badge}>Potential match</div>,
        ...app
      }]

      delete input.value
    }
    return <SelectApps disableHiddenApps initialOptions={initialOptions} {...input} />
  }

  getConvertedAmount = (value) => {
    const { modifiedAmount = 1, amount = 1 } = this.props.input
    const rate = amount / modifiedAmount
    return value * rate
  }

  shouldShowConvertedAmount = () => {
    const { currency } = this.props
    const { origCurrency } = this.props.input
    return currency !== origCurrency
  }

  InputWithPrefixField = ({ label, prefix, disabled, input, meta, ...props }) => {
    return (
      <FormGroup label={label} disabled={disabled} error={meta.touched && meta.error}>
        <div>
          <Input
            prefix={prefix}
            {...props}
            {...input}
          />
          {this.shouldShowConvertedAmount() &&
            <div {...CSS.details}>
              {`Converted to ${this.props.currency}: `}
              <CurrencySymbol />
              {numeral(this.getConvertedAmount(input.value)).format('0,0.00')}
            </div>
          }
        </div>
      </FormGroup>
    )
  }
  render () {
    const { cancelButton, submitButton, header, isOpen, input: { label, app, origDate, date, description, modifiedAmount, origAmount, origCurrency }, editMode } = this.props
    return <ToriiPopup
      isOpen={isOpen}
      onCloseAction={cancelButton.onClick}
    >
      <ToriiPopup.Header header={header} />
      <ToriiPopup.Form
        onSubmit={this.submitForm}
        initialValues={{ app, date: new Date(date), modifiedAmount: modifiedAmount / 100 }}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              <FormGroup label='Original info:' >
                <div {...CSS.info}>
                  <div>Description: {description}</div>
                  <div>Transaction date: {moment(origDate).format('MM/DD/YYYY')}</div>
                  <div>Amount: {`${origCurrency} ${numeral(origAmount / 100).format('0,0.00')}`}</div>
                </div>
              </FormGroup>
              <FormGroup label={label} >
                <Field name='app' component={this.selectApp} autoFocus={!editMode} />
              </FormGroup>
              <FormGroup label='Transaction date' >
                <Field name='date' component={FormField.mapping[formFieldTypes.datePicker].component} />
              </FormGroup>
              <FormGroup label='Amount' >
                <Field
                  name='modifiedAmount'
                  prefix={origCurrency}
                  type='number'
                  component={this.InputWithPrefixField}
                />
              </FormGroup>
            </div>
          </form>
        }}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            cancelButtonText={cancelButton.label}
            mainButtonText={submitButton.label}
            isMainSubmit
            formProps={formProps}
            isMainButtonDisabled={!formProps.valid}
          />
        )}
      />
    </ToriiPopup>
  }
}

EditTransactionPopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  cancelButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  submitButton: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  }).isRequired,
  input: PropTypes.shape({
    label: PropTypes.string.isRequired,
    idApp: PropTypes.number
  }).isRequired,
  header: PropTypes.string,
  autoHideOnSubmit: PropTypes.bool
}

EditTransactionPopup.defaultProps = {
  editMode: false,
  autoHideOnSubmit: true
}

export default EditTransactionPopup
