import styled from 'styled-components'

import colors from '../../../shared/style/colors'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Name = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.black};
  `

export const Expenses = styled.span`
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    color: ${colors.grey2};
    
`
