import React, { ReactElement } from 'react'
import { BranchLabelContent } from './branchLabelContent'
import { BRANCH_TYPE } from './types'
import { DEFAULT_BRANCH_LABEL } from '@pages/workflow_v2/workflowEditor/consts'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'
import { NodeProps } from 'reactflow'
import { DefaultBranchData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'

type Props = NodeProps<DefaultBranchData>

export const DefaultBranchLabel = (props: Props): ReactElement => {
  const { idBranchingNode } = props.data

  const workflow = useSelectedWorkflow()

  const { getActionConfiguration } = useActionsConfiguration({ workflow })
  const branchesConfig = getActionConfiguration(idBranchingNode)?.branchesConfiguration
  const defaultBranchLabel = branchesConfig?.default?.label ?? DEFAULT_BRANCH_LABEL

  return <BranchLabelContent branchType={BRANCH_TYPE.DEFAULT_BRANCH} label={defaultBranchLabel} />
}
