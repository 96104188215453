import React from 'react'
import colors from '../../../shared/style/colors'
import { css } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import BarsChart from '../../barsChartBox/chart'
import { oneLiner } from '../../../shared/style/mixins'
import pluralize from 'pluralize'
import ResizeDetector from 'react-resize-detector'
import PropTypes from 'prop-types'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import Currency from '@root/components/currency'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const CSS = {
  main: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  header: css({
    minHeight: '50px',
    padding: `7px ${BOX_PADDING} 0 ${BOX_PADDING}`,
    borderBottom: `1px solid ${colors.border}`
  }),
  chartsContainer: css({
    height: `calc(100% - ${HEADER_HEIGHT})`
  }),
  chart: css({
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  totalAnnualPrice: css(oneLiner, {
    color: colors.black,
    lineHeight: '35px',
    display: 'flex',
    flexDirection: 'row'
  }),
  totalContractsCount: css({
    paddingRight: '3px'
  })
}

const className = 'annualRenewals'

class AnnualRenewals extends React.Component {
  state = { rotateXAxis: false }

  onResize = (width) => {
    this.setState({ rotateXAxis: width < 560 })
  }

  render () {
    const { loading, renewalsBarChartData, totalAnnualPrice, totalContractsCount } = this.props
    const { rotateXAxis } = this.state
    const totalAnnualPriceDiv = <div {...CSS.totalAnnualPrice} >
      {totalContractsCount ? <div {...CSS.totalContractsCount}>{`${totalContractsCount} ${pluralize('Contract', totalContractsCount)},`}</div> : null}
      <Currency value={totalAnnualPrice} />
    </div>

    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={CSS.header} header='renewals' subHeader='Next 12 months. Contracts with renewal costs' rightContent={totalAnnualPriceDiv} overrideRightContentStyle={{ opacity: 1 }} />
        <div {...CSS.chartsContainer}>
          <ResizeDetector handleWidth onResize={this.onResize} />
          <div {...CSS.chart}>
            <BarsChart
              tooltipOverrideStyle={{ position: { y: 0 } }}
              loading={loading}
              data={renewalsBarChartData}
              fill={colors.purple}
              formatter={value => <Currency value={value} format={'$0,0'} />}
              barSize={10}
              chartHeight={220}
              tooltipType={CONTENT_TOOLTIP_TYPES.CATEGORIES}
              interval={0}
              rotateXAxis={rotateXAxis}
              overrideStyle={{ width: '100%', padding: '10px 24px', marginLeft: '-20px' }}
              labelDataKey='count'
              alwaysShowLabels
              isAnimationActive={false}
            />
          </div>
        </div>
      </div>
    )
  }
}

AnnualRenewals.propTypes = {
  loadingRenewals: PropTypes.bool
}

export default AnnualRenewals
