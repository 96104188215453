import React, { Fragment, ReactElement } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { PopupRendererProps } from '@components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '@components/popups/configureMergeUsersRulesPopup/stepConfig/types'
import { Styles } from './style'
import { useDispatch } from 'react-redux'
import { createMergeUsersRules, updateMergeUsersRule } from '@shared/actions/org'
import { SUPPORT_ARTICLES } from '@root/articles'
import Analytics from '../../analytics'
import { MergeUsersReport } from '../mergeUsersReportButton'
import { MERGE_USERS_RULES_TYPE } from '@components/mergeUsersRules/types'
import { Body2 } from '@toriihq/design-system'
import pick from 'lodash/pick'

export const ApplyMerge = ({
  sharedState,
  sharedProps,
  onCancel,
  navigateToNextStep,
  navigateToBackStep
}: PopupRendererProps<SharedState, SharedProps>): ReactElement => {
  const { idOrg } = sharedProps
  const { selectedRuleIdApp, selectedRuleUserFieldKey, idMergeUsersRule } = sharedState
  const dispatch = useDispatch()

  const ruleData = { type: MERGE_USERS_RULES_TYPE.ALIAS, config: { idApp: selectedRuleIdApp as number, userFieldKey: selectedRuleUserFieldKey as string } }

  const onApplyMergeClick = async () => {
    if (idMergeUsersRule) {
      await dispatch(updateMergeUsersRule({
        idOrg,
        idRule: idMergeUsersRule,
        data: pick(ruleData, 'config')
      }))
    } else {
      await dispatch(createMergeUsersRules({
        idOrg,
        data: ruleData
      }))
    }

    Analytics.onApplyMerge({ idOrg, idApp: selectedRuleIdApp })
    navigateToNextStep()
  }

  const onBackButtonClick = () => {
    Analytics.onBackClick({ idOrg })
    navigateToBackStep()
  }

  return (<Fragment>
    <ToriiPopup.Header header={'Merge users'} />
    <ToriiPopup.Content contentAreaStyle={{ padding: '24px 24px 24px' }}>
      <Styles.Content>
        <Styles.TextContainer>
          <Styles.TextListHeader>What to expect</Styles.TextListHeader>
          <Styles.TextList>
            <Body2><li>The number of users within Torii will change.</li></Body2>
            <Body2><li>Data on users will be combined for the merged users together (including access to apps, licenses, annual cost etc.).</li></Body2>
            <Body2><li>App ownership and similar fields will be updated.</li></Body2>
            <Body2><li> This process is reversible - and users can be un-merged at any time.</li></Body2>
          </Styles.TextList>
          <Styles.Link href={SUPPORT_ARTICLES.MERGE_USERS} target='_blank' rel='noopener noreferrer' onClick={() => Analytics.onLearnMoreClick({ idOrg, buttonLocation: 'popup' })}>
            Learn more
          </Styles.Link>
        </Styles.TextContainer>
        <MergeUsersReport isRevokeMode={false} previewRule={ruleData} />
      </Styles.Content>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      cancelButtonText={'Cancel'}
      cancelButtonAction={onCancel}
      mainButtonText={'Apply merge'}
      mainButtonAction={onApplyMergeClick}
      backButtonAction={onBackButtonClick}
      showBackButton
    />
  </Fragment>)
}
