import { css } from 'glamor'
import colors from '@root/shared/style/colors'
import { fontSize, fontWeight } from '@root/shared/style/sizes'
import { smallDeviceWidth } from '@root/components/popups/ToriiPopupV2/style'

export const Container = css({
  display: 'flex',
  border: `1px solid ${colors.lightBlue5}`,
  borderRadius: '10px',
  width: '100%'
})

export const ClickableContainer = css({
  cursor: 'pointer',
  ':hover': {
    boxShadow: '0px 4px 8px -6px rgba(51, 51, 51, 0.4)'
  },
  '[disabled]': {
    opacity: 1,
    ':hover': {
      boxShadow: 'none'
    }
  }
})

export const ImageContainer = css({
  paddingLeft: '24px',
  paddingTop: '38px'
})

export const DataContainer = css({
  display: 'flex',
  flexDirection: 'column',
  padding: '36px',
  paddingLeft: 16,
  width: '100%',
  gap: '16px'
})
export const Header = css({
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: fontSize.large,
  fontWeight: fontWeight.semiBold
})

export const HeaderText = css({
  alignItems: 'center',
  paddingBottom: '6px',
  fontSize: fontSize.mediumLarge,
  color: colors.darkText
})

export const LineSeparator = css({
  width: '100%',
  height: '1px',
  backgroundColor: colors.lightBlue5,
  color: colors.darkText
})

export const RecommendationBody = css({
  display: 'flex',
  justifyContent: 'space-between',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    flexDirection: 'column'
  }
})

export const SavingsContainer = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  textAlign: 'right',
  gap: '8px',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    paddingTop: '16px',
    textAlign: 'left'
  }
})

export const SavingTextContainer = css({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  gap: '5px',
  backgroundColor: colors.lightGreen,
  color: colors.darkText,
  padding: '5px 10px',
  borderRadius: '4px',
  [`@media(max-width: ${smallDeviceWidth})`]: {
    justifyContent: 'center',
    width: '50%'
  }
})

export const SavingTextContainerNoLicenseCost = css({
  backgroundColor: colors.grey3,
  color: colors.grey2
})

export const SavingText = css({
  textAlign: 'center',
  fontWeight: fontWeight.bold
})

export const Info = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '60%'
})

export const InfoTextContainer = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
})

export const SubTitle = css({
  color: colors.grey2,
  fontSize: fontSize.small
})

export const InfoText = css({
  fontSize: fontSize.medium,
  fontWeight: fontWeight.semiBold,
  color: colors.darkText
})

export const AccountText = css({
  color: colors.grey2,
  fontSize: fontSize.small
})

export const TooltipLabel = css({
  textAlign: 'center'
})
