import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import styled from 'styled-components'

export const Popup = {
  modal: {
    width: '1165px',
    height: '700px',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column'
  }
}

export const Container = css({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

export const Upper = css({
  borderBottom: `solid 1px ${colors.lightBlue2}`,
  padding: '80px 40px 10px'
})

export const Box = css({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
})

export const MainTableArea = css({
  width: '100%',
  height: '476px'
})

export const Name = css(texts.headers.large, {
  lineHeight: '18px',
  color: colors.black,
  marginRight: '15px'
})

export const TableTd = {
  style: {
    border: 0,
    width: '100%',
    padding: '3px 10px',
    color: colors.black
  }
}

export const CountHeader = css({
  fontSize: '12px',
  fontWeight: 600,
  lineHeight: '16px',
  letterSpacing: '0px',
  textAlign: 'left',
  marginBottom: '2px'
})

export const Period = css({
  fontSize: '13px',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0px',
  textAlign: 'left'
})

export const Footer = css({
  borderColor: colors.lightBlue2,
  borderStyle: 'solid',
  borderWidth: '1px 0 0',
  padding: '20px 35px',
  display: 'flex',
  justifyContent: 'flex-end'
})

export const UsageHeaderTooltipLabel = styled.div`
  text-align: center;
  max-width: 200px;
`
