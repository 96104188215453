import {
  GET_USER_FIELD_DISPLAY_NAMES,
  GET_USER_FIELDS
} from '../constants'

const initialState = {
  loadingFields: false,
  fields: [],
  fieldsDisplayNames: {}
}

const userDetailsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_USER_FIELDS}_RESPONSE`: {
      const { fields } = action.payload
      return {
        ...state,
        loadingFields: false,
        fields
      }
    }
    case `${GET_USER_FIELD_DISPLAY_NAMES}_PENDING`: {
      const { userFieldKey } = action.meta

      return {
        ...state,
        fieldsDisplayNames: {
          ...state.fieldsDisplayNames,
          [userFieldKey]: {
            displayNames: [],
            ...state.fieldsDisplayNames[userFieldKey],
            isLoading: true
          }
        }
      }
    }
    case `${GET_USER_FIELD_DISPLAY_NAMES}_RESPONSE`: {
      const fieldDisplayNames = action.payload
      const { userFieldKey } = action.meta

      const fieldsDisplayNames = { ...state.fieldsDisplayNames }
      fieldsDisplayNames[userFieldKey] = {
        isLoading: false,
        displayNames: fieldDisplayNames
      }

      return {
        ...state,
        fieldsDisplayNames
      }
    }
    default: {
      return state
    }
  }
}

export default userDetailsReducer
