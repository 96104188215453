import View from './view'
import { connect } from 'react-redux'
import { getIsSmallScreen } from '../../selectors/ui'
import { withRouter } from 'react-router-dom'
import { compact } from 'lodash'
import AccessControl from '@lenses/accessControl'

const mapStateToProps = (state, ownProps) => {
  const { tabsName, tabsFeature = [] } = ownProps
  const nonAccessibleTabIndices = compact(tabsFeature.map((feature, index) => AccessControl.isFeatureEnabledInPlan({ feature, state }) ? null : index))

  let { tabName = tabsName[0] } = ownProps.match.params
  if (ownProps.isSecondary) {
    const pathBreakdown = ownProps.location.pathname.split('/')
    tabName = pathBreakdown[pathBreakdown.length - 1]
  }
  const tabIndex = tabsName.indexOf(tabName)
  const selectedIndex = tabIndex === -1 ? 0 : tabIndex
  const isSmallScreen = getIsSmallScreen(state)
  return {
    isSmallScreen,
    selectedIndex,
    nonAccessibleTabIndices
  }
}

export default withRouter(connect(mapStateToProps)(View))
