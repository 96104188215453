import React, { useState } from 'react'
import Analytics from '@helpers/analytics'
import { css } from 'glamor'
import {
  DROP_ZONE_DEFAULT,
  DROP_ZONE_ERROR,
  DROP_ZONE_PARSING_ERROR,
  DROP_ZONE_SUCCESS,
  DROP_ZONE_UPLOADING,
  DROP_ZONE_DROP_ERROR
} from '@components/uploadExpenses/constants'
import * as Style from './style'
import { uploadFile } from '@shared/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import CheckMark from '@media/checkmark.svg'
import { FileRejection, UploadArea } from '@toriihq/design-system'

export const UPLOAD_FILE_POST_PROCESS_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  PARSING_ERROR: 'PARSING_ERROR',
  LOADING: 'LOADING'
}

type Props = {
  popupInstructions: JSX.Element
  validFileTypes: string []
  fileDescription: string
  uploadedFileType: string
  successMessage?: JSX.Element
  isError: boolean
  onIsError?: (show: boolean) => void
  onShowFooter: (show: boolean) => void
  postUploadAction?: (idOrg: number, idUpload: number) => Promise<{ status: string }>
  hideFooterAfterSuccess?: boolean
  errorInfo?: string
  idApp?: number
  uploadEventType: string
}

const UploadFileArea = (props: Props): JSX.Element => {
  const {
    popupInstructions,
    validFileTypes = [],
    fileDescription,
    uploadedFileType,
    successMessage = <></>,
    onIsError,
    onShowFooter,
    isError,
    postUploadAction,
    hideFooterAfterSuccess = false,
    errorInfo,
    idApp = undefined,
    uploadEventType
  } = props
  const [dropZone, setDropZone] = useState<string>(DROP_ZONE_DEFAULT)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const { id: idOrg }: { id: number } = useSelector(getCurrentOrg)

  const dispatch = useDispatch()

  const updateFooterConditions = (dropZone: string) => {
    onIsError && onIsError([DROP_ZONE_PARSING_ERROR, DROP_ZONE_ERROR, DROP_ZONE_DROP_ERROR].includes(dropZone))
    onShowFooter([DROP_ZONE_SUCCESS].includes(dropZone))
  }

  const onSetDropZone = (dropZone: string) => {
    setDropZone(dropZone)
    if (dropZone === DROP_ZONE_UPLOADING && isError) {
      updateFooterConditions(DROP_ZONE_PARSING_ERROR)
      setDropZone(DROP_ZONE_PARSING_ERROR)
    }
  }

  const renderBody = () => {
    if ([DROP_ZONE_PARSING_ERROR, DROP_ZONE_ERROR].includes(dropZone) && !isError) {
      onSetDropZone(DROP_ZONE_DEFAULT)
    }

    return (
      <div {...Style.bodyDescription}>
        <div {...css({ display: 'grid', justifyContent: 'center', gridTemplateColumns: '100%' })}>
          {(() => {
            switch (dropZone) {
              case DROP_ZONE_DEFAULT:
              case DROP_ZONE_UPLOADING:
              case DROP_ZONE_DROP_ERROR:
              case DROP_ZONE_ERROR:
              case DROP_ZONE_PARSING_ERROR:
                return renderDropZone()
              case DROP_ZONE_SUCCESS:
                return renderPostUploadSuccess()
              default:
                return null
            }
          })()
          }
        </div>
      </div>
    )
  }

  const renderDropZone = () => {
    const state = dropZone === DROP_ZONE_UPLOADING ? 'loading' : dropZone === DROP_ZONE_DEFAULT ? 'neutral' : 'error'
    return (
      <>
        {popupInstructions}
        <div {...Style.dropZoneContent}>
          <UploadArea
            state={state}
            validFileTypes={validFileTypes}
            multiple={false}
            onFileSelect={onDrop}
            errorMessage={errorInfo || errorMessage}
          />
        </div>
      </>
    )
  }

  const onDrop = async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    setDropZone(DROP_ZONE_UPLOADING)

    if (rejectedFiles.length) {
      Analytics.track(`Upload ${fileDescription} file failed (invalid file type)`, {
        name: rejectedFiles[0].file.name,
        type: rejectedFiles[0].file.type
      })
      setErrorMessage(`The file must be in ${fileDescription} format.`)
      onSetDropZone(DROP_ZONE_DROP_ERROR)
    }

    const [file] = acceptedFiles
    Analytics.track(`Upload ${fileDescription} file`, {
      name: file.name
    })

    await onUploadFile(file)
  }

  const onUploadFile = async (file: File) => {
    let idUpload: null | number = null
    try {
      const response = await dispatch(uploadFile({ idOrg, file, idApp, type: uploadedFileType }))
      idUpload = response.idUpload as number
      if (postUploadAction) {
        const postActionResult = await postUploadAction(idOrg, idUpload)
        if (postActionResult.status === UPLOAD_FILE_POST_PROCESS_STATUS.LOADING) {
          return onSetDropZone(DROP_ZONE_UPLOADING)
        }
        if (postActionResult.status !== UPLOAD_FILE_POST_PROCESS_STATUS.SUCCESS) {
          Analytics.track(`Failed to process uploaded file`, {
            'Upload process place': uploadEventType,
            idUpload: idUpload
          })

          const errorDropZone = (postActionResult.status === UPLOAD_FILE_POST_PROCESS_STATUS.PARSING_ERROR) ? DROP_ZONE_PARSING_ERROR : DROP_ZONE_ERROR
          updateFooterConditions(errorDropZone)
          setErrorMessage(errorDropZone === DROP_ZONE_PARSING_ERROR ? 'Failed to parse file.' : 'We\'re sorry, something went wrong.')
          return onSetDropZone(errorDropZone)
        }
      }
    } catch (e) {
      Analytics.track(`Upload ${fileDescription} file failed`, {
        'Upload process place': uploadEventType,
        name: file.name
      })

      updateFooterConditions(DROP_ZONE_ERROR)
      setErrorMessage('We\'re sorry, something went wrong.')
      return onSetDropZone(DROP_ZONE_ERROR)
    }

    onSetDropZone(DROP_ZONE_SUCCESS)
    setErrorMessage(null)
    !hideFooterAfterSuccess && onShowFooter(true)
  }

  const renderPostUploadSuccess = () => {
    return (
      <div {...Style.alignment}>
        <span {...Style.iconContainer}>
          <img height={78} width={99} src={CheckMark} alt='import-successful' />
        </span>
        <div>
          <div {...Style.text}>{successMessage}</div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {renderBody()}
    </div>
  )
}

export default UploadFileArea
