import {
  GET_ROLES,
  DELETE_ROLE, GET_SCOPES, CREATE_ROLE, UPDATE_ROLE, GET_ROLE
} from '../constants'

const initialState = {
  loading: false,
  roles: [],
  scopesInfo: {
    loading: false,
    scopes: []
  },
  roleInfo: {
    loading: false
  }
}

const rolesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_ROLES}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_ROLES}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_ROLES}_RESPONSE`: {
      const { roles } = action.payload
      return {
        ...state,
        loading: false,
        roles
      }
    }
    case `${DELETE_ROLE}_RESPONSE`: {
      const { idRole } = action.meta
      const roles = state.roles
      const updatedRoles = roles.filter(role => role.id !== idRole)

      return {
        ...state,
        roles: updatedRoles
      }
    }

    case `${GET_SCOPES}_PENDING`: {
      return {
        ...state,
        scopesInfo: {
          loading: true
        }
      }
    }

    case `${GET_SCOPES}_FAILED`: {
      return {
        ...state,
        scopesInfo: {
          loading: false
        }
      }
    }

    case `${GET_SCOPES}_RESPONSE`: {
      const { scopes } = action.payload

      return {
        ...state,
        scopesInfo: {
          loading: false,
          scopes
        }
      }
    }

    case `${CREATE_ROLE}_RESPONSE`: {
      const role = action.payload
      const updatedroles = state.roles.concat({ ...role, usersCount: 0 })

      return {
        ...state,
        roles: updatedroles
      }
    }

    case `${UPDATE_ROLE}_RESPONSE`: {
      const { idRole, name, description, scopes } = action.meta

      const updatedRoles = state.roles.map(role => role.id === idRole ? { ...role, name, description, scopes } : role)

      return {
        ...state,
        roles: updatedRoles
      }
    }

    case `${GET_ROLE}_PENDING`: {
      return {
        ...state,
        roleInfo: {
          loading: true
        }
      }
    }

    case `${GET_ROLE}_FAILED`: {
      return {
        ...state,
        roleInfo: {
          loading: false
        }
      }
    }

    case `${GET_ROLE}_RESPONSE`: {
      const roleInfo = action.payload

      return {
        ...state,
        roleInfo: {
          loading: false,
          roleInfo
        }
      }
    }

    default: {
      return state
    }
  }
}

export default rolesReducer
