import moment from 'moment'
import {
  DATE_FORMAT,
  EXPENSES_SOURCES,
  SCOPES,
  TRANSACTION_MAPPING_LOGIC_DISPLAY,
  TRANSACTION_MAPPING_STATUS,
  TRANSACTION_MAPPING_STATUS_DISPLAY
} from '@root/constants'
import OriginalAndConvertedCurrencies from '@components/originalAndConvertedCurrencies'
import { keepMultipleSpaces } from '@lenses/utils'
import RelativeTeamLink from '@components/relativeTeamLink'
import { css } from 'glamor'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { AppIcon, Avatar, Tooltip, Link } from '@toriihq/design-system'
import { getDisplayName } from '@lenses/users'
import { hoverActions } from '@shared/style/mixins'
import EnableFor from '@components/enableFor'
import ExpensesActions from '@components/expensesActions'
import React from 'react'
import Table from '@components/table'

const CSS = {
  fileIcon: css({
    marginRight: '10px'
  }),
  archivedMark: css({
    textDecoration: 'line-through',
    '> * > *': css({
      textDecoration: 'line-through'
    })
  }),
  description: css({
    whiteSpace: 'normal'
  })
}

const archivedCSS = (mappingStatus) => mappingStatus === TRANSACTION_MAPPING_STATUS.ARCHIVED ? css(CSS.archivedMark) : {}

export const getColumns = ({ idOrg, usersById, appsById, onEditActionClicked, onArchiveActionClicked, displayAppColumn }) => [
  {
    Header: 'Transaction date',
    accessor: 'transactionDate',
    Cell: ({ value: date, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{moment.utc(date).format(DATE_FORMAT)}</span>,
    width: 170
  },
  {
    Header: 'Amount',
    accessor: 'amount',
    Cell: ({ value: amount, row: { modifiedAmount, origAmount, origCurrency, mappingStatus } }) =>
      <span {...archivedCSS(mappingStatus)}>
        <OriginalAndConvertedCurrencies
          value={modifiedAmount || origAmount}
          convertedValue={amount}
          currency={origCurrency}
        />
      </span>,
    width: 100,
    ...Table.numericFieldProps
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value: description, row: { extraInfo, mappingStatus } }) => <div {...CSS.description} {...archivedCSS(mappingStatus)}>{keepMultipleSpaces(description)}{extraInfo ? ` - ${keepMultipleSpaces(extraInfo)}` : ''}</div>,
    width: 180
  },
  {
    Header: 'Source',
    accessor: 'toriiSource',
    Cell: ({ value: source, row: { idUpload, fileName, mappingStatus } }) => {
      if (source === 'csv' && idUpload) {
        const decodedFileName = decodeURIComponent(fileName)
        return <RelativeTeamLink
          idOrg={idOrg}
          nav={undefined}
          overrideStyle={css({ display: 'flex' })}
          disabled={undefined}
          supportDisabledLink={undefined}
          className={undefined}
          to={`/expenses/uploads/${idUpload}`}
          state={undefined}>
          <Link>
            <Link.Icon name='File' />
            <span {...archivedCSS(mappingStatus)}>{decodedFileName}</span>
          </Link>
        </RelativeTeamLink>
      }
      return <span {...archivedCSS(mappingStatus)}>
        <div>{EXPENSES_SOURCES[source] || source}</div>
      </span>
    },
    width: 140
  },
  {
    Header: 'Account',
    accessor: 'appAccountName',
    Cell: ({ value: appAccountName, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{appAccountName}</span>,
    width: 120
  },
  {
    Header: 'Reported By',
    accessor: 'reportedByFullName',
    Cell: ({ row: { reportedBy } }) => {
      if (reportedBy && usersById[reportedBy]) {
        const reporterUserDetails = reportedBy && usersById[reportedBy]
        const { firstName, lastName, photoUrl } = reporterUserDetails

        return (
          <span style={{ marginRight: '15px' }}>
            <RelativeTeamUserLink idUser={reportedBy}>
              <Tooltip label={`Reported by ${getDisplayName(reporterUserDetails)}`}>
                <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
              </Tooltip>
            </RelativeTeamUserLink>
          </span>
        )
      }

      return null
    },
    width: 100
  },
  {
    Header: 'Matching Status',
    accessor: 'mappingStatus',
    Cell: ({ value: mappingStatus }) => <span {...archivedCSS(mappingStatus)}>{TRANSACTION_MAPPING_STATUS_DISPLAY[mappingStatus] || ''}</span>,
    width: 120
  },
  {
    Header: 'Matched By',
    accessor: 'mappingLogic',
    Cell: ({ value: mappingLogic, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{TRANSACTION_MAPPING_LOGIC_DISPLAY[mappingLogic?.toLowerCase()]}</span>,
    width: 120
  },
  {
    Header: 'Matched App',
    accessor: 'appName',
    Cell: ({ row: { idApp, mappingStatus } }) => {
      const app = appsById[idApp]
      return !app ? null : (
        <div {...archivedCSS(mappingStatus)} style={{ display: 'flex', alignItems: 'center' }}>
          <RelativeTeamLink
            idOrg={idOrg}
            to={`/app/${idApp}`}
            nav={undefined}
            overrideStyle={undefined}
            disabled={undefined}
            supportDisabledLink={undefined}
            className={undefined}
            state={undefined}>
            <AppIcon
              appImageUrl={app.imageUrl}
              appName={app.name}
              hoverable
            />
          </RelativeTeamLink>
          <div style={{ padding: '0 15px' }}>{app.name}</div>
        </div>
      )
    },
    show: displayAppColumn,
    resizable: false,
    width: 180
  },
  {
    Header: 'Source Transaction ID',
    accessor: 'idExternalTransaction',
    Cell: ({ value: idExternalTransaction, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{idExternalTransaction}</span>,
    width: 200
  },
  {
    Header: 'GL Account ID',
    accessor: 'externalAccountId',
    Cell: ({ value: externalAccountId, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{externalAccountId}</span>,
    width: 200
  },
  {
    Header: 'GL Account',
    accessor: 'externalAccountName',
    Cell: ({ value: externalAccountName, row: { mappingStatus } }) => <span {...archivedCSS(mappingStatus)}>{externalAccountName}</span>,
    width: 200
  },
  {
    id: 'action',
    Header: '',
    className: css(hoverActions).toString(),
    Cell: ({ row: { id, idApp, description, mappingStatus, mappingLogic, transactionDate, origTransactionDate, amount, modifiedAmount, origAmount, origCurrency } }) =>
      <div>
        <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
          <ExpensesActions
            key={id}
            id={id}
            idApp={idApp}
            name={description}
            idOrg={idOrg}
            mappingStatus={mappingStatus}
            mappingLogic={mappingLogic}
            transactionDate={transactionDate}
            origTransactionDate={origTransactionDate}
            amount={amount}
            modifiedAmount={modifiedAmount}
            origAmount={origAmount}
            origCurrency={origCurrency}
            onEdit={onEditActionClicked}
            onArchive={onArchiveActionClicked}
          />
        </EnableFor>
      </div>,
    fixed: 'right',
    resizable: false,
    width: 100,
    sortable: false,
    hideFromCSV: true
  },
  {
    accessor: 'idUpload',
    show: false
  },
  {
    accessor: 'idOrg',
    show: false
  },
  {
    accessor: 'fileName',
    show: false
  },
  {
    accessor: 'idExternalTransaction',
    show: false
  },
  {
    accessor: 'id',
    show: false
  },
  {
    accessor: 'extraInfo',
    show: false
  },
  {
    accessor: 'origTransactionDate',
    show: false
  },
  {
    accessor: 'modifiedAmount',
    show: false
  },
  {
    accessor: 'origAmount',
    show: false
  },
  {
    accessor: 'origCurrency',
    show: false
  },
  {
    accessor: 'reportedBy',
    show: false
  },
  {
    accessor: 'idApp',
    show: false
  }
]
