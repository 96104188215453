import React from 'react'
import { TORII_APP_ID, WORKFLOW_TRIGGER_TYPES, WORKFLOW_TYPES } from '@root/constants'
import { getNewPolicyName, getWorkflowPathPrefix } from '@lenses/workflows'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createWorkflow, getWorkflow, updateWorkflow } from '@shared/actions/workflows'
import { addEmptyActionNodeToRoot } from '@pages/createWorkflow/createWorkflowButton/utils'

const withCreatePolicy = (WrappedComponent) => (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { idWorkflow } = useSelector(state => state.ui.addAppCatalogPolicyPopup)

  const onCreateCustomPolicy = async ({ idOrg, idApp, appName, onAfterCreation }) => {
    const { workflow: newPolicy } = await dispatch(createWorkflow({ idOrg, type: WORKFLOW_TYPES.appCatalog }))
    const updatedWorkflow = { ...newPolicy, name: getNewPolicyName(appName), idApp: TORII_APP_ID, triggerType: WORKFLOW_TRIGGER_TYPES.REQUEST_ACCESS, triggerIdApp: idApp }
    let updatedActions
    if (idWorkflow) {
      const { workflow: workflowToDuplicate } = await dispatch(
        getWorkflow({ idOrg, idWorkflow })
      )
      updatedActions = workflowToDuplicate.actions
    } else {
      const addEmptyActionResult = addEmptyActionNodeToRoot(updatedWorkflow.actions)
      updatedActions = addEmptyActionResult.actions
    }
    await dispatch(updateWorkflow({
      idOrg,
      idWorkflow: updatedWorkflow.id,
      workflow: { ...updatedWorkflow, actions: updatedActions }
    }))

    onAfterCreation && onAfterCreation()
    history.push(`/team/${idOrg}/${getWorkflowPathPrefix({ workflowType: WORKFLOW_TYPES.appCatalog })}/${newPolicy.id}`)
  }

  return (
    <WrappedComponent onCreateCustomPolicy={onCreateCustomPolicy} {...props} />
  )
}

export default withCreatePolicy
