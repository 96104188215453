import styled from 'styled-components'
import colors from '@shared/style/colors'

export namespace Styles {
  export const Wrapper = styled.div``

  export const NoSourcesLabel = styled.span`
    padding-right: 8px;
  `

  export const SourceApps = styled.div`
    padding-bottom: 16px;
  `

  export const SourceApp = styled.div`
    display: flex;
    align-items: center;
    padding: 14px 18px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
    &:hover button > div {
      opacity: 1;
    }
  `

  export const SourceAppDetails = styled.div`
    flex-grow: 1;
    font-size: 13px;
  `

  export const AccountInfo = styled.div`
    font: ${({ theme }) => theme.typography.font.caption01};
    color: ${colors.grey2};
  `

  export const ActionButtons = styled.div`
    & > button > div {
      display: flex;
      opacity: 0;
    }

    &:hover > button > div {
      opacity: 1;
    }
  `
}
