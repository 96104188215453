import View from './view'
import { connect } from 'react-redux'
import { getServicesSyncData, toggleAddApplication, getIntegrations } from '@shared/actions'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import { getConstantServicesByIdApp, getSyncStatusForNonManualSyncType } from '@selectors/services'
import { withRouter } from 'react-router-dom'
import groupBy from 'lodash/groupBy'

export const NEW_APP_ACCOUNT_ID = -1

const mapStateToProps = (state) => {
  const appsById = deprecatedGetAppsByIds(state)
  const availableServices = getConstantServicesByIdApp(state)
  const connectedServices = groupBy(getSyncStatusForNonManualSyncType(state), 'idApp')

  return {
    availableServices,
    connectedServices,
    appsById
  }
}

const mapDispatchToProps = {
  getServicesSyncData,
  getIntegrations,
  toggleAddApplication
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
