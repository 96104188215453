import styled from 'styled-components'
import colors from '@shared/style/colors'

export const ButtonContentContainer = styled.span`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const ButtonText = styled.span`
  font: ${({ theme }) => theme.typography.font.component02};
`

export const buttonOverrideStyle: Partial<CSSStyleDeclaration> = {

}

export const IconContainer = styled.span`
  display: flex;
  align-items: center;
  transition: color .2s;
  color: ${({ theme }) => theme.palette.icon.secondary};
`

export const ImportButtonContainer = styled.span`
  justify-content: flex-start;
  max-height: 32px;
  margin-left: 5px;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button:hover ${IconContainer} {
    color: ${({ theme }) => theme.palette.button.primary};
  }
  
  button[disabled]:hover ${IconContainer} {
    color: ${colors.grey2};
  }
`
