import View from './view'
import { connect } from 'react-redux'
import { getAdminRoles, isLoadingRoles } from '../../selectors/roles'
import { getRoles, toggleRolePopup } from '../../shared/actions'
import { getIsSmallScreen } from '../../selectors/ui'

const mapStateToProps = (state) => {
  const roles = getAdminRoles(state)

  return {
    isSmallScreen: getIsSmallScreen(state),
    roles,
    loading: isLoadingRoles(state) && roles.length === 0
  }
}

const mapDispatchToProps = {
  getRoles,
  toggleRolePopup
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
