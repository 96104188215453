import {
  TOGGLE_APP_DETAIL_HISTORY_POPUP,
  TOGGLE_CONFIGURE_APP_CHARGEBACK,
  TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP,
  TOGGLE_CONNECT_CUSTOM_SERVICE,
  TOGGLE_CONNECT_SCIM_SERVICE,
  TOGGLE_CONNECT_SERVICE,
  TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP,
  TOGGLE_CONTRACT_POPUP,
  TOGGLE_CSV_EXPORT_POPUP,
  TOGGLE_HIDE_APP_POPUP,
  TOGGLE_MANUAL_ACTION_STARTED_POPUP,
  TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP,
  TOGGLE_MATCHING_RULES_POPUP,
  TOGGLE_OFFBOARDING_PREVIEW_POPUP,
  TOGGLE_OFFBOARDING_STARTED_POPUP,
  TOGGLE_POPUP,
  TOGGLE_ROLE_POPUP,
  TOGGLE_SELECT_FIELD_TYPE_POPUP,
  TOGGLE_SENT_EMAILS_POPUP,
  TOGGLE_TRIGGER_PREVIEW_POPUP,
  TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP,
  TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP,
  TOGGLE_USERS_OVERLAP_POPUP,
  TOGGLE_WORKFLOW_PREVIEW_POPUP,
  TOGGLE_ACTIVATE_WORKFLOW_POPUP,
  TOGGLE_WORKFLOW_STARTED_POPUP,
  TOGGLE_ADD_APP_CATALOG_POLICY_POPUP,
  TOGGLE_IMPORT_USERS_POPUP,
  TOGGLE_SYNC_STATUS_POPUP,
  TOGGLE_DELETE_IMPORTED_USERS_POPUP,
  TOGGLE_LICENSE_EDIT_POPUP,
  TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP,
  TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP,
  TOGGLE_EDIT_WORKFLOW_TAGS_POPUP,
  TOGGLE_CREATE_WORKFLOW_TAGS_POPUP,
  TOGGLE_EDIT_WORKFLOW_TAG_POPUP
} from '@root/constants'

export const togglePopup = (popupName, isOpen = true) =>
  ({
    type: TOGGLE_POPUP,
    payload: { popupName, isOpen },
    meta: {}
  })

export const toggleSelectFieldTypePopup = (isOpen, idGroup, isClosedByUser, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType) =>
  ({
    type: TOGGLE_SELECT_FIELD_TYPE_POPUP,
    payload: { isOpen, idGroup, isClosedByUser, addDetailsField, groupsForSelectGroup, hideFormQuestion, entityType },
    meta: {}
  })

export const toggleUpdateAppDetailsField = ({ isOpen, idField, name, isByUser = true }) =>
  ({
    type: TOGGLE_UPDATE_APP_DETAIL_FIELD_POPUP,
    payload: { isOpen, idField, name },
    meta: { isOpen, idField, name, isByUser }
  })

export const toggleUpdateContractDetailsField = ({ isOpen, idField, name, isByUser = true }) =>
  ({
    type: TOGGLE_UPDATE_CONTRACT_DETAIL_FIELD_POPUP,
    payload: { isOpen, idField, name },
    meta: { isOpen, idField, name, isByUser }
  })

export const toggleHideAppPopup = ({ open, idApp, name }) => ({
  type: TOGGLE_HIDE_APP_POPUP,
  payload: { open, idApp, name },
  meta: {}
})

export const toggleTriggerPreviewPopup = (isOpen = true, idWorkflow, workflowName, triggerPreviewConfig) =>
  ({
    type: TOGGLE_TRIGGER_PREVIEW_POPUP,
    payload: { isOpen, idWorkflow, workflowName, triggerPreviewConfig },
    meta: {}
  })

export const toggleDiscoveredAppsPreviewPopup = (
  isOpen,
  onMainActionButton,
  onCancelButton
) =>
  ({
    type: TOGGLE_DISCOVERED_APPS_PREVIEW_POPUP,
    payload: {
      isOpen,
      onMainActionButton,
      onCancelButton
    },
    meta: {}
  })

export const toggleWorkflowPreviewPopup = ({ isOpen, actionType }) =>
  ({
    type: TOGGLE_WORKFLOW_PREVIEW_POPUP,
    payload: { isOpen, actionType }
  })

export const toggleManuallyRunWorkflowPopup = ({ isOpen, triggerType, idWorkflow, workflowName, source, workflowType, formInitialValues }) =>
  ({
    type: TOGGLE_MANUALLY_RUN_WORKFLOW_POPUP,
    payload: { isOpen, triggerType, idWorkflow, workflowName, source, workflowType, formInitialValues }
  })

export const toggleOffboardingPreviewPopup = ({ isOpen, isByUser = true, users }) =>
  ({
    type: TOGGLE_OFFBOARDING_PREVIEW_POPUP,
    payload: { isOpen, isByUser, users }
  })

export const toggleOffboardingStartedPopup = ({ isOpen, isByUser = true, users }) =>
  ({
    type: TOGGLE_OFFBOARDING_STARTED_POPUP,
    payload: { isOpen, isByUser, users }
  })

export const toggleAppDetailHistoryPopup = ({ isOpen, idApp, detail }) =>
  ({
    type: TOGGLE_APP_DETAIL_HISTORY_POPUP,
    payload: { isOpen, idApp, detail }
  })

export const toggleContractDetailHistoryPopup = ({ isOpen, idContract, detail, idApp }) =>
  ({
    type: TOGGLE_CONTRACT_DETAIL_HISTORY_POPUP,
    payload: { isOpen, idContract, detail, ...(idApp ? { idApp } : []) }
  })

export const toggleManualActionStartedPopup = ({ isOpen, isByUser = true, users, idWorkflow, triggerType }) =>
  ({
    type: TOGGLE_MANUAL_ACTION_STARTED_POPUP,
    payload: { isOpen, isByUser, users, idWorkflow, triggerType }
  })

export const toggleMatchingRulesPopup = ({ isOpen, isByUser = true, rules }) => ({
  type: TOGGLE_MATCHING_RULES_POPUP,
  payload: { isOpen, isByUser },
  meta: {
    rules
  }
})

export const toggleContractPopup = ({ isOpen, isByUser = true, idContract, idApp, initialValues, buttonLabel, isDuplicated, isAppMatchingMode = false, onSuccessCB }) => ({
  type: TOGGLE_CONTRACT_POPUP,
  payload: { isOpen, isByUser, idContract, idApp, initialValues, buttonLabel, isDuplicated, isAppMatchingMode, onSuccessCB }
})

export const toggleLicenseEditPopup = ({ isOpen, idApp = null, idLicense = null, appName = null, licenses = null, selectedColumn = null, conversionDateFromContract = null, currencyFromContract = null, openedFromContractFlow = false }) => ({
  type: TOGGLE_LICENSE_EDIT_POPUP,
  payload: { isOpen, idApp, idLicense, appName, licenses, selectedColumn, conversionDateFromContract, currencyFromContract, openedFromContractFlow }
})

export const toggleAskToUpdateLicenseCostPopup = ({ isOpen, idApp = null, appName = null, licenses = null, conversionDate = null, currency = null }) => ({
  type: TOGGLE_ASK_TO_UPDATE_LICENSE_COST_POPUP,
  payload: { isOpen, idApp, appName, licenses, conversionDate, currency }
})

export const toggleCsvExportPopup = ({ isOpen }) => (dispatch) => {
  dispatch({
    type: TOGGLE_CSV_EXPORT_POPUP,
    payload: { isOpen }
  })
}

export const toggleConfigureBrowserActivationEmailPopup = ({ isOpen }) =>
  ({
    type: TOGGLE_CONFIGURE_BROWSER_ACTIVATION_EMAIL_POPUP,
    payload: { isOpen }
  })

export const toggleSentEmailsPopup = ({ isOpen, sampleUsers, usersCount, idWorkflow }) =>
  ({
    type: TOGGLE_SENT_EMAILS_POPUP,
    payload: { isOpen, sampleUsers, usersCount, idWorkflow }
  })

export const toggleRolePopup = ({ isOpen = true, idRole, isReadOnly = false }) =>
  ({
    type: TOGGLE_ROLE_POPUP,
    payload: { isOpen, idRole, isReadOnly }
  })

export const toggleUsersOverlapPopup = ({ apps, idUsers, appsNames, source, withLicenses }) =>
  ({
    type: TOGGLE_USERS_OVERLAP_POPUP,
    payload: { apps, idUsers, withLicenses },
    meta: { appsNames, idUsers, source, withLicenses }
  })

export const toggleConnectService = ({ isConnectServiceOpen = true, isByUser = true, idApp, appName, isReconnect = false, showReconnectAlertInfo = false }) =>
  ({
    type: TOGGLE_CONNECT_SERVICE,
    payload: { isConnectServiceOpen, isByUser, idApp, appName, isReconnect, showReconnectAlertInfo }
  })

export const toggleConnectCustomService = ({ isOpen = true, coordinate, isByUser = true }) =>
  ({
    type: TOGGLE_CONNECT_CUSTOM_SERVICE,
    payload: { isOpen, coordinate, isByUser }
  })

export const toggleConnectSCIMService = ({ isOpen = true, idApp, isByUser = true, isReconnect = false, showReconnectAlertInfo = false, idAppAccount, idAppToken, serviceName }) =>
  ({
    type: TOGGLE_CONNECT_SCIM_SERVICE,
    payload: { isOpen, idApp, isByUser, isReconnect, showReconnectAlertInfo, idAppToken, idAppAccount },
    meta: { serviceName }
  })

export const toggleConfigureAppChargeback = ({ isConfigureAppChargebackOpen = true, idApp, callback }) =>
  ({
    type: TOGGLE_CONFIGURE_APP_CHARGEBACK,
    payload: { isConfigureAppChargebackOpen, idApp, callback }
  })

export const toggleActivateWorkflowPopup = ({ isOpen = true, idWorkflow, workflowName, triggerType }) =>
  ({
    type: TOGGLE_ACTIVATE_WORKFLOW_POPUP,
    payload: { isOpen, idWorkflow, workflowName, triggerType }
  })

export const toggleWorkflowStartedPopup = ({ isOpen = true, idWorkflow, workflowName, triggerType, referrer, entitiesAmount, workflowType }) =>
  ({
    type: TOGGLE_WORKFLOW_STARTED_POPUP,
    payload: { isOpen, idWorkflow, workflowName, triggerType, referrer, entitiesAmount, workflowType }
  })

export const toggleAddAppCatalogPolicyPopup = ({ isOpen = true, isByUser, buttonLabel, isViewOnly = false, idWorkflow = null }) =>
  ({
    type: TOGGLE_ADD_APP_CATALOG_POLICY_POPUP,
    payload: { isOpen, isViewOnly, idWorkflow },
    meta: { isOpen, isByUser, buttonLabel, isViewOnly }
  })

export const toggleImportUsers = params => {
  const { isOpen, idApp = null, appName = null } = params
  return ({
    type: TOGGLE_IMPORT_USERS_POPUP,
    payload: { isOpen, idApp, appName }
  })
}

export const toggleSyncStatusPopup = params => {
  const { isOpen, idAppAccount = null, connectByMessage = null, connectedAt = null } = params
  return ({
    type: TOGGLE_SYNC_STATUS_POPUP,
    payload: { isOpen, idAppAccount, connectByMessage, connectedAt }
  })
}

export const toggleDeleteImportedUsers = params => {
  const { isOpen, idApp = null } = params
  return ({
    type: TOGGLE_DELETE_IMPORTED_USERS_POPUP,
    payload: { isOpen, idApp }
  })
}

export const toggleEditWorkflowTagsPopup = params => {
  const { isOpen, idWorkflow } = params

  return ({
    type: TOGGLE_EDIT_WORKFLOW_TAGS_POPUP,
    payload: { isOpen, idWorkflow }
  })
}

export const toggleEditWorkflowTagPopup = params => {
  const { isOpen, idTag } = params

  return ({
    type: TOGGLE_EDIT_WORKFLOW_TAG_POPUP,
    payload: { isOpen, idTag }
  })
}

export const toggleCreateWorkflowTagsPopup = ({ isOpen }) =>
  ({
    type: TOGGLE_CREATE_WORKFLOW_TAGS_POPUP,
    payload: { isOpen }
  })
