import React, { ReactElement, useState } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { SCOPES, WORKFLOW_TAG_NAME_MAX_LENGTH } from '@root/constants'
import { toggleCreateWorkflowTagsPopup } from '@shared/actions'
import FormGroup from '@components/form/formGroup'
import { AlertBox, AlertBoxType, toast, ToastType } from '@toriihq/design-system'
import { ContentAreaStyle } from './styles'
import { keyBy } from 'lodash'
import Input from '@components/form/input'
import { createWorkflowTags } from '@shared/actions/workflows'
import { getWorkflowTags } from '@selectors/workflows'
import { analytics } from '@shared/services/workflows/analytics'

const CreateWorkflowTagsPopup = (): ReactElement => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen } = useSelector(state => state.ui.createWorkflowTagsPopup)
  const existingTags = useSelector(getWorkflowTags)

  const dispatch = useDispatch()

  const [newTag, setNewTag] = useState<string>('')
  const [actionError, setActionError] = useState('')

  const closePopup = () => {
    dispatch(toggleCreateWorkflowTagsPopup({ isOpen: false }))
  }

  const onClose = () => {
    analytics.tags.closeAddTagDialog({ buttonLabel: 'X' })
    closePopup()
  }

  const onCancel = () => {
    analytics.tags.closeAddTagDialog({ buttonLabel: 'Cancel' })
    closePopup()
  }

  const onSave = async (): Promise<void> => {
    const existingTagsByLabel = keyBy(existingTags, 'label')

    if (existingTagsByLabel[newTag]) {
      setActionError(`"${newTag}" tag already exists`)
      return
    }

    try {
      analytics.tags.addCustomTag({ actionLocation: 'Add tag dialog' })
      await dispatch(createWorkflowTags({
        idOrg,
        tags: [newTag]
      }))
      setActionError('')
      setTimeout(() => {
        toast({
          message: `Successfully created "${newTag}" tag.`,
          type: ToastType.SUCCESS
        })
        onClose()
      }, 500)
    } catch (err) {
      setActionError('The changes couldn\'t be saved. Try saving again.')
    }
  }

  const onChange = (e) => {
    setActionError('')
    setNewTag(e.target.value)
  }

  return <ToriiPopup isOpen={isOpen} onCloseAction={onClose} closeOnOverlayClick={false}>
    <ToriiPopup.Header header={`Add tag`} />
    <ToriiPopup.Content contentAreaStyle={ContentAreaStyle}>
      {
        actionError ? (
          <FormGroup>
            <AlertBox type={AlertBoxType.NEGATIVE} description={actionError} />
          </FormGroup>
        ) : null
      }
      <FormGroup label='Tag name'>
        <Input
          maxLength={WORKFLOW_TAG_NAME_MAX_LENGTH}
          value={newTag}
          onChange={onChange}
          autoFocus
        />
      </FormGroup>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonAction={onSave}
      mainButtonText='Save'
      scopes={[SCOPES.AUTOMATION_WRITE]}
      cancelButtonAction={onCancel}
      cancelButtonText='Cancel'
      isMainButtonDisabled={!newTag?.trim() || Boolean(actionError)}
    />
  </ToriiPopup>
}

export default CreateWorkflowTagsPopup
