import { Avatar, Button, ButtonType } from '@toriihq/design-system'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import React from 'react'
import { getDisplayName } from '@lenses/users'
import * as Style from './style'
import { EMPTY_OBJECT } from '@root/constants'
import { getFormattedDate } from '@lenses/utils'
import { AnalyticsInfo, AuditLogInfo, AuditLogUsers } from '@components/auditLogs/auditLog.types'
import Analytics from '@root/helpers/analytics'
import { getActionDescriptionByAuditType } from '@lenses/workflows.t'

type Props = AuditLogInfo & {
  users: AuditLogUsers,
  openURLPrefix: string,
  openURLAnalytics: AnalyticsInfo
}

const getActionDescriptionElement = ({ firstName, lastName, email, type, creationTime, isActionsChanged, isEnabled }) => {
  const actionDescription = getActionDescriptionByAuditType({ type, isActionsChanged, isEnabled })

  return <Style.TextContainer>
    <div>
      <Style.Bold>{getDisplayName({ firstName, lastName, email })}</Style.Bold>
      <span>&nbsp;{actionDescription}&nbsp;</span>
      <span>the workflow</span>
    </div>
    <Style.Date>{getFormattedDate({ date: creationTime, includeTime: true })}</Style.Date>
  </Style.TextContainer>
}

const AuditLogRow = ({ id, users, performedBy, creationTime, type, openURLPrefix, openURLAnalytics, isActionsChanged, isEnabled }: Props): JSX.Element => {
  const { id: idUser, firstName, lastName, email, photoUrl } = users[performedBy] || EMPTY_OBJECT

  const onClick = (): void => {
    Analytics.track(openURLAnalytics.name, openURLAnalytics.props)
    const url = `${openURLPrefix}?previewId=${id}`
    const newWindow = window.open(url, '_blank') || { opener: null }
    newWindow.opener = null
  }

  return (
    <Style.Wrapper key={id}>
      <Style.Item>
        <RelativeTeamUserLink idUser={idUser}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            imageUrl={photoUrl}
          />
        </RelativeTeamUserLink>
        {getActionDescriptionElement({ firstName, lastName, email, type, creationTime, isActionsChanged, isEnabled })}
        <Style.ActionButton>
          <Button type={ButtonType.primary} onClick={onClick} label='View' />
        </Style.ActionButton>
      </Style.Item>
    </Style.Wrapper>
  )
}

export default AuditLogRow
