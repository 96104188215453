import analytics from '@root/helpers/analytics'

const Analytics = {
  onOpenImportPopupAnalytics: (buttonLabel) => {
    const props = {
      'Button label': buttonLabel
    }
    analytics.track('Open import-users-dialog', props)
  },
  onImportUsersClickAnalytics: () => {
    analytics.track('Open import-users-button-popover')
  },
  onOpenDeletePopupAnalytics: () => {
    analytics.track('Open delete-imported-users-dialog')
  },
  onCloseDeletePopupAnalytics: (buttonLabel: 'X' | 'Cancel') => {
    const props = {
      'Button label': buttonLabel
    }
    analytics.track('Close delete-imported-users-dialog', props)
  },
  onDeleteImportedUsersAnalytics: (usersCount: number) => {
    const props = {
      '# Entities': usersCount
    }
    analytics.track('Delete imported-users', props)
  },
  onCloseImportPopupAnalytics: (stepName, buttonLabel) => {
    const props = {
      'Button label': buttonLabel,
      'Step name': stepName
    }
    analytics.track('Close import-users-dialog', props)
  },
  onClickImportUsersAnalytics: () => {
    analytics.track('Click on import-users-button-in-dialog')
  },
  onClickPreviewButton: () => {
    analytics.track('Click on preview-button')
  },
  onClickSkipUsersPanel: () => {
    analytics.track('Click on skipped-users-panel')
  },
  onClickMissingUsersPanel: () => {
    analytics.track('Click on missing-users-panel')
  },
  onBackActionAnalytics: (stepName) => {
    const props = {
      'Step name': stepName
    }
    analytics.track('Click on Back', props)
  },
  onLearnMoreClickAnalytics: () => {
    analytics.track('Click on learn-more-about-importing-users-link')
  },
  onRemoveMissingUsersRadioButtonAnalytics: (selectionToText) => {
    const props = {
      'radio button selection': selectionToText
    }
    analytics.track('Select Remove-missing-users-radio-btn', props)
  },
  onDateFormatRadioButton: (selectionToText) => {
    const props = {
      'radio button selection': selectionToText
    }
    analytics.track('Select Date-format-radio-btn', props)
  }
}

export default Analytics
