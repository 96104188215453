import View from './view'
import { connect } from 'react-redux'
import { updateUsersLifecycleStatus } from '../../../shared/actions'
import omit from 'lodash/omit'

const mapDispatchToProps = {
  updateUsersLifecycleStatus
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { idOrg, idUser, onUserLifecycleStatusUpdated } = ownProps
  return {
    ...stateProps,
    ...omit(dispatchProps, ['updateUserLifecycleStatus']),
    updateUserLifecycleStatus: async (lifecycleStatus) => {
      await dispatchProps.updateUsersLifecycleStatus({ idOrg, idUsers: [idUser], lifecycleStatus })
      onUserLifecycleStatusUpdated()
    },
    ...ownProps
  }
}

export default connect(null, mapDispatchToProps, mergeProps)(View)
