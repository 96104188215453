import keyBy from 'lodash/keyBy'
import partition from 'lodash/partition'
import pick from 'lodash/pick'
import get from 'lodash/get'
import { getTablesViews, getUserPreferences } from '../selectors/ui'
import { EMPTY_ARRAY, EMPTY_OBJECT, formFieldTypesToFieldTypes, TABLES } from '../constants'

export const getUserTablePreferences = (state, tableKey) => {
  const userPreferences = getUserPreferences(state)
  const userTablePreferences = (tableKey && userPreferences[tableKey]) || EMPTY_OBJECT
  return userTablePreferences
}

export const getTableSelectedViewID = (state, tableKey) => {
  const userTablePreferences = getUserTablePreferences(state, tableKey)
  const { id } = userTablePreferences
  return id
}

export const getTableSelectedView = (state, tableKey) => {
  const selectedViewId = getTableSelectedViewID(state, tableKey)
  const tableViews = getTablesViews(state)[tableKey] || {}
  return tableViews[selectedViewId]
}

export const getTableInfo = (tableKey, columnsConfiguration, filters, dynamicColumns, defaultSort) => {
  const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
    result[column] = true
    return result
  }, {})

  const filteredDynamicColumnsInfo = dynamicColumns
    .map(detail => ({ ...detail, key: detail.key || detail.systemKey, idField: detail.idField || detail.id }))
    .filter(detail => columnsConfiguration.includes(detail.key))
  const dynamicFilterOptions = dynamicColumns.map(detail => ({
    label: detail.name,
    value: detail.key || detail.systemKey,
    type: formFieldTypesToFieldTypes[detail.type],
    options: detail.options,
    idApp: get(detail, 'sourceIdApp', null)
  }))

  const allDynamicColumnsInfo = dynamicColumns
    .map(detail => ({ ...detail, key: detail.key || detail.systemKey, idField: detail.idField || detail.id }))

  const specialColumns = TABLES[tableKey].specialColumns || EMPTY_ARRAY
  const [specialColumnsInfo, dynamicColumnsInfo] = partition(filteredDynamicColumnsInfo, field => specialColumns.includes(field.key))
  const specialColumnsInfoByKey = keyBy(specialColumnsInfo, 'key')

  return {
    ...pick(TABLES[tableKey], ['key', 'name', 'preDefinedColumnsMapping', 'originFiltersOptions']),
    columnsConfiguration: columnsConfigurationByAccessor,
    dynamicColumnsInfo,
    specialColumnsInfo: specialColumnsInfoByKey,
    allDynamicColumnsInfo,
    filtersOptions: (TABLES[tableKey].filtersOptions || []).concat(dynamicFilterOptions),
    filters,
    defaultSort
  }
}
