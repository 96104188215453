import React from 'react'
import { Icon, Tooltip } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import { css } from 'glamor'
import Analytics from '../../helpers/analytics'
import { clearButton } from '@shared/style/mixins'
import Cookie from 'cookie'

const CSS = {
  deleteURLCookieButton: css(clearButton, {
    height: '36px',
    width: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    transition: 'background .2s',
    ':hover': {
      background: colors.lightBlue5,
      cursor: 'pointer'
    },
    ':focus': {
      background: colors.lightBlue5
    },
    ':active': {
      boxShadow: `0 0 0 2px ${colors.lightBlue5}`,
      background: colors.lightBlue5
    }
  })
}

const DeleteURLCookieButton = () => {
  const deleteCookie = (name) => {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  }

  const removeQueryParam = (param) => {
    const url = new URL(window.location.href)
    url.searchParams.delete(param)
    window.history.replaceState({}, document.title, url.pathname + url.search)
  }

  const onClick = () => {
    Analytics.track('Click to delete URL cookie')
    deleteCookie('API_BASE_URL')
    removeQueryParam('API_BASE_URL')
    window.location.reload()
  }
  const cookies = document ? Cookie.parse(document.cookie || '') : {}

  return cookies.API_BASE_URL ? <Tooltip
    placement='bottom'
    label={`Delete Preview URL: ${cookies.API_BASE_URL}`}>
    <button {...CSS.deleteURLCookieButton} onClick={() => onClick()}>
      <Icon name='Danger' size='mobile' color='error' />
    </button>
  </Tooltip> : null
}

export default DeleteURLCookieButton
