import React, { ReactElement } from 'react'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import * as Style from './style'
import { SubMenu } from '@toriihq/design-system'
import { ActionStep } from '@pages/workflow_v2/hooks/useActionsConfiguration/types'
import { analytics } from '@shared/services/workflows/analytics'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'

interface Props {
  steps: ActionStep[] | undefined
}

export const DrawerNavigation = ({ steps = [] }: Props): ReactElement => {
  const { idSelectedNodeStep, idSelectedNode, selectNodeStep } = useWorkflowNavigation()
  const workflow = useSelectedWorkflow()
  const { getActionLabel, getActionSteps } = useActionsConfiguration({ workflow })

  const drawerNavigationItems = steps.map((step, index) => {
    const { label, getState } = step
    const stepIndex = index.toString()
    const isStepSelected = stepIndex === idSelectedNodeStep
    const onClick = () => {
      if (!isStepSelected && idSelectedNode) {
        selectNodeStep(stepIndex)

        const actionName = getActionLabel(idSelectedNode)
        const stepName = getActionSteps(idSelectedNode)?.[index].label
        analytics.action.navigateToActionConfiguration({
          actionName: actionName!,
          stepName: stepName!
        })
      }
    }
    return { label, onClick, state: getState(isStepSelected) }
  })

  return (
    <Style.Container>
      <SubMenu items={drawerNavigationItems} />
    </Style.Container>
  )
}
