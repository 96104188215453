import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import EnableFor from '../../enableFor'
import { SCOPES } from '@root/constants'
import UserDetails from '../../userDetails'
import { getDisplayName } from '@lenses/users'
import isArray from 'lodash/isArray'
import SelectUsers from '@components/selectUsers'
import isString from 'lodash/isString'
import CustomOptionDetails from '@components/customOptionDetails'
import texts from '@shared/style/texts'
import CreateUserPopup from '@components/popups/createUserPopup'

const CSS = {
  container: css({
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    gap: '8px'
  }),
  specialOption: css({
    padding: '5px 15px'
  }),
  labelSpace: css({
    paddingRight: '5px'
  }),
  appOption: css({
    display: 'flex',
    height: '50px',
    alignItems: 'center'
  }),
  addNewUser: css(texts.body, {
    color: colors.blue,
    padding: '15px 5px'
  })
}

class SingleUser extends React.Component {
  onClickCustomEmailOption = (input) => {
    const { creatable, toggleAddUser, field } = this.props
    creatable && toggleAddUser(true, input, field.id)
  }

  onChangeTo = (selection) => {
    const { onChange } = this.props
    if (!selection) {
      return onChange(null)
    }
    if (selection.customEmail) {
      return this.onClickCustomEmailOption(selection.value)
    }
    const selectedValue = (selection.value || selection.id) || (isArray(selection) && selection.length > 0) || null
    onChange(selectedValue)
  }

  renderSpecialOption = (option) => {
    return (
      <div key={option.value || option.id}>
        <div>
          <CustomOptionDetails {...option} />
        </div>
      </div>
    )
  }

  renderCustomEmail = (option) => {
    return (
      <div {...CSS.addNewUser}>+ Add new user with email <strong>{option.value}</strong></div>
    )
  }

  renderOption = ({ data: option }) => {
    const isSpecialOption = isString(option.value)
    const isCustomEmail = option.customEmail
    if (isCustomEmail) {
      return this.renderCustomEmail(option)
    }
    if (isSpecialOption || option.app) {
      return this.renderSpecialOption(option)
    }

    return (
      <div key={option.value || option.id}>
        <div>
          <UserDetails {...option} showPastUserBadge />
        </div>
      </div>
    )
  }

  valueRenderer = ({ data: option }) => {
    return option.label || getDisplayName(option)
  }

  render () {
    const { value, overrideStyle, label, disabled, specialUsers, isCreateUserPopupOpen, creatable, actionType, isExternal, borderless } = this.props

    return (
      <div {...css(CSS.container, overrideStyle)} >
        {label}
        <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
          <SelectUsers
            optionRenderer={this.renderOption}
            valueRenderer={this.valueRenderer}
            value={value}
            onChange={this.onChangeTo}
            clearable={false}
            disabled={disabled}
            specialUsers={specialUsers}
            creatable={creatable}
            isExternal={isExternal}
            showSelectedChildren
            borderless={borderless}
          />
        </EnableFor>
        {isCreateUserPopupOpen
          ? <CreateUserPopup onSuccess={(usersList) => this.onChangeTo(usersList[usersList.length - 1])} selectedUsers={[value]} actionType={actionType} /> : null}
      </div>
    )
  }
}

SingleUser.propTypes = {
  selectClassName: PropTypes.string,
  overrideStyle: PropTypes.object,
  value: PropTypes.node,
  onChange: PropTypes.func,
  label: PropTypes.string,
  actionType: PropTypes.string,
  triggerType: PropTypes.string,
  specialUsers: PropTypes.object,
  creatable: PropTypes.bool,
  isExternal: PropTypes.bool
}

export default SingleUser
