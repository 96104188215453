import { useSelector } from 'react-redux'
import {
  Action,
  IdWorkflowNode,
  WORKFLOW_NODE_TYPE,
  WorkflowActionsModel,
  WorkflowNodeModel,
  WorkflowNodes
} from '@shared/workflows/types'
import {
  ActionConfiguration,
  ActionsConfigurationByIdAction,
  ActionsConfigurationUtils,
  ActionStep,
  ActionUiConfigStep,
  Props
} from './types'
import { getActionsConfigByType } from '@selectors/workflows'
import { get, isNil } from 'lodash'
import { WORKFLOW_ACTION_TYPES } from '@root/constants'
import {
  defaultActionConfig,
  getActionValidationObj,
  getNodeStepState,
  getStepLabel,
  isConfigStepDisabled,
  isFieldsValid
} from './utils'
import { getFinalActionLabelV2 } from '@lenses/workflows'

export const useActionsConfiguration = ({ workflow } : Props): ActionsConfigurationUtils => {
  const actionsConfigByType = useSelector(getActionsConfigByType)

  const createActionConfiguration = (idWorkflowNode: IdWorkflowNode, workflowNode: WorkflowNodeModel): ActionConfiguration => {
    const workflowAction: Action = workflowNode.action
    const workflowActionType = workflowAction.type

    const isActionSelected = Boolean(workflowActionType)
    const actionErrorMsg = (!isActionSelected && workflowAction.errorMsg) || ''

    const actionConfig = workflowActionType ? actionsConfigByType[workflowActionType] : {}
    const actionConfigurationSteps: ActionStep[] = []

    const configStepDisabled = isConfigStepDisabled(workflowNode.type)
    const actionSelectionStep: ActionStep = {
      type: workflowActionType || WORKFLOW_ACTION_TYPES.SELECT,
      label: 'Action',
      isValid: isActionSelected,
      errorMsg: actionErrorMsg,
      getState: (isSelectedNodeStep) => getNodeStepState(isSelectedNodeStep, configStepDisabled)
    }
    actionConfigurationSteps.push(actionSelectionStep)

    const actionUiConfig = actionConfig.uiConfig || defaultActionConfig
    const actionInputSchema = actionConfig.inputSchema || {}

    const numberOfActionSteps = actionUiConfig.steps.length
    actionUiConfig.steps.forEach((step: ActionUiConfigStep, index) => {
      const isValidFields = isFieldsValid({ step, actionInputSchema, workflowAction })
      const actionValidationObj = getActionValidationObj({ isValidFields, workflowAction })

      const configurationStepIndex = index + 1
      const configurationStep: ActionStep = {
        type: step.type,
        label: getStepLabel({ currentStep: step, currentStepIndex: configurationStepIndex, totalNumberOfSteps: numberOfActionSteps }),
        ...actionValidationObj,
        getState: (isSelectedNodeStep) => getNodeStepState(isSelectedNodeStep, !isActionSelected)
      }
      actionConfigurationSteps.push(configurationStep)
    })

    const hasInvalidStep = actionConfigurationSteps.some(step => !step.isValid)
    const uiConfig = actionConfig.uiConfig
    return {
      id: idWorkflowNode,
      type: workflowNode.type,
      label: getFinalActionLabelV2(actionConfig, workflowAction),
      steps: actionConfigurationSteps,
      thenText: get(uiConfig, ['thenText'], ''),
      isValid: !hasInvalidStep,
      continueOnError: workflowAction.continueOnError,
      branchesConfiguration: get(uiConfig, ['branchesConfiguration']),
      imageUrl: uiConfig?.imageUrl
    }
  }

  const getActionsConfigurationByIdAction = (): ActionsConfigurationByIdAction => {
    const actionsConfigurationByIdAction = {}
    const workflowActions: WorkflowActionsModel | null = workflow.actions
    const workflowNodes: WorkflowNodes = workflowActions?.nodes || {}

    for (const [idWorkflowNode, workflowNode] of Object.entries(workflowNodes)) {
      const actionConfiguration = createActionConfiguration(idWorkflowNode, workflowNode)
      actionsConfigurationByIdAction[idWorkflowNode] = actionConfiguration
    }

    return actionsConfigurationByIdAction
  }

  const getActionConfiguration = (idAction: IdWorkflowNode): ActionConfiguration | undefined => {
    const actionsConfigurationByIdAction = getActionsConfigurationByIdAction()
    return actionsConfigurationByIdAction[idAction]
  }

  const getActionLabel = (idAction: IdWorkflowNode): string | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.label
  }

  const getActionSteps = (idAction: IdWorkflowNode): ActionStep[] | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.steps
  }

  const doesActionExist = (idAction: IdWorkflowNode): boolean =>
    !isNil(getActionConfiguration(idAction))

  const isActionValid = (idAction: IdWorkflowNode): boolean | undefined => {
    const actionConfiguration = getActionConfiguration(idAction)
    return actionConfiguration?.isValid
  }

  const isActionTypeSelected = (idWorkflowNode: IdWorkflowNode): boolean => {
    const workflowNode = workflow.actions?.nodes[idWorkflowNode]
    if (!workflowNode) {
      return false
    }

    if (workflowNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
      return true
    }

    if (workflowNode) {
      const { action } = workflowNode
      return Boolean(action.type)
    }

    return false
  }

  const getFixedBranchesLabelsText = (idAction: IdWorkflowNode): string => {
    const actionConfiguration = getActionConfiguration(idAction)
    if (!actionConfiguration?.branchesConfiguration) {
      return ''
    }

    const { default: defaultBranch, branches } = actionConfiguration.branchesConfiguration
    return [...branches.map(branch => branch.label), defaultBranch.label]
      .map(label => `"${label}"`)
      .join(' and ')
  }

  return {
    getActionConfiguration,
    getActionLabel,
    doesActionExist,
    isActionValid,
    getActionSteps,
    isActionTypeSelected,
    getFixedBranchesLabelsText
  }
}
