import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { Tooltip, Icon, Body2 } from '@toriihq/design-system'
import Placeholder from '../../placeholder'
import Select from '../../select'
import { SCOPES } from '@root/constants'
import EnableFor from '@components/enableFor'

const CSS = {
  main: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    padding: '21px 20px',
    background: colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    ':not(:last-child)': {
      marginBottom: '14px'
    }
  }),
  line: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  })
}

class Setting extends React.Component {
  render () {
    const { options, id, name, description, frequency, onChange, loading, scopes } = this.props
    return (
      <Placeholder loading={loading} rows={2} style={{ maxWidth: '80%', marginTop: '10px' }}>
        <EnableFor scopes={scopes}>
          <div {...CSS.main}>
            <div {...CSS.line}>
              <Body2>{name}</Body2>
              {description && <Tooltip label={description} placement='right' >
                <Icon name='Info' color='secondary' />
              </Tooltip>}
            </div>
            <EnableFor scopes={scopes}>
              <Select
                options={options}
                value={frequency}
                clearable={false}
                openOnFocus
                onChange={(selected) => {
                  const value = (selected && selected.value)
                  onChange({ prevFrequency: frequency, frequency: value, idField: id })
                }}
              />
            </EnableFor>
          </div>
        </EnableFor>
      </Placeholder>
    )
  }
}

Setting.propTypes = {
  name: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  scopes: PropTypes.arrayOf(PropTypes.oneOf(Object.values(SCOPES)))
}

Setting.defaultProps = {
  scopes: []
}

export default Setting
