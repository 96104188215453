import React from 'react'
import PropTypes from 'prop-types'
import { Col, RowWithoutMargin } from '@toriihq/design-system'
import MonthlyRenewalBox from '../../components/monthlyRenewalBox'
import map from 'lodash/map'
import moment from 'moment'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import colors from '../../shared/style/colors'
import pluralize from 'pluralize'
import { CopyTextPopup } from '../copyTextPopup'
import { SUPPORT_ARTICLES } from '@root/articles'
import config from '@root/config'

const CSS = {
  yearRow: css({
    marginBottom: '10px',
    ' >div': {
      display: 'flex',
      flex: 1,
      justifyContent: 'space-between'
    }
  }),
  year: css(texts.headers.regular, {
    color: colors.text
  }),
  calendarRow: css({
    width: '100%',
    display: 'flex',
    ' >div:nth-child(1)': {
      border: `solid ${colors.border}`,
      borderWidth: '0 1px 1px 0'
    },
    ' >div:nth-child(2)': {
      border: `solid ${colors.border}`,
      borderWidth: '0 1px 1px 0'
    },
    ' >div:nth-child(3)': {
      borderBottom: `1px solid ${colors.border}`
    }
  }),
  smallCalendarRow: css({
    width: '100%',
    ' >div': {
      borderBottom: `1px solid ${colors.border}`
    }
  }),
  share: css({
    display: 'flex',
    justifyContent: 'flex-end'
  })
}

CSS.yearlyCalendar = css({
  border: `1px solid ${colors.border}`,
  borderRadius: '4px',
  [` >.${CSS.calendarRow}:nth-last-child(1) >div, >[data-${CSS.calendarRow}]:nth-last-child(1) >div`]: {
    borderWidth: '0 1px 0 0'
  },
  [` >.${CSS.smallCalendarRow}:nth-last-child(1), >[data-${CSS.smallCalendarRow}]:nth-last-child(1)`]: {
    ' >div:nth-last-child(2)': {
      borderWidth: '0 0 0 0'
    }
  }

})

class RenewalsCalendar extends React.Component {
  render () {
    const { idOrg, loading, isSmallScreen, rowStyle, years, appsInfo, renewals, monthsInRow, contractStatusOptionsByValue, calendars } = this.props
    const now = moment().startOf('month')
    const [renewalCalendar] = calendars

    return (
      <>
        {renewalCalendar && <div {...CSS.share}>
          <CopyTextPopup
            icon='Share'
            label='Share Calendar'
            textToCopy={`${config.apiBaseUrl}/api/orgs/${idOrg}/ical/${renewalCalendar.uuid}`}
            analyticsText='Click on Copy iCalendar link'
            bodyText='Share the renewal calendar by adding the following public URL to Google Calendar or Outlook:'
            supportArticleLink={SUPPORT_ARTICLES.RENEWAL_CALENDAR_SHARE}
          />
        </div>}
        {
          map(years, (year, yearKey) => {
            const rowsAmount = year.length / monthsInRow
            const months = []
            for (let rowNumber = 1; rowNumber <= rowsAmount; rowNumber++) {
              const currRowMonthsIndex = year.slice((rowNumber - 1) * monthsInRow, (rowNumber * 3))

              const currRowMonths = currRowMonthsIndex.map(month => {
                const date = moment(`01/${month}/${yearKey}`, 'DD/MM/YYYY')
                const key = `${yearKey}_${month}`
                const renewalsInMonth = renewals[key] || []
                return (
                  <Col xs={isSmallScreen ? 12 : 4} key={key}>
                    <MonthlyRenewalBox
                      key={key}
                      renewals={(renewalsInMonth).sort((a, b) => a.date.diff(b.date))}
                      header={`${date.format('MMMM')}`}
                      subHeader={`${renewalsInMonth.length} ${pluralize('app', renewalsInMonth.length)}`}
                      isSelected={now.isSame(date)}
                      isPassed={date < now}
                      appsInfo={appsInfo}
                      loading={loading}
                      contractStatusOptionsByValue={contractStatusOptionsByValue}
                    />
                  </Col>
                )
              })

              const calendarRowStyle = isSmallScreen ? CSS.smallCalendarRow : CSS.calendarRow
              months.push(<RowWithoutMargin key={rowNumber} nogutter {...calendarRowStyle}>{currRowMonths}</RowWithoutMargin>)
            }

            return (
              <div key={yearKey}>
                <RowWithoutMargin nogutter {...css(rowStyle, CSS.yearRow)}>{yearKey}</RowWithoutMargin>
                <RowWithoutMargin nogutter {...css(rowStyle, CSS.yearlyCalendar)}>{months}</RowWithoutMargin>
              </div>
            )
          })
        }
      </>
    )
  }
}

RenewalsCalendar.propTypes = {
  contractStatusOptionsByValue: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isEmptyState: PropTypes.bool.isRequired
}

export default RenewalsCalendar
