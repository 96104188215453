import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../table/index'
import { ITEMS_PER_PAGE, TABLES } from '../../../constants'
import colors from '../../../shared/style/colors'
import { getNameColumn } from '../columns'
import emptyImage from '@media/dance.svg'
import { objectToQueryString, sortStringFromArray } from '../../../shared/utils'
import debounce from 'lodash/debounce'
import config from '../../../config'
import { activeAppsFilter, externalUsersBaseFilters, isParentUserFilter } from '../../../shared/actions'
import { renderAppCell, renderAppTextValue } from '@root/components/table/appCell'
import { isFieldSupportAutocompleteValues } from '@shared/fieldUtils'
import { EmptyState } from '@toriihq/design-system'

const DEFAULT_FIELDS_TO_FETCH = ['firstName', 'lastName', 'email', 'activeAppsCount', 'idApps', 'domain', 'lifecycleStatus', 'additionalEmails']

class ExternalUsers extends React.Component {
  state = { dataNotFetched: !this.props.users.length }

  componentDidMount () {
    const { idOrg, getExternalUsersAmount } = this.props
    this.fetchData(true)
    getExternalUsersAmount({ idOrg })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      this.fetchData(true)
    }
  }

  columns = [
    {
      ...getNameColumn(),
      id: 'fullName'
    },
    {
      Header: 'Additional emails',
      id: 'additionalEmails',
      accessor: 'additionalEmails',
      width: 160,
      Cell: ({ row: { additionalEmails } }) => additionalEmails?.join(', '),
      show: true
    },
    {
      accessor: 'email',
      show: false,
      textValue: ({ email }) => email,
      textHeader: 'Email'
    },
    {
      Header: 'Domain',
      id: 'domain',
      width: 160,
      accessor: 'domain'
    },
    {
      Header: 'Apps used',
      id: 'activeAppsCount',
      accessor: 'activeAppsCount',
      width: 100,
      ...Table.numericFieldProps
    },
    {
      id: 'idApps',
      accessor: (row) => (row.idApps || []).length,
      textValue: (row) => renderAppTextValue({ original: row.original, apps: this.props.apps }),
      Cell: (row) => renderAppCell({ original: row.original, apps: this.props.apps, numberOfOwnedAppsToDisplay: 3 }),
      Header: 'Apps',
      width: 160,
      sortable: false,
      hideFromCSV: true
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    },
    {
      accessor: 'lifecycleStatus',
      show: false
    }
  ]

  getUsers = async ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false, props = this.props, filters = this.props.tableInfo.filters, sort = this.props.defaultSort, q = this.q }) => {
    const { idOrg, getExternalUsers } = props

    await getExternalUsers({ idOrg, fields: DEFAULT_FIELDS_TO_FETCH, limit, offset, sort: sortStringFromArray(sort), q, reset, filters })
    this.setState({ dataNotFetched: false })
  }

  onFilterChange = debounce((filters) => {
    this.getUsers({ filters, reset: true })
  }, 250)

  fetchFieldValues = (field) => {
    const { idOrg, getExternalUsersFieldValues, tableInfo: { filtersOptions: options } } = this.props

    const isFieldSupportValues = isFieldSupportAutocompleteValues({
      fieldOptions: options,
      fieldOption: field
    })
    if (isFieldSupportValues) {
      getExternalUsersFieldValues({ idOrg, fields: [field] })
    }
  }

  fetchData = (reset = false) => {
    const { users } = this.props

    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }

  onSearch = debounce((q) => {
    this.q = q
    this.getUsers({ q, reset: true })
  }, 500)

  onSortedChange = debounce((sort) => {
    this.getUsers({ sort, reset: true })
  }, 500)

  renderNoUsers = () => {
    return <EmptyState
      image={<img src={emptyImage} alt='No users' />}
      title='There are no external users in your organization'
    />
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  exportToCsv = ({ sort, query, filters }) => {
    const { idOrg } = this.props

    const sortParam = 'sort=' + sortStringFromArray(sort)
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${this.columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',').replace('fullName', 'firstName,lastName,email')}`
    const pageFilters = objectToQueryString(externalUsersBaseFilters)
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters.concat([activeAppsFilter, isParentUserFilter])))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${pageFilters}&${filtersParam}&includeAppsColumn=true`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  render () {
    const { users, loading, loadingMore, tableInfo, fieldsValues, total } = this.props
    const { dataNotFetched } = this.state

    return (
      <Table
        tableKey={TABLES.externalUsersTable.key}
        emptyStateMessage={this.renderNoUsers()}
        data={users}
        columns={this.columns}
        loading={loading || dataNotFetched}
        loadingMore={loadingMore}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        getTdProps={setTdStyle}
        filterable
        filtersOptions={tableInfo.filtersOptions}
        filterOptionsValuesPerKey={fieldsValues}
        manual
        fetchData={this.fetchData}
        onSortedChangeCB={this.onSortedChange}
        onSearch={this.onSearch}
        forceShowSearch
        exportFunction={this.exportToCsv}
        onFilterChange={this.onFilterChange}
        fetchFieldValues={this.fetchFieldValues}
        totalCount={total}
      />
    )
  }
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

ExternalUsers.propTypes = {
  apps: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({
    idApps: PropTypes.arrayOf(PropTypes.number),
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
  }))
}

export default ExternalUsers
