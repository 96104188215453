import View from './view'
import { connect } from 'react-redux'
import {
  updateAppChargebackConfig,
  deleteAppChargebackConfig,
  getUserDetailsFields,
  getUserFieldDisplayNames,
  getAppChargebackConfig,
  getAppChargeback,
  bulkUpdateLicensesTypes,
  getLicensesTypes,
  updateLicenseCurrencyAndDate
} from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { getChargebackUserDetailsFieldsAsSelectOptions, getUserFieldsDisplayNamesSelector } from '@selectors/userDetails'
import { getAppChargebackConfig as getAppChargebackConfigSelector, isLoadingChargebackConfig as isLoadingChargebackConfigSelector } from '@selectors/chargeback'
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../constants'
import { getLicensesTypes as getLicensesTypesSelector, isLoadingLicensesTypes } from '@selectors/licenses'
import { getCurrencySymbol } from '@selectors/org'
import { getCurrencySymbols } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const { id: idOrg } = getCurrentOrg(state) || {}
  const { app } = ownProps
  const { id: idApp, name: appName } = app
  const currencySymbol = getCurrencySymbol(state)
  const currencySymbols = getCurrencySymbols(state)

  const userFieldsOptions = getChargebackUserDetailsFieldsAsSelectOptions(state)
  const appChargebackConfig = getAppChargebackConfigSelector(state)[app.id] || EMPTY_OBJECT
  const isLoading = isLoadingChargebackConfigSelector(state)

  const userFieldsDisplayNames = getUserFieldsDisplayNamesSelector(state) || EMPTY_OBJECT

  const licenses = getLicensesTypesSelector(state)[idApp] || EMPTY_ARRAY
  const isLoadingLicenses = isLoadingLicensesTypes(state)

  return {
    idOrg,
    idApp,
    appName,
    isLoading,
    userFieldsOptions,
    userFieldsDisplayNames,
    appChargebackConfig,
    licenses,
    isLoadingLicenses,
    currencySymbol,
    currencySymbols
  }
}

const mapDispatchToProps = {
  getUserDetailsFields,
  getUserFieldDisplayNames,
  getAppChargebackConfig,
  updateAppChargebackConfig,
  deleteAppChargebackConfig,
  bulkUpdateLicensesTypes,
  updateLicenseCurrencyAndDate,
  getLicensesTypes,
  getAppChargeback
}

export { validate } from './view'

export default connect(
  mapStateToProps,
  mapDispatchToProps)(View)
