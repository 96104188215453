import send from '@shared/redux-fetch'
import {
  GET_APP_DATA_FROM_AI, GET_CONTRACT_DETAILS_FROM_AI
} from '@root/constants'
import { isUndefined, omitBy } from 'lodash'

export const getDataAboutAppFromAI = ({ appName }) => {
  return send(GET_APP_DATA_FROM_AI, {
    url: `/api/ai/app?appName=${encodeURIComponent(appName)}`
  })
}

export const getContractDetailsFromAI = ({ idOrg, idUpload, idApp }: { idOrg: number, idUpload: number, idApp }) => {
  return send(GET_CONTRACT_DETAILS_FROM_AI, {
    url: `/api/orgs/${idOrg}/contracts/details/ai/${idUpload}`,
    query: omitBy({ idApp }, isUndefined)
  })
}
