import React, { useState } from 'react'
import UploadFileArea, { UPLOAD_FILE_POST_PROCESS_STATUS } from '@root/components/uploadFileArea'
import { UPLOAD_TYPES } from '@root/constants'
import { Spacer, Body2, Link } from '@toriihq/design-system'
import { PARSING_STATUS, UPLOAD_ERROR_TEXTS } from '../../constants'
import Analytics from '../../analytics'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { IMPORT_USERS_COLUMNS, SharedProps, SharedState } from '../types'
import { useDispatch } from 'react-redux'
import { createUsersImportParsing, getUsersImportParsing, getUsersImportPreviewChanges } from '@root/shared/actions'
import { getSelectedDateFormat } from '../../shared'
import { UsePolling } from '../usePolling'
import { IMPORT_METHOD } from '../importMethod/types'

const HELP_CENTER_ARTICLE_URL = 'https://support.toriihq.com/hc/en-us/articles/12256710253211-Add-Users-Manually'

const UploadFile = ({
  navigateToNextStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const [errorType, setErrorType] = useState<string>('')
  const dispatch = useDispatch()

  const onPollFailure = (parsingResult: { errorCode?: string }) => {
    const { errorCode } = parsingResult
    clearPolling()
    setIsError(true)
    errorCode && setErrorType(errorCode)
    throw new Error(errorCode)
  }

  const { startPolling, isError, setIsError, clearPolling } = UsePolling({
    onPollFailure
  })

  const pollLogic = async (idOrg, idApp, idUsersImportParsing, idAppAccount) => {
    const response = await dispatch(getUsersImportParsing({ idOrg, idApp, idUsersImportParsing }))
    const { parsingStatus, parsingPreviewData, parsingResult } = response
    if (parsingStatus === PARSING_STATUS.parsingSucceeded) {
      const { filePreview, suggestedMapping, suggestedDateFormat } = parsingPreviewData
      const columnsKeys = Object.keys(suggestedMapping) as IMPORT_USERS_COLUMNS[]
      const selectedDateFormat = getSelectedDateFormat(suggestedDateFormat)

      clearPolling()
      setIsError(false)

      const newState = {
        idUsersImportParsing,
        idAppAccount,
        filePreview,
        previewDataMapping: suggestedMapping,
        selectedColumnsToImport: columnsKeys,
        suggestedDateFormat,
        selectedDateFormat,
        originalSuggestions: { suggestedMapping, suggestedDateFormat }
      }

      setState({ ...sharedState, ...newState })

      if (parsingResult?.customParserName) {
        const previewChangesResponse = await dispatch(getUsersImportPreviewChanges({ idOrg, idApp, idUsersImportParsing, idAppAccount }))
        const { users: { validUsers, invalidUsers, missingUsers }, uiConfig } = previewChangesResponse
        const { customParserName, importedColumns } = parsingResult

        setState({
          ...sharedState,
          ...newState,
          customParser: {
            name: customParserName,
            columnsToImport: importedColumns,
            uiConfig
          },
          parsingPreviewChanges: { validUsers, invalidUsers, missingUsers },
          importMethod: IMPORT_METHOD.ADD_UPDATE_REMOVE
        })

        navigateToNextStep(4)
      } else {
        navigateToNextStep(sharedProps.isFirstTimeImport ? 2 : 1)
      }
    } else if (parsingStatus === PARSING_STATUS.parsingFailed) {
      const { parsingResult } = response
      onPollFailure(parsingResult)
    }
  }

  const onSuccessfulUpload = async (idOrg: number, idUpload: number): Promise<{ status: string }> => {
    try {
      const { idUsersImportParsing, idAppAccount } = await dispatch(createUsersImportParsing({ idOrg, idApp: sharedProps.idApp, idUpload }))
      await startPolling(async () => pollLogic(idOrg, sharedProps.idApp, idUsersImportParsing, idAppAccount))
      return { status: UPLOAD_FILE_POST_PROCESS_STATUS.SUCCESS }
    } catch (error) {
      setIsError(true)
      return { status: UPLOAD_FILE_POST_PROCESS_STATUS.ERROR }
    }
  }

  const learnMoreLink = (
    <Body2 color='secondary'>Learn more about importing users <Link color='primary' target={'_blank'} href={HELP_CENTER_ARTICLE_URL} rel={'noopener noreferrer'} onClick={() => Analytics.onLearnMoreClickAnalytics()}>{'here'}</Link></Body2>
  )

  const instructionText = isError ? <></> : (
    <>
      <Spacer bottom='space-300'>
        <Body2 color='primary'>
          Import an active user list from the application's admin console or create your own spreadsheet and upload it in CSV format. The file must include user emails. Adding user licenses and last used date data is recommended but optional.<br /><br />
          You will be able to review the results before completing the import.
        </Body2>
      </Spacer>
      <Spacer bottom='space-300'>
        {learnMoreLink}
      </Spacer>
    </>
  )

  return (
    <>
      <ToriiPopup.Header header='Import users' />
      <ToriiPopup.Content>
        <UploadFileArea
          popupInstructions={instructionText}
          validFileTypes={['text/csv', 'application/vnd.ms-excel']}
          uploadedFileType={UPLOAD_TYPES.APP_USERS_IMPORT}
          successMessage={<>File uploaded</>}
          isError={isError}
          fileDescription={'CSV'}
          onShowFooter={() => {}}
          hideFooterAfterSuccess
          postUploadAction={onSuccessfulUpload}
          errorInfo={UPLOAD_ERROR_TEXTS[errorType]}
          idApp={sharedProps.idApp}
          uploadEventType='App users list'
        />
      </ToriiPopup.Content>
    </>
  )
}

export default UploadFile
