import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOffboardingSettings, updateAppOffboardingSettings } from '@shared/actions'
import { getCurrentOrg } from '@selectors/me'
import { getAppOffboardingSettings, isLoadingAppOffboardingSettings } from '@selectors/org'
import EnableFor from '@components/enableFor'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import ToriiSelect from '@components/select'
import { SCOPES } from '@root/constants'
import isEmpty from 'lodash/isEmpty'
import AdvancedToggle from '@components/advancedToggle'
import * as Style from '../styles'

const REMINDER_INTERVAL_OPTIONS = [
  { label: 'every day', value: 1 },
  { label: 'every 3 days', value: 3 },
  { label: 'once a week', value: 7 },
  { label: 'every 2 weeks', value: 14 }
]

const RemindersSettings = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const offboardingSettings = useSelector(getAppOffboardingSettings)
  const loading = useSelector(isLoadingAppOffboardingSettings) || isEmpty(offboardingSettings)
  const dispatch = useDispatch()

  const updateOffboardingConfig = ({ remindersEnabled, remindersIntervalInDays }) => {
    const dataToUpdate = omitBy({ remindersEnabled, remindersIntervalInDays }, isUndefined)

    dispatch(updateAppOffboardingSettings({ idOrg, ...dataToUpdate }))
  }

  useEffect(() => {
    idOrg && dispatch(getOffboardingSettings({ idOrg }))
  }, [dispatch, idOrg])

  const SelectReminders = () => (<Style.SelectRemindersWrapper>
    <span>Send reminders' frequency</span>
    <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <ToriiSelect
        options={REMINDER_INTERVAL_OPTIONS}
        value={offboardingSettings.remindersIntervalInDays}
        onChange={(option) => updateOffboardingConfig({ remindersIntervalInDays: option.value })}
        clearable={false}
        isLoading={loading}
        disabled={!offboardingSettings.remindersEnabled}
      />
    </EnableFor>
  </Style.SelectRemindersWrapper>)

  return (<AdvancedToggle
    checked={Boolean(offboardingSettings.remindersEnabled)}
    loading={loading}
    toggleId={'email_reminders_toggle'}
    text={'Send reminders about tasks delegated by email or Slack'}
    tooltipText={'The configuration will also apply to user removal requests sent by workflows'}
    scopes={[SCOPES.AUTOMATION_WRITE]}
    onToggle={(remindersEnabled) => updateOffboardingConfig({ remindersEnabled })}
    subComponent={<SelectReminders />}
  />)
}

export default RemindersSettings
