import View from './view'
import { connect } from 'react-redux'
import { searchUserRestricted } from '../../shared/actions'
import { withRouter } from 'react-router-dom'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  return {
    idOrg
  }
}

const mapDispatchToProps = {
  searchUserRestricted
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
