import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import colors from '../../../shared/style/colors'
import { WHITE_SPACE } from '../../../constants'
import { fontSize } from '../../../shared/style/sizes'
import { usePrevious } from '@shared/hooks'
import { StyledLink } from '@components/_shared/styledLink'

const CSS = {
  main: css({
    marginBottom: 24
  }),
  label: css(texts.heading, {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    color: colors.black,
    marginBottom: '10px'
  }),
  errorMessage: css(texts.body, {
    color: colors.darkRed,
    marginTop: '5px',
    height: 0,
    transition: 'height 0.2s',
    overflow: 'hidden'
  }),
  required: css({
    color: colors.blue
  }),
  disabled: css({
    opacity: 0.5
  }),
  details: css({
    marginTop: '10px',
    fontSize: fontSize.small,
    color: colors.grey1
  }),
  labelTextStart: css({
    marginRight: '5px'
  }),
  labelTextEnd: css({
    marginRight: 'auto'
  }),
  tooltip: css({
    marginLeft: '4px',
    '> span': {
      verticalAlign: 'middle'
    }
  })
}

const FormGroup = (props) => {
  const { disabled, label, labelAction, details, error, children, isRequired, overrideStyle, overrideErrorMessage, labelStyle, tooltip = null, learnMoreUrl } = props
  const required = isRequired ? <span {...CSS.required}> *</span> : ''
  const splittedLabelByLines = (label || '').split('\n')

  return <div {...css(CSS.main, overrideStyle)}>
    {label && <div {...css(CSS.label, disabled && CSS.disabled, labelStyle)}>
      <div {...css((labelAction && labelAction.isJustifyToEnd) || learnMoreUrl ? CSS.labelTextEnd : CSS.labelTextStart)}>
        {splittedLabelByLines.map((line, index) => index !== splittedLabelByLines.length - 1 ? <div key={`splittedLabelByLines-${index}`}>{line}</div> : <span key={index}>{line}</span>)}
        {required}
        {tooltip && <div {...CSS.tooltip}>{tooltip}</div>}
      </div>
      {learnMoreUrl && <StyledLink href={learnMoreUrl}>Learn more</StyledLink>}
      {labelAction && <div>{labelAction.component}</div>}
    </div>}
    <div>
      {children}
    </div>
    {details && <div {...CSS.details}><span dangerouslySetInnerHTML={{ __html: details }} /></div>}
    <ErrorMessageAnimation error={error} overrideStyle={overrideErrorMessage} />
  </div>
}

const ErrorMessageAnimation = ({ error, overrideStyle }) => {
  const prevErrorForAnimation = usePrevious(error)

  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  useEffect(() => {
    setHeight(ref.current.clientHeight)
  }, [error])

  return (
    <div {...css(CSS.errorMessage, error && { height }, overrideStyle)}>
      <div ref={ref}>{error || prevErrorForAnimation || WHITE_SPACE}</div>
    </div>
  )
}

FormGroup.propTypes = {
  overrideStyle: PropTypes.object,
  overrideErrorMessage: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  labelAction: PropTypes.object,
  details: PropTypes.string,
  children: PropTypes.node,
  isRequired: PropTypes.bool,
  labelStyle: PropTypes.object,
  learnMoreUrl: PropTypes.string,
  tooltip: PropTypes.element
}

export default FormGroup
