import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { getCurrentOrg } from '@selectors/me'
import { getMatchingRules } from '@selectors/transactions'
import { useDispatch, useSelector } from 'react-redux'
import {
  getOrgMatchingRules,
  updateOrgMatchingRules
} from '@shared/actions'
import EnableFor from '@components/enableFor'
import { SCOPES, TRANSACTION_MAPPING_STATUS } from '@root/constants'
import DraggableOptions from '@components/draggableOptions'
import * as Style from './style'
import TransactionMatchingRule from '@components/transactionMatchingRules/transactionMatchingRule'
import keyBy from 'lodash/keyBy'
import colors from '@root/shared/style/colors'
import { Field, Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import analytics from '@root/helpers/analytics'
import { Spacer, Body2, Button, ButtonSize, ButtonType, Icon, toast, ToastType } from '@toriihq/design-system'
import { removeItemAtIndex } from '@lenses/utils'

export const TransactionMatchingRulesComponent = (props) => {
  const { disabled, onAddRuleClick } = props

  const [isMatchingRulesChanged, setIsMatchingRulesChanged] = useState(false)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const initialMatchingRules = useSelector(getMatchingRules)

  const [reset, setReset] = useState(false)
  const [rules, setRules] = useState(initialMatchingRules)

  const dispatch = useDispatch()

  const [value, setValue] = useState(null)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (idOrg) {
      dispatch(getOrgMatchingRules({ idOrg }))
    }
  }, [dispatch, idOrg])

  useEffect(() => {
    setRules(initialMatchingRules)
  }, [initialMatchingRules])

  useEffect(() => {
    if (rules) {
      setValue(rules.map(rule => rule.id))
    }
  }, [rules])

  const onChange = async ({ rules, isLocally = true, reset = false }) => {
    if (isLocally) {
      setReset(reset)
      setRules(rules)
      setTimeout(() => setReset(false), 0)
    } else {
      dispatch(await updateOrgMatchingRules({ rules, idOrg }))
      toast({
        message: 'Your changes were saved successfully. Please allow a few minutes for matching updates.',
        type: ToastType.SUCCESS
      })
    }
  }

  const sendAnalyticsOnChange = (matchingRuleChange) => {
    let analyticName = null
    const matchingRule = rules.find(rule => rule.id === matchingRuleChange.id)
    const { op, term, mappingStatus, app } = matchingRule

    let properties
    const idApp = matchingRuleChange.app ? matchingRuleChange.app.id : null
    if (matchingRuleChange.term !== term) {
      analyticName = 'Update existing-expense-matching-rules-text'
      properties = { 'New text': matchingRuleChange.term }
    } else if (matchingRuleChange.mappingStatus !== mappingStatus) {
      analyticName = 'Select existing-expense-matching-rules-operator'
      properties = { 'Rule type': matchingRuleChange.mappingStatus }
    } else if (idApp !== (app ? app.id : null)) {
      analyticName = 'Select existing-expense-matching-rules-app'
      properties = { 'App name': matchingRuleChange.app.name }
    } else if (matchingRuleChange.op !== op) {
      analyticName = 'Select existing-expense-matching-rules-operator'
      properties = { Operator: matchingRuleChange.op }
    }

    analyticName && analytics.track(analyticName, properties)
  }

  const onEditRule = (rule) => {
    const updatedRules = replaceItemById(rules, rule)
    setIsMatchingRulesChanged(true)
    sendAnalyticsOnChange(rule)
    onChange({ rules: updatedRules }).then()
  }

  const onApply = () => {
    onChange({ rules, isLocally: false })
    setIsMatchingRulesChanged(false)
    setErrors({})
    analytics.track('Edit existing-expense-matching-rules', { 'Button label': 'Apply changes' })
  }

  const onCancel = () => {
    onChange({ rules: initialMatchingRules, reset: true })
    setIsMatchingRulesChanged(false)
    setErrors({})
  }

  const onRemove = (index) => {
    const updatedRules = removeItemAtIndex(rules, index)
    onChange({ rules: updatedRules }).then()
    setIsMatchingRulesChanged(true)
  }

  const onDraggableChange = (selection) => {
    const rulesById = keyBy(rules, 'id')
    const updatedRules = selection.map(id => rulesById[id])
    setIsMatchingRulesChanged(true)
    analytics.track('Reorder existing-expense-matching-rules')
    onChange && onChange({ rules: updatedRules })
  }

  const renderRule = (rule, { disabled }) => {
    return (
      <Field key={rule.id} name={`rule_${rule.id}.label`}>
        {({ input, meta }) => (
          <TransactionMatchingRule
            key={rule.id}
            rule={rule}
            reset={reset}
            disabled={disabled}
            onChange={(e) => {
              input.onChange(e)
              onEditRule(e)
            }}
            removeFieldsBorders
            spacesBetweenFields
            updateErrorsStatus={updateErrorsStatus}
          />
        )}
      </Field>
    )
  }

  const updateErrorsStatus = (e) => {
    const { id, error, field } = e
    setErrors({
      ...errors,
      [id]: {
        ...errors[id],
        [field]: error
      }
    })
  }

  const fieldsWithoutErrors = () => {
    const ids = Object.keys(errors)
    return ids.every(id => {
      const ruleErrors = errors[id]
      const fields = Object.keys(ruleErrors)
      return fields.every(field => !ruleErrors[field])
    })
  }

  const areAllRulesValid = useMemo(() => {
    return rules.every(rule => (
      rule.term &&
      rule.term !== '' &&
      [TRANSACTION_MAPPING_STATUS.MAPPED, TRANSACTION_MAPPING_STATUS.IGNORED].includes(rule.mappingStatus) &&
      (rule.mappingStatus === TRANSACTION_MAPPING_STATUS.MAPPED ? !!rule.app : true)
    ))
  }, [rules])

  return (
    <Fragment>
      <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
        <Form
          initialValues={{ rules }}
          mutators={{
            ...arrayMutators
          }}
          onSubmit={() => {}}
          render={(formProps) => {
            return (
              <div {...Style.MatchingRulesTableContainer}>
                <div {...Style.MatchingRulesTableContent}>

                  <div {...Style.TopTitleContainer}>
                    <div {...Style.TopTitle(fieldsWithoutErrors() ? Style.HeaderColors({ isMatchingRulesChanged }) : Style.ErrorHeaderColors)}>
                      <div>{isMatchingRulesChanged
                        ? <div {...Style.TitleCotainer}>
                          <Spacer right={'space-100'}>
                            <Icon name='Info' color={fieldsWithoutErrors() ? 'primary' : 'error'} />
                          </Spacer>
                          <div {...Style.Title}>{!fieldsWithoutErrors() ? 'You have errors that require your attention.' : 'You have unsaved changes.'}</div>
                        </div>
                        : 'When transaction'}</div>

                      {!isMatchingRulesChanged
                        ? <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
                          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onAddRuleClick} label='Add rule' />
                        </EnableFor>
                        : <div {...Style.ButtonsContainer}>
                          <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={() => {
                            formProps.values = initialMatchingRules
                            onCancel()
                          }} label='Cancel' />
                          <Button disabled={!fieldsWithoutErrors() || !areAllRulesValid} onClick={onApply} type={!fieldsWithoutErrors() ? ButtonType.secondary : ButtonType.primary} label='Apply changes' />
                        </div>}
                    </div>
                  </div>
                  <FieldArray name='rules'>
                    {(props) => {
                      return <EnableFor scopes={[SCOPES.EXPENSE_WRITE]}>
                        <DraggableOptions
                          itemContainerOverrideStyle={{ marginBottom: -2, marginTop: -2 }}
                          overrideStyle={{ padding: '0 10px', border: `1px solid ${colors.lightBlue5}`, minHeight: '70px' }}
                          optionProps={{ disabled }}
                          optionRenderer={renderRule}
                          value={value}
                          valueKey='id'
                          options={props.fields.value}
                          onChange={onDraggableChange}
                          onRemove={onRemove}
                        />
                      </EnableFor>
                    }}
                  </FieldArray>
                </div>
              </div>
            )
          }}
        />
      </EnableFor>
      <Spacer top={'space-400'} bottom={'space-400'}>
        <Body2>Otherwise match using Torii AI</Body2>
      </Spacer>
    </Fragment>
  )
}

const replaceItemById = (array, item) => {
  const index = array.findIndex(elem => elem.id === item.id)
  return Object.assign([...array], { [index]: item })
}
