import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import pluralize from 'pluralize'
import texts from '@shared/style/texts'
import { toggleUsersOverlapPopup } from '@shared/actions'
import RelativeTeamLink from '@components/relativeTeamLink'
import { AppIcon, Spacer, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import * as Style from '../style'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import VisibleFor from '@components/visibleFor'
import { HeaderWrapper } from './styles'

const OverlapsTable = ({ sets, apps, tab, highlightedOverlap, onHighlight }) => {
  const dispatch = useDispatch()

  const onViewUsersClick = ({ sets, size }, source) => {
    dispatch(toggleUsersOverlapPopup({ apps: sets.map(idApp => apps[idApp]), idUsers: size, appsNames: sets.map(idApp => apps[idApp].name), source, withLicenses: tab === 'licenses' }))
  }

  const onMouseHoverEffect = (overlap) => {
    if (overlap) {
      onHighlight({ overlap, source: 'table' })
    } else {
      onHighlight()
    }
  }

  const overlaps = sets.filter(set => set.sets.length > 1)
  if (!overlaps.length) {
    return <div {...Style.NoOverlapTableWrapper}>
      <div {...Style.NoOverlapTableMainTitle}>Hmm, we found no overlaps...</div>
      <div {...texts.body}>It looks like there aren't any users with licenses for more than one of the compared apps.</div>
    </div>
  }
  return <div {...Style.OverlapsTableWrapper(overlaps.length)}>
    {overlaps.map(overlap => {
      const addBackgroundColor = highlightedOverlap.sets && overlap.sets.toString() === highlightedOverlap.sets.toString()
      return (
        <Fragment key={overlap.sets.toString()}>
          <div
            {...Style.OverlapsTableAppsColumnWrapper(addBackgroundColor)}
            onMouseEnter={() => {
              onMouseHoverEffect(overlap)
            }}
            onMouseLeave={onMouseHoverEffect}
          >
            <HeaderWrapper>
              {overlap.sets.map((idApp, i) => {
                const app = apps[idApp]
                return <Fragment key={app.name}>
                  <RelativeTeamLink to={`/app/${idApp}`}>
                    <AppIcon appImageUrl={app.imageUrl} appName={app.name} />
                  </RelativeTeamLink>
                  <Spacer left={'space-100'}>{app.name}</Spacer>
                  {i < overlap.sets.length - 1 && <Spacer right={'space-400'} left={'space-400'}>+</Spacer>}
                </Fragment>
              })}
            </HeaderWrapper>

            <span {...Style.DescriptionRowWrapper}>
              <span {...Style.buttonWrapper}>
                <EnableFor scopes={[SCOPES.USERS_READ]} allowForToriiAdmin showAsDisabled={false}>
                  <Button type={ButtonType.compact} size={ButtonSize.small}
                    onClick={() => onViewUsersClick(overlap, 'view_users_link')}
                    label={`${pluralize('user', overlap.size, true)} `}
                  />
                </EnableFor>
              </span>
              {tab === 'licenses' ? ` ${overlap.size > 1 ? 'have' : 'has'} licenses for these apps` : ` ${overlap.size > 1 ? 'were' : 'was'} discovered in these apps`}
            </span>
          </div>
          <VisibleFor scopes={[SCOPES.USERS_READ]}>
            <div
              {...Style.ViewUsersColumnWrapper(addBackgroundColor)}
              onMouseEnter={() => {
                onMouseHoverEffect(overlap)
              }}
              onMouseLeave={onMouseHoverEffect}
            >
              <Spacer left={'space-300'} right={'space-300'}>
                <Button onClick={() => onViewUsersClick(overlap, 'view_users_button')} label='View users' />
              </Spacer>
            </div>
          </VisibleFor>
        </Fragment>
      )
    })}
  </div>
}

OverlapsTable.propTypes = {
  sets: PropTypes.arrayOf(PropTypes.shape({
    size: PropTypes.number,
    sets: PropTypes.array,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    textColor: PropTypes.string
  })).isRequired,
  apps: PropTypes.objectOf(PropTypes.object).isRequired,
  highlightedOverlap: PropTypes.object.isRequired,
  onHighlight: PropTypes.func.isRequired,
  tab: PropTypes.oneOf(['all', 'licenses'])
}

export default OverlapsTable
