import React, { ReactElement, useState } from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { useSelector, useDispatch } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { SCOPES } from '@root/constants'
import { toggleEditWorkflowTagPopup } from '@shared/actions'
import FormGroup from '@components/form/formGroup'
import Input from '@components/form/input'
import { getWorkflowTags, getWorkflowTagsById } from '@selectors/workflows'
import { updateWorkflowTag } from '@shared/actions/workflows'
import { AlertBox, AlertBoxType, toast, ToastType } from '@toriihq/design-system'
import { analytics } from '@shared/services/workflows/analytics'

const EditWorkflowTagPopup = (): ReactElement => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, idTag } = useSelector(state => state.ui.editWorkflowTagPopup)
  const tags = useSelector(getWorkflowTags)
  const originalTag = useSelector(getWorkflowTagsById)[idTag]

  const dispatch = useDispatch()

  const [tagLabel, setTagLabel] = useState(originalTag.label)
  const [actionError, setActionError] = useState('')

  const closePopup = () => {
    dispatch(toggleEditWorkflowTagPopup({ isOpen: false }))
  }

  const onClose = () => {
    analytics.tags.closeEditTagDialog({ buttonLabel: 'X' })
    closePopup()
  }

  const onCancel = () => {
    analytics.tags.closeEditTagDialog({ buttonLabel: 'Cancel' })
    closePopup()
  }

  const onSave = async (): Promise<void> => {
    analytics.tags.saveEditedCustomTag()

    if (tags.find(tag => tag.label === tagLabel && tag.id !== idTag)) {
      setActionError(`"${tagLabel}" tag already exists`)
      return
    }

    try {
      await dispatch(updateWorkflowTag({ idOrg, idTag, label: tagLabel }))
      setTimeout(() => {
        toast({
          message: `Successfully edited "${tagLabel}"`,
          type: ToastType.SUCCESS
        })
        onClose()
      }, 700)
    } catch (err) {
      setActionError('The changes couldn\'t be saved. Try saving again.')
      throw err
    }
  }

  return <ToriiPopup isOpen={isOpen} onCloseAction={onClose} closeOnOverlayClick={false}>
    <ToriiPopup.Header header='Edit tag' />
    <ToriiPopup.Content>
      {
        actionError ? (
          <FormGroup>
            <AlertBox type={AlertBoxType.NEGATIVE} description={actionError} />
          </FormGroup>
        ) : null
      }
      <FormGroup label='Tag name'>
        <Input
          maxLength={32}
          value={tagLabel}
          onChange={e => setTagLabel(e.target.value)}
          autoFocus
        />
      </FormGroup>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      mainButtonAction={onSave}
      mainButtonText='Save'
      scopes={[SCOPES.AUTOMATION_WRITE]}
      cancelButtonAction={onCancel}
      cancelButtonText='Cancel'
      isMainButtonDisabled={!tagLabel || tagLabel.trim() === originalTag.label}
    />
  </ToriiPopup>
}

export default EditWorkflowTagPopup
