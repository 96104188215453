import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { toggleNavigation, toggleSmallScreen, setHeaderHeight } from '../../shared/actions'
import { getMyOrg } from '../../selectors/me'
import { getHeaderHeight, getIsNavigationVisible, getIsSmallScreen } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const isDemoMode = getMyOrg(state) !== idOrg
  const isSmallScreen = getIsSmallScreen(state)
  const headerHeight = getHeaderHeight(state)
  const isNavigationVisible = getIsNavigationVisible(state)

  return {
    isDemoMode,
    headerHeight,
    isNavigationVisible,
    isSmallScreen
  }
}

const mapDispatchToProps = {
  toggleNavigation,
  toggleSmallScreen,
  setHeaderHeight
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
