import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  archiveTransaction,
  editTransaction,
  getAllTransactions,
  getTransactionsFieldValues,
  getSupportedFeatures,
  resetTransactions
} from '@shared/actions'
import {
  getIsLoadingMoreTransactions,
  getIsLoadingTransactions,
  getTotalTransactions,
  getTransactions,
  getTransactionsFilterOptionsValuesPerKey,
  getTransactionsResources
} from '@selectors/transactions'
import {
  EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS,
  ITEMS_PER_PAGE,
  SCOPES,
  TRANSACTION_MAPPING_STATUS,
  TRANSACTION_MAPPING_STATUS_OPEN_FOR_EXPENSES_WRITE,
  FEATURE_FLAGS
} from '@root/constants'
import { getSupportedFeatures as getSupportedFeaturesSelector } from '@selectors/org'
import { getUserPreferences } from '@selectors/ui'
import { getExpensesTablesInfo } from '@selectors/tables'
import AccessControl from '@lenses/accessControl'
import config from '@root/config'
import Table from '@components/table'
import { getColumns } from '@components/expensesTable/columns'
import ExpensesEmptyState from '@components/expensesTable/expensesEmptyState'
import EditTransactionPopup from '@components/popups/editTransactionPopup'
import { TransactionModel } from '@components/expensesTable/transactions.types'
import { initialTransaction } from '@components/expensesTable/utils'
import TableCSVButton from '@components/table/tableCSVButton'
import Analytics from '@helpers/analytics'
import Confirmation from '@components/confirmation'
import { Tooltip, ButtonType } from '@toriihq/design-system'
import { debounce, omit, map } from 'lodash'
import numeral from 'numeral'
import { getValidFilters } from '@root/shared/filters'
import useDeepCompareEffect from 'use-deep-compare-effect'

type ExpensesTableProps = {
  idApp?: number,
  idOrg: number,
  tableKey: string
}

const ExpensesTable = (props: ExpensesTableProps): JSX.Element => {
  const { idOrg, idApp, tableKey } = props
  const dispatch = useDispatch()
  const hideUnmatchedFeatureEnabled = useSelector(getSupportedFeaturesSelector)[FEATURE_FLAGS.HIDE_UNMATCHED_EXPENSES]
  const showUnmatchedExpenses = AccessControl.useIsAccessible({ scopes: [SCOPES.EXPENSE_WRITE] }) && !idApp && !hideUnmatchedFeatureEnabled
  const { filtersOptions, preDefinedColumnsMapping, filters: tableFilters } = useSelector(getExpensesTablesInfo)[tableKey]
  const transactions = useSelector(getTransactions)
  const { defaultSort = [], columnsConfiguration, defaultCustomSelectOption } = useSelector(getUserPreferences)[tableKey] || {}
  const totalTransactions = useSelector(getTotalTransactions)
  const [initialLoading, setInitialLoading] = useState(true)
  const isLoading = useSelector(getIsLoadingTransactions) && !transactions.length
  const isLoadingMore = useSelector(getIsLoadingMoreTransactions)
  const { users: usersById, apps: appsById } = useSelector(getTransactionsResources)
  const filterValues = useSelector(getTransactionsFilterOptionsValuesPerKey)
  const [query, setQuery] = useState('')
  const validFilters = getValidFilters(tableFilters)
  const [isArchivePopUpOpen, setIsArchivePopUpOpen] = useState(false)
  const [isEditPopUpOpen, setIsEditPopUpOpen] = useState(false)
  const [customSelectOption, setCustomSelectOption] = useState(defaultCustomSelectOption)
  const [currentTransaction, setCurrentTransaction] = useState<TransactionModel>(initialTransaction)
  const [columns, setColumns] = useState<any[]>([])
  const app = appsById[currentTransaction.idApp] || currentTransaction.idApp
  const mappingStatusToFetch = showUnmatchedExpenses ? customSelectOption.value : customSelectOption.value.filter(status => !TRANSACTION_MAPPING_STATUS_OPEN_FOR_EXPENSES_WRITE.includes(status))
  const sortStringFromArray = sortArray => sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  const columnsOptions = map(preDefinedColumnsMapping, (value, key) => {
    return { value: key, label: value }
  })
  useEffect(() => {
    idOrg && dispatch(getSupportedFeatures({ idOrg }))
  }, [dispatch, idOrg])

  useEffect(() => {
    initialLoading && dispatch(resetTransactions())
  }, [dispatch, initialLoading])

  useDeepCompareEffect(() => {
    idOrg && fetchTransactions({ reset: true })
  }, [idOrg, customSelectOption, defaultSort, validFilters])

  useDeepCompareEffect(() => {
    idOrg && dispatch(getTransactionsFieldValues({ idOrg, idApp, mappingStatus: mappingStatusToFetch, fields: ['concatenatedDescription', 'reportedBy', 'toriiSource', 'appAccountName', 'idExternalTransaction', 'mappingStatus', 'mappingLogic', 'externalAccountName', 'externalAccountId', 'appName'] }))
  }, [idOrg, idApp, mappingStatusToFetch])

  useDeepCompareEffect(() => {
    idOrg && setColumns(getColumns({ idOrg, usersById, appsById, onEditActionClicked, onArchiveActionClicked, displayAppColumn: !idApp }))
  }, [idOrg, idApp, usersById, appsById])

  const fetchTransactionsData = debounce((reset = false) => {
    fetchTransactions({ offset: reset ? 0 : transactions.length, reset })
  }, 500, { leading: true, trailing: false })

  const fetchTransactions = async ({ offset = 0, reset = false, q = query, sort = defaultSort }) => {
    dispatch(getAllTransactions({ idOrg, idApp, sort: sortStringFromArray(sort), reset, limit: ITEMS_PER_PAGE, offset, q, filters: tableFilters, mappingStatus: mappingStatusToFetch }))
    setInitialLoading(false)
  }

  const editTransactionAppWithAnalytics = async (idApp, transactionDate, modifiedAmount, amount) => {
    const { id: idTransaction, name, idApp: currentIdApp, mappingStatus: currentMappingStatus, mappingLogic: currentMappingLogic } = currentTransaction

    await Analytics.track('Edit transaction', {
      'Transaction ID': idTransaction,
      'Transaction name': name,
      'idApp': idApp
    })

    const isIdAppChanged = idApp && (idApp !== currentIdApp)
    const isUnmappedBecomeMapped = idApp && [TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_MAP, TRANSACTION_MAPPING_STATUS.UNKNOWN].includes(currentMappingStatus)
    const mappingStatus = isUnmappedBecomeMapped ? TRANSACTION_MAPPING_STATUS.MAPPED : currentMappingStatus
    const mappingLogic = (isIdAppChanged || isUnmappedBecomeMapped) ? 'manually' : currentMappingLogic

    setCurrentTransaction({ ...currentTransaction, transactionDate, modifiedAmount, amount, idApp, mappingStatus, mappingLogic })
    await dispatch(editTransaction({ idOrg, prevIdApp: currentIdApp, idApp, idTransaction, transactionDate, modifiedAmount, amount, mappingStatus, mappingLogic }))
  }

  const archiveTransactionWithAnalytics = async () => {
    await Analytics.track('Archive transaction', {
      'Transaction ID': currentTransaction.id,
      'Transaction name': currentTransaction.name
    })
    await dispatch(archiveTransaction({ idOrg, idTransaction: currentTransaction.id }))
    setTimeout(() => (setIsArchivePopUpOpen(false)), 1000)
  }

  const getCustomSelectOptions = () => {
    return Object.values(showUnmatchedExpenses ? EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS : omit(EXPENSES_TABLE_CUSTOM_SELECT_OPTIONS, 'unmatched'))
  }

  const exportToCsvButton = () => {
    const TRANSACTIONS_EXPORT_LIMIT = 100000
    const isDisabledByLimit = totalTransactions > TRANSACTIONS_EXPORT_LIMIT
    const tooltipLabel = <span>Export is limited to {numeral(TRANSACTIONS_EXPORT_LIMIT).format('0,0')} rows. Apply filters to narrow down the data and try again.</span>
    return <Tooltip hide={!isDisabledByLimit} label={tooltipLabel}>
      <TableCSVButton onClick={exportToCsv} disabled={!transactions || transactions.length === 0 || isDisabledByLimit} />
    </Tooltip>
  }

  const exportToCsv = () => {
    const sortParam = 'sort=' + sortStringFromArray(defaultSort)
    const queryParam = `q=${query}`
    const columnsOrdered = [...columns].sort((a, b) => columnsConfiguration.indexOf(a.id || a.accessor) - columnsConfiguration.indexOf(b.id || b.accessor))
    const fieldsParam = `fields=${columnsOrdered.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(tableFilters))}`
    const mappingStatusParam = `mappingStatus=${mappingStatusToFetch.join(',')}`
    const idAppParam = idApp ? `idApp=${idApp}` : ''

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/transactions/csv?${idAppParam}&${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}&${mappingStatusParam}`
    const newWindow = window.open(url, '_blank')
    if (newWindow) {
      newWindow.opener = null
    }
  }

  const onCustomSelectOptionChange = async (option) => {
    setCustomSelectOption(option)
  }

  const onSearch = debounce((q) => {
    setQuery(q)
    fetchTransactions({ reset: true, q })
  }, 500)

  const onEditActionClicked = ({ transaction }) => {
    setIsEditPopUpOpen(true)
    setCurrentTransaction(transaction)
    Analytics.track('Clicked to open popup: edit transaction app', {
      'Transaction ID': currentTransaction.id,
      'Transaction name': currentTransaction.name
    })
  }

  const onCloseEditPopup = () => {
    setIsEditPopUpOpen(false)
    Analytics.track('Closed popup: edit transaction app', {
      'Transaction ID': currentTransaction.id,
      'Transaction name': currentTransaction.name
    })
  }

  const onArchiveActionClicked = ({ transaction }) => {
    setIsArchivePopUpOpen(true)
    setCurrentTransaction(transaction)
    Analytics.track('Clicked to open popup: archive transaction', {
      'Transaction ID': currentTransaction.id,
      'Transaction name': currentTransaction.name
    })
  }

  const onCloseArchivePopup = () => {
    setIsArchivePopUpOpen(false)
    Analytics.track('Closed popup: archive transaction', {
      'Transaction ID': currentTransaction.id,
      'Transaction name': currentTransaction.name
    })
  }

  const archiveTransactionConfirmPopup = <Confirmation
    isOpen={isArchivePopUpOpen}
    header='Archive transaction'
    text={<div>Are you sure you want to archive <strong>{currentTransaction.name}</strong>?</div>}
    confirmText='Archive'
    declineText='Cancel'
    decline={onCloseArchivePopup}
    confirm={archiveTransactionWithAnalytics}
    close={onCloseArchivePopup}
    mainButtonType={ButtonType.destructive}
  />

  const editTransactionPopup = <EditTransactionPopup
    isOpen={isEditPopUpOpen}
    header='Update transaction'
    cancelButton={{ label: 'Cancel', onClick: onCloseEditPopup }}
    submitButton={{ label: 'Save', onClick: ({ app, date, modifiedAmount, amount }) => editTransactionAppWithAnalytics(app.id, date, modifiedAmount, amount) }}
    input={{
      label: 'App Name',
      app: app,
      date: currentTransaction.transactionDate,
      origDate: currentTransaction.origTransactionDate,
      description: currentTransaction.name,
      amount: currentTransaction.amount,
      modifiedAmount: currentTransaction.modifiedAmount,
      origAmount: currentTransaction.origAmount,
      origCurrency: currentTransaction.origCurrency,
      mappingStatus: currentTransaction.mappingStatus
    }}
    editMode
  />

  return (
    <>
      {archiveTransactionConfirmPopup}
      {editTransactionPopup}
      <Table
        tableKey={tableKey}
        extraHeaderComponent={exportToCsvButton()}
        data={transactions}
        emptyStateMessage={<ExpensesEmptyState customSelectOption={customSelectOption} idApp={idApp} />}
        filterable
        filtersOptions={filtersOptions}
        filterOptionsValuesPerKey={filterValues}
        manual
        customSelect
        customSelectOptions={getCustomSelectOptions()}
        customSelectSelectedValue={customSelectOption}
        customSelectOnChange={onCustomSelectOptionChange}
        searchable
        onSearch={onSearch}
        forceShowSearch
        fetchData={fetchTransactionsData}
        totalCount={totalTransactions}
        columns={columns}
        configurableColumnsOptions={columnsOptions}
        loading={isLoading}
        loadingMore={isLoadingMore}
      />
    </>
  )
}

export default ExpensesTable
