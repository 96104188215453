import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import * as venn from '@upsetjs/venn.js'
import './venn.css'
import { useDispatch } from 'react-redux'
import colors from '@shared/style/colors'
import { toggleUsersOverlapPopup } from '@shared/actions'

const VennDiagram = ({ sets, apps, tab, highlightedOverlap, onHighlight, colorScheme = {}, disabled = false }) => {
  const isEnter = useRef(false)

  const dispatch = useDispatch()

  useEffect(() => {
    const chart = venn.VennDiagram({
      symmetricalTextCentre: true
    }).fontSize('12px').width(400).colours(key => {
      const idApp = Number(key[0])
      return colorScheme[idApp]
    })

    const div = d3.select('#venn')
    div.datum(sets).call(chart)
    div.select('svg').attr('viewBox', '0 0 400 350')

    div.selectAll('path')
      .style('stroke', d => d.textColor || 'unset')

    div.selectAll('g')
      .on('mouseenter', function (d, i) {
        const tooltip = d3.select('body').append('div').attr('class', 'venntooltip')
        venn.sortAreas(div, i)

        tooltip.transition().duration(400).style('opacity', 0.9)
        tooltip.text(i.tooltip)

        const selection = d3.select(this).transition('tooltip').duration(400)
        selection
          .select('path')
          .style('fill-opacity', i.sets.length === 1 ? 0.7 : 0.55)
          .style('stroke-opacity', 1)

        selection
          .select('text')
          .style('fill', colors.black)

        if (i.sets.length > 1) {
          d3.select(this).style('cursor', 'pointer')
        }

        onHighlight({ overlap: i })

        if (!isEnter.current) {
          isEnter.current = true
          tooltip
            .style('left', (d.pageX) + 'px')
            .style('top', (d.pageY) + 'px')
        }
      })

      .on('mouseleave', function (d, i) {
        d3.selectAll('.venntooltip').remove()
        d3.select(this).style('cursor', 'default')
        const selection = d3.select(this)
        selection
          .select('path')
          .style('fill-opacity', i.sets.length === 1 ? 0.2 : 0)

        selection
          .select('text')
          .style('fill', i.textColor)

        onHighlight()
        isEnter.current = false
      })

    if (!disabled) {
      div.selectAll('g').on('click', (d, i) => {
        if (i.sets.length > 1) {
          dispatch(toggleUsersOverlapPopup({
            apps: i.sets.map(idApp => apps[idApp]),
            idUsers: i.size,
            appsNames: i.sets.map(idApp => apps[idApp].name),
            source: 'venn_diagram',
            withLicenses: tab === 'licenses'
          }))
        }
      })
    }
  }, [sets, colorScheme, disabled, dispatch, tab, apps, onHighlight])

  useEffect(() => {
    const div = d3.select('#venn')
    div.selectAll('path')
      .style('fill-opacity', d => d.sets.length === 1 ? 0.2 : 0)
    if (highlightedOverlap.sets && highlightedOverlap.source === 'table') {
      d3.select("[data-venn-sets='" + highlightedOverlap.sets.join('_') + "']")
        .selectAll('path')
        .style('fill-opacity', 0.5)
    }
  }, [highlightedOverlap])

  return (
    <div id='venn' />
  )
}

VennDiagram.propTypes = {
  sets: PropTypes.arrayOf(PropTypes.shape({
    size: PropTypes.number,
    sets: PropTypes.array,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    textColor: PropTypes.string
  })).isRequired,
  highlightedOverlap: PropTypes.object.isRequired,
  onHighlight: PropTypes.func.isRequired,
  colorScheme: PropTypes.object
}

VennDiagram.defaultProps = {
  colorScheme: [colors.blue, colors.orange, colors.darkGreen, colors.lightBlue4]
}
export default VennDiagram
