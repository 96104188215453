import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import { Icon, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { fontWeight } from '@root/shared/style/sizes'

const CSS = {
  selectedFieldContainer: css({
    minHeight: '71px',
    display: 'flex',
    border: `1px solid ${colors.border}`,
    padding: '21px',
    alignItems: 'center',
    ' .div:last-child': {
      alignSelf: 'right'
    }
  }),
  enabledFieldContainer: css({
    cursor: 'move',
    backgroundColor: colors.transparent,
    ' .removeIcon': {
      opacity: 0
    },
    '&:hover': {
      backgroundColor: colors.background,
      ' .removeIcon': {
        opacity: 1
      },
      ' .indexField': {
        display: 'none'
      },
      ' .dotsField': {
        display: 'flex'
      }
    }
  }),
  icon: css({
    fontSize: '16px',
    marginLeft: 'auto'
  }),
  removeField: css({
    marginLeft: 'auto'
  }),
  dotsField: css({
    display: 'none',
    width: '20px',
    marginRight: '22px',
    paddingLeft: '2px',
    position: 'relative'
  }),
  indexField: css({
    fontWeight: fontWeight.semiBold,
    color: colors.grey1,
    width: '10px',
    paddingLeft: '8px',
    marginRight: '32px'
  })
}

export default class DraggableOption extends React.Component {
  onRemoveClicked = () => {
    const { index, onRemove } = this.props
    onRemove(index)
  }

  render () {
    const { index, showIndex, showDots, optionComponent, disabled, onRemove, overrideStyle } = this.props

    return (
      <div {...css(CSS.selectedFieldContainer, !disabled && CSS.enabledFieldContainer, overrideStyle)}>
        {showIndex && <div className='indexField' {...CSS.indexField}>{index + 1}</div>}
        {showDots && <div className='dotsField' {...CSS.dotsField}><Icon name='Drag' /></div>}
        {optionComponent}
        {onRemove && !disabled && <div {...CSS.removeField} className='removeIcon'>
          <Button type={ButtonType.tertiary} size={ButtonSize.small} disabled={disabled} onClick={this.onRemoveClicked} icon='Trash' />
        </div>}
      </div>
    )
  }
}
