import View from './view'
import { connect } from 'react-redux'
import {
  toggleConfigureExecuteActionOnUsers,
  getWorkflowsActionsConfig,
  toggleConnectSource,
  getWorkflowsTriggersConfig,
  getServicesSyncData,
  getServicesConfig,
  validateWorkflow,
  toggleManualActionStartedPopup
} from '@shared/actions'
import { createWorkflow, updateWorkflow } from '@shared/actions/workflows'
import { getCurrentOrg } from '../../../selectors/me'
import {
  getFieldsDefaultValues,
  getActionsConfigByType,
  getFlattenActionsConfig,
  getSelectOptionsOfActionsByAppFor
} from '../../../selectors/workflows'
import {
  EMPTY_OBJECT,
  WORKFLOW_TRIGGER_TYPES,
  WORKFLOW_TYPES
} from '../../../constants'
import { getSourceByType } from '../../../sourcesConfig'
import { getStepsAndFieldsWithDefaultValues } from '@shared/workflows/getStepsAndFieldsWithDefaultValues'
import { deprecatedGetAppsByIds } from '../../../selectors/apps'
import omit from 'lodash/omit'
import moment from 'moment'
import { createActionsWithASingleAction } from '@root/shared/workflows/actions/utils/createActionsWithASingleAction'
import { getExecuteActionOnUsersPopupUsers } from '@selectors/ui'

const actionsToOmit = {
  170: ['slackNotification']
}

const mapStateToProps = state => {
  const { isOpen, idApp, onConfig, actionToRun, extraFieldsDefaultValues, fieldsToValue, executionFlow, flowType, idRecommendation } = state.ui.configureExecuteActionOnUsersPopup
  const { id: idOrg } = getCurrentOrg(state)
  const actionsConfig = getFlattenActionsConfig(state)
  const fieldsDefaultValues = getFieldsDefaultValues(state)
  const actionsByType = getActionsConfigByType(state)
  const users = getExecuteActionOnUsersPopupUsers(state)

  const { name: appName } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT

  const actionSelectOptions = (getSelectOptionsOfActionsByAppFor(state)[idApp] || [])
    .filter(option => !(actionsToOmit[idApp] || []).includes(option.value))

  const app = {
    idApp,
    name: appName
  }

  const action = actionSelectOptions.find(option => option.value === actionToRun) || EMPTY_OBJECT

  return {
    triggerType: WORKFLOW_TRIGGER_TYPES.EXECUTE_ON_USERS,
    actionSelectOptions,
    fieldsDefaultValues,
    actionsConfig,
    actionsByType,
    idOrg,
    isOpen,
    app,
    isConnectSourceOpen: state.ui.isConnectSourceOpen,
    onConfig,
    action,
    users,
    extraFieldsDefaultValues,
    fieldsToValue,
    flowType,
    executionFlow,
    idRecommendation
  }
}

const mapDispatchToProps = {
  toggleConfigureExecuteActionOnUsers,
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  createWorkflow,
  updateWorkflow,
  validateWorkflow,
  toggleConnectSource,
  getServicesConfig,
  getServicesSyncData,
  toggleManualActionStartedPopup
}

const WORKFLOW_ID_APP = getSourceByType('torii').idApp

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...omit(stateProps, ['fieldsDefaultValues', 'actionsByType']),
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleConfigureExecuteActionOnUsers({ isOpen: false })
  },
  close () {
    dispatchProps.toggleConfigureExecuteActionOnUsers({ isOpen: false, isByUser: false })
  },
  async setupRunActionWorkflow ({ action }) {
    const { idOrg, triggerType, users, executionFlow, idRecommendation } = stateProps
    const idUsers = users.map(user => user.originIdUser)
    const { workflow } = await dispatchProps.createWorkflow({ idOrg, type: WORKFLOW_TYPES.manual })
    workflow.triggerType = triggerType
    workflow.isActive = true
    workflow.name = `Run actions on users ${moment().format('DD MMM, YYYY')}`
    workflow.triggerConfiguration = [{ id: 'users', value: idUsers }]
    workflow.idApp = WORKFLOW_ID_APP
    workflow.actions = createActionsWithASingleAction(action)
    await dispatchProps.updateWorkflow({ idOrg, idWorkflow: workflow.id, workflow, executeWorkflow: true, executionFlow, idRecommendation })
    return dispatchProps.toggleManualActionStartedPopup({ users, idWorkflow: workflow.id, isOpen: true, triggerType })
  },
  async validateWorkflow ({ action }) {
    const { idOrg, triggerType } = stateProps
    const workflow = {
      triggerType,
      idApp: WORKFLOW_ID_APP,
      actions: [action]
    }
    return dispatchProps.validateWorkflow({ idOrg, workflow })
  },
  createAction (actionType) {
    if (!actionType) {
      return {}
    }

    const { actionsConfig, fieldsDefaultValues, actionsByType, extraFieldsDefaultValues, fieldsToValue } = stateProps
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === actionType)
    const { fields } = getStepsAndFieldsWithDefaultValues(WORKFLOW_TRIGGER_TYPES.EXECUTE_ON_USERS, actionConfig, { ...fieldsDefaultValues, ...extraFieldsDefaultValues }, actionsByType, fieldsToValue)

    return { id: new Date().getTime(), type: actionType, idApp: actionConfig.idApp, fields }
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
