import React from 'react'
import { useHistory } from 'react-router-dom'
import ToriiPopup from '../ToriiPopupV2'
import * as Style from './style'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/me'
import { toggleWorkflowStartedPopup } from '@shared/actions'
import Analytics from '@helpers/analytics'
import successImage from '@components/popups/workflowStartedPopup/images/success.svg'
import capitalize from 'lodash/capitalize'
import { getPopupHeader } from '@components/popups/manuallyRunWorkflowPopup/utils'
import { getWorkflowPathPrefix } from '@lenses/workflows'
import { WORKFLOW_TYPES, WORKFLOW_TYPE_TO_ITEM_NAME } from '@root/constants'

const WorkflowStartedPopup = () => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen, idWorkflow, workflowName, triggerType, referrer, entitiesAmount, workflowType = WORKFLOW_TYPES.regular } = useSelector(state => state.ui.workflowStartedPopup)
  const itemName = WORKFLOW_TYPE_TO_ITEM_NAME[workflowType]

  const dispatch = useDispatch()
  const history = useHistory()

  const onViewLogsClick = () => {
    history.push(`/team/${idOrg}/${getWorkflowPathPrefix({ workflowType, triggerType })}/${idWorkflow}/executions?entitiesAmount=${entitiesAmount}`)

    Analytics.track('Click on view-logs-button', {
      'Workflow name': workflowName,
      'Workflow ID': idWorkflow,
      'Trigger name': triggerType,
      'Dialog name': referrer
    })

    dispatch(toggleWorkflowStartedPopup({ isOpen: false }))
  }

  const onCancel = () => {
    dispatch(toggleWorkflowStartedPopup({ isOpen: false }))
  }

  return <ToriiPopup
    isOpen={isOpen}
    onCloseAction={onCancel}
  >
    <ToriiPopup.Header header={getPopupHeader(workflowType)} subHeader={workflowName} />
    <ToriiPopup.Content >
      <div {...Style.ContentContainer}>
        <div {...Style.DoneWorkflowTextArea}>
          <div {...Style.StartedText}>{`${capitalize(itemName)} started`}</div>
          <div {...Style.LogsMsg}>It might take a few minutes until logs will appear</div>
        </div>
        <div>
          <img src={successImage} alt='Success' />
        </div>
      </div>
    </ToriiPopup.Content>
    <ToriiPopup.Footer
      showCancelButton
      cancelButtonText='Close'
      mainButtonAction={onViewLogsClick}
      mainButtonText={'View Logs'}
    />
  </ToriiPopup>
}

export default WorkflowStartedPopup
