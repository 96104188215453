import { dateTimeColumn, wrapColumn } from '@components/workflowExecutions/columns'
import { getActionDetails } from '@components/workflowActionExecutions/view'
import AppDetails from '@components/appDetails'
import React from 'react'
import { EMPTY_OBJECT, WORKFLOW_TYPES } from '@root/constants'
import { Link } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'
import ExecutionStatus from '@components/workflowExecutions/executionStatus'
import LinkableAvatar from '@components/_shared/linkableAvatar'
import { getWorkflowPathPrefix, getRecipientName } from '@lenses/workflows'
import get from 'lodash/get'
import pluralize from 'pluralize/pluralize'
import { getDisplayName } from '@lenses/users'
import reduce from 'lodash/reduce'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import SafeTemplate from '@components/safeTemplate'

export const getColumns = ({ appsById, usersById, appFields, contractsFields }) =>
  [
    {
      Header: 'Triggered at (UTC)',
      accessor: 'runTime',
      ...dateTimeColumn,
      sortable: false,
      width: 150
    },
    {
      Header: 'Action',
      accessor: 'runtimeInfo',
      Cell: ({ value: runtimeInfo, row: { actionAuditData } }) => {
        const { userFields, audit } = actionAuditData || {}
        return generateActionDescription({ runtimeInfo: runtimeInfo || {}, userFields, audit })
      },
      maxWidth: 400,
      ...wrapColumn,
      sortable: false,
      minWidth: 350
    },
    {
      Header: 'Status',
      accessor: 'completionTime',
      id: 'status',
      Cell: ({ value: completionTime, row: { executionErrorType } }) => <ExecutionStatus completionTime={completionTime} hasError={Boolean(executionErrorType)} />,
      width: 110,
      sortable: false
    },
    {
      Header: 'App name',
      accessor: 'idApp',
      Cell: ({ value: idApp }) => {
        const app = appsById[idApp]
        return <AppDetails {...app} component='workflow executions audit' />
      },
      sortable: false,
      width: 160
    },
    {
      Header: 'Action trigger',
      accessor: 'triggerReason',
      ...wrapColumn,
      sortable: false,
      minWidth: 200
    },
    {
      Header: 'Workflow / policy name',
      accessor: 'workflowOrPolicyName',
      Cell: ({ value: workflowOrPolicyName, row: { workflowType, idWorkflow } }) => {
        if (workflowOrPolicyName) {
          return <RelativeTeamLink to={`/${getWorkflowPathPrefix({ workflowType, triggerType: null })}/${idWorkflow}`}><Link>{workflowOrPolicyName}</Link></RelativeTeamLink>
        }
      },
      ...wrapColumn,
      sortable: false,
      width: 180
    },
    {
      Header: 'Workflow / policy trigger details',
      accessor: 'triggerOutput',
      Cell: ({ value: triggerOutput, row: { triggerAuditData } }) => {
        const { userFields, audit } = triggerAuditData || {}
        return getTriggerInfo({ triggerOutput, userFields, audit })
      },
      ...wrapColumn,
      sortable: false,
      width: 230
    },
    {
      Header: 'Manually triggered by',
      accessor: 'calculatedTriggeredBy',
      Cell: ({ value: triggeredBy }) => {
        if (triggeredBy) {
          return <LinkableAvatar user={{ ...triggeredBy, photoUrl: usersById[triggeredBy.id]?.photoUrl }} />
        }
        return null
      },
      sortable: false,
      width: 160
    },
    {
      Header: 'Workflow / policy execution ID',
      accessor: 'idWorkflowExecution',
      Cell: ({ value: idWorkflowExecution, row: { idWorkflow, workflowType } }) => {
        const to = workflowType === WORKFLOW_TYPES.appCatalog
          ? `/${getWorkflowPathPrefix({ workflowType, triggerType: null })}/${idWorkflow}/executions/${idWorkflowExecution}` : `/${getWorkflowPathPrefix()}/${idWorkflow}/executions/${idWorkflowExecution}`
        return <RelativeTeamLink nav to={to}>
          <Link>{idWorkflowExecution}</Link>
        </RelativeTeamLink>
      },
      sortable: false
    },
    {
      Header: 'Completed at (UTC)',
      accessor: 'completionTime',
      ...dateTimeColumn,
      width: 150
    },
    {
      Header: 'Details',
      accessor: 'details',
      Cell: ({ value: details, row: { idApp, actionType, executionErrorType, runtimeInfo, completionTime } }) => {
        const app = appsById[idApp] || EMPTY_OBJECT
        return getActionDetails({ app, details, actionType, executionErrorType, action: runtimeInfo, appFields, contractsFields, completionTime })
      },
      sortable: false,
      ...wrapColumn
    },
    {
      accessor: 'executionErrorType',
      show: false
    },
    {
      accessor: 'executionType',
      show: false
    },
    {
      accessor: 'actionType',
      show: false
    },
    {
      accessor: 'idWorkflow',
      show: false
    },
    {
      accessor: 'metadata',
      show: false
    },
    {
      accessor: 'triggerType',
      show: false
    },
    {
      accessor: 'triggerIdApp',
      show: false
    },
    {
      accessor: 'triggerAuditData',
      show: false
    },
    {
      accessor: 'actionAuditData',
      show: false
    },
    {
      accessor: 'workflowType',
      show: false
    },
    {
      accessor: 'id',
      show: false
    }
  ]

const getUserFieldsComponents = (userFields) => reduce(userFields, (result, userField, fieldKey) => {
  result[fieldKey] = <span><RelativeTeamUserLink idUser={userField.id}><Link>{getDisplayName(userField)}</Link></RelativeTeamUserLink>{` (${userField.email})`}</span>

  return result
}, {})

const generateActionDescription = ({ runtimeInfo, userFields, audit }) => {
  const recipients = [].concat(runtimeInfo.to || get(runtimeInfo, ['emailSetup', 'to']))
  const recipient: any = recipients[0] || (runtimeInfo.submissionsTo && runtimeInfo.submissionsTo[0]) || runtimeInfo.from || get(runtimeInfo, ['emailSetup', 'from'])
  const moreRecipientsAmount = recipients.length - 1
  const moreRecipientsText = moreRecipientsAmount > 0 ? ` and ${moreRecipientsAmount} more ${pluralize('user', moreRecipientsAmount)}` : ''
  const triggerUser = runtimeInfo.triggerUser
  const app = runtimeInfo.app || {}
  const triggerApp = runtimeInfo.triggerApp || {}
  const reason = runtimeInfo.reason || '(no reason entered)'

  const userFieldsComponents = getUserFieldsComponents(userFields)

  const data = {
    ...runtimeInfo,
    ...userFieldsComponents,
    reason,
    triggerUser: triggerUser ? <RelativeTeamUserLink idUser={triggerUser.id}><Link>{getDisplayName(triggerUser)}</Link></RelativeTeamUserLink> : 'N/A',
    to: recipient ? <span><RelativeTeamUserLink idUser={recipient.id}><Link>{getRecipientName(recipient)}</Link></RelativeTeamUserLink>{moreRecipientsText}</span> : 'fallback recipient',
    app: <RelativeTeamLink to={`/app/${app.id}`}><Link>{app.name}</Link></RelativeTeamLink>,
    triggerApp: <RelativeTeamLink to={`/app/${triggerApp.id}`}><Link>{triggerApp.name}</Link></RelativeTeamLink>
  }

  return <SafeTemplate template={audit} data={data} highlightData />
}

const getTriggerInfo = ({ triggerOutput, userFields, audit }) => {
  const userFieldsComponents = getUserFieldsComponents(userFields)

  const { triggerUser } = userFields
  const app = triggerOutput.app || {}
  const triggerApp = triggerOutput.triggerApp || {}
  const license = triggerOutput.triggerLicense || {}

  const data = {
    ...triggerOutput,
    ...userFieldsComponents,
    triggerUser: triggerUser?.id
      ? <span>
        <RelativeTeamUserLink idUser={triggerUser.id}>
          <Link>{getDisplayName(triggerUser)}</Link>
        </RelativeTeamUserLink>{` (${triggerUser.email})`}
      </span>
      : 'a contract or an expense',
    app: <RelativeTeamLink to={`/app/${app.id}`}><Link>{app.name}</Link></RelativeTeamLink>,
    triggerApp: <RelativeTeamLink to={`/app/${triggerApp.id}`}><Link>{triggerApp.name}</Link></RelativeTeamLink>,
    triggerLicense: <span>{license.name}</span>
  }

  return <SafeTemplate template={audit} data={data} highlightData />
}
