import React from 'react'
import PropTypes from 'prop-types'
import { Props } from './types'
import WizardPopup from '../wizardPopup'
import { SharedProps, SharedState } from './stepsConfig/types'

export default function EditUserTypeSourcesPopup ({
  initialSharedState,
  sharedProps,
  isOpen = true,
  onCancel,
  onSubmitSuccess,
  wizardSteps
}: Props) {
  return (
    <WizardPopup<SharedState, SharedProps>
      isOpen={isOpen}
      width={'750px'}
      stepsConfig={wizardSteps}
      initialSharedState={initialSharedState}
      sharedProps={sharedProps}
      onCancel={() => onCancel()}
      onClose={() => onCancel()}
      onSubmitSuccess={() => onSubmitSuccess()}
    />
  )
}

EditUserTypeSourcesPopup.propTypes = {
  sharedProps: PropTypes.any.isRequired,
  initialSharedState: PropTypes.any.isRequired,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  wizardSteps: PropTypes.arrayOf(PropTypes.shape({
    stepRenderer: PropTypes.func.isRequired
  })).isRequired
}

EditUserTypeSourcesPopup.defaultProps = {
  isOpen: true
}
