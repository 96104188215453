import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import { BranchData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { ActionNodeModel, ActionWithFixedBranchesNodeModel, BranchNodeModel, WORKFLOW_ACTION_TYPE, WORKFLOW_NODE_TYPE } from '@shared/workflows/types'
import { getBranchActionBranchesData } from '@shared/workflows/actions/utils/getBranchActionBranchesData'
import { BranchLabelContent } from './branchLabelContent'
import { BRANCH_TYPE, GetBranchDataResult } from './types'
import { getFixedBranchesData } from '@shared/workflows/actions/utils/getFixedBranchesData'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'
import { exhaustiveCheck } from '@shared/utils'

type Props = NodeProps<BranchData>

const getBranchData = (
  branchNode: BranchNodeModel | ActionWithFixedBranchesNodeModel,
  branchIndex: number
): GetBranchDataResult => {
  if (branchNode.type === WORKFLOW_NODE_TYPE.BRANCH_ACTION) {
    const { branches } = getBranchActionBranchesData(branchNode)
    const branch = branches[branchIndex]

    return {
      branch,
      branchLabel: branch?.label
    }
  } else if (branchNode.type === WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES) {
    const { branches } = getFixedBranchesData(branchNode)

    return {
      branch: branches[branchIndex]
    }
  }
  exhaustiveCheck(branchNode)

  const actionNode = (branchNode as ActionNodeModel).action?.type
  if (actionNode !== WORKFLOW_ACTION_TYPE.REQUEST_APPROVAL) {
    throw new Error('Branch node type is not supported')
  }

  return {}
}

export const BranchLabel = (props: Props): ReactElement => {
  const workflow = useSelectedWorkflow()
  const { getActionConfiguration } = useActionsConfiguration({ workflow })
  const { idBranchingNode, branchIndex } = props.data

  const branchesConfig = getActionConfiguration(idBranchingNode)?.branchesConfiguration
  const label = branchesConfig?.branches[branchIndex]?.label ?? ''
  const branchNode = workflow.actions?.nodes[idBranchingNode] as BranchNodeModel | ActionWithFixedBranchesNodeModel | undefined

  const { branch = null, branchLabel = label } = branchNode ? getBranchData(branchNode, branchIndex) : {}

  return branch ? <BranchLabelContent branchType={BRANCH_TYPE.REGULAR} label={branchLabel} /> : <></>
}
