import React, { ReactElement } from 'react'
import { NodeProps } from 'reactflow'
import { WorkflowNodeData } from '@pages/workflow_v2/workflowEditor/workflowGraph/types'
import { useParamSelector } from '@shared/utils'
import { getAppActionsConfig } from '@selectors/workflows'
import { ActionNodeModel } from '@shared/workflows/types'
import ActionIconUrl from './actionIcon.svg'
import { AppActionsConfig } from '@selectors/workflows/types'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { ActionNodeBase } from '../actionNodeBase'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'

type ActionNodeProps = NodeProps<WorkflowNodeData>

export const ActionNode = ({ data }: ActionNodeProps): ReactElement => {
  const { idWorkflowNode } = data
  const workflow = useSelectedWorkflow()
  const { getActionConfiguration } = useActionsConfiguration({ workflow })

  const { action } = workflow.actions?.nodes[idWorkflowNode] as ActionNodeModel | undefined || {}
  const idApp = action?.idApp
  const actionApp: AppActionsConfig | undefined = useParamSelector(getAppActionsConfig, { idApp })
  const actionConfiguration = getActionConfiguration(idWorkflowNode)
  const appIconUrl = actionApp?.imageUrl ?? actionConfiguration?.imageUrl ?? ActionIconUrl

  return (
    <ActionNodeBase
      data={data}
      iconUrl={appIconUrl}
    />
  )
}
