import React, { ReactElement } from 'react'
import * as Styles from './styles'
import { useSelectedWorkflow } from '@pages/workflow_v2/hooks/useSelectedWorkflow'
import { useWorkflowEditorContext } from '@pages/workflow_v2/workflowEditor/context'
import TagsContainer from '@components/tagsContainer'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { Button } from '@toriihq/design-system'
import { toggleEditWorkflowTagsPopup } from '@shared/actions'
import { useDispatch } from 'react-redux'
import { analytics } from '@shared/services/workflows/analytics'

export const WorkflowTagsContainer = (): ReactElement => {
  const dispatch = useDispatch()
  const workflow = useSelectedWorkflow()
  const { isAppCatalogPoliciesView, isRequestNewAppPoliciesView } = useWorkflowEditorContext()

  if (isAppCatalogPoliciesView || isRequestNewAppPoliciesView) {
    return <></>
  }

  const onEditTagsClick = () => {
    analytics.tags.popupOpened({ openedFrom: 'Workflow page' })
    dispatch(toggleEditWorkflowTagsPopup({
      isOpen: true,
      idWorkflow: workflow.id
    }))
  }

  const renderTags = () => {
    if (!workflow.tags?.length) {
      return <></>
    }

    return <TagsContainer
      tags={workflow.tags}
      maxTagsToShow={3}
    />
  }

  return (
    <Styles.WorkflowTagsContainer>
      {renderTags()}
      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
        <Button
          type={'compact'}
          icon={'Edit'}
          onClick={onEditTagsClick}
          label='Edit tags'
        />
      </EnableFor>
    </Styles.WorkflowTagsContainer>
  )
}
