import React from 'react'
import * as Style from './style'
import Placeholder from '@components/placeholder'
import NoResults from '@media/no-results.svg'
import { AuditLogInfo, AuditLogUsers, AnalyticsInfo } from '@components/auditLogs/auditLog.types'
import AuditLogRow from './auditLogRow'

type Props = {
  loading: boolean,
  auditLogs: AuditLogInfo[],
  users: AuditLogUsers,
  openURLPrefix: string,
  openURLAnalytics: AnalyticsInfo
}

const AuditLogs = ({ loading = false, auditLogs = [], users = {}, openURLPrefix, openURLAnalytics }: Props): JSX.Element => {
  if (!loading && auditLogs.length === 0) {
    return <Style.EmptyState>
      <img src={NoResults} alt='No results found' height={102} width={112} />
      <Style.EmptyStateText>No history edits found</Style.EmptyStateText>
    </Style.EmptyState>
  }

  return (
    <Style.Wrapper>
      <Placeholder loading={loading} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
        {auditLogs.map(log => {
          return (
            <AuditLogRow key={log.id} {...log} users={users} openURLPrefix={openURLPrefix} openURLAnalytics={openURLAnalytics} />
          )
        })}
      </Placeholder>
    </Style.Wrapper>
  )
}

export default AuditLogs
