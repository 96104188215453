import React from 'react'
import { css } from 'glamor'
import { Checkbox, Spacer } from '@toriihq/design-system'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import get from 'lodash/get'
import groupBy from 'lodash/groupBy'
import Placeholder from '../placeholder'
import TableSearch from '../table/tableSearch'
import FieldByType from './fieldByType'

const CSS = {
  main: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    ' .ef-field': {
      borderBottom: '0'
    },
    ' .ef-input': {
      display: 'inline-block',
      width: '90%'
    },
    ' textarea.ef-input': {
      height: '100px'
    },
    marginBottom: '28px'
  }),
  header: css(texts.heading, {
    paddingBottom: '20px',
    textTransform: 'uppercase'
  }),
  actions: css({
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  }),
  search: css({
    flex: 1
  }),
  groupHeader: css(texts.heading, {
    padding: '20px',
    textTransform: 'uppercase'
  }),
  file: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }),
  uploadFileIcon: css({
    color: colors.blue,
    marginRight: '5px'
  }),
  fileIcon: css({
    marginRight: '10px'
  }),
  fileName: css(texts.body, {
    color: colors.blue
  })
}

class BillingDetails extends React.Component {
  state = { hideEmptyFields: false, search: '' }

  onSave (idField, value) {
    const { idOrg, idApp, setAppsDetails } = this.props
    const values = [].concat(value)
    setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField, values }] })
  }

  onRemove (idField) {
    const { idOrg, idApp, setAppsDetails } = this.props
    setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField, values: [null] }] })
  }

  onHistory (detail) {
    const { idApp, toggleAppDetailHistoryPopup } = this.props
    toggleAppDetailHistoryPopup({ isOpen: true, idApp, detail })
  }

  onSearch = (e) => {
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
  }

  toggleHideEmptyFields = () => {
    const { hideEmptyFields } = this.state
    this.setState({ hideEmptyFields: !hideEmptyFields })
  }

  emptyFieldFilter = (detail) => {
    const { hideEmptyFields } = this.state
    return !hideEmptyFields || Boolean(get(detail, ['values', 0], ''))
  }

  searchFilter = (detail) => {
    const { search } = this.state
    const searchTerm = search.toLowerCase()
    return !searchTerm || detail.name.toLowerCase().includes(searchTerm)
  }

  renderAppDetailsGroup (details, label) {
    const { loading, readonly, disabled, currencySymbol, usersById } = this.props

    const filteredDetails = details
      .filter(this.emptyFieldFilter)
      .filter(this.searchFilter)

    return (
      <div key={label} {...CSS.main}>
        <div {...CSS.groupHeader}>{label}</div>
        <div>
          {filteredDetails.map(detail => {
            const value = get(detail, ['values', 0])
            const onSave = value => this.onSave(detail.idField, value)
            const onRemove = () => this.onRemove(detail.idField)
            const onHistory = () => this.onHistory(detail)

            if (loading) {
              return (
                <Placeholder key={detail.idField} loading={loading} rows={1} style={{ maxWidth: '95%', margin: '20px' }}>
                  <div />
                </Placeholder>
              )
            }

            return <FieldByType
              key={detail.idField}
              currencySymbol={currencySymbol}
              usersById={usersById}
              loading={loading}
              readonly={readonly}
              disabled={disabled || detail.isPluginGroup !== 0 || detail.isReadOnlyGroup}
              field={detail}
              identifier={detail.idField}
              label={detail.name}
              value={value}
              onSave={onSave}
              onRemove={onRemove}
              onHistory={onHistory}
              showHistoryButton
            />
          })}
        </div>
      </div>
    )
  }

  render () {
    const { groups, details } = this.props

    if (!details) {
      return null
    }

    const detailsByGroups = groupBy(details, 'idGroup')
    return (
      <div>
        <div {...CSS.header}>Application details</div>
        <div {...CSS.actions}>
          <TableSearch onSearch={this.onSearch} overrideStyle={CSS.search} />
          <Spacer left={'space-300'}>
            <Checkbox label='Hide blank fields' onChange={this.toggleHideEmptyFields} checked={this.state.hideEmptyFields} />
          </Spacer>
        </div>
        {
          groups
            .filter(group => Boolean(detailsByGroups[group.id]))
            .map(group => this.renderAppDetailsGroup(detailsByGroups[group.id], group.label))
        }
      </div>
    )
  }
}

export default BillingDetails
