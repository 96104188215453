import React, { useEffect } from 'react'
import { Button } from '@toriihq/design-system'
import { useDispatch, useSelector } from 'react-redux'
import { getSelf } from '@selectors/me'
import { getInsightsStats, toggleSubscribePopup } from '@shared/actions'
import SubscribePopup from '../subscribePopup'

interface Props {
  idOrg: number
}

const SubscribeButton = (props: Props) => {
  const dispatch = useDispatch()

  const me = useSelector(getSelf)
  const idOrg = props.idOrg ?? me.org.id

  useEffect(() => {
    if (idOrg) {
      dispatch(getInsightsStats({ idOrg }))
    }
  }, [idOrg, dispatch])

  const onSubscribe = () => {
    dispatch(toggleSubscribePopup({ isOpen: true }))
  }

  return (
    <>
      <Button onClick={onSubscribe} label='Subscribe' />
      <SubscribePopup idOrg={idOrg} />
    </>
  )
}

export default SubscribeButton
