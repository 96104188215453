import React, { ReactElement } from 'react'
import { KeyOption, ValueOption } from '@components/filters/keyOpValueFilter/types'
import { Filter } from '@shared/filters/types'
import VerticalFilterWrapper from '@components/filters/verticalFilterWrapper'
import KeyOpValueVerticalFilter from '@components/filters/keyOpValueVerticalFilter'

interface Props {
  filter: Filter
  optionsKey: KeyOption[]
  optionsValues: ValueOption[]
  onChange: (Filter) => void
  disabled: boolean
  fieldsAutoFocus?: boolean
  onRemove: (any) => void
}

const AdvancedFiltersColumn = ({
  optionsKey,
  optionsValues,
  onChange,
  onRemove,
  filter,
  disabled,
  fieldsAutoFocus
}: Props): ReactElement => {
  return (
    <VerticalFilterWrapper
      disabled={disabled}
      onRemove={onRemove}
    >
      <KeyOpValueVerticalFilter
        filter={filter}
        optionsKey={optionsKey}
        optionsValues={optionsValues}
        onChange={onChange}
        disabled={disabled}
        fieldsAutoFocus={fieldsAutoFocus}
      />
    </VerticalFilterWrapper>
  )
}

export default AdvancedFiltersColumn
