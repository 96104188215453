import View from './view'
import { connect } from 'react-redux'
import { getCurrentOrg } from '../../selectors/me'
import { setAppsDetails, deleteAppDetailsFieldValue, toggleAppDetailHistoryPopup } from '../../shared/actions'
import { getAppDetailsByIdApp } from '../../lenses/apps'
import { getAppAndDetailsResources, getAppDetailsGroups, isAppsLoaded } from '../../selectors/apps'
import { getCurrencySymbol } from '../../selectors/org'
import { formFieldTypes } from '@root/constants'

const APP_COMPLIANCE_GROUP_LABEL = 'certifications & compliance'

const mapStateToProps = (state, ownProps) => {
  const { idApp, loading } = ownProps
  const { id: idOrg } = getCurrentOrg(state)
  const details = getAppDetailsByIdApp(state, idApp)
    .filter(detail => detail.category === 'app_details' && ![formFieldTypes.fileUpload].includes(detail.type) && detail.isShown)
  const groups = getAppDetailsGroups(state)
  const filteredGroups = groups.filter(group => !(group.label?.toLowerCase() === APP_COMPLIANCE_GROUP_LABEL && group.isPredefined))
  const loadingDetails = [{ idField: 1, idGroup: 1 }, { idField: 2, idGroup: 1 }, { idField: 3, idGroup: 1 }, { idField: 4, idGroup: 1 }, { idField: 5, idGroup: 1 }]
  const loadingGroup = [{ id: 1, label: '' }]
  const loadingAppDetails = loading && details.length === 0
  const currencySymbol = getCurrencySymbol(state)
  const usersById = getAppAndDetailsResources(state).users

  return {
    details: loadingAppDetails ? loadingDetails : details,
    idOrg,
    loading: loadingAppDetails && isAppsLoaded(state),
    usersById,
    groups: loadingAppDetails ? loadingGroup : filteredGroups,
    currencySymbol
  }
}

const mapDispatchToProps = {
  setAppsDetails,
  deleteAppDetailsFieldValue,
  toggleAppDetailHistoryPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
