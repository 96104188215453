import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import { Spacer } from '@toriihq/design-system'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import ToriiSelect from '../../select'
import FormGroup from '../../form/formGroup'
import { APP_PERMISSIONS, SCOPES } from '@root/constants'
import ConnectDirectIntegrationPopup from '../connectDirectIntegrationPopup'
import { updateActionFieldValue } from '@shared/workflows/updateActionFieldValue'
import EditWorkflowAction from '../configureAppForOffboardingPopup/editWorkflowAction'
import pluralize from 'pluralize'
import UsersIconsList from '../../usersIconsList'
import { isArray, isEqual, isEmpty, get, isBoolean, noop } from 'lodash'
import Analytics from './analytics'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'

const CSS = {
  popup: {
    modal: { width: '700px' }
  },
  employeesCount: css(texts.body, {
    lineHeight: '7px',
    color: colors.black
  })
}

class ConfigureExecuteActionOnUsersPopup extends React.Component {
  state = {
    action: this.props.action,
    updateVersion: null
  }

  componentDidMount () {
    const { getWorkflowsActionsConfig, getWorkflowsTriggersConfig, idOrg, getServicesSyncData, getServicesConfig, createAction, action } = this.props
    const idApp = get(this.props, ['app', 'idApp'])
    getWorkflowsActionsConfig(idOrg, idApp)
    getWorkflowsTriggersConfig(idOrg, idApp)
    getServicesSyncData({ idOrg })
    getServicesConfig({ idOrg })
    if (!isEmpty(action)) {
      const currentAction = createAction(action.value)
      this.setState({ action: currentAction })
    }
  }

  componentDidUpdate (prevProps) {
    const { action } = this.props
    if (!isEqual(prevProps.action, action)) {
      this.onSelectedActionChange(action)
    }
  }

  validateFields = (updateVersion) => {
    const { action } = this.state
    const { validateWorkflow } = this.props

    if (!action.fields) {
      return
    }

    validateWorkflow({ action }).then(({ workflow }) => {
      if (this.state.updateVersion && this.state.updateVersion !== updateVersion) {
        return
      }
      this.setState({ action: workflow.actions[0] })
    })
  }

  onFieldChange = ({ selectedValue, fieldId }, locallyOnly) => {
    const { action } = this.state

    const isValid = true
    const updatedAction = updateActionFieldValue(action, selectedValue, fieldId, isValid)
    const updateVersion = new Date().getTime()
    const callback = locallyOnly ? noop : () => this.validateFields(updateVersion)
    this.setState({ action: { ...updatedAction, isValid: false }, isDirty: true, updateVersion }, callback)
  }

  onSelectedActionChange = (option) => {
    const { createAction } = this.props
    const { action: previousAction } = this.state
    const { value: actionType } = (option || {})

    const didSelectionChanged = !previousAction || actionType !== previousAction.type

    if (!didSelectionChanged) {
      return
    }

    const action = createAction(actionType)
    this.setState({ action })
  }

  renderSelectAction = () => {
    const { actionSelectOptions, triggerType } = this.props
    const { action } = this.state

    return <>
      <div style={{ marginBottom: '20px' }}>
        <ToriiSelect
          options={actionSelectOptions}
          value={action.type}
          onChange={this.onSelectedActionChange}
        />
      </div>
      {action.type && <EditWorkflowAction triggerType={triggerType} action={action} onChange={this.onFieldChange} actionLabel='Action Setup' />}
    </>
  }

  onSubmit = async () => {
    const { setupRunActionWorkflow, close, onConfig = () => {}, users, flowType, app } = this.props
    const { action } = this.state

    Analytics.onRunAction(app.name, users.length, flowType)

    await setupRunActionWorkflow({ action })
    close()
    onConfig()
  }

  renderConnectPopup = () => {
    const { isConnectSourceOpen } = this.props

    if (!isConnectSourceOpen) {
      return null
    }

    return (
      <ConnectDirectIntegrationPopup
        permission={APP_PERMISSIONS.read_write}
        disableReadOption
        showDeleteExplanation
      />
    )
  }

  isDataValid = () => {
    const { actionsConfig } = this.props
    const { action } = this.state

    if (!action.type) {
      return false
    }

    let isDataValid = true
    const actionConfig = actionsConfig.find(actionConfig => actionConfig.type === action.type) || { inputSchema: [] }
    Object.keys(actionConfig.inputSchema).forEach(fieldId => {
      const fieldConfig = actionConfig.inputSchema[fieldId]
      const fieldInAction = action.fields.find(field => field.id === fieldConfig.id)

      let isHiddenField = false
      if (fieldConfig.showOnFieldSpecificValue) {
        const dependsOnField = action.fields.find(field => field.id === fieldConfig.showOnFieldSpecificValue.id)
        const dependsOnFieldValue = get(dependsOnField, ['value', 'value']) || dependsOnField.value
        isHiddenField = dependsOnFieldValue !== fieldConfig.showOnFieldSpecificValue.value
      }

      const isRequiredFieldEmpty = !isHiddenField && fieldConfig.validations.includes('required') && (!fieldInAction.value || (isArray(fieldInAction.value) && fieldInAction.value.length === 0))
      const isFieldInvalidFromConfig = isBoolean(fieldInAction.value?.config?.isValid) && !fieldInAction.value.config.isValid

      if (isRequiredFieldEmpty || isFieldInvalidFromConfig) {
        isDataValid = false
      }
    })

    return isDataValid
  }

  onCancel = () => {
    const { cancel, app, flowType, users } = this.props
    Analytics.onCancel(app.name, users.length, flowType)
    cancel()
  }

  render () {
    const { cancel, isOpen, users, app, idOrg } = this.props

    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} styles={CSS.popup}>
        <ToriiPopup.Header header={'Run action'} />
        <ToriiPopup.Content >
          <Fragment>
            <div {...CSS.employeesCount}>{`Run action for ${pluralize('user', users.length, true)}`}</div>
            <Spacer top={'space-150'} bottom={'space-300'}>
              <UsersIconsList users={users} maxUsers={5} />
            </Spacer>
            <FormGroup label='Action:'>
              {this.renderSelectAction()}
            </FormGroup>
            {this.renderConnectPopup()}
          </Fragment>
        </ToriiPopup.Content>
        <ToriiPopup.Footer
          cancelButtonText={'Cancel'}
          cancelButtonAction={this.onCancel}
          mainButtonText={'Run action'}
          mainButtonAction={this.onSubmit}
          isMainButtonDisabled={!this.isDataValid()}
          scopes={[SCOPES.AUTOMATION_WRITE, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_WRITE, idOrg, app.idApp)]}
        />
      </ToriiPopup>
    )
  }
}

ConfigureExecuteActionOnUsersPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  app: PropTypes.shape({
    idApp: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  }),
  idOrg: PropTypes.number
}

export default ConfigureExecuteActionOnUsersPopup
