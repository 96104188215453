import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Style from './style'
import { Spacer, H4, Stack } from '@toriihq/design-system'
import LogoContainer from '@components/appCatalog/components/LogoContainer'
import BrowseUploadBox from '@components/browseUploadBox'
import { getCurrentOrg } from '@selectors/me'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { LOGO_TYPES } from '@root/constants.t'
import Input from '@components/form/input'
import Placeholder from '@components/placeholder'
import { isOrgLoading } from '@selectors/org'
import { removeOrgLogo, updateOrg, uploadOrgLogo } from '@shared/actions'
import Analytics from '@helpers/analytics'
import ToriiPopup from '@components/popups/ToriiPopupV2'

const EmailsSettings = () => {
  const dispatch = useDispatch()
  const org = useSelector(getCurrentOrg)
  const { id: idOrg } = org
  const loading = useSelector(isOrgLoading) && !idOrg

  const [emailAlias, setEmailAlias] = useState(org.emailAlias)
  const [isEditLogoLoading, setIsEditLogoLoading] = useState(false)
  const [logoUploadingErrorOpen, setLogoUploadingErrorOpen] = useState(false)

  const onFileSelected = async (file, shouldDelete) => {
    Analytics.track('Changed emails logo')
    setIsEditLogoLoading(true)

    shouldDelete && await handleRemoveLogo()
    const succeed = await dispatch(uploadOrgLogo({ idOrg, logoFile: file, logoType: LOGO_TYPES.EMAIL }))

    setLogoUploadingErrorOpen(!succeed)
    setIsEditLogoLoading(false)
  }

  const handleRemoveLogo = async () => {
    Analytics.track('Removed email logo')
    await dispatch(removeOrgLogo({ idOrg, logoType: LOGO_TYPES.EMAIL }))
  }

  useEffect(() => {
    setEmailAlias(org.emailAlias)
  }, [org.emailAlias])

  const onEmailAliasChange = (emailAlias) => {
    if (emailAlias) {
      dispatch(updateOrg({ idOrg, emailAlias }))
    }
  }

  return (
    <Style.Wrapper>
      <Spacer bottom={'space-300'}><H4>Emails</H4></Spacer>
      <Stack gap='space-600'>
        <Style.Section>
          <Style.Header>Outgoing email display name</Style.Header>
          <Spacer bottom={'space-100'}>
            <div>Default email display name for outgoing emails (emails are sent from hello@toriihq.com)</div>
          </Spacer>
          <Placeholder loading={loading} type='rect' style={Style.placeholderStyle}>
            <EnableFor scopes={[SCOPES.SETTINGS_WRITE]}>
              <div style={{ width: '200px' }}>
                <Input
                  suffix='via Torii'
                  placeholder='via Torii'
                  value={emailAlias}
                  onChange={(e) => setEmailAlias(e.target.value)}
                  onBlur={e => onEmailAliasChange(e.target.value)}
                />
              </div>
            </EnableFor>
          </Placeholder>
        </Style.Section>
        <Style.Section>
          <Style.Header>Logo</Style.Header>
          <Spacer bottom={'space-100'}>
            <div>Show your company logo in outgoing emails</div>
          </Spacer>
          <Style.GreyText>
            {
              org.emailLogoUrl
                ? <LogoContainer logo={org.emailLogoUrl} onEdit={(file) => onFileSelected(file, true)} onRemove={handleRemoveLogo} isLoading={isEditLogoLoading} logoType={LOGO_TYPES.EMAIL} />
                : <BrowseUploadBox onFileSelect={(file) => onFileSelected(file, false)} types={['image/png']} scopes={[SCOPES.SETTINGS_WRITE]} />
            }
            <Spacer top={'space-100'}>
              <div>Recommended size: 1320px x 300px. PNG format is supported.</div>
            </Spacer>
          </Style.GreyText>
          <ToriiPopup isOpen={logoUploadingErrorOpen} onCloseAction={() => setLogoUploadingErrorOpen(false)}>
            <ToriiPopup.Header header={`Failed To Upload Image`} />
            <ToriiPopup.Content>
              <span>Your image could not be uploaded because it’s format was not PNG or SVG. Please select a valid image and try again.</span>
            </ToriiPopup.Content>
            <ToriiPopup.Footer
              showCancelButton={false}
              mainButtonAction={() => setLogoUploadingErrorOpen(false)}
              mainButtonText='Close'
            />
          </ToriiPopup>
        </Style.Section>
      </Stack>
    </Style.Wrapper>)
}

export default EmailsSettings
