import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { ButtonType } from '@toriihq/design-system'
import Confirmation from '@components/confirmation'
import { useDispatch } from 'react-redux'
import { IdWorkflowNode, WORKFLOW_NODE_TYPE, WorkflowActionsModel, Workflow } from '@shared/workflows/types'
import { deleteWorkflowActionV2 } from '@shared/actions/workflows'
import { deleteActionNode } from '@pages/workflow_v2/workflowEditor/utils/deleteActionNode'
import { useWorkflowNavigation } from '@pages/workflow_v2/hooks/useWorkflowNavigation'
import { exhaustiveCheck } from '@shared/utils'
import { deleteActionWithBranches } from '@pages/workflow_v2/workflowEditor/utils/deleteActionWithBranches'
import { analytics } from '@shared/services/workflows/analytics'
import { useActionsConfiguration } from '@pages/workflow_v2/hooks/useActionsConfiguration'

interface Props {
  idAction: IdWorkflowNode
  workflow: Workflow
  isOpen: boolean
  onClose: () => void
}

const getPopupData = (nodeType: WORKFLOW_NODE_TYPE, fixedBranchesLabelText: string) => {
  switch (nodeType) {
    case WORKFLOW_NODE_TYPE.ACTION:
      return {
        header: 'Delete action?',
        text: 'By clicking "Delete", the action will be removed from the workflow and the action configuration will ' +
        'no longer be available. The logs will not be affected.',
        confirmText: 'Delete',
        declineText: 'Cancel',
        deleteFunction: deleteActionNode
      }
    case WORKFLOW_NODE_TYPE.BRANCH_ACTION:
      return {
        header: 'Delete If/else action?',
        text: 'By clicking “Delete”, all actions under the If/else branch will be deleted and their configuration will ' +
        'no longer be available. The logs will not be affected.',
        confirmText: 'Delete',
        declineText: 'Cancel',
        deleteFunction: deleteActionWithBranches
      }
    case WORKFLOW_NODE_TYPE.ACTION_WITH_FIXED_BRANCHES:
      return {
        header: 'Delete action?',
        text: `By clicking “Delete”, all actions under the ${fixedBranchesLabelText} branches will be deleted and their configuration will no longer be available. The logs will not be affected.`,
        confirmText: 'Delete',
        declineText: 'Cancel',
        deleteFunction: deleteActionWithBranches
      }
    default:
      exhaustiveCheck(nodeType)
      throw new Error(`Unknown node type: ${nodeType}`)
  }
}

export const DeleteActionConfirmation = ({
  idAction,
  workflow,
  isOpen,
  onClose
}: Props): ReactElement => {
  const { idOrg } = useParams()
  const dispatch = useDispatch()
  const actions = workflow.actions!
  const { idSelectedNode, unselectNode } = useWorkflowNavigation()
  const { getActionLabel, getFixedBranchesLabelsText } = useActionsConfiguration({ workflow })

  const nodeType = actions.nodes[idAction].type
  const actionName = getActionLabel(idAction)
  const branchesLabelText = getFixedBranchesLabelsText(idAction)

  const { header, text, confirmText, declineText, deleteFunction } = getPopupData(nodeType, branchesLabelText)

  const deleteAction = () => {
    analytics.action.delete.deleted({
      idWorkflow: workflow.id,
      workflowName: workflow.name,
      actionName: actionName!
    })

    const updatedActions: WorkflowActionsModel = deleteFunction(idAction, actions)
    dispatch(deleteWorkflowActionV2({
      idOrg: Number(idOrg),
      idWorkflow: workflow.id,
      workflow: { ...workflow, actions: updatedActions }
    }))

    if (idSelectedNode === idAction) {
      unselectNode()
    }
  }

  const handleConfirm = () => {
    onClose()
    deleteAction()
  }

  const handleCancel = () => {
    analytics.action.delete.popupClosed({
      idWorkflow: workflow.id,
      actionName: actionName!
    })
    onClose()
  }

  return <Confirmation
    isOpen={isOpen}
    header={header}
    text={text}
    confirmText={confirmText}
    declineText={declineText}
    decline={handleCancel}
    confirm={handleConfirm}
    close={handleCancel}
    mainButtonType={ButtonType.destructive}
  />
}
