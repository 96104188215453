import texts from '@root/shared/style/texts'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import { fontWeight } from '@root/shared/style/sizes'

export const HeaderArea = css({
  display: 'flex',
  alignItems: 'center'
})

export const AppIcon = css({
  marginRight: '10px'
})

export const SubHeader = css(texts.subheading, {
})

export const MainArea = css({
  width: '100%',
  height: '80%',
  paddingBottom: '0'
})

export const Popup = {
  modal: {
    width: '80%',
    height: '800px'
  }
}

export const EmptyState = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translateY(52%)',
  marginRight: '151px'
})

export const TransactionMatchingRule = css({
  borderRadius: '4px',
  border: `1px solid ${colors.lightBlue2}`,
  padding: '20px 32px',
  backgroundColor: colors.lightBlue3
})

export const MarginTop = ({ margin }) => css({
  marginTop: margin
})

export const Bold = css({
  fontWeight: fontWeight.bold
})
