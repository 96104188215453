import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getRenewalsMonthlyStats } from '../../../selectors/contracts'
import { getCurrencySymbol } from '../../../selectors/org'
import isEmpty from 'lodash/isEmpty'
import sumBy from 'lodash/sumBy'

const mapStateToProps = (state, ownProps) => {
  const { loadingRenewals } = ownProps

  const renewalsBarChartData = getRenewalsMonthlyStats(state)
  const totalAnnualPrice = sumBy(renewalsBarChartData, 'value')
  const totalContractsCount = sumBy(renewalsBarChartData, 'count')
  const currencySymbol = getCurrencySymbol(state)

  const loading = loadingRenewals && isEmpty(renewalsBarChartData)

  return {
    loading,
    renewalsBarChartData,
    totalAnnualPrice,
    totalContractsCount,
    currencySymbol
  }
}

export default withRouter(connect(mapStateToProps)(View))
