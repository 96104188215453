import React, { JSX, Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getSelf } from '@root/selectors/me'
import { AUTH_STATE } from '@root/constants'

const ChurnZero = () : JSX.Element => {
  const me = useSelector(getSelf)

  useEffect(() => {
    const isAuthenticatedAdmin = me.authState === AUTH_STATE.AUTHENTICATED && me.isAdmin
    if (isAuthenticatedAdmin) {
      const setupScript = document.createElement('script')
      setupScript.type = 'text/javascript'
      setupScript.id = 'churnzero-settings'
      setupScript.innerHTML = `
        var ChurnZero = ChurnZero || [];
        (function(a,b,c,d){var cz=a.createElement(b); cz.type=c; cz.async=true; cz.src=d;var s=a.getElementsByTagName(b)[0];s.parentNode.insertBefore(cz, s);})(document, "script",'text/javascript', 'https://toriihq.us1app.churnzero.net/churnzero.js');
        ChurnZero.push(['setAppKey', '1!HWQDmSUTLoqdZ4wcCf1ugupGay2ED-RTs6w0PQxnobwt13EE']);
        ChurnZero.push(['setContact', ${me.idOrg}, ${me.id}]);
      `
      if (document.getElementById('churnzero-settings') === null) {
        document.body.appendChild(setupScript)
      }
    }
  }, [me])

  return (<Fragment />)
}

export default ChurnZero
