import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getOffboardingTodoUsers,
  getOffboardingInProgressUsers,
  getOffboardingDoneUsers,
  getOffboardingTodoUsersCount, getOffboardingDoneUsersCount, getOffboardingInProgressUsersCount
} from '@shared/actions'
import {
  getOffboardingDoneUsersCountSelector,
  getOffboardingDoneUsersSelector, getOffboardingInProgressUsersCountSelector,
  getOffboardingTodoUsersCountSelector,
  getOffboardingTodoUsersSelector
} from '@selectors/offboarding'
import { TABLES } from '../../constants'
import { getUserPreferences } from '@selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)

  const todoUsers = getOffboardingTodoUsersSelector(state)
  const doneUsers = getOffboardingDoneUsersSelector(state)

  const loading = !(todoUsers.isLoaded && doneUsers.isLoaded) ||
                  (todoUsers.loading && todoUsers.users.length === 0) ||
                  (doneUsers.loading && doneUsers.users.length === 0)
  const todoCount = getOffboardingTodoUsersCountSelector(state)
  const inProgressCount = getOffboardingInProgressUsersCountSelector(state)
  const doneCount = getOffboardingDoneUsersCountSelector(state)

  const userPreferences = getUserPreferences(state)
  const { defaultSort: todoDefaultSort = [] } = userPreferences[TABLES.offboardingTodoTable.key] || {}
  const { defaultSort: inprogressDefaultSort = [] } = userPreferences[TABLES.offboardingInprogressTable.key] || {}
  const { defaultSort: doneDefaultSort = [] } = userPreferences[TABLES.offboardingDoneTable.key] || {}

  return {
    idOrg,
    todoUsers,
    doneUsers,
    loading,
    todoCount,
    inProgressCount,
    doneCount,
    todoDefaultSort,
    inprogressDefaultSort,
    doneDefaultSort
  }
}

const mapDispatchToProps = {
  getOffboardingTodoUsers,
  getOffboardingInProgressUsers,
  getOffboardingDoneUsers,
  getOffboardingTodoUsersCount,
  getOffboardingInProgressUsersCount,
  getOffboardingDoneUsersCount
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
