import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getCurrentOrg } from '@selectors/me'
import { exportInactiveUsersCSV, exportSSOAuditCSV, exportCSVReport } from '@shared/actions'
import AccessControl from '@lenses/accessControl'
import { FEATURES } from '@shared/features'

const mapStateToProps = (state) => {
  const { id: idOrg } = getCurrentOrg(state)
  const isAppComplianceEnabledInPlan = AccessControl.isFeatureEnabledInPlan({ feature: FEATURES.APPLICATION.COMPONENTS.APP_COMPLIANCE, state })

  return { idOrg, isAppComplianceEnabledInPlan }
}

const mapDispatchToProps = {
  exportSSOAuditCSV,
  exportInactiveUsersCSV,
  exportCSVReport
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
