import {
  GET_API_KEYS
} from '../constants'

const initialState = {
  isLoaded: false,
  loading: false,
  apiKeys: [],
  resources: {},
  isScimEnabled: false
}

const apiKeysReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_API_KEYS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_API_KEYS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_API_KEYS}_RESPONSE`: {
      const { apiKeys, config, resources } = action.payload
      return {
        ...state,
        loading: false,
        isLoaded: true,
        apiKeys,
        config,
        resources
      }
    }
    default: {
      return state
    }
  }
}

export default apiKeysReducer
