import PropTypes from 'prop-types'
import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import EditableField from '@components/editableField'
import config from '../../../config'
import Placeholder from '../../placeholder'
import isEmpty from 'lodash/isEmpty'
import { fontWeight } from '../../../shared/style/sizes'
import UploadFileDropzone from '../../uploadAppDetailsAttachments/uploadFileDropzone'
import { Link, Button, ButtonType } from '@toriihq/design-system'

const CSS = {
  uploadFileContainer: css({
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    ' .ef-field': {
      borderBottom: '0'
    },
    ' .ef-actions': {
      paddingLeft: '8px'
    },
    marginBottom: '28px'
  }),
  filesContainer: css({
    ' div:nth-of-type(1) .ef-field': {
      borderTop: '0'
    }
  }),
  noFiles: css(texts.subheading, {
    padding: '10px 20px',
    fontWeight: fontWeight.normal
  }),
  headerContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '13px 20px',
    borderBottom: `1px solid ${colors.border}`,
    alignItems: 'center'
  }),
  header: css(texts.heading, {
    textTransform: 'uppercase'
  }),
  description: css(texts.subheading, {
    padding: '10px 20px'
  }),
  file: css({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }),
  fileIcon: css({
    marginRight: '10px'
  }),
  fileName: css({
    display: 'flex',
    overflowWrap: 'anywhere'
  }),
  field: css({
    ' div': {
      flex: 'auto',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }),
  uploadFileBox: css({
    borderTop: `1px solid ${colors.border}`,
    padding: '40px'
  })
}

class EditableUploadFileBox extends React.Component {
  onRemoveFile (idField, idFile) {
    const { onRemove } = this.props
    onRemove({ idField, idFile })
  }

  render () {
    const { disabled, idOrg, idApp, uploadDetails, readonly, toggleUploadAppDetailsAttachments, loading, uploadViaPopup, onUpload, supportMultiOnUploadCallback } = this.props
    const onUploadFile = () => toggleUploadAppDetailsAttachments(true, true, idApp, uploadDetails.idField)
    const uploadFile = <Button type={ButtonType.compact} icon='Upload' disabled={disabled} onClick={onUploadFile} label='Upload' />
    const headerComponent = (
      <div {...CSS.headerContainer}>
        <div {...CSS.header}>{uploadDetails.header || uploadDetails.name || 'Files'}</div>
        {uploadViaPopup && uploadFile}
      </div>
    )
    const descriptionComponent = uploadDetails.description && <div {...CSS.description}>{uploadDetails.description}</div>

    const fileFields = uploadDetails.values && uploadDetails.values.map(file => {
      const onRemoveFile = () => this.onRemoveFile(uploadDetails.idField, file.id)
      return (
        <EditableField
          readonly={readonly}
          disabled={disabled}
          key={file.id || file.idUpload}
          label={<div {...CSS.fileName}><Link href={`${config.apiBaseUrl}/api/uploads/${idOrg}/${file.idUpload}`} target='_blank' rel='noopener noreferrer'>{file.name}</Link></div>}
          value={''}
          ignoreEditMode
          onRemove={onRemoveFile}
          showRemoveButton
          emptyValue=''
          overrideStyle={CSS.field}
        />
      )
    })
    return (
      <div {...CSS.uploadFileContainer}>
        <Placeholder loading={loading} rows={3} style={{ maxWidth: '95%', margin: '10px', padding: '20px' }}>
          {headerComponent}
          {descriptionComponent}
          <div {...CSS.filesContainer}>
            {isEmpty(fileFields) && <div {...CSS.noFiles}>No files uploaded yet</div>}
            {fileFields}
            {!uploadViaPopup && <UploadFileDropzone idApp={idApp} disabled={disabled} overrideStyle={CSS.uploadFileBox} onPostUpload={onUpload} supportMultiOnUploadCallback={supportMultiOnUploadCallback} />}
          </div>
        </Placeholder>
      </div>
    )
  }
}

EditableUploadFileBox.propTypes = {
  uploadDetails: PropTypes.shape({
    idField: PropTypes.number.isRequired,
    header: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      idUpload: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }))
  }),
  uploadViaPopup: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  idOrg: PropTypes.number,
  idApp: PropTypes.number,
  onRemove: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  supportMultiOnUploadCallback: PropTypes.bool
}

EditableUploadFileBox.defaultProps = {
  uploadViaPopup: true,
  disabled: false,
  readonly: false,
  loading: false,
  supportMultiOnUploadCallback: false
}

export default EditableUploadFileBox
