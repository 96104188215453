import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  isOrgLoading,
  getCurrency,
  getCompanyName,
  getOrgEmailAlias,
  getGeoLocationSettings,
  getBenchmarkSettings,
  getIsBlockThirdPartyAppsEnabled
} from '@selectors/org'
import { updateOrg } from '@shared/actions'
import { getDisplayCurrencyOptions } from '@root/selectors/ui'

const mapStateToProps = (state, ownProps) => {
  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const loading = isOrgLoading(state)
  const defaultCurrency = getCurrency(state)
  const companyName = getCompanyName(state)
  const emailAlias = getOrgEmailAlias(state)
  const isGeoLocationAllowed = getGeoLocationSettings(state)
  const isBenchmarkAllowed = getBenchmarkSettings(state)
  const displayCurrencyOptions = getDisplayCurrencyOptions(state).map(currency => ({ label: currency, value: currency }))
  const isBlockThirdPartyAppsEnabled = getIsBlockThirdPartyAppsEnabled(state)

  return {
    idOrg,
    loading,
    defaultCurrency,
    companyName,
    emailAlias,
    isGeoLocationAllowed,
    isBenchmarkAllowed,
    displayCurrencyOptions,
    isBlockThirdPartyAppsEnabled
  }
}

const mapDispatchToProps = {
  updateOrg
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
