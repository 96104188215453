import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { Tooltip, Icon } from '@toriihq/design-system'
import ConfiguredMessage from '../offboarding/configured/configuredMessage'
import AppOffboardingActionButtons from '../offboarding/configured/appOffobardingActionButtons'
import { fontWeight } from '@shared/style/sizes'
import Placeholder from '../placeholder'
import { NO_APP_ACCOUNT_ID } from '../../constants'

const CSS = {
  main: css(texts.body, {
    position: 'relative',
    flexDirection: 'left',
    alignItems: 'center',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px',
    background: colors.white,
    color: colors.grey1
  }),
  body: css({
    display: 'flex',
    verticalAlign: 'middle',
    lineHeight: '40px',
    width: '100%',
    alignItems: 'center',
    '>div': {
      lineHeight: '40px',
      verticalAlign: 'middle'
    }
  }),
  header: css(texts.subheading, {
    color: colors.black,
    fontSize: '14px',
    lineHeight: '22px',
    marginRight: '10px'
  }),
  headerWrapper: css({
    width: '100%',
    padding: '20px',
    borderBottom: 'solid 1px',
    borderColor: colors.lightBlue2,
    display: 'flex'
  }),
  bodyContainer: css({
    display: 'flex',
    margin: '0 15px',
    alignItems: 'center'
  }),
  offboardingStatusContainer: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  }),
  buttons: css({
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  }),
  addButton: css(texts.subheading, {
    display: 'flex',
    color: colors.grey1,
    fontWeight: fontWeight.normal,
    ' span': {
      alignSelf: 'center'
    }
  }),
  missing: css({
    'div &': {
      opacity: 0.5,
      background: 'inherit',
      height: '30px',
      width: '30px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      border: `1px dashed ${colors.blue}`,
      borderRadius: '50px',
      fontWeight: '100',
      fontSize: '20px',
      color: colors.blue,
      transition: 'opacity .2s',
      ':hover': {
        opacity: 1
      }
    }
  }),
  missingText: css({
    marginLeft: '17px'
  }),
  appsContainer: css({
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  }),
  appAndAccountName: css({
    display: 'flex',
    whiteSpace: 'pre',
    height: '44px',
    alignItems: 'center'
  })
}

const BOX_BODY_CLASS = 'appOffboardingStatusBox'

class AppOffboardingStatusBox extends React.Component {
  componentDidMount () {
    const { idOrg, idApp, getOffboardingApp, getWorkflowsTriggersConfig, getWorkflowsActionsConfig } = this.props
    getOffboardingApp({ idOrg, idApp })
    getWorkflowsTriggersConfig(idOrg, idApp)
    getWorkflowsActionsConfig(idOrg, idApp)
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idApp, getOffboardingApp } = this.props

    if (prevProps.idApp !== idApp || prevProps.idOrg !== idOrg) {
      getOffboardingApp({ idOrg, idApp })
    }
  }

  offboardingStatusRenderer = () => {
    const { appOffboardingConfig, loading } = this.props

    if (loading) {
      return null
    }

    return <div {...CSS.appsContainer}>
      {
        appOffboardingConfig.map(appAccount => {
          const isConfigured = appAccount.actions && appAccount.actions.length > 0
          const isNeedsAttention = Boolean(appAccount.pendingOffboardingAmount)
          const hoverClass = `${BOX_BODY_CLASS}-${appAccount.idAppAccount}`
          const name = `${appAccount.appAccountName || (appAccount.idAppAccount === NO_APP_ACCOUNT_ID ? 'Unmanaged Account' : '')}`

          return <div key={appAccount.idAppAccount} {...CSS.offboardingStatusContainer} className={hoverClass}>
            <div {...CSS.appAndAccountName}>
              <b>{name}</b>
              <div> -</div>
              {isConfigured ? <ConfiguredMessage actions={appAccount.actions} /> : 'Not configured'}
            </div>
            <div {...CSS.buttons}>
              {<AppOffboardingActionButtons app={appAccount} hoverClass={hoverClass} hideDeleteButton={!isConfigured} showAlertBox={isConfigured || isNeedsAttention} />}
            </div>
          </div>
        })
      }
    </div>
  }

  render () {
    const { loading } = this.props

    return (
      <div {...CSS.main}>
        <div {...CSS.headerWrapper}>
          <header {...CSS.header}>OFFBOARDING ACTION</header>
          <Tooltip
            placement='top'
            label='The Offboarding action will be executed when "Start offboarding" is triggered on a user of this application'>
            <Icon name='Info' />
          </Tooltip>
        </div>

        <div {...CSS.bodyContainer}>
          <div {...CSS.body}>
            <Placeholder loading={loading} type='text' rows={1} style={{ paddingTop: '20px', height: '60px', width: '100%' }}>
              {this.offboardingStatusRenderer()}
            </Placeholder>
          </div>
        </div>
      </div>
    )
  }
}

AppOffboardingStatusBox.propTypes = {
  idApp: PropTypes.number.isRequired
}

export default AppOffboardingStatusBox
