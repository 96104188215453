import React, { ReactElement } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { createWorkflow, updateWorkflow } from '@shared/actions/workflows'
import { WORKFLOW_TRIGGER_TYPE, WORKFLOW_TYPE } from '@shared/types'
import { getTriggersByType } from '@selectors/workflows'
import { useHistory } from 'react-router-dom'
import { TriggerConfiguration, TriggersByType } from '@selectors/workflows/types'
import { getNewWorkflowTriggerConfiguration, getUpdatedWorkflowInfo } from '@pages/createWorkflow/createWorkflowButton/utils'
import { SCOPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import EnableFor from '@components/enableFor'
import { analytics } from '@shared/services/workflows/analytics'

interface Props {
  idOrg: number
  workflowType?: WORKFLOW_TYPE | null
  triggerType: string | null
  triggerTypeIdApp: number | null
  isHidden: boolean
  label?: string | null
}

const CreateNewWorkflowButton = ({ idOrg, workflowType, triggerType, triggerTypeIdApp, isHidden, label }: Props): ReactElement => {
  const history = useHistory()
  const dispatch = useDispatch()
  const triggerTypes: TriggersByType = useSelector(getTriggersByType)
  const trigger: TriggerConfiguration = triggerType && triggerTypes[triggerType]

  const createNewWorkflow = async (): Promise<void> => {
    const triggerConfiguration = getNewWorkflowTriggerConfiguration(trigger)

    const { workflow: newWorkflow } = await dispatch(createWorkflow({
      idOrg,
      type: workflowType ?? WORKFLOW_TYPE.regular,
      isHiddenForCustomers: isHidden,
      triggerType: triggerType! as WORKFLOW_TRIGGER_TYPE,
      triggerConfiguration,
      triggerTypeIdApp: triggerTypeIdApp!
    }))
    analytics.create.created({ triggerType: triggerType! })
    const { idNewNode, workflow: updatedWorkflow } = getUpdatedWorkflowInfo(newWorkflow, trigger, triggerTypeIdApp!)
    await dispatch(
      updateWorkflow({
        idOrg,
        idWorkflow: newWorkflow.id,
        workflow: updatedWorkflow,
        shouldAutoTag: true
      })
    )
    const path = triggerType === WORKFLOW_TRIGGER_TYPES.REQUEST_NEW_APP ? `/team/${idOrg}/appCatalog/newAppPolicy/${newWorkflow.id}` : `/team/${idOrg}/workflow/${newWorkflow.id}`
    history.push(path, { idNewNode })
  }

  return (
    <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
      <Button
        disabled={!triggerType || !triggerTypeIdApp}
        onClick={createNewWorkflow}
        label={label ?? 'Next'}
        type={ButtonType.primary}
        size={ButtonSize.small}
      />
    </EnableFor>
  )
}

export default CreateNewWorkflowButton
