import View from './view'
import { connect } from 'react-redux'
import { getIsSmallScreen } from '@selectors/ui'
import { withRouter } from 'react-router-dom'
import { getWorkflowExecutions, getWorkflowsTriggersConfig } from '@shared/actions'
import { getWorkflowExecutionsById, getWorkflowExecutionsStateById } from '@lenses/workflows'
import {
  isLoadingWorkflows,
  getTriggersByType,
  getAppCatalogPoliciesByIdSelector,
  getWorkflowsById,
  isLoadingAppCatalogPoliciesSelector,
  getWorkflowExecutions as getWorkflowExecutionsSelector
} from '@selectors/workflows'
import range from 'lodash/range'
import qs from 'query-string'
import get from 'lodash/get'
import { EMPTY_OBJECT, TRIGGER_TYPE_TO_ITEM, WORKFLOW_TYPES } from '@root/constants'
import pluralize from 'pluralize'
import { getWorkflow } from '@shared/actions/workflows'

const REFRESH_ALERT_BOX_THRESHOLD = 10

const mapStateToProps = (state, ownProps) => {
  const { idWorkflow, idOrg, isAppCatalogPoliciesView } = ownProps
  const workflow = (isAppCatalogPoliciesView ? getAppCatalogPoliciesByIdSelector(state)[idWorkflow] : getWorkflowsById(state)[idWorkflow]) || EMPTY_OBJECT
  const { triggerType, type } = workflow
  const entityType = (TRIGGER_TYPE_TO_ITEM[triggerType] || '').toLowerCase()
  const pluralizedEntities = pluralize(entityType)

  const executions = getWorkflowExecutionsById(state, idWorkflow)
  const triggerTypes = getTriggersByType(state)
  const { loadingMore, total } = getWorkflowExecutionsStateById(state, idWorkflow)
  const loading = isAppCatalogPoliciesView ? isLoadingAppCatalogPoliciesSelector(state) : isLoadingWorkflows(state)
  const { idTriggerUsersToParents } = getWorkflowExecutionsSelector(state)

  const entitiesAmount = get(qs.parse(window.location.search), ['entitiesAmount'])
  const isValidNumber = (value) => /^[0-9]+$/.test(value)
  const entitiesAmountValue = isValidNumber(entitiesAmount) ? Number(entitiesAmount) : 0
  const showRefreshAlertBox = type && entitiesAmount > REFRESH_ALERT_BOX_THRESHOLD
  const isManualActionRun = type === WORKFLOW_TYPES.manual

  return {
    idWorkflow,
    idOrg,
    entities: pluralizedEntities,
    triggerTypes,
    executions: loading ? range(12).map(() => ({})) : executions,
    loading,
    isSmallScreen: getIsSmallScreen(state),
    loadingMore,
    total,
    entitiesAmount: entitiesAmountValue,
    entityType,
    triggerType,
    isManualActionRun,
    showRefreshAlertBox,
    idTriggerUsersToParents: idTriggerUsersToParents
  }
}

const mapDispatchToProps = {
  getWorkflow,
  getWorkflowExecutions,
  getWorkflowsTriggersConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
