import React from 'react'
import PropTypes from 'prop-types'
import ToriiSelect from '../select'
import Placeholder from '../placeholder'
import EnableFor from '../enableFor'
import Analytics from '../../helpers/analytics'
import { Icon, SelectComponents, Spacer } from '@toriihq/design-system'

class SelectState extends React.Component {
  state = { selectedValue: this.props.selectedValue }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.selectedValue !== this.props.selectedValue) {
      this.setState({ selectedValue: this.props.selectedValue })
    }
  }

  onClickHideApp = () => {
    const { idApp, name, toggleHideAppPopup } = this.props
    Analytics.track('Clicked to open popup: hide application', {
      idApp,
      name
    })
    if (idApp) {
      toggleHideAppPopup({ open: true, idApp, name })
    }
  }

  setValue = (selectedValue) => {
    const { idOrg, idApp, idField, setAppsDetails, onChange } = this.props
    this.setState({ selectedValue })
    if (onChange) {
      return onChange(selectedValue)
    }
    if (idApp && idOrg) {
      setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField, values: [selectedValue] }] })
    }
  }

  trackOnStateChange = (selectedValue) => {
    const { selectedValue: oldSelectedValue, pageName, name: appName } = this.props
    const selectOptions = this.getSelectOptions()
    const oldSelectedOption = selectOptions.find(option => option.value === oldSelectedValue)

    Analytics.track('Update app-state', {
      'Page Name': pageName,
      'Old State': oldSelectedOption?.label ?? oldSelectedValue,
      'New State': selectedValue?.label,
      'App Name': appName
    })
  }

  render () {
    const { selectedValue } = this.state
    const { idApp, overrideStyle, isLoading, allowedScopes = [], readOnly, className, allowHideApp, options } = this.props

    return <div {...overrideStyle} className={className}>
      <Placeholder loading={isLoading} type='rect' style={{ width: '114px', height: '20px' }}>
        <EnableFor scopes={allowedScopes}>
          <ToriiSelect
            placeholder='Select state...'
            options={options}
            components={{ Option: SelectComponents.StateOption, SingleValue: SelectComponents.StateValue }}
            onChange={this.setValue}
            value={selectedValue}
            clearable={false}
            searchable={false}
            key={`state_${idApp}`}
            name={`state_${idApp}`}
            disabled={readOnly}
            customMenuOption={allowHideApp ? <div style={{ display: 'flex', alignItems: 'center' }} onClick={this.onClickHideApp}><Spacer right='space-050'><Icon name='EyeOff' /></Spacer>Hide app</div> : null}
          />
        </EnableFor>
      </Placeholder>
    </div>
  }
}

SelectState.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
    isDeleted: PropTypes.number
  })),
  selectedValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setAppsDetails: PropTypes.func.isRequired,
  idApp: PropTypes.number,
  idField: PropTypes.number,
  idOrg: PropTypes.number,
  overrideStyle: PropTypes.object,
  isLoading: PropTypes.bool,
  allowHideApp: PropTypes.bool,
  useDefaultSelectCSS: PropTypes.bool,
  pageName: PropTypes.string
}

SelectState.defaultProps = {
  allowHideApp: true,
  options: [],
  useDefaultSelectCSS: false
}

export default SelectState
