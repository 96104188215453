import { createStore, applyMiddleware } from 'redux'
import rootReducer from '@root/reducers'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { composeWithDevTools } from 'redux-devtools-extension'
import analyticsMiddleWare from './middlewares/analytics'
import storageMiddleWare from './middlewares/storage'
import accessControlMiddleWare from './middlewares/accessControl'
import { extendWithUpdateArrayByKey } from '@helpers/immutabilityExtensions'

extendWithUpdateArrayByKey()

const isDevEnv = process.env.NODE_ENV === 'development'
const middleware = [thunk, accessControlMiddleWare, analyticsMiddleWare, storageMiddleWare]
if (isDevEnv) {
  const logger = createLogger({ collapsed: true })
  middleware.push(logger)
}

const appliedMiddleware = composeWithDevTools(applyMiddleware(...middleware))

export default createStore(rootReducer, appliedMiddleware)
