import { css } from 'glamor'
import colors from './colors'
import { fontWeight } from '@shared/style/sizes'

const texts = {
  headers: {
    xLargeBold: css({
      fontSize: '40px',
      fontWeight: fontWeight.semiBold
    }),
    xLarge: css({
      fontSize: '40px',
      fontWeight: fontWeight.normal
    }),
    large: css({
      fontSize: '25px',
      fontWeight: fontWeight.semiBold
    }),
    medium: css({
      fontSize: '20px',
      fontWeight: fontWeight.semiBold
    }),
    regular: css({
      fontSize: '18px',
      fontWeight: fontWeight.semiBold
    }),
    small: css({
      fontSize: '16px',
      fontWeight: fontWeight.semiBold
    }),
    xSmall: css({
      fontSize: '12px',
      fontWeight: fontWeight.semiBold
    })
  },
  subTitle: css({
    fontSize: '20px',
    lineHeight: '32px',
    fontFamily: 'Nunito',
    fontWeight: fontWeight.semiBold
  }),
  heading: css({
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: fontWeight.bold,
    color: colors.black
  }),
  heading2: css({
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: fontWeight.bold,
    color: colors.grey2
  }),
  subheading: css({
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: fontWeight.semiBold,
    color: colors.grey2
  }),
  body: css({
    color: colors.text,
    fontSize: '13px',
    lineHeight: '18px',
    fontWeight: fontWeight.normal,
    ' strong': {
      fontWeight: fontWeight.bold
    }
  }),
  caption: css({
    fontSize: '10px',
    fontWeight: fontWeight.normal
  }),
  link: css({
    color: `${colors.blue} !important`,
    fontWeight: 'normal !important',
    cursor: 'pointer',
    transition: 'color .2s',
    '&:active, &:hover, &:focus': {
      color: `${colors.lightBlue1} !important`
    }
  })
}

export default texts
