import View from './view'
import { connect } from 'react-redux'
import { toggleConfigureAppChargeback } from '../../../shared/actions'
import { getCurrentOrg } from '../../../selectors/me'
import { deprecatedGetAppsByIds } from '../../../selectors/apps'
import { EMPTY_OBJECT } from '../../../constants'

const mapStateToProps = state => {
  const { configureAppChargebackPopup } = state.ui
  const { isOpen, idApp, callback } = configureAppChargebackPopup
  const { id: idOrg } = getCurrentOrg(state)

  const app = (idApp && deprecatedGetAppsByIds(state)[idApp]) || EMPTY_OBJECT

  return {
    isOpen,
    idOrg,
    app,
    callback
  }
}

const mapDispatchToProps = {
  toggleConfigureAppChargeback
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,
  cancel () {
    dispatchProps.toggleConfigureAppChargeback({ isConfigureAppChargebackOpen: false })
  },
  close () {
    dispatchProps.toggleConfigureAppChargeback({ isConfigureAppChargebackOpen: false })
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
