import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { oneLiner } from '../../shared/style/mixins'
import { getPermissionDescription } from '../../shared/thirdPartyPermissions'
import { Tooltip, ExpandableSection, AppIcon } from '@toriihq/design-system'
import { permissionsLegend } from '../riskIcon'
import get from 'lodash/get'
import Analytics from '@helpers/analytics'
import RelativeTeamLink from '@components/relativeTeamLink'

const CSS = {
  container: css({
    minHeight: '82px',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.border}`,
    borderRadius: '4px'
  }),
  onePermissionBox: css({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minHeight: '82px',
    padding: '20px 21px 20px 30px'
  }),
  icon: css({
    width: '24px',
    height: '24px',
    marginRight: '30px'
  }),
  header: css(
    texts.heading, {
      marginBottom: '10px'
    }
  ),
  permissionsText: css(
    texts.body
  )
}

class AppPermissionsBox extends React.Component {
  componentDidMount () {
    const { idOrg, idApp, getAppsPermissions } = this.props
    if (idOrg) {
      getAppsPermissions({ idOrg, idApp })
    }
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idApp, getAppsPermissions } = this.props
    if (idOrg && prevProps.idOrg !== idOrg) {
      getAppsPermissions({ idOrg, idApp })
    }
  }

  renderPermissionList (permissions, idSource) {
    return (
      <div>
        <div {...CSS.header}>PERMISSIONS</div>
        <div {...CSS.permissionsText}>
          {permissions.map((permission) => {
            const description = getPermissionDescription(idSource, permission)
            return (
              <div key={permission}>
                <Tooltip
                  label={<div>{description}</div>}
                >
                  <div {...oneLiner}>{description}</div>
                </Tooltip>
              </div>
            )
          }
          )}
        </div>
      </div>
    )
  }

  onToggle = (isOpen, component) => {
    if (isOpen) {
      Analytics.track('Clicked to view insight', {
        'Component': `Permissions list for ${component}`
      })
    }
  }

  render () {
    const { sourcesWithRiskLevels, app } = this.props
    return (
      sourcesWithRiskLevels.map((source) => (
        source.app.permissions.length ? <ExpandableSection
          key={source.source.name}
          avatar={(
            <RelativeTeamLink to={`/app/${source.source.idApp}`}>
              <AppIcon appName={source.source.name} appImageUrl={get(source.source, 'icon.color')} hoverable />
            </RelativeTeamLink>
          )}
          title={`${app.name}'s ${source.source.name} permissions risk level is ${permissionsLegend[source.app.riskLevel].text}`}
          onToggle={isOpen => this.onToggle(isOpen, source.source.name)}
        >
          {this.renderPermissionList(source.app.permissions, source.source.id)}
        </ExpandableSection> : null
      ))
    )
  }
}

AppPermissionsBox.propTypes = {
  app: PropTypes.object.isRequired,
  sourcesWithRiskLevels: PropTypes.array.isRequired
}

export default AppPermissionsBox
