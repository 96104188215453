import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  toggleConfigureAppForOffboarding,
  deleteWorkflow,
  getOffboardingApp,
  getWorkflowsTriggersConfig
} from '@shared/actions'
import { getActionsConfigByType, getActionsUserOptions, getTriggersByType } from '@selectors/workflows'
import { getOffboardingAppsResources } from '@selectors/offboarding'

const mapStateToProps = (state, ownProps) => {
  const loading = ownProps.loading
  const forceLoading = ownProps.forceLoading
  const triggerByType = getTriggersByType(state)
  const actionsByType = getActionsConfigByType(state)
  const usersById = getOffboardingAppsResources(state).users
  const specialUsersOfActions = getActionsUserOptions(state) || []

  return {
    loading: Boolean(forceLoading || (loading && ownProps.apps.length === 0)),
    triggerByType,
    actionsByType,
    usersById,
    specialUsersOfActions
  }
}

const mapDispatchToProps = {
  toggleConfigureAppForOffboarding,
  deleteWorkflow,
  getOffboardingApp,
  getWorkflowsTriggersConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
