import View from './view'
import { connect } from 'react-redux'
import { isAppsLoading } from '../../selectors/apps'
import { getExpensesSummary } from '../../selectors/expenses'
import { toggleUploadExpenses, toggleAllExpenseCategories } from '../../shared/actions'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
  const loading = isAppsLoading(state)
  const { month, year } = ownProps
  const expensesSummary = getExpensesSummary(state)
  const hasData = (expensesSummary.length > 0)
  const expenses = expensesSummary.find(item => (item.year === year && item.month === month)) || expensesSummary[0]

  return {
    loading: loading && !hasData,
    expenses: expenses || {},
    date: moment(`01/${month}/${year}`, 'DD/MM/YYYY')
  }
}

const mapDispatchToProps = {
  toggleUploadExpenses,
  toggleAllExpenseCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
