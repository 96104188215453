import { PopupRendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState } from '../types'
import ToriiPopup from '@root/components/popups/ToriiPopupV2'
import { Caption1, RadioButton, Spacer, Subtitle2 } from '@toriihq/design-system'
import { Option } from './styles'
import { IMPORT_METHOD } from './types'
import Analytics from '@components/popups/importUsersPopup/analytics'

const ImportMethod = ({
  onCancel, navigateToNextStep, navigateToBackStep, sharedState, sharedProps, setState
}: PopupRendererProps<SharedState, SharedProps>): JSX.Element => {
  const stepNameForAnalytics = 'Import mode'

  const onImportMethodChange = (importMethod: IMPORT_METHOD) => {
    const selectionToText = importMethod === IMPORT_METHOD.ONLY_ADD_UPDATE ? 'Keep users' : 'Remove users'
    Analytics.onRemoveMissingUsersRadioButtonAnalytics(selectionToText)
    setState({ ...sharedState, importMethod })
  }

  const onCancelClick = () => {
    Analytics.onCloseImportPopupAnalytics(stepNameForAnalytics, 'Cancel')
    onCancel()
  }

  const onBackClick = () => {
    Analytics.onBackActionAnalytics(stepNameForAnalytics)
    navigateToBackStep()
  }

  const popupHeader = sharedState.customParser ? 'Manually import users' : 'Import users'

  return (
    <>
      <ToriiPopup.Header header={popupHeader} />
      <ToriiPopup.Content>
        <Spacer bottom='space-200'>
          <Subtitle2>Choose how to import your file</Subtitle2>
        </Spacer>
        <Option>
          <RadioButton
            label='Uploaded file contains the entire user list'
            checked={sharedState.importMethod === IMPORT_METHOD.ADD_UPDATE_REMOVE}
            onChange={() => onImportMethodChange(IMPORT_METHOD.ADD_UPDATE_REMOVE)}
          />
          <Spacer left='space-300'>
            <Caption1 color='secondary'>Torii will mark users not on the file as removed from the app.</Caption1>
          </Spacer>
        </Option>
        <Spacer top='space-150'>
          <Option>
            <RadioButton
              label='Uploaded file only contains user additions or updates'
              checked={sharedState.importMethod === IMPORT_METHOD.ONLY_ADD_UPDATE}
              onChange={() => onImportMethodChange(IMPORT_METHOD.ONLY_ADD_UPDATE)}
            />
            <Spacer left='space-300'>
              <Caption1 color='secondary'>Torii will keep users not on the file intact.</Caption1>
            </Spacer>
          </Option>
        </Spacer>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        cancelButtonAction={onCancelClick}
        cancelButtonText='Cancel'
        mainButtonText='Next'
        mainButtonAction={navigateToNextStep}
        showBackButton
        backButtonAction={onBackClick}
      />
    </>
  )
}

export default ImportMethod
