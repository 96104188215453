import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  getNonDeletedWorkflows as getNonDeletedWorkflowsSelector,
  getWorkflowsResources,
  isLoadingWorkflows
} from '@selectors/workflows'
import { getWorkflowsTriggersConfig } from '@shared/actions'
import { isToriiAdmin } from '@selectors/me'
import { getShowHiddenWorkflows } from '@selectors/ui'
import { getWorkflows } from '@shared/actions/workflows'

const mapStateToProps = (state, ownProps) => {
  const isHidden = getShowHiddenWorkflows(state) && isToriiAdmin(state)

  const idOrg = parseInt(ownProps.match.params.idOrg, 10)
  const workflows = getNonDeletedWorkflowsSelector(state)
  const loading = isLoadingWorkflows(state)
  const title = workflows.length > 0 ? `Workflows (${workflows.length})` : 'Workflows'
  const { users: usersMap = {} } = getWorkflowsResources(state)

  return {
    idOrg,
    workflows,
    title,
    loading,
    isHidden,
    usersMap
  }
}

const mapDispatchToProps = {
  getWorkflows,
  getWorkflowsTriggersConfig
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
