import React, { Fragment, useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ButtonType, ButtonSize, AppIcon, Spacer, Tooltip } from '@toriihq/design-system'
import RelativeTeamLink from '@components/relativeTeamLink'
import * as Style from './style'
import Table from '@components/table'
import { TABLES } from '@root/constants'
import pluralize from 'pluralize'
import uniq from 'lodash/uniq'
import { customJoin } from '@lenses/utils'
import PropTypes from 'prop-types'
import Analytics from '@helpers/analytics'
import { getComparisonRecommendations } from '@shared/actions'
import OverflowTooltip from '@components/overflowTooltip'
import { getComparisonRecommendations as getComparisonRecommendationsSelector } from '@selectors/apps'
import BlueEyes from '@media/blue-eyes.svg'

const ComparisonRecommendationsTable = ({ idOrg, onAppsChange }) => {
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const comparisonRecommendations = useSelector(getComparisonRecommendationsSelector)
  const { comparisons, resources, isLoaded } = comparisonRecommendations
  const { apps } = resources

  useEffect(() => {
    const getRecommendationsData = async ({ idOrg }) => {
      setIsLoading(true)
      await dispatch(getComparisonRecommendations({ idOrg }))
      setIsLoading(false)
    }

    if (!idOrg || isLoaded) {
      return
    }

    getRecommendationsData({ idOrg })
  }, [dispatch, idOrg, isLoaded])

  const onCreateCustomComparisonClick = () => {
    onAppsChange([], false)
    Analytics.track('Click on Create custom comparison button / Comparison dashboard / Compare tab / Applications')
  }

  const noRecommendationsState = <div {...Style.NoRecommendationsState}>
    <img alt='No recommandetions' src={BlueEyes} />
    <div {...Style.EmptyStateText}>
      <div>We did not yet find similar apps that are interesting to compare.</div>
      <div>Create a custom comparison and select any apps you want to explore side by side.</div>
    </div>
  </div>

  const columns = useMemo(() => {
    if (!apps) {
      return []
    }

    const getAppsCategories = (idApps) => uniq(idApps.map(idApp => apps[idApp] && apps[idApp].category))
    const getAppsCategoriesString = (idApps) => customJoin(getAppsCategories(idApps))
    const getAppsString = (idApps) => customJoin(idApps.map(idApp => apps[idApp] && apps[idApp].name))

    return [
      {
        Header: 'Similar apps',
        id: 'similarApps',
        minWidth: 350,
        accessor: ({ idApps }) => idApps && idApps.every(idApp => apps[idApp]) ? getAppsString(idApps) : '-',
        Cell: ({ row: { idApps } }) => (<Style.Wrapper>
          {idApps.map((idApp, i) => {
            const app = apps[idApp]
            return <Fragment key={app.name} >
              <RelativeTeamLink to={`/app/${idApp}`}>
                <AppIcon appImageUrl={app.imageUrl} appName={app.name} />
              </RelativeTeamLink>
              <Spacer left={'space-150'}>
                <Style.Name>{app.name}</Style.Name>
              </Spacer>
              {i < idApps.length - 1 &&
              <Spacer left={'space-400'} right={'space-400'}>
                <Style.Separator>+</Style.Separator>
              </Spacer>
              }
            </Fragment>
          })}
        </Style.Wrapper>)
      },
      {
        Header: 'Category',
        accessor: 'idApps',
        width: 170,
        Cell: ({ value: idApps }) => {
          const categories = getAppsCategoriesString(idApps)
          return <OverflowTooltip label={categories}>{categories}</OverflowTooltip>
        },
        sortMethod: (a, b) => {
          const appsA = getAppsCategoriesString(a)
          const appsB = getAppsCategoriesString(b)
          return appsA.localeCompare(appsB)
        }
      },
      {
        Header: (
          <Tooltip label='Users discovered in all similar apps'>
            Overlapping users
          </Tooltip>
        ),
        accessor: 'overlappingUsers',
        width: 170,
        ...Table.numericFieldProps
      },
      {
        Header: '',
        sortable: false,
        resizable: false,
        id: 'compareAction',
        minWidth: 120,
        Cell: ({ row: { idApps } }) => {
          const categories = getAppsCategories(idApps)

          return <Button size={ButtonSize.small} onClick={async () => {
            Analytics.track('Click on Compare button / Comparison dashboard / Compare tab / Applications', {
              Category: categories.length === 1 ? categories[0] : 'mixed'
            })
            onAppsChange(idApps)
          }} label='Compare' />
        },
        style: {
          display: 'flex',
          justifyContent: 'flex-end'
        }
      },
      {
        accessor: 'idApps',
        show: false
      }
    ]
  }, [apps, onAppsChange])

  return <Table
    tableKey={TABLES.comparisonRecommendationsTable.key}
    emptyStateMessage={noRecommendationsState}
    data={comparisons}
    header={comparisons.length !== 0 ? `We found ${comparisons.length} interesting app ${pluralize('comparison', comparisons.length)} for you!` : null}
    columns={columns}
    loading={!isLoaded || isLoading}
    customButton={{
      button: <Button size={ButtonSize.small} type={ButtonType.secondary} onClick={onCreateCustomComparisonClick} label='Create custom comparison' />
    }}
    overrideTrStyle={{ height: '75px' }}
    clientPaging
    pageSize={50}
  />
}

ComparisonRecommendationsTable.propTypes = {
  onAppsChange: PropTypes.func.isRequired,
  idOrg: PropTypes.number
}

export default ComparisonRecommendationsTable
