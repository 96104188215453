import { fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import { css } from 'glamor'

export const CSS = {
  emailSetupContainer: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  emailSetupPreview: css({
    display: 'flex',
    justifyContent: 'flex-end',
    fontWeight: fontWeight.normal,
    marginBottom: '15px'
  }),
  contractOptionContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  }),
  valueContainer: css({
    display: 'flex'
  }),
  invalidValueMessage: css({
    color: colors.error,
    marginLeft: '10px'
  })
}
