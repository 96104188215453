import { objectToQueryString, sortStringFromArray } from '@shared/utils'
import config from '@root/config'
import { activeAppsFilter, currentUsersBaseFilters, isParentUserFilter } from '@shared/actions'

export const exportExtensionStatusCsv = ({ idOrg, sort = [{ id: 'lastReportedTime', desc: true }] }) => {
  const sortParam = 'sort=' + sortStringFromArray(sort)
  const fieldsParam = `fields=fullName,email,lifecycleStatus,lastReportedTime,browser`
  const pageFilters = objectToQueryString(currentUsersBaseFilters)
  const filtersParam = `filters=${encodeURIComponent(JSON.stringify([activeAppsFilter, isParentUserFilter]))}`

  const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${fieldsParam}&${pageFilters}&${filtersParam}`
  const newWindow = window.open(url, '_blank')
  newWindow.opener = null
}
